import { Button, Fade, Tooltip } from '@material-ui/core'
import React, { useState, useRef, useEffect, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import CustomInput from '../../../../product/components/atoms/Inputs/CustomInput'
import './ProductCard.css'
import FavouriteCheckbox from '../../../../product/components/atoms/FavoriteCheckbox'
import PrimaryButton from '../../../../product/components/atoms/PrimaryButton'
import SecondaryButton from '../../../../product/components/atoms/SecondaryButton'
import Timer from '../../../../product/common/timer'
import ReverseBidding from '../../molecules/Reverse/Bidding/BiddingItem'
import Buynow from '../../molecules/Buynow/BuynowItem'
import { dateFormatFront } from '../../../common/components'
import { handleRedirectInternal } from '../../../common/components'
import CommonContext from '../../../../product/context/common/commonContext'
import CustomCommonContext from '../../../../custom/context/common/commonContext'
import { google, outlook, office365, yahoo, ics } from 'calendar-link'
import AuthContext from '../../../../product/context/auth/authContext'
import { currencyFormat } from '../../../../product/common/components'
import UpdatePhoneNumber from './../../organisms/UpdatePhoneNumber/index'
import alertContext from '../../../../product/context/alert/alertContext'
import AvatarImage from '../../atoms/AvatarImage'

const Grid = (props) => {
    const commonContext = useContext(CommonContext)
    const authContext = useContext(AuthContext)
    const customCommonContext = useContext(CustomCommonContext)
    const { t } = useTranslation()

    const { setBidHistoryValue } = commonContext
    const { user, isAuthenticated } = authContext
    // console.log(user, 'user', isAuthenticated, 'is authenticated')
    const { configVariables, allLocations, allCondition } = customCommonContext
    const { setAlert } = useContext(alertContext)
    const [event, setEvent] = useState(null)

    const [product, setProduct] = useState()

    const history = useHistory()
    const [manageuser, setManageUser] = useState({
        popup: false,
        status: 'new',
        data: null,
    })
    const onMakePaymentClick = (data) => {
        if (user.phone == null) {
            toggleFullScreenUserPopup(true, 'new', null)
            setAlert('Please enter phone number', 'error')
        } else {
            handleRedirectInternal(history, `checkout/auction/${data.group_carts}`)
        }
    }
    const toggleFullScreenUserPopup = (popup, status, data) => {
        setManageUser({ popup, status, data })
    }
    useEffect(() => {
        setProduct(props.data)
        console.log('props.data', props.data)
    }, [props.data])

    useEffect(() => {
        if (product) {
            setEvent({
                title: product.title,
                description: product.short_desc,
                start: product.slot_start_time,
                end: product.slot_end_time,
                url: window.location.href,
            })
        }
    }, [product])

    const addDefaultSrc = (e) => {
        e.target.src = '/assets/images/noimage.png'
    }

    const didUserBid = (userID, bidHistory) => {
        let didBid = false
        bidHistory.forEach((element, index, array) => {
            if (element.user_id === userID) {
                didBid = true
            }
        })
        return didBid
    }

    return (
        <Fade in={true} timeout={600}>
            <div className="productCardGrid">
                {product ? (
                    <>
                        <div className="pcgImg">
                            <AvatarImage
                                data={product}
                                onClick={() =>
                                    handleRedirectInternal(history, `liveAuction/${product.id}`)
                                }
                                folder="product"
                            />

                            {isAuthenticated && (
                                <>
                                    {product.bidtopstatus === 'outbid' ? (
                                        <h4 className="productWinningStatus outbid">
                                            {'Your rank in bidders: ' + product.rank}
                                        </h4>
                                    ) : null}
                                    {product.bidtopstatus === 'winner' ? (
                                        product.rprice >= product.current_bid ? (
                                            <h4 className="productWinningStatus winning">
                                                {'Your rank in bidders: ' + product.rank}
                                            </h4>
                                        ) : (
                                            <h4 className="productWinningStatus winning">
                                                {'Your rank in bidders: ' + product.rank}
                                                <span>
                                                    <br />
                                                    But Reserve price not met!
                                                </span>
                                            </h4>
                                        )
                                    ) : null}

                                    {product.bidtopstatus === 'won' ? (
                                        <h4 className="productWinningStatus won">Won</h4>
                                    ) : null}
                                    {product.bidtopstatus === 'lost' ? (
                                        <h4 className="productWinningStatus lost">Lost</h4>
                                    ) : null}

                                    {/* {(product.bidtopstatus === 'outbid' ||
                                        product.bidtopstatus === 'winner') &&
                                        product.wprice <= product.rprice && (
                                            <>
                                                <Tooltip title="Reserve price not met">
                                                    <span className="material-icons reserveNotMet">
                                                        lock_open
                                                    </span>
                                                </Tooltip>
                                            </>
                                        )} */}
                                </>
                            )}
                        </div>

                        <h2 className="gridProdTitle" onClick={props.drawerHandler}>
                            {product.title}
                        </h2>

                        {/* <div className="gridLotDetails d-flex justify-content-start align-items-center">
                            <p
                                dangerouslySetInnerHTML={{
                                    __html: product.desc_proc,
                                }}
                            ></p>
                        </div> */}
                        {/* <div className="gridBidInfo d-flex justify-content-between align-items-center">
                            <h6>{t('auction')} id:</h6>
                            <p>
                                <span>{`#${product.auctionid}`}</span>
                            </p>
                        </div> */}

                        {product.auction ? (
                            <>
                                {product.market_status === 'open' ? (
                                    <>
                                        <div className="gridBidInfo d-flex justify-content-between align-items-center">
                                            <div>
                                                <p className="">{currencyFormat(product.wprice)}</p>
                                                <h6>{product.cbidtext} </h6>
                                            </div>

                                            <div className="d-flex justify-content-between align-items-center">
                                                {product.auction ? (
                                                    <div className="gridMiscInfo">
                                                        <p
                                                            className="cursorDecoy"
                                                            onClick={() =>
                                                                setBidHistoryValue({
                                                                    id: product.id,
                                                                    proxy: true,
                                                                })
                                                            }
                                                        >
                                                            {product.bid_count}
                                                        </p>
                                                        <span className="material-icons">
                                                            gavel
                                                        </span>
                                                    </div>
                                                ) : null}
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div className="gridBidInfo d-flex justify-content-between align-items-center">
                                            <p className="d-flex justify-content-between align-items-end w-100">
                                                <div>
                                                    <p>{currencyFormat(product.buynowamount)}</p>
                                                    <h6>{product.cbidtext}</h6>
                                                </div>
                                                <div className="gridMiscInfo">
                                                    <p
                                                        className="cursorDecoy"
                                                        onClick={() =>
                                                            setBidHistoryValue({
                                                                id: product.id,
                                                                proxy: true,
                                                            })
                                                        }
                                                    >
                                                        {product.bid_count}
                                                    </p>
                                                    <span className="material-icons">gavel</span>
                                                </div>
                                            </p>
                                        </div>
                                    </>
                                )}
                            </>
                        ) : (
                            <div className="gridBidInfo d-flex justify-content-between align-items-center">
                                <div>
                                    <p className="">{currencyFormat(product.bprice)}</p>
                                    <h6>Buy Now Price </h6>
                                </div>
                            </div>
                        )}

                        {props.from === 'dashboard' &&
                        (props.action === 'won' || props.action === 'buynow') &&
                        product.buynowed &&
                        product.buynowed === user.id ? (
                            <div className="actionAfterWin">
                                {parseInt(product.buynowpaid, 10) === 1 ? (
                                    <>
                                        <SecondaryButton
                                            label={'View Invoice'}
                                            btnSize="small"
                                            onClick={() =>
                                                handleRedirectInternal(
                                                    history,
                                                    `invoice/${product.common_invoice}`,
                                                )
                                            }
                                        />
                                    </>
                                ) : (
                                    <>
                                        {/* <PrimaryButton
                                            label={'Make Payment'}
                                            btnSize="small"
                                            onClick={() => onMakePaymentClick(product)}
                                        /> */}
                                    </>
                                )}
                            </div>
                        ) : product.market_status === 'open' && !product.future_active ? (
                            <>
                                {product.auction ? (
                                    <div className="gridBidBox">
                                        <ReverseBidding
                                            data={product}
                                            type={
                                                product.custom_auctionlot_bid_type
                                                    ? product.custom_auctionlot_bid_type
                                                    : product.bid_type
                                            }
                                            accept_rejected={product.enable_duplicate}
                                            accept_non_winning={
                                                product.custom_auctionlot_enable_non_winning
                                            }
                                        />
                                    </div>
                                ) : null}
                                {product.buynow && !product.auction ? (
                                    <div className="gridBidBox">
                                        <Buynow
                                            data={product}
                                            editQuantity={true}
                                            isOnlyBuynow={product.auction === 0 ? true : false}
                                        />
                                    </div>
                                ) : null}
                            </>
                        ) : product.buynowed && product.buynowed === user.id ? (
                            <div className="actionAfterWin">
                                {parseInt(product.buynowpaid, 10) === 1 ? (
                                    <>
                                        <SecondaryButton
                                            label={'View Invoice'}
                                            btnSize="small"
                                            onClick={() =>
                                                handleRedirectInternal(
                                                    history,
                                                    `invoice/${product.common_invoice}`,
                                                )
                                            }
                                        />
                                    </>
                                ) : (
                                    <>
                                        {/* <PrimaryButton
                                            label={'Make Payment'}
                                            btnSize="small"
                                            onClick={() => {
                                                handleRedirectInternal(
                                                    history,
                                                    `checkout/auction/${product.group_carts}`,
                                                )
                                            }}
                                        /> */}
                                        {event ? (
                                            <div className="adCaledarDiv d-flex align-items-center justify-content-end">
                                                <label className="m-0">
                                                    <small>Add to Calendar:</small>
                                                </label>
                                                <a
                                                    href={google(event)}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <Tooltip title="Google Calendar">
                                                        <img src="/assets/svg/google_calendar.svg" />
                                                    </Tooltip>
                                                </a>
                                                <a
                                                    href={outlook(event)}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <Tooltip title="Outlook">
                                                        <img src="/assets/svg/outlook.svg" />
                                                    </Tooltip>
                                                </a>
                                                <a
                                                    href={office365(event)}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <Tooltip title="Office 365">
                                                        <img src="/assets/svg/office.svg" />
                                                    </Tooltip>
                                                </a>
                                                <a
                                                    href={yahoo(event)}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <Tooltip title="Yahoo">
                                                        <img src="/assets/svg/yahoo.svg" />
                                                    </Tooltip>
                                                </a>
                                                <a
                                                    href={ics(event)}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <Tooltip title="iCalendar">
                                                        <img src="/assets/images/iCalendar.png" />
                                                    </Tooltip>
                                                </a>
                                            </div>
                                        ) : null}
                                    </>
                                )}
                            </div>
                        ) : (
                            <div className="gridBidBox"></div>
                        )}

                        {product.market_status === 'open' ? (
                            <div className="gridTimer d-flex justify-content-start align-items-center">
                                <span className="material-icons-outlined">timer</span>
                                <p>
                                    {product.date_closed ? (
                                        <Timer
                                            date_added={product.date_added}
                                            date_closed={product.date_closed}
                                            withText={1}
                                            endText={'Time left' + ':'}
                                            startText={'Starts in' + ':'}
                                        ></Timer>
                                    ) : null}
                                </p>
                            </div>
                        ) : (
                            <div className="gridTimer d-flex justify-content-center align-items-center">
                                <h6>{'Closed On'}:</h6>
                                <p className="d-flex align-items-center">
                                    {dateFormatFront(product.date_closed)}
                                </p>
                            </div>
                        )}
                        {/* 
                        <div className="gridAucInfo">
                            <p>
                                <span className="material-icons-outlined">inventory_2</span>
                                Condition:
                            </p>
                            {allCondition
                                .filter((value) => product.conditionTypeId === value.id)
                                .map((location) => (
                                    //    console.log(location),
                                    <Tooltip title={location.detail}>
                                        <span className="adrsInfoCrd cursorHelper">
                                            {location.description}
                                        </span>
                                    </Tooltip>
                                ))}
                        </div> */}

                        <div className="gridAucInfo">
                            <p>
                                <span className="material-icons-round">warning_amber</span>
                                {product.local_pickup_check === 1 && product.shipping_check === 1
                                    ? 'Local pickup & Shipping'
                                    : product.local_pickup_check === 1
                                    ? 'Local Pickup only'
                                    : product.shipping_check === 1
                                    ? 'Shipping only'
                                    : null}
                            </p>
                        </div>
                        <div className="gridAucInfo">
                            <p>
                                <span className="material-icons-outlined">place</span>Location:
                            </p>
                            {allLocations
                                .filter((value) => product.location_id === value.id)
                                .map((location) => (
                                    //    console.log(location),
                                    <span className="adrsInfoCrd addrsLoc">{location.name}</span>
                                ))}
                        </div>
                        <div className="moreInfo d-flex justify-content-between align-items-center">
                            <Button
                                onClick={() =>
                                    handleRedirectInternal(
                                        history,
                                        `liveAuction/${product.auctionid}`,
                                    )
                                }
                            >
                                View Details
                            </Button>
                            {product.market_status === 'open' && (
                                <FavouriteCheckbox
                                    watchlisted={product.watchlistid}
                                    project_id={product.id}
                                />
                            )}
                        </div>
                    </>
                ) : null}
                <div className="PaymentModal">
                    <UpdatePhoneNumber data={manageuser} function={toggleFullScreenUserPopup} />
                </div>
            </div>
        </Fade>
    )
}

export default Grid
