import React, { useEffect, useContext, useState } from 'react'
import CustomTable from '../../../product/components/molecules/CustomTable'
import CustomSearch from '../../../product/components/molecules/CustomSearch'
import ReportContext from '../../../product/context/seller/report/reportContext'
import FullScreenPopup from '../../../product/components/organisms/FullScreenPopup'

const ReportDisplay = (props) => {
    const formik = props.formik
    const fieldsToShow = props.fieldsToShow
    const data = props.data
    const toggleFullScreenPopup = props.function

    const reportContext = useContext(ReportContext)
    const {
        reports_data,
        getAllReportDisplay,
        getAllReportDownload,
        responseStatus: responseStatusReport,
        clearResponse: clearResponseReport,
    } = reportContext

    const [tableBody, setTableBody] = useState([])

    let tableValues = []
    if (reports_data) {
        tableValues = fieldsToShow.filter(
            (field) =>
                field.group.indexOf(reports_data.requests.report.toString()) !== -1 &&
                reports_data.requests.fields.includes(field.field),
        )
    }

    const tableActions = []

    const searchInfo = [
        {
            label: 'Search Term',
            placeholder: 'Enter Search Term',
            class: 'col-md-4 col-sm-6 col-12',
            type: 'text',
            name: 'searchterm',
            formik: formik,
        },
    ]

    useEffect(() => {
        if (reports_data) {
            setTableBody(reports_data.records.length ? reports_data.records : [])
        }
    }, [reports_data])

    return (
        <FullScreenPopup
            className={'adTempltPopup'}
            modaltitle={reports_data ? `${reports_data.topheading} REPORT` : 'REPORT'}
            open={data.popup}
            handleClose={() => toggleFullScreenPopup(false)}
        >
            <div className="fspBody rprtDisplay">
                <CustomSearch searchInfo={searchInfo} formik={formik} />
                <CustomTable
                    formik={formik.values}
                    tableBody={tableBody}
                    tableValues={tableValues}
                    tableActions={tableActions}
                    allData={reports_data}
                    changeTableFunction={getAllReportDisplay}
                />
            </div>
        </FullScreenPopup>
    )
}

export default ReportDisplay
