import React from 'react'
import './Dashboard.css'

import { handleRedirectInternal } from '../../product/common/components'

import SavedSearchComponent from '../../product/components/species/components/user/SavedSearchComponent'

function SavedSearch() {
    const getSearchBarObjet = (value) => {
        setSearchValue({
            filters: {
                searchbar: {
                    value: value,
                },
            },
        })
        handleRedirectInternal(history, 'search')
    }

    return <SavedSearchComponent getSearchBarObjet={getSearchBarObjet} />
}

export default SavedSearch
