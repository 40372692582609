import React, { useContext, useEffect, useState } from 'react'
import PrimaryButton from '../../../atoms/PrimaryButton/index'
import { useFormik } from 'formik'
import { handleRedirectInternal, mapData } from '../../../../common/components'
import PhoneValidationComponent from './PhoneValidationComponent'
import AuthContext from '../../../../context/auth/authContext'
import { useHistory } from 'react-router-dom'
import CommonContext from '../../../../context/common/commonContext'
import { SET_ALERT } from '../../../../../product/context/alert/alertTypes'
import AlertContext from '../../../../context/alert/alertContext'
import CustomDialog from '../../../../components/organisms/Dialog'
import { Button } from '@material-ui/core'

const RegisterComponent = (props) => {
    const history = useHistory()

    const authContext = useContext(AuthContext)
    const alertContext = useContext(AlertContext)
    const { register, responseStatus, clearResponse } = authContext
    const { setAlert } = alertContext
    const commonContext = useContext(CommonContext)
    const { configFeatures } = commonContext

    const [phoneVerify, setPhoneVerify] = useState(false)
    const [disable, setDisable] = useState(false)
    const [popup, setPopup] = useState(false)
    const changePhoneVerify = () => {
        setPhoneVerify(!phoneVerify)
    }

    // Set Iniaital values
    const formik = useFormik({
        initialValues: props.initialValues ? props.initialValues : {},
        validationSchema: props.validationSchema,
        enableReinitialize: true,
        onSubmit: (values) => {
            let value = values
            console.log(value, 'values')
            if (props.phoneVerify) {
                if (value.phone_verified === 1 && value.verified_phonenum === value.phone) {
                    //value.phone = value.phone.replace(/[^+0-9]/g, '')
                    if (props.isConfirmPopup) {
                        setPopup(true)
                    } else {
                        handleRegister(value)
                    }
                } else {
                    setPhoneVerify(true)
                }
            } else {
                if (props.isConfirmPopup) {
                    setPopup(true)
                } else {
                    handleRegister(value)
                }
            }
            setDisable(false)
        },
    })

    const handleRegister = (payload) => {
        register(payload, props.noAlert, props.fromVariable)
        if (popup) setPopup(false)
    }

    const RegistrationForm = props.formFields ? { formik: formik, ...props.formFields } : {}

    // Check response status is succesuful
    useEffect(() => {
        if (responseStatus) {
            if (responseStatus.from === 'register' || responseStatus.from === props.fromVariable) {
                if (responseStatus.status === 'success') {
                    if (props.onSuccess) {
                        props.onSuccess(responseStatus.data)
                    } else {
                        handleRedirectInternal(history, 'login')
                    }
                }
            } else if (responseStatus.from === 'checkValidation') {
                if (responseStatus.status !== 'success') {
                } else {
                    setActiveStep(1)
                    window.scrollTo(0, 200)
                }
            }
        }
    }, [responseStatus])

    useEffect(() => {
        if (formik.values.company_id) {
            formik.values.company_id = props.id
            formik.values.disable = true
        }
    }, [props.id])

    useEffect(() => {
        if (props.additionalFieldUpdate) {
            props.additionalFieldUpdate(formik.values)
        }
    }, [formik.values])

    return (
        <>
            <form autoComplete="nofill">
                <div className="row mt-4">{mapData(RegistrationForm)}</div>
                <div className="regActBox">
                    <PrimaryButton
                        id="craete_account"
                        label={props.SubmitBtnLabel ? props.SubmitBtnLabel : 'Create my account '}
                        onClick={formik.handleSubmit}
                        disable={disable}
                    />
                    <PhoneValidationComponent
                        phoneVerify={phoneVerify}
                        formik={formik}
                        setPhoneVerify={setPhoneVerify}
                        changePhoneVerify={changePhoneVerify}
                        verifyStatusChange={props.verifyStatusChange}
                    />
                </div>
            </form>
            <CustomDialog
                title="Confirmation"
                open={props.isConfirmPopup && popup}
                function={() => setPopup(false)}
            >
                <h6>{props.popupBodyLabel ? props.popupBodyLabel : ''}</h6>
                <div className="actionWrapper">
                    <Button id="reg_cancel" onClick={() => setPopup(false)}>
                        Cancel
                    </Button>

                    <PrimaryButton
                        id="reg_confirm"
                        onClick={() => handleRegister(formik.values)}
                        label={props.popupSubmitBtnLable ? props.popupSubmitBtnLable : `Confirm `}
                    />
                </div>
            </CustomDialog>
        </>
    )
}

export default RegisterComponent
