import React, { useContext, useState, useEffect } from 'react'
import DashboardLayout from '@/views/Dashboard/DashboardLayout'
import { Button } from '@material-ui/core'
import { Pagination } from '@material-ui/lab'
import NoRecordsFound from '@/product/components/atoms/NoRecordsFound'
import PrimaryButton from '@/product/components/atoms/PrimaryButton'
import CustomDialog from '@/custom/components/organisms/Dialog'
import ProductContext from '@/product/context/product/productContext'
import AuthContext from '@/product/context/auth/authContext'
import { dateTimeFormatFunction } from '@/product/common/components'

const SavedSearchComponent = (props) => {
    const productContext = useContext(ProductContext)
    const authContext = useContext(AuthContext)
    const [toggleDialog, setToggleDialog] = useState(false)
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPage, setTotalPage] = useState(0)
    const [currentID, setCurrentID] = useState(0)
    const [record, setRecord] = useState([])
    const [limit, setLimit] = useState(15)
    const { isAuthenticated } = authContext

    const { responseStatus, getSavedSearch, saved_searches, removeSavedSearch } = productContext

    useEffect(() => {
        // console.log(saved_searches, 'from response status')
        if (saved_searches) {
            /*if (responseStatus.from === 'saved_search' && responseStatus.data) {*/
            //console.log(responseStatus.from, 'from is working')
            console.log(saved_searches.records, 'data is working')
            setRecord(saved_searches.records)
            setTotalPage(saved_searches.totalRecords)
            // setRecord(responseStatus.data.records)
            // setTotalPage(responseStatus.data.totalRecords)
            // }
        }
    }, [saved_searches])

    const loadTable = (page = currentPage) => {
        getSavedSearch({ page: page, limit: limit })
    }

    const removeRecord = (id) => {
        removeSavedSearch({ id })
        loadTable()
        setToggleDialog(false)
    }

    useEffect(() => {
        if (isAuthenticated) {
            loadTable()
        }
    }, [isAuthenticated])

    const onHandlePage = async (event, value) => {
        setCurrentPage(value)
        loadTable(value)
    }

    const changeDialogStatus = () => {
        setToggleDialog(!toggleDialog)
    }

    return (
        <DashboardLayout title="My saved searches">
            <div className="dashboardInner mySavedSearches">
                {record.length ? (
                    <>
                        <h5 className="dashSubtitle">
                            You can find all your saved searches here
                            <br />
                            Saved search helps you find your preferred products easily
                        </h5>
                        <div className="table-responsive dpTable">
                            <table className="table text-left">
                                <thead>
                                    <tr>
                                        <th scope="col">S.No</th>
                                        <th scope="col">Title</th>
                                        <th scope="col">Date Added</th>
                                        <th scope="col">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {record.map((data, index) => (
                                        <tr key={`saved_${index}`}>
                                            <td>{index + 1}</td>
                                            <td>{data.search_text}</td>
                                            <td>{dateTimeFormatFunction(data.created_at)}</td>
                                            <td>
                                                <div className="d-flex justify-content-center ssActBtn">
                                                    <Button
                                                        id="savedSearch_open"
                                                        onClick={() =>
                                                            props.getSearchBarObjet(
                                                                data.search_text,
                                                            )
                                                        }
                                                    >
                                                        <span className="material-icons">
                                                            open_in_new
                                                        </span>
                                                    </Button>
                                                    <Button
                                                        id="savedSearch_delete"
                                                        onClick={() => {
                                                            setCurrentID(data.id)
                                                            setToggleDialog(true)
                                                        }}
                                                    >
                                                        <span className="material-icons">
                                                            delete
                                                        </span>
                                                    </Button>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <Pagination
                                count={Math.ceil(totalPage / limit)}
                                page={currentPage}
                                onChange={onHandlePage}
                                siblingCount={3}
                                showFirstButton
                                showLastButton
                                boundaryCount={2}
                            />
                        </div>
                    </>
                ) : (
                    <NoRecordsFound />
                )}
            </div>

            <CustomDialog title="Confirmation" open={toggleDialog} function={changeDialogStatus}>
                <h5>You are about to delete this saved search ?</h5>
                <h5>Please confirm if you want to continue with this action ?</h5>
                <div className="actionWrapper">
                    <Button id="savedSearch_status" onClick={() => setToggleDialog(false)}>
                        Cancel
                    </Button>
                    <PrimaryButton
                        id="savedSearch_confirm"
                        type="button"
                        onClick={() => removeRecord(currentID)}
                        label="Confirm"
                    />
                </div>
            </CustomDialog>
        </DashboardLayout>
    )
}

export default SavedSearchComponent
