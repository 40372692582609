import React, { useState, useEffect, useContext } from 'react'
import './PostProject.css'
import PrimaryButton from '../../../product/components/atoms/PrimaryButton'
import SecondaryButton from '../../../product/components/atoms/SecondaryButton'
import SellerProductContext from '../../../product/context/seller/product/productContext'
import SellerAuctionContext from '../../../product/context/seller/auction/auctionContext'
import CustomCommonContext from '../../../custom/context/common/commonContext'
import AuthContext from '../../../product/context/auth/authContext'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import moment from 'moment'
import {
    mapData,
    handleRedirectInternal,
    dateFormatFrontDay,
    dateFormatFrontTime,
    dateTimeFormatFunction,
    currencyFormat,
} from '../../../product/common/components'
import { Button, IconButton } from '@material-ui/core'
import FormDialog from '../../../custom/components/organisms/Dialog'
import PostTemplate from './PostTemplate'
import { useHistory } from 'react-router-dom'
import { TramRounded } from '@material-ui/icons'

const CreateLot = (props) => {
    const history = useHistory()
    const sellerProductContext = useContext(SellerProductContext)
    const authContext = useContext(AuthContext)
    const [slotPopup, setSlotPopup] = useState(false)
    const [reload, setReload] = useState(false)

    const {
        getSingleAuction,
        single_auction,
        auctionAction,
        responseStatus: responseStatusSeller,
    } = useContext(SellerAuctionContext)
    const [manage, setManage] = useState({
        popup: false,
        status: 'edit',
        id: props.match.params.id,
    })

    const {
        getAllProducts,
        all_products,
        projectAction,
        getSingleProject,
        single_project,
        responseStatus,
        clearResponse,
    } = sellerProductContext

    const { isAuthenticated } = authContext
    const [tableBody, setTableBody] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [state, setState] = useState(false)
    const [isSingle, setSingle] = useState(true)
    const [prevDate, setPrevDate] = useState(new Date())

    useEffect(() => {
        if (isAuthenticated && manage.id) {
            getSingleAuction({ id: manage.id })
        }
    }, [isAuthenticated, manage])

    const validationArray = Yup.object({
        title: Yup.string().required('Required!'),
        slot_start_time: Yup.date('Please enter valid Date!')
            .nullable()
            .typeError('Required!')
            .required('Required!'),
        slot_end_time: Yup.date().when('all_day', {
            is: false,
            then: Yup.date('Please enter valid Date!')
                .transform((__, value) =>
                    value && typeof value === 'string'
                        ? new Date(
                              moment(new Date(value.replace(/-/g, '/'))).subtract(1, 'minutes'),
                          )
                        : new Date(moment(value).subtract(1, 'minutes')),
                )
                .min(Yup.ref('slot_start_time'), 'Should be greater than start Time')
                .nullable()
                .typeError('Required!')
                .required('Required!'),
            otherwise: Yup.date('Please enter valid Date!')
                .transform((__, value) =>
                    value && typeof value === 'string'
                        ? new Date(
                              moment(new Date(value.replace(/-/g, '/'))).subtract(1, 'minutes'),
                          )
                        : new Date(moment(value).subtract(1, 'minutes')),
                )
                .min(prevDate, 'Should be greater than start Time')
                .nullable()
                .typeError('Required!')
                .required('Required!'),
        }),

        sprice: Yup.number().required('Required!').positive('Should be greater than 1'),
        rprice: Yup.number()
            .positive('Should be greater than 1')
            .max(Yup.ref('sprice'), 'Should be equal or lesser than the starting price')
            .required('Required!'),
        all_day: Yup.boolean().nullable(),
    })

    const formik = useFormik({
        initialValues: {
            id: 0,
            title: '',
            auctionid: manage.id,
            desc_proc: '',
            buynow: false,
            slot_start_time: dateTimeFormatFunction(new Date()),
            slot_end_time: dateTimeFormatFunction(new Date()),
            date_convert_keys: ['slot_start_time', 'slot_end_time'],
            sprice: 0,
            rprice: 0,
            bprice: 0,
            uploadimages: [],
            market_status: 'open',
            auction: true,
            all_day: false,
            auctiontype: 'live',
            extends_enable: false,
            min_time: 0,
            extends_time: 0,
        },
        validationSchema: validationArray,
        onSubmit: (values) => {
            const value = values
            if (value.all_day) {
                value.slot_start_time = moment(
                    `${value.slot_start_time} 0:00 am`,
                    'MM-DD-YYYY h:mm a',
                ).format()
                value.slot_end_time = moment(
                    `${value.slot_end_time} 11:59 pm`,
                    'MM-DD-YYYY h:mm a',
                ).format()
            } else {
                value.slot_start_time = moment(value.slot_start_time, 'MM-DD-YYYY h:mm a').format()
                value.slot_end_time = moment(value.slot_end_time, 'MM-DD-YYYY h:mm a').format()
            }

            projectAction(value)
            setSlotPopup(false)
            resetFrom()
        },
    })

    useEffect(() => {
        if (formik.values.all_day) {
            if (formik.values.slot_start_time && formik.values.slot_start_time !== '-') {
                let startDate = moment(formik.values.slot_start_time).format('MM-DD-YYYY')
                let prevStartDate = moment(formik.values.slot_start_time)
                    .subtract(1, 'days')
                    .format('MM-DD-YYYY')
                let endDate = moment(formik.values.slot_end_time).format('MM-DD-YYYY')
                if (startDate != formik.values.slot_start_time) {
                    formik.setFieldValue('slot_start_time', startDate)
                }
                if (endDate != formik.values.slot_end_time) {
                    formik.setFieldValue('slot_end_time', endDate)
                }
                setPrevDate(prevStartDate)
            }
        } else {
            formik.setFieldValue(
                'slot_start_time',
                single_project.record.slot_start_time
                    ? dateTimeFormatFunction(single_project.record.slot_start_time)
                    : dateTimeFormatFunction(new Date()),
            )
            formik.setFieldValue(
                'slot_end_time',
                single_project.record.slot_end_time
                    ? dateTimeFormatFunction(single_project.record.slot_end_time)
                    : dateTimeFormatFunction(new Date()),
            )
        }
    }, [formik.values.all_day, formik.values.slot_start_time])

    const slotValue = [
        {
            label: 'Slot Title *',
            type: 'text',
            placeholder: 'Enter your Slot Title',
            class: 'col-sm-12 col-12 mb-4',
            name: 'title',
            formik: formik,
            shrink: true,
        },
        {
            label: `Enter slot start date ${formik.values.all_day ? '' : '& time'}`,
            type: formik.values.all_day ? 'date' : 'datetime',
            disablePast: true,
            class: 'col-md-6 col-sm-6 col-12',
            name: 'slot_start_time',
            formik: formik,
        },
        {
            label: `Enter slot end date ${formik.values.all_day ? '' : '& time'}`,
            disablePast: true,
            type: formik.values.all_day ? 'date' : 'datetime',
            class: 'col-md-6 col-sm-6 col-12',
            name: 'slot_end_time',
            minDate: dateTimeFormatFunction(formik.values.slot_start_time),
            minDateMessage: `Date should not be before minimal date ${dateTimeFormatFunction(
                formik.values.slot_start_time,
            )}`,

            formik: formik,
        },
        {
            label: 'All day',
            type: 'checkbox',
            class: 'col-12',
            name: 'all_day',
            formik: formik,
        },
        {
            label: 'Start price',
            placeholder: 'Enter starting price',
            type: 'number',
            class: 'col-md-6 col-sm-6 col-12',
            name: 'sprice',
            // disabled: formik.values.auction ? false : true,
            formik: formik,
        },
        {
            label: 'Reserve price',
            placeholder: 'Enter reserve price',
            type: 'number',
            class: 'col-md-6 col-sm-6 col-12',
            name: 'rprice',
            formik: formik,
        },
    ]

    console.log(formik.values, 'formik.values')
    const listing = useFormik({
        initialValues: {
            page: 1,
            limit: 20,
            order: '',
            orderby: '',
            filters: {
                auctionid: {
                    value: manage.id,
                    type: 'in',
                    field: 'p.auctionid',
                },
            },
        },
    })

    const onClickEdit = (id) => {
        setState(true)
        formik.values.id = id
        getSingleProject({ id })
        setSlotPopup(true)
    }
    const addClick = () => {
        resetFrom()
        setSlotPopup(true)
    }

    const cancelClick = () => {
        setSlotPopup(false)
        resetFrom()
    }

    const resetFrom = () => {
        formik.values.id = ''
        formik.values.title = ''
        formik.values.slot_start_time = dateTimeFormatFunction(new Date())
        formik.values.slot_end_time = dateTimeFormatFunction(new Date())
        formik.values.all_day = false
        formik.values.sprice = ''
        formik.values.rprice = ''
        formik.handleReset()
    }

    useEffect(() => {
        if (isAuthenticated && manage.id) {
            getAllProducts(listing.values)
        }
    }, [isAuthenticated])

    useEffect(() => {
        if (responseStatus) {
            if (responseStatus.from === 'projectAction') {
                if (responseStatus.status === 'success') {
                    setIsLoading(true)
                    getAllProducts(listing.values)
                }
            }
        }
        return () => {
            clearResponse()
        }
    }, [responseStatus])

    useEffect(() => {
        if (all_products) {
            setTableBody(all_products.records.length ? all_products.records : [])
        }
        setIsLoading(false)
        console.log(tableBody, 'table')
    }, [all_products])

    useEffect(() => {
        if (single_project.record) {
            setIsLoading(false)

            const project = single_project.record
            formik.values.title = project.title
            formik.values.slot_start_time = dateTimeFormatFunction(project.slot_start_time)
            formik.values.slot_end_time = dateTimeFormatFunction(project.slot_end_time)
            formik.values.all_day = project.all_day || false
            formik.values.sprice = project.sprice
            formik.values.rprice = project.rprice
            setReload(!reload)
        } else {
            setIsLoading(false)
        }
    }, [single_project])

    useEffect(() => {
        if (single_auction) {
            if (single_auction.record) {
                const auction = single_auction.record
                formik.values.extends_enable = auction.extends_enable
                formik.values.min_time = auction.min_time
                formik.values.extends_time = auction.extends_time
            }
        }
    }, [single_auction])

    useEffect(() => {
        if (single_auction.record.event_type == 1 && tableBody.length >= 1) {
            setSingle(false)
        } else {
            setSingle(true)
        }
    }, [single_auction, tableBody])
    // useEffect(() => {
    //     if (all_products.records.length > 0) {
    //         setShowValues(true)
    //     } else {
    //         setShowValues(false)
    //         setState(true)
    //     }
    // }, [all_products])

    return (
        <PostTemplate stepCount={2}>
            <div className="stpInrDiv">
                <h4>Details about shift to be worked</h4>
                {tableBody.length > 0 ? (
                    <>
                        {console.log(tableBody, 'tableBody')}
                        <div className="slotLstWrpr text-left">
                            {isSingle ? (
                                <Button className="adNwSltBtn" onClick={() => addClick()}>
                                    <span className="material-icons-outlined">
                                        add_circle_outline
                                    </span>{' '}
                                    Add a new slot
                                </Button>
                            ) : null}
                            <div className="table-responsive dpTable mt-4">
                                <table className="table text-left">
                                    <thead>
                                        <tr>
                                            <th>Slot Name</th>
                                            <th>Starting time</th>
                                            <th>Ending time</th>
                                            <th>Start price</th>
                                            <th>Reserve price</th>
                                            <th>Edit</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {tableBody.map((data, index) => (
                                            <tr key={index}>
                                                <td>{data.title}</td>
                                                <td>
                                                    {dateFormatFrontDay(data.slot_start_time)}
                                                    <br />
                                                    <span className="timSpn">
                                                        {dateFormatFrontTime(data.slot_start_time)}
                                                    </span>
                                                </td>
                                                <td>
                                                    {dateFormatFrontDay(data.slot_end_time)} <br />
                                                    <span className="timSpn">
                                                        {dateFormatFrontTime(data.slot_end_time)}
                                                    </span>
                                                </td>
                                                <td className="crncyVl">
                                                    {currencyFormat(data.sprice)}
                                                </td>
                                                <td className="crncyVl">
                                                    {currencyFormat(data.rprice)}
                                                </td>
                                                <td>
                                                    <IconButton
                                                        className="icnBtn"
                                                        onClick={() => onClickEdit(data.id)}
                                                    >
                                                        <span className="material-icons">edit</span>
                                                    </IconButton>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <Button className="slotEntBtn" onClick={() => setSlotPopup(true)}>
                            <img src="/assets/svg/createslot.svg" />
                            <p>Create a new shift to be covered</p>
                        </Button>
                    </>
                )}
                <div className="btnWrpr d-flex align-items-center justify-content-center">
                    <SecondaryButton
                        label="Previous"
                        onClick={() => {
                            {
                                manage.id
                                    ? handleRedirectInternal(
                                          history,
                                          `post_project/auction_detail/edit/${manage.id}`,
                                      )
                                    : handleRedirectInternal(
                                          history,
                                          'post_project/auction_detail/new',
                                      )
                            }
                        }}
                    />
                    <PrimaryButton
                        label="Next"
                        onClick={() =>
                            handleRedirectInternal(
                                history,
                                `post_project/auction_setting/${manage.id}`,
                            )
                        }
                    />
                </div>
            </div>
            <FormDialog
                className="adSlotPopup"
                title="Add New Slot"
                open={slotPopup}
                openFunction={() => setSlotPopup(false)}
            >
                <form onSubmit={formik.handleSubmit}>
                    <div className="row">{mapData(slotValue)}</div>
                </form>
                <div className="d-flex align-items-center justify-content-end mt-4">
                    <SecondaryButton label="Cancel" onClick={() => cancelClick()} />
                    <PrimaryButton
                        btnSize="ml-2"
                        label="Add"
                        onClick={() => formik.handleSubmit()}
                    />
                </div>
            </FormDialog>
        </PostTemplate>
    )
}

export default CreateLot
