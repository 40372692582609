import { Button } from '@material-ui/core'
import React, { useState, useEffect, useContext } from 'react'
import moment from 'moment'
import './Dashboard.css'
import DashboardLayout from './DashboardLayout'
import ProductContext from '../../product/context/product/productContext'
import AuthContext from '../../product/context/auth/authContext'
import AlertContext from '../../product/context/alert/alertContext'
import Pagination from '@material-ui/lab/Pagination'
import auctionContext from '../../product/context/auction/auctionContext'
import { useLocation, useHistory } from 'react-router-dom'
import { handleRedirectInternal } from '../../product/common/components'
import NoRecordsFound from '../../product/components/atoms/NoRecordsFound'
import { useMediaQuery } from 'react-responsive'
import AuctionCard from '../../custom/components/molecules/ProductCard/AuctionCard'

function Follows(props) {
    const productContext = useContext(ProductContext)
    const authContext = useContext(AuthContext)
    const alertContext = useContext(AlertContext)
    const AuctionContext = useContext(auctionContext)
    const location = useLocation()
    const history = useHistory()
    const {
        search_alldashboardproducts,
        getAllDashboardProducts,
        responseStatus: productresponse,
    } = productContext
    const {
        search_alldashboardauctions,
        getAllDashboardwatchlist,
        removeWatchlistauction,
        responseStatus: auctionresponse,
    } = AuctionContext
    const { user } = authContext
    const { setAlert } = alertContext
    const [nav, setNav] = useState({
        total: '',
    })

    const isLarge = useMediaQuery({
        query: '(min-width: 1600px)',
    })

    const isTab = useMediaQuery({
        query: '(max-width: 980px)',
    })

    let [viewProduct, setViewProduct] = useState([])
    let [viewAuctions, setViewAuction] = useState([])
    let [isLoading, setIsLoading] = useState(true)

    const [searchAuction, setSearchAuction] = useState({
        limit: 8,
        page: 1,
        orderby: 'p.date_closed,asc',
        order: '',
        action: 'watchlist',
    })

    // useEffect(() => {
    //     setSearch({ ...search, limit: isLarge ? 12 : 9 })
    // }, [isLarge])

    let filters = {
        filters: {
            market_status: {
                value: 'sold,closed,unsold,removed',
                type: 'array',
                field: 'p.market_status',
            },
        },
    }
    let having = {
        having: {
            future_active: {
                value: 1,
                type: 'in',
                field: 'future_active',
            },
        },
    }

    // const getProductData = () => {
    //     if (search.action != '') {
    //         let filter = {}
    //         filters.filters.market_status.field = 'p.market_status'
    //         if (props?.action === 'current' || props?.match?.params?.id === 'current') {
    //             filters.filters.market_status.value = ['open']
    //             having.having.future_active.value = 0
    //             filter = { ...search, ...filters, ...having }
    //         } else if (props?.action === 'upcoming' || props?.match?.params?.id === 'upcoming') {
    //             filter = { ...search, ...having }
    //         } else if (props?.action === 'past' || props?.match?.params?.id === 'past') {
    //             filters.filters.market_status.value = ['closed', 'sold']
    //             filter = { ...search, ...filters }
    //         }
    //         getAllDashboardProducts(filter, props?.action)
    //     }
    // }
    // useEffect(() => {
    //     getProductData()
    // }, [search, props?.match?.params?.id, props?.action])

    const getAuctionData = () => {
        if (searchAuction.action != '') {
            let filter = {}
            filters.filters.market_status.field = 'ac.market_status'
            if (props?.action === 'current' || props?.match?.params?.id === 'current') {
                having.having.future_active.value = 0
                filters.filters.market_status.value = ['open']
                filter = { ...searchAuction, ...filters, ...having }
            } else if (props?.action === 'upcoming' || props?.match?.params?.id === 'upcoming') {
                filter = { ...searchAuction, ...having }
            } else if (props?.action === 'past' || props?.match?.params?.id === 'past') {
                filters.filters.market_status.value = ['closed', 'sold']
                filter = { ...searchAuction, ...filters }
            }
            //setIsLoading(true)
            getAllDashboardwatchlist({ ...filter, orderby: 'ac.date_closed,asc' }, props?.action)
        }
    }

    useEffect(() => {
        getAuctionData()
    }, [searchAuction, props?.match?.params?.id, props?.action])

    useEffect(() => {
        //setIsLoading(true)
    }, [props?.match?.params?.id, props?.action])
    useEffect(() => {
        if (search_alldashboardauctions.from === props.action) {
            if (search_alldashboardauctions.records.length) {
                setViewAuction(search_alldashboardauctions.records)
                setIsLoading(false)
            } else {
                setViewAuction([])
                setIsLoading(false)
            }
        }
    }, [search_alldashboardauctions, props?.match?.params?.id, props?.action])

    useEffect(() => {
        if (search_alldashboardproducts.from === props.action) {
            if (search_alldashboardproducts.records.length) {
                setViewProduct(search_alldashboardproducts.records)
                setNav({ ...nav, total: search_alldashboardproducts.totalRecords })
                setIsLoading(false)
            } else {
                setViewProduct([])
                setIsLoading(false)
            }
        }
    }, [search_alldashboardproducts, props?.match?.params?.id, props?.action])

    useEffect(() => {
        setViewProduct([])
        setViewAuction([])
        return () => {
            setViewProduct([])
            setViewAuction([])
            setIsLoading(false)
        }
    }, [])

    // const handleChange = (e, value) => {
    //     setSearch({ ...search, page: value })
    //     window.scrollTo({ top: 0, behavior: 'smooth' })
    // }

    const handleChangeAuction = (e, value) => {
        setSearchAuction({ ...searchAuction, page: value })
    }

    useEffect(() => {
        if (auctionresponse) {
            if (auctionresponse.from === (props?.action || 'auctionwatchlist')) {
                if (auctionresponse.status === 'success') {
                    setIsLoading(false)
                    getAuctionData()
                } else {
                    if (auctionresponse?.data?.message) {
                        setAlert(auctionresponse.data.message, 'error')
                        setIsLoading(false)
                    }
                }
            }
        }
        if (productresponse) {
            if (
                productresponse.from === 'removeWatchlist' ||
                productresponse.from === 'addWatchlist'
            ) {
                if (productresponse.status === 'success') {
                    getProductData()
                    getAuctionData()
                    setIsLoading(false)
                } else {
                    setIsLoading(false)
                    setAlert(productresponse.data.message, 'error')
                }
            }
        }
    }, [productresponse, auctionresponse])

    const removeauction = (id) => (e) => {
        removeWatchlistauction({ auction_id: id })
    }

    return (
        <>
            {/*<DashboardLayout>*/}
            <div className="follows dashInner">
                <h2 className="dashTitle mb-4 mt-5">{props.type} Auctions Followed</h2>
                {viewAuctions.length ? (
                    <>
                        <>
                            {viewAuctions.map((data, index) => (
                                <>
                                    <AuctionCard
                                        data={data}
                                        key={index}
                                        count={data.all_projects.length}
                                    />
                                    {/* <div className="followsAuctionHead d-flex justify-content-start align-items-center flex-wrap">
                                        <h3
                                            className="cursorDecoy"
                                            onClick={() =>
                                                handleRedirectInternal(
                                                    history,
                                                    `collections/${data.id}/${data.title}`,
                                                )
                                            }
                                        >
                                            {data.title}
                                        </h3>
                                        <h4>
                                            {moment(data.date_added).format('MM/DD/yyyy')} -{' '}
                                            {moment(data.date_closed).format('MM/DD/yyyy')}
                                        </h4>
                                        <Button onClick={removeauction(data.id)}>
                                            <svg
                                                width="21"
                                                height="21"
                                                viewBox="0 0 21 21"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M11.6895 11.6895V14.3828H9.68652V11.6895H7V9.69336H9.68652V7H11.6895V9.69336H14.376V11.6895H11.6895Z"
                                                    fill="#4D4E56"
                                                />
                                                <circle
                                                    cx="10.5"
                                                    cy="10.5"
                                                    r="10"
                                                    stroke="#E2DED8"
                                                />
                                            </svg>
                                            <i className="fa fa-check mr-2"></i>
                                            Following
                                        </Button>
                                    </div> */}
                                </>
                            ))}
                        </>
                        {/* <div className="mb-5 mt-2">
                            {search_alldashboardauctions?.totalRecords > search.limit ? (
                                <Pagination
                                    count={Math.ceil(
                                        parseInt(search_alldashboardauctions.totalRecords) /
                                            parseInt(searchAuction.limit),
                                    )}
                                    page={searchAuction.page}
                                    onChange={handleChangeAuction}
                                />
                            ) : (
                                ''
                            )}
                        </div> */}
                    </>
                ) : (
                    <NoRecordsFound />
                )}
            </div>
            {/* </DashboardLayout> */}
        </>
    )
}

export default Follows
