import React, { useState, useContext, useEffect, useRef, useCallback } from 'react'
import './Home.css'
import { useHistory } from 'react-router-dom'
import AuthContext from '../../product/context/auth/authContext'
import AlertContext from '../../product/context/alert/alertContext'
import { makeStyles } from '@material-ui/core/styles'
import { handleRedirectInternal, mapData } from '../../product/common/components'
import { useFormik } from 'formik'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Slider from 'react-slick'
import PrimaryButton from '../../product/components/atoms/PrimaryButton'
import { Button } from '@material-ui/core'
import CustomDialog from '../../product/components/organisms/Dialog'

function TabPanel(props) {
    const { children, value, index, ...other } = props

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    )
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    }
}

const useStyles = makeStyles({
    root: {
        flexGrow: 1,
    },
})

function Home() {
    const history = useHistory()
    const authContext = useContext(AuthContext)
    const alertContext = useContext(AlertContext)
    const { user, isAuthenticated } = authContext
    const { setAlert } = alertContext

    const [searchText, setSearchText] = useState('')

    const classes = useStyles()
    const [value, setValue] = React.useState(0)

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    const settings = {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        centerMode: true,
        // className: 'center',
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
            // You can unslick at a given breakpoint now by adding:
            // settings: "unslick"
            // instead of a settings object
        ],
    }

    const formik = useFormik({
        initialValues: {
            first_name: '',
            email: '',
            phone: '',
        },
        onSubmit: (values) => {
            console.log(values)
        },
    })

    const quickRegister = [
        {
            label: 'Full Name',
            name: 'first_name',
            type: 'text',
            placeholder: 'Enter your first name',
            class: 'col-12',
            formik: formik,
        },
        {
            label: 'Email Address',
            name: 'email',
            type: 'email',
            placeholder: 'Enter your email address',
            class: 'col-12',
            formik: formik,
        },
        {
            label: 'Phone #',
            name: 'phone',
            type: 'phone',
            placeholder: 'Enter your mobile number',
            class: 'col-12',
            formik: formik,
        },
    ]

    const [freeTrialModal, setFreeTrialModal] = useState(false)

    const handleFreeTrialModal = () => {
        setFreeTrialModal(!freeTrialModal)
    }

    return (
        <div className="homeWrapper" style={{ minHeight: 'calc(100vh - 316px)' }}>
            <div className="topMainBanner">
                <div className="customContainer">
                    <img className="bannerImage" src="./assets/images/main_banner.png" />
                    <div className="bnrTxtCntnr">
                        <h4>Holiday Shifts</h4>
                        <p>Reverse Auction</p>
                        <p className="hlTxt">We make booking holiday shifts convenient</p>
                    </div>
                    <img className="lapTopImg" src="./assets/images/bannerlaptop.png" />
                </div>
            </div>
            <div className="wyBdSft">
                <div className="customContainer">
                    <img className="whyBck" src="./assets/images/whyback.png" />
                    <div className="wyCntWrp">
                        <h2 className="cstmHdr">
                            Why <span>Bid A Shift?</span>
                        </h2>
                        <div className="whyGrid">
                            <div className="whyCard">
                                <img src="./assets/svg/tick.svg" />
                                <h2>Exclusive Auctions</h2>
                                <p>
                                    BidaShift auctions are invite only. The seller chooses who can
                                    see the auction and place bids. How you set your shift prices is
                                    your business and kept private to your group.
                                </p>
                            </div>
                            <div className="whyCard">
                                <img src="./assets/svg/tick.svg" />
                                <h2>Transparency</h2>
                                <p>
                                    BidaShift auctions are intuitive to build and bid. Bidders can
                                    see each other names and bid amounts, or not, depending on the
                                    auction setup preferences.
                                </p>
                            </div>
                            <div className="whyCard">
                                <img src="./assets/svg/tick.svg" />
                                <h2>Convenience</h2>
                                <p>
                                    BidaShift works equally well on a desktop browser or on your
                                    mobile device. Login can be remembered for 30 days to make quick
                                    auction checks a breeze.
                                </p>
                            </div>
                            <div className="whyCard">
                                <img src="./assets/svg/tick.svg" />
                                <h2>Reliable</h2>
                                <p>
                                    BidaShift auction platform is based on tried and true auction
                                    software that has hosted hundreds of thousands of auctions. You
                                    don&apos;t have to worry about the auction process - We&apos;ve
                                    got you covered!
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {!isAuthenticated || user?.admin ? (
                <div className="tstMlWrpr freeTrial">
                    <h2 className="cstmHdr">
                        Free trial <span>for 14 days</span>
                    </h2>
                    <div className="tstmlCntnr">
                        <div className="customContainer">
                            <p>We are offering a 14 days free trial for sellers.</p>
                            <PrimaryButton
                                label="Learn more"
                                onClick={() => handleFreeTrialModal()}
                            />
                        </div>
                    </div>
                </div>
            ) : null}

            <div className="hwItWksWrpr">
                <div className="customContainer">
                    <h2 className="cstmHdr">
                        How <span>it works</span>
                    </h2>
                    <div className="tabHdCntnr">
                        <Tabs value={value} onChange={handleChange} centered>
                            <Tab label="Bidders" {...a11yProps(0)} />
                            <Tab label="Auctioneers" {...a11yProps(1)} />
                        </Tabs>
                    </div>
                </div>
                <div className="tbDtlCntnr">
                    <TabPanel value={value} index={0}>
                        <div className="customContainer">
                            <div className="hiwFlx">
                                <div className="hiwCard">
                                    <h2>Invite</h2>
                                    <p>Get invited to exclusive reverse auctions.</p>
                                </div>
                                <img className="rtArw" src="./assets/svg/rightarrow.svg" />
                                <div className="hiwCard">
                                    <h2>Bid</h2>
                                    <p>
                                        Place your bid for shifts you want and see what others bid.
                                    </p>
                                </div>
                                <img className="rtArw" src="./assets/svg/rightarrow.svg" />
                                <div className="hiwCard">
                                    <h2>Track</h2>
                                    <p>Keep track of your shifts with your Calendar.</p>
                                </div>
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <div className="customContainer">
                            <div className="customContainer">
                                <div className="hiwFlx">
                                    <div className="hiwCard">
                                        <h2>Create</h2>
                                        <p>
                                            Create auctions for one or more future shifts, setting
                                            options for bidding.
                                        </p>
                                    </div>
                                    <img className="rtArw" src="./assets/svg/rightarrow.svg" />
                                    <div className="hiwCard">
                                        <h2>Invite</h2>
                                        <p>
                                            Select who can see your auction and invite them to
                                            participate.
                                        </p>
                                    </div>
                                    <img className="rtArw" src="./assets/svg/rightarrow.svg" />
                                    <div className="hiwCard">
                                        <h2>Track</h2>
                                        <p>
                                            {' '}
                                            Keep track of future, current and past auctions with
                                            ease.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </TabPanel>
                </div>
            </div>
            <div className="tstMlWrpr">
                <h2 className="cstmHdr">
                    We’re <span>loved</span>
                </h2>
                <div className="tstmlCntnr">
                    <div className="customContainer">
                        <Slider {...settings}>
                            <div>
                                <div className="tsmlCard">
                                    <h4>“I had my shift covered in just a few minutes!”</h4>
                                    <p>Brooke Haber</p>
                                </div>
                            </div>
                            <div>
                                <div className="tsmlCard">
                                    <h4>
                                        “Such an easy way to be home with my family for New Years
                                        Eve!”
                                    </h4>
                                    <p>Frankfurt Gillopskie</p>
                                </div>
                            </div>
                            <div>
                                <div className="tsmlCard">
                                    <h4>“So simple to use, only takes minutes!”</h4>
                                    <p>Jenny Smith</p>
                                </div>
                            </div>
                            <div>
                                <div className="tsmlCard">
                                    <h4>“I had my shift covered in just a few minutes!”</h4>
                                    <p>Brooke Haber</p>
                                </div>
                            </div>
                            <div>
                                <div className="tsmlCard">
                                    <h4>
                                        “Such an easy way to be home with my family for New Years
                                        Eve!”
                                    </h4>
                                    <p>Frankfurt Gillopskie</p>
                                </div>
                            </div>
                            <div>
                                <div className="tsmlCard">
                                    <h4>“So simple to use, only takes minutes!”</h4>
                                    <p>Jenny Smith</p>
                                </div>
                            </div>
                        </Slider>
                    </div>
                </div>
            </div>
            {/* <div className="rgstNwRpr">
                <div className="customContainer">
                    <h2 className="cstmHdr">
                        Register <span>now!</span>
                    </h2>
                    <div className="qckRegForm">
                        <form>
                            {Object.values(mapData(quickRegister))}
                            <div className="col-12">
                                <PrimaryButton label="Register" />
                            </div>
                        </form>
                    </div>
                </div>
            </div> */}
            <CustomDialog
                title="How does the free trial work?"
                open={freeTrialModal}
                function={handleFreeTrialModal}
            >
                <h6>Just enter your credit card details and enjoy your 14 day trial for free.</h6>
                <h6>Make sure to cancel before your trial ends to avoid any charges.</h6>
                <div className="actionWrapper">
                    <Button onClick={() => handleFreeTrialModal()}>Cancel</Button>
                    <PrimaryButton
                        onClick={() => handleRedirectInternal(history, 'subscription')}
                        label="Setup your free account"
                    />
                </div>
            </CustomDialog>
        </div>
    )
}

export default Home
