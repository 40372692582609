import React from 'react'
import Follows from './Follows'
import DashboardLayout from './DashboardLayout'
import './Dashboard.css'
function AllFollows() {
    return (
        <DashboardLayout>
            <>
                <Follows type="Current" action="current" key="1" />
                <div className="upcomingFollows">
                    <Follows type="Upcoming" action="upcoming" key="2" />
                </div>
                {/* <h1>Past follows</h1>
                <Follows action="past" key="3" /> */}
            </>
        </DashboardLayout>
    )
}
export default AllFollows
