import React, { useContext, useState, useEffect } from 'react'
import { NavLink, useHistory } from 'react-router-dom'
import DashboardLayout from '../../../Dashboard/DashboardLayout'
import '../../../Dashboard/Dashboard.css'
import CustomTable from '../../../../product/components/molecules/CustomTable'
import CustomSearch from '../../../../product/components/molecules/CustomSearch'

import { useFormik } from 'formik'
import AuctionContext from '../../../../product/context/seller/auction/auctionContext'
import AuthContext from '../../../../product/context/auth/authContext'
import { Button, ListItem } from '@material-ui/core'
import AuctionManage from './AuctionManage'
import AuctionListing from './AuctionListing'

import CustomDialog from '../../../../product/components/organisms/Dialog'
import PrimaryButton from '../../../../product/components/atoms/PrimaryButton'
import {
    mapData,
    dateTimeFormatFunction,
    handleRedirectInternal,
} from '../../../../product/common/components'
import moment from 'moment'
import * as Yup from 'yup'

const AuctionLots = (props) => {
    const auctionContext = useContext(AuctionContext)
    const authContext = useContext(AuthContext)

    const { user, isAuthenticated } = authContext

    const history = useHistory()

    const [isLoading, setIsLoading] = useState(true)

    const {
        all_auctions,
        getAllAuctions,
        changeAuctionStatus,
        responseStatus: responseStatusAuction,
        clearResponse: clearResponseAuction,
    } = auctionContext

    let action = props.match.params.action

    const [manage, setManage] = React.useState({
        popup: false,
        status: 'new',
        id: 0,
    })

    const toggleFullScreenPopup = (popup, status, id) => {
        if (popup) {
            setManage({ popup, status, id })
        } else {
            setManage({ popup, status: 'new', id: 0 })
        }
    }

    const [tableBody, setTableBody] = useState([])

    const formik = useFormik({
        initialValues: {
            page: 1,
            limit: 20,
            order: '',
            orderby: '',
            filters: {
                action: {
                    value: action == 'upcoming' ? 'open' : action,
                    type: 'in',
                    field: 'ac.market_status',
                },
                searchterm: {
                    value: '',
                    type: 'like',
                    field: 'ac.title',
                },
                fromdate: {
                    value: '',
                    type: 'dategreaterequal',
                    field: 'ac.date_closed',
                },
                todate: {
                    value: '',
                    type: 'datesmallerequal',
                    field: 'ac.date_closed',
                },
            },
            having: {
                future_active: {
                    value: action === 'upcoming' ? 1 : 0,
                    type: 'in',
                    field: 'future_active',
                },
            },
        },
        onSubmit: (values) => {
            setIsLoading(true)
            getAllAuctions(values)
        },
    })
    useEffect(() => {
        if (all_auctions) {
            setTableBody(all_auctions.records.length ? all_auctions.records : [])
            setIsLoading(false)
        }
    }, [all_auctions])

    useEffect(() => {
        if (isAuthenticated) {
            setTableBody([])
            setIsLoading(true)
            formik.values.filters.action.value = action == 'upcoming' ? 'open' : action
            formik.values.filters.searchterm.value = ''
            formik.values.filters.fromdate.value = ''
            formik.values.filters.todate.value = ''
            formik.values.having.future_active.value = action === 'upcoming' ? 1 : 0
            formik.values.page = 1
            getAllAuctions(formik.values)
            /*  return () => {
                setTableBody([])
            }*/
        }
    }, [action, isAuthenticated])

    const onClickEdit = (id, secondaryID, type) => {
        console.log('onClickEdit', id, type)
        if (type === 'edit') {
            handleRedirectInternal(history, `post_project/auction_detail/edit/${id}`)
        } else {
            toggleFullScreenPopup(true, type, id)
        }
    }

    const validationArray = Yup.object({
        action: Yup.string().required('Action is required'),
        date_closed: Yup.date('Please enter valid date!')
            .nullable()
            .when('action', {
                is: (action) => action === 'date_closed',
                then: Yup.date('Please enter valid date!').required('Required!'),
            }),
    })
    const formikSelection = useFormik({
        initialValues: {
            action: '',
            auction_id: '',
            date_closed: dateTimeFormatFunction(new Date()),
            mainUpdate: false,
        },
        validationSchema: validationArray,
        onSubmit: (values) => {
            if (
                values.action === 'removed' ||
                values.action === 'closenow' ||
                values.action === 'date_closed'
            ) {
                const value = values
                if (value.action === 'date_closed') {
                    value.date_closed = moment(value.date_closed, 'MM-DD-YYYY h:mm a').format()
                } else {
                    value.date_closed = null
                }
                setIsLoading(true)
                changeAuctionStatus(value)
            }
        },
    })

    const [changeStatus, setChangeStatus] = React.useState(false)

    const changeChangeStatus = () => {
        setChangeStatus(!changeStatus)
    }

    const onSelectMultiProducts = (data, action) => {
        console.log('data', data)
        console.log('action', action)
        formikSelection.values.action = action
        formikSelection.values.auction_id = data
        formikSelection.values.date_closed = dateTimeFormatFunction(new Date())
        if (action === 'removed' || action === 'closenow') {
            setChangeStatus(true)
        } else if (action === 'date_closed') {
            formikSelection.values.mainUpdate = true
            setChangeStatus(true)
        }
    }

    useEffect(() => {
        if (responseStatusAuction) {
            if (
                responseStatusAuction.status === 'success' &&
                responseStatusAuction.from === 'auctionchange'
            ) {
                setChangeStatus(false)
                getAllAuctions(formik.values)
            }
        }
    }, [responseStatusAuction])

    const getMultipleAction = () => {
        let actionPush = [
            {
                buttonType: 'edit',
                clickType: 'edit',
                onclick: onClickEdit,
                tooltipTitle: 'Edit Auction',
            },
            {
                buttonType: 'visibility',
                clickType: 'viewListing',
                onclick: onClickEdit,
                tooltipTitle: 'View Slots',
            },
        ]
        return actionPush
    }

    const tableValues = [
        {
            field: 'id',
            type: '',
            firstChild: true,
            sort: true,
            sortField: 'ac.id',
            disablePadding: false,
            label: 'ID',
        },
        {
            field: 'title',
            type: '',
            firstChild: false,
            sort: true,
            disablePadding: false,
            label: 'Title',
        },
        {
            field: 'date_added',
            sortField: 'ac.date_added',
            type: 'datetime',
            sort: true,
            firstChild: false,
            disablePadding: false,
            label: 'Start date',
        },
        {
            field: 'date_closed',
            type: 'datetime',
            sort: true,
            firstChild: false,
            disablePadding: false,
            label: 'End date',
        },
        {
            field: 'projectcount',
            type: '',
            sort: true,
            firstChild: false,
            disablePadding: false,
            label: 'No of slots',
        },
        {
            field: 'market_status',
            type: 'capitalize',
            sort: true,
            firstChild: false,
            disablePadding: false,
            label: 'Status',
        },
        {
            field: 'created_at',
            type: 'datetime',
            firstChild: false,
            sort: true,
            disablePadding: false,
            label: 'Created At',
        },
        {
            field: 'updated_at',
            type: 'datetime',
            firstChild: false,
            sort: true,
            disablePadding: false,
            label: 'Updated At',
        },
        {
            field: 'action',
            type: 'button',
            isMultiple: true,
            clickID: 'id',
            sort: false,
            firstChild: false,
            disablePadding: false,
            label: 'Action',
            isMultiple: getMultipleAction(),
        },
    ]

    const tableActions = [
        {
            label: 'Terminate',
            icon: <span className="material-icons">block</span>,
            onclick: onSelectMultiProducts,
            type: 'closenow',
        },
        {
            label: 'Delete',
            icon: <span className="material-icons">delete</span>,
            onclick: onSelectMultiProducts,
            type: 'removed',
        },
        {
            label: 'Change End Date',
            icon: <span className="material-icons">today</span>,
            onclick: onSelectMultiProducts,
            type: 'date_closed',
        },
    ]

    const dateClosed = {
        formik: formikSelection,
        data: [
            {
                label: 'Change End Date *',
                placeholder: 'Ender End Date',
                disablePast: true,
                type: 'datetime',
                class: `col-12 ${formikSelection.values.mainUpdate ? '' : 'd-none'}`,
                name: 'date_closed',
                minDate: dateTimeFormatFunction(new Date()),
                minDateMessage: `Date should not be before minimal date ${dateTimeFormatFunction(
                    new Date(),
                )}`,
                formik: formikSelection,
            },
        ],
    }

    const searchInfo = {
        formik: formik,
        data: [
            {
                label: 'Auction Lot Title',
                placeholder: 'Enter auction lot title',
                class: 'col-md-4 col-sm-6 col-12',
                type: 'text',
                name: 'searchterm',
                filter: true,
            },
            {
                label: 'Closed From Date',
                placeholder: 'Date Ending from',
                class: 'col-md-4 col-sm-6 col-12',
                type: 'date',
                name: 'fromdate',
                filter: true,
            },
            {
                label: 'Closed To Date',
                placeholder: 'Date Ending To',
                class: 'col-md-4 col-sm-6 col-12',
                type: 'date',
                name: 'todate',
                filter: true,
            },
        ],
    }

    useEffect(() => {
        if (responseStatusAuction) {
            if (
                responseStatusAuction.status === 'success' &&
                responseStatusAuction.from === 'auctionAction'
            ) {
                toggleFullScreenPopup(false)
                getAllAuctions(formik.values)
            }
        }
    }, [responseStatusAuction])

    return (
        <DashboardLayout>
            <div className="dashboardInner  auctionLots">
                <div className="auctionStatus">
                    <ListItem button>
                        <NavLink activeClassName="active" to="/seller/auctions/upcoming">
                            Upcoming
                        </NavLink>
                    </ListItem>

                    <ListItem button>
                        <NavLink activeClassName="active" to="/seller/auctions/open">
                            Active
                        </NavLink>
                    </ListItem>

                    <ListItem button>
                        <NavLink activeClassName="active" to="/seller/auctions/closed">
                            Past
                        </NavLink>
                    </ListItem>
                </div>
                <CustomSearch searchInfo={searchInfo} formik={formik} />
                <CustomTable
                    formik={formik.values}
                    tableBody={tableBody}
                    tableValues={tableValues}
                    tableActions={tableActions}
                    allData={all_auctions}
                    isLoading={isLoading}
                    loadingTemplate={'table'}
                    changeTableFunction={getAllAuctions}
                />
            </div>
            <AuctionManage data={manage} function={toggleFullScreenPopup} />
            <AuctionListing data={manage} function={toggleFullScreenPopup} />
            <CustomDialog
                title={'Change auction status'}
                open={changeStatus}
                function={changeChangeStatus}
            >
                <h5>
                    {formikSelection.values.action === 'closenow'
                        ? 'Are you sure you want to close the auctions and lots related'
                        : 'Are you sure you want to change the status'}
                </h5>
                {formikSelection.values.action === 'date_closed' && (
                    <div className="row">{mapData(dateClosed)}</div>
                )}
                <div className="actionWrapper">
                    <Button onClick={() => changeChangeStatus()}>Cancel</Button>
                    <form onSubmit={formikSelection.handleSubmit} autoComplete="nofill">
                        <PrimaryButton type="submit" label="Confirm" />
                    </form>
                </div>
            </CustomDialog>
        </DashboardLayout>
    )
}

export default AuctionLots
