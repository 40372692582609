import React, { useState, useContext, useEffect, useRef } from 'react'
import InvoiceCard from '../../custom/components/molecules/InvoiceCard'
import DashboardLayout from './DashboardLayout'
import InvoiceContext from '../../product/context/invoice/invoiceContext'
import AuthContext from '../../product/context/auth/authContext'
import AlertContext from '../../product/context/alert/alertContext'
import { Pagination } from '@material-ui/lab'
import NoRecordsFound from '../../product/components/atoms/NoRecordsFound'
import ShipmentTracking from '../../custom/components/organisms/ShipmentTracking'
import { useTranslation } from 'react-i18next'
import { ListItem } from '@material-ui/core'
import { NavLink } from 'react-router-dom'

function MyInvoices(props) {
    const authContext = useContext(AuthContext)
    const invoiceContext = useContext(InvoiceContext)
    const { t } = useTranslation()
    const { search_alluserinvoices, getAllUserInvoices } = invoiceContext
    const { isAuthenticated } = authContext

    let [viewProduct, setViewProduct] = useState([])

    const [search, setSearch] = useState({
        limit: 10,
        page: 1,
        orderby: 1,
        checkout: 1,
        action: props.match.params.action,
    })

    useEffect(() => {
        setSearch({
            ...search,
            action:
                props.match.params.action === 'all'
                    ? 'allinv'
                    : props.match.params.action === 'partial'
                    ? (props.match.params.action = 'unpaid')
                    : props.match.params.action,
        })
    }, [props.match.params.action])

    useEffect(() => {
        if (isAuthenticated) {
            getAllUserInvoices(search)
        }
    }, [search, isAuthenticated])

    const onHandlePage = (event, value) => {
        setSearch({ ...search, page: value })
    }

    useEffect(() => {
        setViewProduct(search_alluserinvoices.records.length ? search_alluserinvoices.records : [])
    }, [search_alluserinvoices])

    useEffect(() => {
        setViewProduct([])
        return () => {
            setViewProduct([])
        }
    }, [])

    const [manageTracking, setManageTracking] = useState({
        popup: false,
        status: 'new',
        data: null,
    })

    const toggleTrackingPopup = (popup, status, data) => {
        if (popup) {
            setManageTracking({ popup, status, data })
        } else {
            setManageTracking({ popup, status: 'new', data: null })
        }
    }

    return (
        <DashboardLayout title={'My Invoices'}>
            <div className="dashboardInner myInvoices">
                <div className="auctionStatus">
                    <ListItem button>
                        <NavLink activeClassName="active" to="/invoices/all">
                            All
                        </NavLink>
                    </ListItem>

                    <ListItem button>
                        <NavLink activeClassName="active" to="/invoices/paid">
                            Paid
                        </NavLink>
                    </ListItem>

                    <ListItem button>
                        <NavLink activeClassName="active" to="/invoices/partial">
                            Unpaid
                        </NavLink>
                    </ListItem>
                </div>
                {viewProduct.length ? (
                    <>
                        <div className="searchResults Grid">
                            {viewProduct.map((data, index) => (
                                <>
                                    <InvoiceCard
                                        data={data}
                                        toggleTrackingPopup={toggleTrackingPopup}
                                    />
                                </>
                            ))}
                        </div>
                        <div className="d-flex justify-content-between align-items-center flex-wrap w-100 my-3 pagination-wrapper col-md-12">
                            <h6>
                                {'Showing'}&nbsp; {search_alluserinvoices.setDisp} &nbsp;{' of '}
                                &nbsp;
                                {search_alluserinvoices.totalRecords}
                            </h6>
                            <Pagination
                                count={Math.ceil(
                                    search_alluserinvoices.totalRecords / search.limit,
                                )}
                                page={search.page}
                                onChange={onHandlePage}
                                siblingCount={3}
                                showFirstButton
                                showLastButton
                                boundaryCount={2}
                            />
                        </div>
                    </>
                ) : (
                    <NoRecordsFound />
                )}
            </div>
            <ShipmentTracking data={manageTracking} function={toggleTrackingPopup} />
        </DashboardLayout>
    )
}

export default MyInvoices
