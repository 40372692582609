import React, { useContext, useEffect } from 'react'
import PrimaryButton from '@/product/components/atoms/PrimaryButton'
import UserContext from '@/product/context/user/userContext'
import { mapData } from '@/product/common/components'
import { useFormik } from 'formik'
// import '../Dashboard.css'

const ChangePasswordComponent = (props) => {
    const userContext = useContext(UserContext)
    const { updateProfile, responseStatus: responseStatusUser } = userContext

    const formik = useFormik({
        initialValues: props.initialValues,
        validationSchema: props.validationSchema,
        onSubmit: (values) => {
            updateProfile(values)
        },
    })

    const changePasswordForm = props.formFields ? { formik: formik, ...props.formFields } : {}

    useEffect(() => {
        if (responseStatusUser) {
            if (responseStatusUser.from === 'updateProfile') {
                if (responseStatusUser.status === 'success') {
                    if (props.onSuccess) {
                        formik.resetForm()
                        props.onSuccess(responseStatusUser.data)
                    } else {
                        formik.resetForm()
                    }
                }
            }
        }
    }, [responseStatusUser])

    useEffect(() => {
        if (props.additionalfieldUpdate) {
            props.additionalfieldUpdate(formik.values)
        }
    }, [formik.values])

    return (
        <div className="accountCnt">
            <form onSubmit={formik.handleSubmit} autoComplete="nofill">
                <div className="row">{mapData(changePasswordForm)}</div>
                <PrimaryButton
                    id="changePassword_submit"
                    type="submit"
                    label={props.SubmitBtnLabel ? props.SubmitBtnLabel : 'change'}
                />
            </form>
        </div>
    )
}

export default ChangePasswordComponent
