import React, { useContext, useState, useEffect } from 'react'

import PrimaryButton from '../../../product/components/atoms/PrimaryButton'
import SecondaryButton from '../../../product/components/atoms/SecondaryButton'
import FullScreenPopup from '../../../product/components/organisms/FullScreenPopup'
import UserContext from '../../../product/context/seller/user/userContext'
import AuthContext from '../../../product/context/auth/authContext'
import { LinearProgress } from '@material-ui/core'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { mapData } from '../../../product/common/components'
import Loaders from '../../../product/components/molecules/Loaders'
import CustomTable from '../../../product/components/molecules/CustomTable'
import CustomSearch from '../../../product/components/molecules/CustomSearch'
import SettingContext from '../../../product/context/seller/setting/settingContext'
import CustomDialog from '../../../product/components/organisms/Dialog'
import { Button } from '@material-ui/core'
import './inviteUser.css'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Box from '@material-ui/core/Box'

function TabPanel(props) {
    const { children, value, index, ...other } = props

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    )
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    }
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
}))

const UserManage = (props) => {
    const userContext = useContext(UserContext)
    const settingcontext = useContext(SettingContext)
    const authContext = useContext(AuthContext)
    const { user, isAuthenticated } = authContext

    const { addInviteUser, responseStatus: responseStatusUser } = userContext

    const {
        searchTableAction,
        searchTable,
        table_data,
        responseStatus: responseStatusSetting,
        clearResponse: clearResponseSetting,
    } = settingcontext

    const [isLoading, setIsLoading] = useState(false)
    const data = props.data
    const toggleFullScreenPopup = props.function
    const classes = useStyles()
    const [value, setValue] = React.useState(0)

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    const validationArray = Yup.object({
        email: Yup.string()
            .email('Invalid email format')
            .max(250, 'Maximum 250 characters')
            .required('Required'),
        organization_id: Yup.number().required('Required'),
    })

    const [tableBody, setTableBody] = useState([])

    const formik = useFormik({
        initialValues: {
            email: '',
            organization_id: '',
        },
        validationSchema: validationArray,
        onSubmit: (values) => {
            setIsLoading(true)
            addInviteUser(values)
        },
    })

    const resetFrom = () => {
        formik.values.email = ''
        formik.handleReset()
        setIsLoading(false)
    }

    const IndividualRegistration = {
        formik: formik,
        data: [
            {
                label: 'Invite new user to Bid-a-Shift',
                name: 'email',
                type: 'email',
                placeholder: 'Enter Email Address',
                class: 'col-12 col-sm-12',
            },
        ],
    }

    const formSubmit = (e) => {
        formik.handleSubmit(e)
    }

    const [changeStatus, setChangeStatus] = useState(false)
    const changeChangeStatus = () => {
        setChangeStatus(!changeStatus)
    }

    const formikUsers = useFormik({
        initialValues: {
            page: 1,
            limit: 20,
            order: '',
            orderby: '',
            table: 'user_invite',
            filters: {
                email: {
                    value: '',
                    type: 'like',
                    field: 'usi.email',
                },
                first_name: {
                    value: '',
                    type: 'like',
                    field: 'usi.first_name',
                },
                last_name: {
                    value: '',
                    type: 'like',
                    field: 'usi.last_name',
                },
                organization_id: {
                    value: '',
                    type: 'in',
                    field: 'usi.organization_id',
                },
                // companyname: {
                //     value: '',
                //     type: 'like',
                //     field: 'u.companyname',
                // },
                // trakhees_reg_num: {
                //     value: '',
                //     type: 'like',
                //     field: 'cu.trakhees_reg_num',
                // },
                // dubai_customs_code: {
                //     value: '',
                //     type: 'like',
                //     field: 'cu.dubai_customs_code',
                // },
                // business_category: {
                //     value: [],
                //     type: 'array',
                //     field: 'cu.business_category',
                // },
            },
        },
        onSubmit: (values) => {
            setIsLoading(true)
            searchTable(values, 'user_invite')
        },
    })

    const formikSearchUsers = useFormik({
        initialValues: {
            page: 1,
            limit: 20,
            order: '',
            orderby: '',
            table: 'user_invite',
            filters: {
                email: {
                    value: '',
                    type: 'like',
                    field: 'usi.email',
                },
                organization_id: {
                    value: '',
                    type: 'in',
                    field: 'usi.organization_id',
                },
            },
        },
        onSubmit: (values) => {
            setIsLoading(true)
            searchTable(values, 'user_invite')
        },
    })

    useEffect(() => {
        if (user) {
            if (user && user.organization_id) {
                formik.setFieldValue('organization_id', user.organization_id)
                formikSearchUsers.values.filters.organization_id.value = user.organization_id
            }
        }
    }, [user])

    useEffect(() => {
        if (table_data && table_data.records) {
            if (table_data.from === 'user_invite') {
                setTableBody(table_data.records.length ? table_data.records : [])
                setIsLoading(false)
            }
        }
    }, [table_data])

    useEffect(() => {
        if (isAuthenticated) {
            if (user) {
                setTableBody([])
                setIsLoading(true)
                formikUsers.values.page = 1
                formikUsers.values.filters.email.value = ''
                formikUsers.values.filters.first_name.value = ''
                formikUsers.values.filters.last_name.value = ''
                formikUsers.values.filters.organization_id.value = user.organization_id
                // formik.values.filters.companyname.value = ''
                // formik.values.filters.trakhees_reg_num.value = ''

                searchTable(formikUsers.values, 'user_invite')
                /*  return () => {
                    setTableBody([])
                }*/
            }
        }
    }, [user, isAuthenticated])

    const searchInfo = {
        formik: formikSearchUsers,
        data: [
            {
                label: 'Search user for status',
                placeholder: 'Enter Email Address',
                class: 'col-md-12 col-sm-6 col-12',
                type: 'text',
                name: 'email',
                filter: true,
            },
        ],
    }

    const tableValues = [
        {
            field: 'email',
            type: '',
            firstChild: true,
            sort: true,
            disablePadding: false,
            label: 'Email',
        },
        {
            field: 'status',
            type: '',
            firstChild: true,
            sort: true,
            disablePadding: false,
            label: 'Status',
        },
        {
            field: 'created_at',
            type: 'datetime',
            firstChild: true,
            sort: true,
            disablePadding: false,
            label: 'Created At',
        },
    ]

    const formikSelection = useFormik({
        initialValues: {
            action: '',
            status: '',
            id: 0,
            table: 'user_invite',
        },
        onSubmit: (values) => {
            const dataValue = values
            console.log('hdbhjd', dataValue)
            if (dataValue.action === 'deleted') {
                dataValue.status = dataValue.action === 'deleted' ? 'deleted' : 'pending'
                delete dataValue.action
                searchTableAction(values)
            }
        },
    })

    const onSelectMultiProducts = (data, action) => {
        formikSelection.values.action = action
        formikSelection.values.id = data
        if (action === 'deleted') {
            setChangeStatus(true)
        }
    }

    const tableActions = [
        {
            label: 'Move to Deleted',
            icon: <span className="material-icons">block</span>,
            onclick: onSelectMultiProducts,
            type: 'deleted',
        },
    ]

    useEffect(() => {
        if (responseStatusSetting) {
            if (
                responseStatusSetting.from === 'CommunicationSearchTable' &&
                responseStatusSetting.status === 'success'
            ) {
                setChangeStatus(false)
                // toggleFullScreenPopup(false)
                setTableBody([])
                searchTable(formikUsers.values, 'user_invite')
            }
        }
    }, [responseStatusSetting])

    useEffect(() => {
        if (responseStatusUser) {
            if (responseStatusUser.from === 'addInviteUser') {
                formik.setSubmitting(false)
                searchTable(formikUsers.values, 'user_invite')
                setIsLoading(false)
            }
        }
    }, [responseStatusUser])
    return (
        <FullScreenPopup
            className={'adTempltPopup'}
            modaltitle={'INVITE USER'}
            open={data.popup}
            handleClose={() => toggleFullScreenPopup(false)}
        >
            <div className="addUserModal">
                <div className="fspBody">
                    {formik.isSubmitting ? <LinearProgress /> : null}
                    {isLoading ? (
                        <div className="fspLoader">
                            <Loaders isLoading={isLoading} />
                        </div>
                    ) : (
                        <>
                            <AppBar position="static" color="default">
                                <Tabs
                                    value={value}
                                    onChange={handleChange}
                                    aria-label="simple tabs example"
                                >
                                    <Tab label="New user" {...a11yProps(0)} />
                                    <Tab label="Existing User" {...a11yProps(1)} />
                                </Tabs>
                            </AppBar>
                            <TabPanel value={value} index={0}>
                                <form onSubmit={formSubmit} autoComplete="nofill">
                                    <div>
                                        {/* <h2 className="dashTitle">INDIVIDUAL</h2> */}
                                        <div className="row">{mapData(IndividualRegistration)}</div>
                                    </div>
                                    <p className="bdInfoTxt">
                                        *This will send an email to your bidder with a link for them
                                        to register
                                    </p>
                                    <div className="actionButton d-flex justify-content-center align-items-center flex-wrap">
                                        <SecondaryButton
                                            label="Cancel"
                                            onClick={() => toggleFullScreenPopup(false)}
                                        />
                                        <PrimaryButton
                                            btnSize="ml-2"
                                            type="submit"
                                            label="Send Invite"
                                        />
                                    </div>
                                </form>
                            </TabPanel>
                            <TabPanel value={value} index={1}>
                                <div className="dashboardInner userManagement mt-4">
                                    <CustomSearch
                                        searchInfo={searchInfo}
                                        formik={formikSearchUsers}
                                    />
                                    <CustomTable
                                        formik={formikUsers.values}
                                        tableBody={tableBody}
                                        tableValues={tableValues}
                                        tableActions={tableActions}
                                        allData={table_data}
                                        isLoading={isLoading}
                                        loadingTemplate={'table'}
                                        changeTableFunction={searchTable}
                                        fromVariable="user_invite"
                                    />
                                </div>
                            </TabPanel>
                        </>
                    )}
                </div>

                <CustomDialog
                    title={'Change status'}
                    open={changeStatus}
                    function={changeChangeStatus}
                >
                    <h5>
                        {formikSelection.values.action === 'deleted'
                            ? 'Are you sure you want to delete'
                            : 'Are you sure you want to change the status'}
                    </h5>
                    <div className="actionWrapper">
                        <Button onClick={() => changeChangeStatus()}>Cancel</Button>
                        <form onSubmit={formikSelection.handleSubmit} autoComplete="nofill">
                            <PrimaryButton type="submit" label="Confirm" />
                        </form>
                    </div>
                </CustomDialog>
            </div>
        </FullScreenPopup>
    )
}

export default UserManage
