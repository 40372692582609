import React, { useState, useContext, useEffect, useRef } from 'react'
import openSocket from 'socket.io-client'
import PrimaryButton from '../../product/components/atoms/PrimaryButton'
import CartItem from '../../custom/components/molecules/ProductCard/CartItem'
import { useHistory } from 'react-router-dom'
import CartContext from '../../product/context/cart/cartContext'
import AuthContext from '../../product/context/auth/authContext'
import AlertContext from '../../product/context/alert/alertContext'
import { useTranslation } from 'react-i18next'
import { handleRedirectInternal } from '../../product/common/components'
import './Cart.css'
import EmptyCart from '../EmptyCart'
import { currencyFormat } from '../../custom/common/components'
import Loaders from '../../custom/components/molecules/Loaders'
import CustomAlert from '../../product/components/atoms/CustomAlert'

const Cart = () => {
    const cartContext = useContext(CartContext)
    const authContext = useContext(AuthContext)
    const alertContext = useContext(AlertContext)
    const history = useHistory()
    const { t } = useTranslation()
    const [isLoading, setIsLoading] = useState(true)
    const { search_allproducts, changeOnCart, getAllCartProducts, responseStatus, clearResponse } =
        cartContext
    const { isAuthenticated, loadPendingCount } = authContext

    const { setAlert } = alertContext

    let [cartProducts, setCartProducts] = useState([])
    let [nonCartProducts, setNonCartProducts] = useState([])
    let [cartValues, setCartValues] = useState({})

    const [search, setSearch] = useState({})

    useEffect(() => {
        const socket = openSocket(`${process.env.REACT_APP_DOMAIN}/`)
        console.log('dkjd', socket)
        socket.on('cartProducts', (data) => {
            setIsLoading(true)
            getAllCartProducts(search)
            console.log('dgfh', data)
        })
        return () => {
            socket.off('cartProducts', (data) => {
                console.log(data, 'poioiuiu')
            })
            socket.disconnect()
        }
    }, [])

    useEffect(() => {
        if (isAuthenticated) {
            setIsLoading(true)
            getAllCartProducts(search)
        }
    }, [isAuthenticated])

    useEffect(() => {
        setCartProducts(search_allproducts.cartrecords.length ? search_allproducts.cartrecords : [])
        setNonCartProducts(
            search_allproducts.noncartrecords.length ? search_allproducts.noncartrecords : [],
        )

        let auctionFilter = {
            table: 'buynow',
            field: 'id',
            extraField: '',
            filters: {
                buynow_autype: {
                    value: 'live',
                    type: 'in',
                    field: 'b.buynow_autype',
                },
                paid: {
                    value: '0',
                    type: 'in',
                    field: 'b.paid',
                },
                partial: {
                    value: '0',
                    type: 'in',
                    field: 'b.partial',
                },
            },
        }
        loadPendingCount(auctionFilter, 'auctionCount')

        setTimeout(() => {
            setIsLoading(false)
        }, 1500)
    }, [search_allproducts])

    useEffect(() => {
        if (responseStatus) {
            if (responseStatus.from === 'cartchange' || responseStatus.from === 'addToCart') {
                if (responseStatus.status === 'success') {
                    getAllCartProducts(search)
                }
            }
        }
    }, [responseStatus])

    return (
        <div className="cart">
            {isLoading ? (
                <div className="container">
                    <Loaders name="cart" isLoading={isLoading} loop={1} />
                </div>
            ) : (
                <>
                    {nonCartProducts.length || cartProducts.length ? (
                        <>
                            {cartProducts.length ? (
                                <>
                                    {cartProducts.map((data, index) => (
                                        <>
                                            {data.cartItems.length ? (
                                                <>
                                                    <div className="customContainer">
                                                        <div className="cartCnt d-flex justify-content-between">
                                                            <div className="cartLt">
                                                                <div className="cartProducts">
                                                                    <div className="cartHead d-flex justify-content-start align-items-center">
                                                                        <h1>
                                                                            {t('shopping_cart')}
                                                                        </h1>
                                                                        <span>
                                                                            <span>
                                                                                {
                                                                                    data.cartItems
                                                                                        .length
                                                                                }{' '}
                                                                                results found
                                                                            </span>
                                                                        </span>
                                                                    </div>
                                                                    <div>
                                                                        <h3 className="crtActnTl">
                                                                            {
                                                                                data.cartItems[0]
                                                                                    .auctionlot_title
                                                                            }
                                                                        </h3>
                                                                        {data.cartItems.map(
                                                                            (data, index) => (
                                                                                <CartItem
                                                                                    key={index}
                                                                                    data={data}
                                                                                    from="cart"
                                                                                    changeOnCart={
                                                                                        changeOnCart
                                                                                    }
                                                                                />
                                                                            ),
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="cartRt">
                                                                <div className="cartSummary">
                                                                    <div>
                                                                        <h3>
                                                                            {t(
                                                                                'shopping_cart_summary',
                                                                            )}
                                                                        </h3>
                                                                        <div className="cartSummaryValue">
                                                                            <label>
                                                                                {t('total_payable')}{' '}
                                                                                (
                                                                                {
                                                                                    data.cartValues
                                                                                        .total_items
                                                                                }{' '}
                                                                                {t('items')}):
                                                                            </label>
                                                                            <h5>
                                                                                {currencyFormat(
                                                                                    data.cartValues
                                                                                        .total_amount,
                                                                                )}
                                                                            </h5>
                                                                        </div>
                                                                    </div>
                                                                    <PrimaryButton
                                                                        label={t(
                                                                            'proceed_to_checkout',
                                                                        )}
                                                                        onClick={() => {
                                                                            /*updateCart(
                                                                                data.cartValues.common_invoice.toString(),
                                                                            ),*/
                                                                            handleRedirectInternal(
                                                                                history,
                                                                                `checkout/auction/${data.cartValues.cart_group}`,
                                                                            )
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            ) : (
                                                <></>
                                                // <div className="cartRt">
                                                //     <div className="cartSummary">
                                                //         <h3>Shopping Cart Summary</h3>
                                                //         <label>Subtotal (0 Items)</label>
                                                //         <h5>{currencyFormat(0)}</h5>
                                                //     </div>
                                                // </div>
                                            )}
                                        </>
                                    ))}
                                </>
                            ) : null}
                            <div className="cart customContainer">
                                <div className="d-flex justify-content-between">
                                    <div className="cartLt">
                                        {nonCartProducts.length ? (
                                            <div className="cartProducts">
                                                <div className="cartHead d-flex justify-content-start align-items-center">
                                                    <h1>{t('your_items')}</h1>
                                                    <span>
                                                        {nonCartProducts.length ? (
                                                            <span>
                                                                {nonCartProducts.length}{' '}
                                                                {t('results_found')}
                                                            </span>
                                                        ) : null}
                                                    </span>
                                                </div>
                                                <div>
                                                    {nonCartProducts.map((data, index) => (
                                                        <CartItem
                                                            data={data}
                                                            key={index}
                                                            from="cart"
                                                            blockRemoveCart={false}
                                                            changeOnCart={changeOnCart}
                                                        />
                                                    ))}
                                                </div>
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : (
                        <div className="cart customContainer">
                            <div className="d-flex justify-content-between">
                                <EmptyCart />
                            </div>
                        </div>
                    )}
                </>
            )}
        </div>
    )
}

export default Cart
