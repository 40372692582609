import React from 'react'
import './App.css'
import Routes from './routes'
import { BrowserRouter as Router } from 'react-router-dom'
import { CookiesProvider } from 'react-cookie'
import { I18nextProvider } from 'react-i18next'
import AuthState from './product/context/auth/authState'
import CustomCommonState from './custom/context/common/commonState'
import ProductCommonState from './product/context/common/commonState'
import UserState from './product/context/user/userState'
import AlertState from './product/context/alert/alertState'
import ProductState from './product/context/product/productState'
import InvoiceState from './product/context/invoice/invoiceState'
import AuctionState from './product/context/auction/auctionState'
import CreditState from './product/context/credit/creditState'
import OfflineState from './product/context/offline/offlineState'
import CartState from './product/context/cart/cartState'
import CardState from './product/context/stripe/card/cardState'
import CustomerState from './product/context/stripe/customer/customerState'
import BankState from './product/context/stripe/bank/bankState'
import BuyerState from './product/context/buyer/buyerState'
import AutopltState from './product/context/autopilot/autopltState'
import PayState from './product/context/payment/payState'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import { SnackbarProvider } from 'notistack'
import Alerts from './product/common/alert'
import BuynowState from './product/context/buynow/buynowState'
import AppointmentState from './product/context/appointment/appointmentState'
import i18n from './product/common/language-i18n'
import withRoot from './withRoot'
import ShipengineState from './product/context/shipping/shipengine/shipengineState'
import ArtaShippingState from './product/context/shipping/arta/artaState'
import ShippingCommonState from './product/context/shipping/common/commonState'
import SellerProductState from './product/context/seller/product/productState'
import SellerSettingState from './product/context/seller/setting/settingState'
import SellerAuctionState from './product/context/seller/auction/auctionState'
import SellerBidState from './product/context/seller/bid/bidState'
import TemplateProductState from './product/context/seller/template/product/productState'
import TemplateAuctionState from './product/context/seller/template/auction/auctionState'
import HeadAlert from './custom/components/molecules/HeadAlert'
import NotifyState from './product/context/notify/notifyState'
import ReverseBidState from './product/context/reverse/bid/bidState'
import UserStateseller from './product/context/seller/user/userState'
import SellerReportState from './product/context/seller/report/reportState'
import MembershipState from './product/context/membership/membershipState'

global.site_url = process.env.REACT_APP_DOMAIN
global.images_url = global.site_url + '/uploads/product/'
//'3801', '3804'
global.ignoreStates = []
global.ignoreCountry = []
global.defaultCountry = 'US'
/**
 * Main component it will return whole app
 *
 * @component
 * @example
 * return (
 *   <CookiesProvider>
 *   ...
 *   </CookiesProvider>
 * )
 */
const App = () => {
    return (
        <CookiesProvider>
            <I18nextProvider i18n={i18n}>
                <CustomCommonState>
                    <ProductCommonState>
                        <AuthState>
                            <AlertState>
                                <UserState>
                                    <UserStateseller>
                                        <NotifyState>
                                            <CustomerState>
                                                <CardState>
                                                    <BankState>
                                                        <ProductState>
                                                            <AuctionState>
                                                                <BuynowState>
                                                                    <CartState>
                                                                        <AppointmentState>
                                                                            <CreditState>
                                                                                <PayState>
                                                                                    <ReverseBidState>
                                                                                        <SellerReportState>
                                                                                            <SellerSettingState>
                                                                                                <OfflineState>
                                                                                                    <InvoiceState>
                                                                                                        <BuyerState>
                                                                                                            <AutopltState>
                                                                                                                <SellerProductState>
                                                                                                                    <SellerAuctionState>
                                                                                                                        <TemplateProductState>
                                                                                                                            <TemplateAuctionState>
                                                                                                                                <ArtaShippingState>
                                                                                                                                    <ShippingCommonState>
                                                                                                                                        <ShipengineState>
                                                                                                                                            <MembershipState>
                                                                                                                                                <SellerBidState>
                                                                                                                                                    <MuiPickersUtilsProvider
                                                                                                                                                        utils={
                                                                                                                                                            MomentUtils
                                                                                                                                                        }
                                                                                                                                                    >
                                                                                                                                                        <SnackbarProvider
                                                                                                                                                            maxSnack={
                                                                                                                                                                3
                                                                                                                                                            }
                                                                                                                                                        >
                                                                                                                                                            <div className="App">
                                                                                                                                                                <Alerts />
                                                                                                                                                                <HeadAlert />
                                                                                                                                                                <Router>
                                                                                                                                                                    <Routes />
                                                                                                                                                                </Router>
                                                                                                                                                            </div>
                                                                                                                                                        </SnackbarProvider>
                                                                                                                                                    </MuiPickersUtilsProvider>
                                                                                                                                                </SellerBidState>
                                                                                                                                            </MembershipState>
                                                                                                                                        </ShipengineState>
                                                                                                                                    </ShippingCommonState>
                                                                                                                                </ArtaShippingState>
                                                                                                                            </TemplateAuctionState>
                                                                                                                        </TemplateProductState>
                                                                                                                    </SellerAuctionState>
                                                                                                                </SellerProductState>
                                                                                                            </AutopltState>
                                                                                                        </BuyerState>
                                                                                                    </InvoiceState>
                                                                                                </OfflineState>
                                                                                            </SellerSettingState>
                                                                                        </SellerReportState>
                                                                                    </ReverseBidState>
                                                                                </PayState>
                                                                            </CreditState>
                                                                        </AppointmentState>
                                                                    </CartState>
                                                                </BuynowState>
                                                            </AuctionState>
                                                        </ProductState>
                                                    </BankState>
                                                </CardState>
                                            </CustomerState>
                                        </NotifyState>
                                    </UserStateseller>
                                </UserState>
                            </AlertState>
                        </AuthState>
                    </ProductCommonState>
                </CustomCommonState>
            </I18nextProvider>
        </CookiesProvider>
    )
}

export default withRoot(App)
