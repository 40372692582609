import React, { useContext, useState, useEffect } from 'react'

import DashboardLayout from '../../Dashboard/DashboardLayout'
import { Button, IconButton, ListItem } from '@material-ui/core'
import { NavLink } from 'react-router-dom'

import AuthContext from '../../../product/context/auth/authContext'

import BankDetails from '../../../custom/components/organisms/BankDetails'

import StripeBankContext from '../../../product/context/stripe/bank/bankContext'
import StripeCustomerContext from '../../../product/context/stripe/customer/customerContext'

const Bank = () => {
    const authContext = useContext(AuthContext)
    const { createStripeCustomer } = useContext(StripeCustomerContext)

    const [isLoading, setIsLoading] = useState(false)
    const [manageBank, setManageBank] = useState({
        popup: false,
        status: 'new',
        data: null,
    })

    const toggleFullScreenBankPopup = (popup, status, data) => {
        setManageBank({ popup, status, data })
    }

    const {
        get_all_bank_details,
        getAllStripeBanks,
        responseStatus: responseStatusBank,
        clearResponse: clearResponseBank,
    } = useContext(StripeBankContext)

    const { user, isAuthenticated } = authContext

    const [banks, setBanks] = useState([])

    const getStripeBanks = () => {
        getAllStripeBanks({
            account_id: process.env.REACT_APP_STRIPE_ACCOUNT,
            customer_id: user.stripe_id,
            object: 'bank_account',
        })
        toggleFullScreenBankPopup(false, 'new', null)
    }

    useEffect(() => {
        if (get_all_bank_details) {
            setBanks(get_all_bank_details.records)
        }
        return () => {
            setBanks([])
        }
    }, [get_all_bank_details])

    useEffect(() => {
        if (isAuthenticated) {
            if (user) {
                if (user.id) {
                    if (user.stripe_id) {
                        getStripeBanks()
                    } else {
                        createStripeCustomer(
                            {
                                account_id: process.env.REACT_APP_STRIPE_ACCOUNT,
                                description: 'TEST CUSTOMER',
                                email: user.email,
                                name: user.first_name + ' ' + user.last_name,
                            },
                            true,
                        )
                    }
                }
            }
        }
    }, [user, isAuthenticated])

    useEffect(() => {
        if (responseStatusBank) {
            if (
                responseStatusBank.from === 'bank_source_create' ||
                responseStatusBank.from === 'bank_source_update' ||
                responseStatusBank.from === 'bank_source_delete'
            ) {
                if (responseStatusBank.status === 'success') {
                    getStripeBanks()
                }
            }
            setIsLoading(false)
        }
    }, [responseStatusBank])

    return (
        <DashboardLayout title={'BANK DETAILS'}>
            <div className="dashboardInner mySavedCards">
                <div className="auctionStatus">
                    <ListItem button>
                        <NavLink activeClassName="active" to="/seller/payments/saved_cards">
                            Card Details
                        </NavLink>
                    </ListItem>

                    <ListItem button>
                        <NavLink activeClassName="active" to="/seller/payments/bank_details">
                            Bank Details
                        </NavLink>
                    </ListItem>

                    <ListItem button>
                        <NavLink activeClassName="active" to="/seller/payments/subscription">
                            My Subscription
                        </NavLink>
                    </ListItem>
                </div>
                <div
                    className="emptySavedCard adBnkCard"
                    onClick={() => toggleFullScreenBankPopup(true, 'new', null)}
                >
                    <span className="material-icons">account_balance</span>
                    <h6>Add new Bank Details</h6>
                </div>
                {banks.length > 0
                    ? banks.map((bank, index) => {
                          return (
                              <div className="savedAddressCard" key={index}>
                                  <h4>
                                      <span>Bank Name:</span>
                                      <br />
                                      {bank.bank_name}
                                  </h4>
                                  <h4>
                                      <span>Account holder name:</span>
                                      <br />
                                      {bank.account_holder_name}
                                  </h4>
                                  <div className="d-flex justify-content-end mt-3">
                                      {bank.status === 'new' && (
                                          <Button
                                              onClick={() => {
                                                  toggleFullScreenBankPopup(true, 'verify', bank)
                                              }}
                                          >
                                              Verify
                                          </Button>
                                      )}
                                      <Button
                                          onClick={() => {
                                              toggleFullScreenBankPopup(true, 'update', bank)
                                          }}
                                      >
                                          Edit
                                      </Button>
                                      <Button
                                          onClick={() => {
                                              toggleFullScreenBankPopup(false, 'delete', bank)
                                          }}
                                      >
                                          delete
                                      </Button>

                                      {/* {data.is_primary == '0' ? (
                                            <Button
                                                onClick={addcarddetails(data, 'default')}
                                            >
                                                Make to Default
                                            </Button>
                                        ) : (
                                            <Button
                                                onClick={addcarddetails(data, 'remove')}
                                            >
                                                Remove from Default
                                            </Button>
                                        )} */}
                                  </div>
                              </div>
                          )
                      })
                    : ''}
                <BankDetails data={manageBank} function={toggleFullScreenBankPopup} />
            </div>
        </DashboardLayout>
    )
}

export default Bank
