import { Button } from '@material-ui/core'
import React, { useState, useContext, useEffect } from 'react'
import PrimaryButton from '../../../../../product/components/atoms/PrimaryButton'
import '../../../molecules/ProductCard/ProductCard.css'
import SecondaryButton from '../../../../../product/components/atoms/SecondaryButton'
import { useHistory } from 'react-router-dom'
// import Popup from '../../../../product/components/organisms/Popup'
// import Timer from '../../../common/timer'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import BuyerContext from '../../../../../product/context/buyer/buyerContext'
import AuthContext from '../../../../../product/context/auth/authContext'
import CustomDialog from '../../../organisms/Dialog'
import { handleRedirectInternal } from '../../../../common/components'
import { currencyFormat } from '../../../../common/components'
import { mapData } from '../../../../../product/common/components'

const Bidding = (props) => {
    const buyerContext = useContext(BuyerContext)
    const authContext = useContext(AuthContext)
    let { preBidConfirm } = buyerContext
    const { user, isAuthenticated } = authContext
    const [product, setProduct] = React.useState()
    const history = useHistory()

    const [toggleDialog, setToggleDialog] = useState(false)

    const changeDialogStatus = () => {
        setToggleDialog(!toggleDialog)
    }

    useEffect(() => {
        setProduct(props.data)
    }, [props.data])

    const validationArray = Yup.object({
        wsprice: Yup.number()
            .min(1, `${'Min Bid'} ${currencyFormat(1)}`)
            .integer('Decimal not accept')
            .max(
                product ? product.next_bid : 0,
                `${'Max Bid'} ${product ? currencyFormat(product.next_bid) : currencyFormat(0)}`,
            )
            .required('Enter Bid Amount'),
    })

    const formik = useFormik({
        initialValues: {
            wsprice: '',
            id: props.data.id,
            bidding_type: props.type,
        },
        validationSchema: validationArray,
        enableReinitialize: true,
        onSubmit: (values) => {
            if (toggleDialog) {
                preBidConfirm(values)
                formik.values.wsprice = ''
                setToggleDialog(false)
            } else {
                setToggleDialog(true)
            }
        },
    })

    useEffect(() => {
        formik.values.bidding_type = props.type
    }, [props.type])

    const removeAlpha = (evt) => {
        const re = /^[+-]?\d*(?:[.,]\d*)?$/
        if (re.test(evt.target.value)) {
            formik.handleChange(evt)
        }
        // evt.target.value === 'e' && evt.preventDefault()
    }
    const bidAmount = [
        {
            helperText:
                props.helpText &&
                (product && product.bidhistory.length > 0
                    ? 'Enter ' + currencyFormat(product.next_bid) + ' or less'
                    : product && 'Maximum Bid' + ' ' + currencyFormat(product.next_bid)),
            label: 'Enter Bid Amt',
            name: 'wsprice',
            type: 'number',
            placeholder: `${'Starting Bid'} ${
                product && product.next_bid ? currencyFormat(product.next_bid) : currencyFormat(0)
            }`,
            class: '',
            size: 'small',
            disabled: false,
            autoFocus: false,
            formik: formik,
            onChange: removeAlpha,
        },
    ]

    return (
        <>
            {product ? (
                <>
                    {product.prebid_data && props.showMessage ? (
                        <>
                            Active Pre Bid: {currencyFormat(product.prebid_data.proposed_amount)} as{' '}
                            {product.prebid_data.proposal === 'hard' ? 'Fixed Single' : 'Proxy'}
                        </>
                    ) : (
                        <> </>
                    )}
                    {product.market_status === 'open' ? (
                        <>
                            <form onSubmit={formik.handleSubmit}>
                                <div className="biddingCnt d-flex justify-content-between align-items-start">
                                    {mapData(bidAmount)}

                                    {isAuthenticated ? (
                                        <>
                                            {product.prebid_data ? (
                                                <>
                                                    {product.prebid_data.proposal === 'hard' &&
                                                    formik.values.bidding_type === 'hard' ? (
                                                        <>
                                                            <SecondaryButton
                                                                label={'Edit Fixed Single Pre-Bid'}
                                                                type="submit"
                                                                btnSize="small"
                                                                disabled={false}
                                                            />
                                                        </>
                                                    ) : product.prebid_data.proposal === 'proxy' &&
                                                      formik.values.bidding_type === 'proxy' ? (
                                                        <>
                                                            <SecondaryButton
                                                                label={'Edit Proxy Pre-Bid'}
                                                                type="submit"
                                                                btnSize="small"
                                                                disabled={false}
                                                            />{' '}
                                                        </>
                                                    ) : (
                                                        <>
                                                            <PrimaryButton
                                                                label={
                                                                    formik.values.bidding_type ===
                                                                    'hard'
                                                                        ? 'Place fixed single bid Pre-Bid'
                                                                        : 'Place Proxy Pre-Bid'
                                                                }
                                                                type="submit"
                                                                btnSize="small"
                                                                disabled={false}
                                                            />{' '}
                                                        </>
                                                    )}
                                                </>
                                            ) : (
                                                <PrimaryButton
                                                    label={
                                                        formik.values.bidding_type === 'hard'
                                                            ? 'Place Fixed single Pre-Bid'
                                                            : 'Place Proxy Pre-Bid'
                                                    }
                                                    type="submit"
                                                    btnSize="small"
                                                    disabled={false}
                                                />
                                            )}
                                        </>
                                    ) : (
                                        <PrimaryButton
                                            label={'Login to Bid'}
                                            btnSize="small"
                                            onClick={() => handleRedirectInternal(history, `login`)}
                                        />
                                    )}
                                </div>
                            </form>
                        </>
                    ) : null}

                    <CustomDialog
                        title={'Confirm Bid'}
                        open={toggleDialog}
                        function={changeDialogStatus}
                    >
                        <h5>{'You are about to place a bid'}!</h5>
                        <h5>{'Please confirm if you want to continue with this action'}</h5>
                        <div className="actionWrapper">
                            <Button onClick={() => setToggleDialog(false)}>{'Cancel'}</Button>
                            <PrimaryButton
                                onClick={formik.handleSubmit}
                                type="button"
                                label={'Submit'}
                            />
                        </div>
                    </CustomDialog>
                </>
            ) : null}
        </>
    )
}

export default Bidding
