import React, { useState, useContext, useEffect } from 'react'
import { Button, IconButton, ListItem } from '@material-ui/core'
import { NavLink } from 'react-router-dom'
import DashboardLayout from '../../Dashboard/DashboardLayout'
import PrimaryButton from '../../../product/components/atoms/PrimaryButton'
import SecondaryButton from '../../../product/components/atoms/SecondaryButton'
import { mapData } from '../../../product/common/components'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import authContext from '../../../product/context/auth/authContext'
import StripeCardContext from '../../../product/context/stripe/card/cardContext'
import StripeCustomerContext from '../../../product/context/stripe/customer/customerContext'
import UserContext from '../../../product/context/user/userContext'
import SkyflowCard from '../../../custom/components/organisms/SkyflowCardDetails'

const SkyflowCardDetails = (props) => {
    const [customer, setCustomer] = useState(null)
    const [cards, setCards] = useState([])
    const { user, loadUser, isAuthenticated } = useContext(authContext)

    const { customer_details, createStripeCustomer, getStripeCustomer } =
        useContext(StripeCustomerContext)

    const {
        getSkyflowCredit,
        skyflow_cards,
        responseStatus: responseStatusCard,
        clearResponse: clearResponseCard,
    } = useContext(UserContext)

    const [manageCard, setManageCard] = useState({
        popup: false,
        status: 'new',
        data: null,
    })
    const toggleFullScreenCardPopup = (popup, status, data) => {
        setManageCard({ popup, status, data })
    }

    const getStripeCustomerDetails = () => {
        if (user && user.stripe_id) {
            getStripeCustomer({
                account_id: process.env.REACT_APP_STRIPE_ACCOUNT,
                customer_id: user.stripe_id,
            })
        }
    }

    const { t } = useTranslation()
    //const [addCard, setAddCard] = useState(false)
    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
            confirm_password: '',
        },
    })
    /*const addCardData = [
        {
            label: t('name'),
            name: 'name',
            type: 'email',
            placeholder: t('enter_card_holder_name'),
            class: 'col-12',
            autoFocus: true,
            formik: formik,
        },
        {
            label: t('card_number'),
            name: 'card_number',
            type: 'number',
            placeholder: t('enter_card_number'),
            class: 'col-12',
            formik: formik,
        },
        {
            label: t('name'),
            name: 'name',
            type: 'email',
            placeholder: t('enter_card_holder_name'),
            class: 'col-12',
            autoFocus: true,
            formik: formik,
        },
        {
            label: 'CVV',
            name: 'cvv',
            type: 'text',
            placeholder: t('enter') + ' CVV',
            class: 'col-12',
            formik: formik,
        },
        {
            label: t('expiry_date'),
            name: 'expiry_date',
            type: 'date',
            placeholder: t('date_of_card_expiration'),
            class: 'col-12',
            formik: formik,
        },
    ]*/

    useEffect(() => {
        if (isAuthenticated) {
            if (user) {
                if (user.id) {
                    if (user.stripe_id) {
                        getSkyflowCredit()
                        getStripeCustomerDetails()
                    } else {
                        createStripeCustomer(
                            {
                                account_id: process.env.REACT_APP_STRIPE_ACCOUNT,
                                description: 'TEST CUSTOMER',
                                email: user.email,
                                name: user.first_name + ' ' + user.last_name,
                            },
                            true,
                        )
                    }
                }
            }
        }
    }, [user, isAuthenticated])

    useEffect(() => {
        if (responseStatusCard) {
            if (
                responseStatusCard.from === 'skyflow_add' ||
                responseStatusCard.from === 'skyflow_update' ||
                responseStatusCard.from === 'skyflow_delete'
            ) {
                if (responseStatusCard.status === 'success') {
                    getSkyflowCredit()
                }
            }
            // setIsLoading(false)
        }
    }, [responseStatusCard])

    useEffect(() => {
        if (skyflow_cards) {
            // console.log('!@#get_all_card_details', get_all_card_details)
            setCards(skyflow_cards)
        }
        return () => {
            setCards([])
        }
    }, [skyflow_cards])

    useEffect(() => {
        if (customer_details) {
            setCustomer(customer_details)
        }
        return () => {
            setCustomer(null)
        }
    }, [customer_details])

    return (
        <DashboardLayout title={'CARD DETAILS'}>
            <div className="dashboardInner mySavedCards">
                <div className="auctionStatus">
                    <ListItem button>
                        <NavLink activeClassName="active" to="/seller/payments/saved_cards">
                            Card Details
                        </NavLink>
                    </ListItem>

                    <ListItem button>
                        <NavLink activeClassName="active" to="/seller/payments/bank_details">
                            Bank Details
                        </NavLink>
                    </ListItem>

                    <ListItem button>
                        <NavLink activeClassName="active" to="/seller/payments/subscription">
                            My Subscription
                        </NavLink>
                    </ListItem>
                </div>
                <div
                    className="emptySavedCard"
                    onClick={() => toggleFullScreenCardPopup(true, 'new', null)}
                >
                    <img src="/assets/images/cards.png" alt="bank" className="mt-1 mb-1" />
                    <h6>ADD A NEW CARD</h6>
                    <img src="/assets/images/ccard.png" alt="bank" className="mt-1 mb-1" />
                </div>
                {cards.length > 0
                    ? cards.map((card) => {
                          return (
                              // eslint-disable-next-line react/jsx-key
                              <div
                                  className={`saved-card ${
                                      customer && card.id === customer.default_source
                                          ? 'default'
                                          : ''
                                  }`}
                              >
                                  <div className="filledSavedCard">
                                      {customer && card.id === customer.default_source ? (
                                          <p className="defaultCard">Default</p>
                                      ) : (
                                          ''
                                      )}

                                      <div className="row mt-2">
                                          <div className="col-6 text-left">
                                              <img src="/assets/images/sim.png" alt="bank" />
                                          </div>
                                          {/* <div className="sc-primary-label">Primary Card</div> */}
                                      </div>
                                      <div className="card-num">
                                          <span>****</span>
                                          <span>****</span>
                                          <span>****</span>
                                          <span>{card.card_last4}</span>
                                      </div>
                                      {/* {console.log('!@#card', card)} */}
                                      <div className="clearfix">
                                          <div className="float-left">
                                              <div className="fs-12">{card.brand}</div>
                                          </div>
                                          <div className="float-right">
                                              <div className="fs-12">
                                                  {card.expiry_month} / {card.expiry_year}
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                                  <div className="sc-btns-wrap d-flex align-items-center justify-content-between">
                                      <IconButton
                                          className="edtBtn"
                                          onClick={() =>
                                              toggleFullScreenCardPopup(true, 'update', card)
                                          }
                                      >
                                          <span className="material-icons">edit</span>
                                      </IconButton>
                                      {cards.length > 1 && (
                                          <>
                                              {customer && card.id !== customer.default_source && (
                                                  <>
                                                      <IconButton
                                                          className="defaultBtn"
                                                          onClick={() =>
                                                              toggleFullScreenCardPopup(
                                                                  true,
                                                                  'default',
                                                                  card,
                                                              )
                                                          }
                                                      >
                                                          <span className="material-icons">
                                                              check_circle
                                                          </span>
                                                      </IconButton>

                                                      <IconButton
                                                          className="dltBtn"
                                                          onClick={() =>
                                                              toggleFullScreenCardPopup(
                                                                  true,
                                                                  'delete',
                                                                  card,
                                                              )
                                                          }
                                                      >
                                                          <span className="material-icons">
                                                              delete
                                                          </span>
                                                      </IconButton>
                                                  </>
                                              )}
                                          </>
                                      )}
                                  </div>
                              </div>
                          )
                      })
                    : ''}
                <SkyflowCard
                    allCards={cards}
                    data={manageCard}
                    function={toggleFullScreenCardPopup}
                />
                {/*<Popup
                    open={addCard}
                    size="md"
                    handleClose={() => setAddCard(false)}
                    modaltitle={t('add_a_new_card')}
                >
                    <form onSubmit={formik.handleSubmit}>
                        <div className="row">{Object.values(mapData(addCardData))}</div>
                        <div className="row">
                            <div className="col-12 text-center">
                                <PrimaryButton label={t('save')} />
                            </div>
                        </div>
                    </form>
                </Popup>*/}
            </div>
        </DashboardLayout>
    )
}

export default SkyflowCardDetails
