import React, { useEffect, useContext, useState } from 'react'
import RadioBox from '../../../product/components/atoms/RadioBox'
import './Report.css'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import {
    mapData,
    dateFormatFunction,
    handleRedirectInternal,
} from '../../../product/common/components'
import ReportDisplay from './display'
import { useHistory } from 'react-router-dom'
import DashboardLayout from '../../Dashboard/DashboardLayout'
import CheckBox from '../../../product/components/atoms/CheckBox'

import PrimaryButton from '../../../product/components/atoms/PrimaryButton'
import ReportContext from '../../../product/context/seller/report/reportContext'
import AuthContext from '../../../product/context/auth/authContext'
import CustomCommonContext from '../../../custom/context/common/commonContext'

const ReportManagement = () => {
    const history = useHistory()
    const reportContext = useContext(ReportContext)
    const [resetForm, setResetForm] = React.useState(false)
    const [reportsData, setReportsData] = React.useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const { allBusinessCategory } = useContext(CustomCommonContext)
    const authContext = useContext(AuthContext)
    const { user, isAuthenticated } = authContext

    const [manage, setManage] = React.useState({
        popup: false,
    })

    const toggleFullScreenPopup = (popup) => {
        if (popup) {
            setManage({ popup })
        } else {
            setManage({ popup })
        }
    }

    const {
        reports_data,
        getAllReportDisplay,
        getAllReportDownload,
        responseStatus: responseStatusReport,
        clearResponse: clearResponseReport,
    } = reportContext

    const allSortOptions = [
        { id: 'asc', description: 'Ascending' },
        { id: 'desc', description: 'Descending' },
    ]

    const allSearchOptions = [
        { id: 'within', description: 'Within' },
        { id: 'customSelection', description: 'Custom Selection' },
    ]

    const validation = Yup.object({
        order: Yup.string().required('Required!'),
        action: Yup.string().required('Required!'),
        report: Yup.string().required('Required!'),
        search: Yup.string().required('Required!'),
    })

    const formik = useFormik({
        initialValues: {
            action: 'display',
            order: 'asc',
            orderby: '',
            search: 'within',
            report: 'active_user_management',
            within: 'day',
            page: 1,
            location_id: '',
            limit: 20,
            start_date: dateFormatFunction(new Date()),
            end_date: dateFormatFunction(new Date()),
            limit_results: '',
            searchterm: '',
            fields: [],
        },
        validateOnBlur: false,
        validationSchema: validation,
        onSubmit: (values) => {
            setIsLoading(true)
            const value = values
            if (value.report.indexOf('user_management') !== -1) {
                value.filters = {
                    organization: {
                        value: user.organization_id,
                        type: 'in',
                        field: 'cu.company_id',
                    },
                }
            } else if (value.report.indexOf('product_management') !== -1) {
                value.filters = {
                    userid: {
                        value: user.organization_id,
                        type: 'in',
                        field: 'cu.company_id',
                    },
                }
            } else if (value.report.indexOf('auction_management') !== -1) {
                value.filters = {
                    value: user.organization_id,
                    type: 'in',
                    field: 'cu.company_id',
                }
            } else if (value.report.indexOf('invoice_management') !== -1) {
                value.filters = {
                    value: user.organization_id,
                    type: 'in',
                    field: 'cu.company_id',
                }
            }
            formik.setSubmitting(true)
            if (value.action === 'display') {
                getAllReportDisplay(value)
                toggleFullScreenPopup(true)
            } else {
                getAllReportDownload(value)
            }
        },
    })
    useEffect(() => {
        toggleFullScreenPopup(false)
        setResetForm(!resetForm)
        setReportsData(null)
    }, [])
    useEffect(() => {
        if (reports_data) {
            setReportsData(reports_data)
            console.log('reports_data', reports_data)
            setIsLoading(false)
            // toggleFullScreenPopup(true)
        }
    }, [reports_data])
    useEffect(() => {
        formik.setFieldValue('end_date', formik.values.start_date)
    }, [formik.values.start_date])

    useEffect(() => {
        return () => {
            setIsLoading(false)
        }
    })
    const action = [
        {
            label: 'Action',
            placeholder: 'Enter action',
            class: 'col-12',
            type: 'select',
            options: [
                { value: 'display', show: 'Display' },
                { value: 'download', show: 'Download' },
            ],
            name: 'action',
            formik: formik,
        },
    ]

    const withinRange = [
        {
            label: 'Within',
            placeholder: 'Enter Within',
            class: 'col-12',
            type: 'select',
            options: [
                { value: 'day', show: 'The Past Day' },
                { value: 'week', show: 'The Past Week' },
                { value: 'month', show: 'The Past Month' },
                { value: 'year', show: 'The Past Year' },
            ],
            name: 'within',
            formik: formik,
        },
    ]

    const report = [
        {
            label: 'Report',
            placeholder: 'Enter Report',
            class: 'col-12',
            type: 'select',
            groupoptions: [
                {
                    head: 'User management',
                    options: [
                        { value: 'active_user_management', show: 'Active' },
                        { value: 'deactivate_user_management', show: 'Deactive' },
                        { value: 'unverified_user_management', show: 'Unverified' },
                    ],
                },
                {
                    head: 'Product management',
                    options: [
                        { value: 'open_product_management', show: 'Open' },
                        { value: 'ending_product_management', show: 'Ending' },
                        { value: 'closed_product_management', show: 'Closed' },
                        { value: 'sold_product_management', show: 'Sold' },
                    ],
                },
                {
                    head: 'Auction management',
                    options: [
                        { value: 'open_auction_management', show: 'Open' },
                        { value: 'ending_auction_management', show: 'Ending' },
                        { value: 'closed_auction_management', show: 'Closed' },
                        { value: 'sold_auction_management', show: 'Sold' },
                    ],
                },
                {
                    head: 'Invoice management',
                    options: [
                        { value: 'paid_invoice_management', show: 'Paid' },
                        { value: 'unpaid_invoice_management', show: 'Unpaid' },
                    ],
                },
            ],
            name: 'report',
            formik: formik,
        },
    ]
    const dateRange = [
        {
            label: 'From date',
            placeholder: 'Enter From date',
            type: 'date',
            class: 'col-6',
            name: 'start_date',
            formik: formik,
        },
        {
            label: 'End date',
            placeholder: 'Enter End date',
            minDate: formik.values.start_date,
            value: formik.values.start_date,
            type: 'date',
            class: 'col-6',
            name: 'end_date',
            formik: formik,
        },
    ]
    const limitResult = [
        {
            label: 'Limit results',
            placeholder: 'Enter Limit results',
            type: '',
            class: 'col-12',
            name: 'limit_results',
            formik: formik,
        },
    ]

    let fieldsToShow = []

    fieldsToShow = [
        {
            field: 'id',
            label: 'User ID',
            sort: true,
            type: '',
            group: [
                'active_user_management',
                'deactivate_user_management',
                'unverified_user_management',
            ],
        },
        {
            field: 'user_fullname',
            label: 'User Name',
            type: '',
            group: [
                'active_user_management',
                'deactivate_user_management',
                'unverified_user_management',
            ],
        },
        {
            field: 'email',
            label: 'User Email',
            sort: true,
            type: '',
            group: [
                'active_user_management',
                'deactivate_user_management',
                'unverified_user_management',
            ],
        },
        {
            field: 'phone',
            label: 'Phone Number',
            type: '',
            group: [
                'active_user_management',
                'deactivate_user_management',
                'unverified_user_management',
            ],
        },
        {
            field: 'created_at',
            label: 'Created Date',
            sort: true,
            type: 'datetime',
            group: [
                'active_user_management',
                'deactivate_user_management',
                'unverified_user_management',
            ],
        },
        {
            field: 'last_login',
            label: 'Last Login',
            sort: true,
            type: 'datetime',
            group: [
                'active_user_management',
                'deactivate_user_management',
                'unverified_user_management',
            ],
        },
        {
            field: 'status',
            label: 'Status',
            type: 'capitalize',
            group: [
                'active_user_management',
                'deactivate_user_management',
                'unverified_user_management',
            ],
        },
        {
            field: 'id',
            label: 'ID',
            sort: true,
            type: '',
            group: [
                'open_product_management',
                'ending_product_management',
                'closed_product_management',
                'sold_product_management',
            ],
        },
        {
            field: 'title',
            label: 'Title',
            sort: true,
            type: '',
            group: [
                'open_product_management',
                'ending_product_management',
                'closed_product_management',
                'sold_product_management',
            ],
        },
        {
            field: 'date_added',
            label: 'Starting Date',
            sort: true,
            type: 'datetime',
            group: [
                'open_product_management',
                'ending_product_management',
                'closed_product_management',
                'sold_product_management',
            ],
        },
        {
            field: 'date_closed',
            label: 'End Date',
            sort: true,
            type: 'datetime',
            group: [
                'open_product_management',
                'ending_product_management',
                'closed_product_management',
                'sold_product_management',
            ],
        },
        {
            field: 'id',
            label: 'Auction ID',
            sort: true,
            type: '',
            group: [
                'open_auction_management',
                'ending_auction_management',
                'closed_auction_management',
                'sold_auction_management',
            ],
        },
        {
            field: 'auction_title',
            label: 'Auction Title',
            sort: true,
            type: 'capitalize',
            group: [
                'open_auction_management',
                'ending_auction_management',
                'closed_auction_management',
                'sold_auction_management',
            ],
        },
        {
            field: 'auction_description',
            label: 'Auction Description',
            sort: true,
            type: 'capitalize',
            group: [
                'open_auction_management',
                'ending_auction_management',
                'closed_auction_management',
                'sold_auction_management',
            ],
        },
        {
            field: 'auction_date_added',
            label: 'Auction Starting Date',
            sort: true,
            type: 'date',
            group: [
                'open_auction_management',
                'ending_auction_management',
                'closed_auction_management',
                'sold_auction_management',
            ],
        },
        {
            field: 'auction_date_closed',
            label: 'Auction End Date',
            sort: true,
            type: 'date',
            group: [
                'open_auction_management',
                'ending_auction_management',
                'closed_auction_management',
                'sold_auction_management',
            ],
        },
        {
            field: 'auction_status',
            label: 'Auction Status',
            sort: true,
            type: 'capitalize',
            group: [
                'open_auction_management',
                'ending_auction_management',
                'closed_auction_management',
                'sold_auction_management',
            ],
        },
        {
            field: 'lot_id',
            label: 'Lot ID',
            sort: true,
            type: '',
            group: [
                'open_auction_management',
                'ending_auction_management',
                'closed_auction_management',
                'sold_auction_management',
            ],
        },
        {
            field: 'lot_title',
            label: 'Lot Title',
            sort: true,
            type: 'capitalize',
            group: [
                'open_auction_management',
                'ending_auction_management',
                'closed_auction_management',
                'sold_auction_management',
            ],
        },
        {
            field: 'lot_date_added',
            label: 'Lot Starting Date',
            sort: true,
            type: 'date',
            group: [
                'open_auction_management',
                'ending_auction_management',
                'closed_auction_management',
                'sold_auction_management',
            ],
        },
        {
            field: 'lot_date_closed',
            label: 'Lot End Date',
            sort: true,
            type: 'date',
            group: [
                'open_auction_management',
                'ending_auction_management',
                'closed_auction_management',
                'sold_auction_management',
            ],
        },
        {
            field: 'sprice',
            label: 'Starting Price',
            type: 'price',
            group: [
                'open_product_management',
                'ending_product_management',
                'closed_product_management',
                'sold_product_management',
            ],
        },
        {
            field: 'projects_sprice',
            label: 'Starting Price',
            type: 'price',
            group: [
                'open_auction_management',
                'ending_auction_management',
                'closed_auction_management',
                'sold_auction_management',
            ],
        },
        /*{
            field: 'bprice',
            label: 'Buynow Price',
            type: 'price',
            group: [
                'open_auction_management',
                'ending_auction_management',
                'closed_auction_management',
                'sold_auction_management',
            ],
        },*/
        /*{
            field: 'qty',
            label: 'Total Quantity',
            type: '',
            group: [
                'open_auction_management',
                'ending_auction_management',
                'closed_auction_management',
                'sold_auction_management',
            ],
        },
        {
            field: 'sold',
            label: 'Sold Quantity',
            type: '',
            group: [
                'open_auction_management',
                'ending_auction_management',
                'closed_auction_management',
                'sold_auction_management',
            ],
        },*/
        {
            field: 'buynowamount',
            label: 'Sold Price',
            type: 'price',
            group: [
                'sold_product_management',
                'paid_invoice_management',
                'unpaid_invoice_management',
            ],
        },
        {
            field: 'wprice',
            label: 'Current Price',
            sort: true,
            type: 'price',
            group: [
                'open_product_management',
                'ending_product_management',
                'closed_product_management',
            ],
        },
        {
            field: 'projects_wprice',
            label: 'Current Price',
            sort: true,
            type: 'price',
            group: [
                'open_auction_management',
                'ending_auction_management',
                'closed_auction_management',
                'sold_auction_management',
            ],
        },
        {
            field: 'market_status',
            label: 'Status',
            sort: true,
            type: 'capitalize',
            group: [
                'open_product_management',
                'ending_product_management',
                'closed_product_management',
                'sold_product_management',
            ],
        },
        {
            field: 'totalbids',
            label: 'No. of Bids',
            type: '',
            group: [
                'open_product_management',
                'ending_product_management',
                'closed_product_management',
                'sold_product_management',
                'open_auction_management',
                'ending_auction_management',
                'closed_auction_management',
                'sold_auction_management',
            ],
        },
        {
            field: 'winnerName',
            label: 'Buyer Name',
            type: '',
            group: [
                'sold_product_management',
                'paid_invoice_management',
                'unpaid_invoice_management',
            ],
        },
        {
            field: 'winemail',
            label: 'Winner Email',
            type: '',
            group: [
                'open_product_management',
                'ending_product_management',
                'closed_product_management',
            ],
        },
        {
            field: 'winnerEmail',
            label: 'Winner Email',
            type: 'capitalize',
            group: ['paid_invoice_management', 'unpaid_invoice_management'],
        },
        {
            field: 'buynowemail',
            label: 'Buyer Email',
            type: '',
            group: ['sold_product_management'],
        },
        {
            field: 'common_invoice',
            label: 'Invoice ID',
            sort: true,
            type: '',
            group: [
                'sold_product_management',
                'paid_invoice_management',
                'unpaid_invoice_management',
            ],
        },
        {
            field: 'paid_date',
            label: 'Paid Date',
            sort: true,
            type: 'datetime',
            group: ['sold_product_management'],
        },
        {
            field: 'total_shipping',
            label: 'Shipping Price',
            sort: true,
            type: 'price',
            group: ['paid_invoice_management', 'unpaid_invoice_management'],
        },
        {
            field: 'total_invoice',
            label: 'Total Price',
            sort: true,
            type: 'price',
            group: ['paid_invoice_management', 'unpaid_invoice_management'],
        },
        {
            field: 'date_added',
            label: 'Sold Date',
            sort: true,
            type: 'date',
            group: ['paid_invoice_management', 'unpaid_invoice_management'],
        },
        {
            field: 'paid_date',
            label: 'Paid Date',
            sort: true,
            type: 'date',
            group: ['paid_invoice_management', 'unpaid_invoice_management'],
        },
        {
            field: 'sold_qty',
            label: 'Purchased Qty',
            sort: true,
            type: '',
            group: ['paid_invoice_management', 'unpaid_invoice_management'],
        },
        {
            field: 'paid',
            label: 'Paid Status',
            sort: true,
            type: 'paid_status',
            group: ['paid_invoice_management', 'unpaid_invoice_management'],
        },
    ]

    useEffect(() => {
        if (formik.values.report) {
            formik.values.page = 1
            formik.values.order = 'asc'
            formik.values.orderby = ''
            formik.values.searchterm = ''
            let valueSelected = []
            let fieldsToSort = fieldsToShow
            fieldsToSort
                .filter((field) => field.group.indexOf(formik.values.report.toString()) !== -1)
                .map((d, i) => valueSelected.push(d.field))
            formik.values.fields = valueSelected
            setResetForm(!resetForm)
            setReportsData(null)
        }
        return () => {
            setReportsData(null)
        }
    }, [formik.values.report])

    return (
        <DashboardLayout>
            <div className="dashboardInner reportManagement">
                <ReportDisplay
                    formik={formik}
                    fieldsToShow={fieldsToShow}
                    data={manage}
                    function={toggleFullScreenPopup}
                />
                <form onSubmit={formik.handleSubmit} autoComplete="nofill">
                    <h6 className="dashSubtitle">
                        Report management gives you direct access of accounting overview based on
                        the reporting you wish to formulate.
                    </h6>
                    <div className="reportAction d-flex align-items-center">
                        <div className="row">
                            {Object.values(mapData(action))}
                            {Object.values(mapData(report))}
                        </div>
                    </div>

                    <h6 className="dashSubtitle">
                        Customize your report using a wide variety of date range options.
                    </h6>

                    <div className="reportAction d-flex align-items-center">
                        <div className="reportLabel">
                            <h6>Fields</h6>
                        </div>

                        <div className="reportFields d-flex justify-content-start align-items-center flex-wrap">
                            {fieldsToShow
                                .filter(
                                    (field) =>
                                        field.group.indexOf(formik.values.report.toString()) !== -1,
                                )
                                .map((d, i) => (
                                    <>
                                        <CheckBox
                                            name="fields"
                                            label={d.label}
                                            checked={
                                                formik.values.fields.indexOf(d.field.toString()) !==
                                                -1
                                                    ? true
                                                    : false
                                            }
                                            value={d.field.toString()}
                                            onChange={formik.handleChange}
                                        />
                                    </>
                                ))}
                        </div>
                    </div>

                    <div className="reportAction d-flex align-items-center flex-wrap">
                        <div className="reportLabel">
                            <h6>Search</h6>
                        </div>
                        <div className="d-flex flex-wrap">
                            <div className="d-flex justify-content-center align-items-center">
                                <RadioBox
                                    items={allSearchOptions}
                                    value={formik.values.search}
                                    onChange={formik.handleChange}
                                    name="search"
                                />
                            </div>
                            <div className="reportRange datePicker d-flex align-items-center">
                                {formik.values.search === 'within' ? (
                                    <>
                                        <div className="row">
                                            {Object.values(mapData(withinRange))}
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div className="row">
                                            {Object.values(mapData(dateRange))}
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="reportAction d-flex align-items-center flex-wrap">
                        <div className="reportLabel">
                            <h6>Order Results</h6>
                        </div>
                        <div className="orderResults d-flex justify-content-center align-items-center">
                            <RadioBox
                                items={allSortOptions}
                                value={formik.values.order}
                                onChange={formik.handleChange}
                                name="order"
                            />
                        </div>
                    </div>

                    {/* <div className="reportAction d-flex align-items-center">
                        <div className="reportLabel">
                            <h6>Limit Results</h6>
                        </div>
                        <div className="limitResults">
                            <div className="row">{Object.values(mapData(limitResult))}</div>
                        </div>
                    </div> */}
                    {isLoading && <div>Loading...</div>}
                    <div className="actionButton">
                        <PrimaryButton type="submit" label="Generate Report" />
                    </div>
                </form>
            </div>
        </DashboardLayout>
    )
}

export default ReportManagement
