import React, { useState, useContext, useEffect, useRef } from 'react'
import openSocket from 'socket.io-client'
// import { socket } from '../../product/common/socket'
import PrimaryButton from '../../product/components/atoms/PrimaryButton'
import CartItem from '../../custom/components/molecules/ProductCard/CartItem'
import { useHistory } from 'react-router-dom'
import BuynowContext from '../../product/context/buynow/buynowContext'
import AuthContext from '../../product/context/auth/authContext'
import AlertContext from '../../product/context/alert/alertContext'
//import RosoomContext from '../../product/context/payment/rosoom/rosoomContext'
import { handleRedirectInternal } from '../../product/common/components'
import '../Cart/Cart.css'
import { useTranslation } from 'react-i18next'
import EmptyCart from '../EmptyCart'
import { currencyFormat } from '../../custom/common/components'
import Loaders from '../../custom/components/molecules/Loaders'
import CustomAlert from '../../product/components/atoms/CustomAlert'

const Cart = () => {
    const buynowContext = useContext(BuynowContext)
    const authContext = useContext(AuthContext)
    const alertContext = useContext(AlertContext)
    // const rosoomContext = useContext(RosoomContext)
    //const { getPendingOnCartTransaction, cart_pending_payment } = rosoomContext
    const history = useHistory()
    const { t } = useTranslation()
    const { search_allproducts, changeOnCart, getAllCartProducts, responseStatus, clearResponse } =
        buynowContext
    const { user, isAuthenticated, loadPendingCount } = authContext
    const [isLoading, setIsLoading] = useState(true)

    const { setAlert } = alertContext

    let [cartProducts, setCartProducts] = useState([])
    let [nonCartProducts, setNonCartProducts] = useState([])
    let [cartValues, setCartValues] = useState({})

    const [search, setSearch] = useState({})

    const [pendingPayment, setPendingPayment] = useState([])

    useEffect(() => {
        const socket = openSocket(`${process.env.REACT_APP_DOMAIN}/`, {
            transports: ['websocket'],
        })
        console.log(socket)
        socket.on('cartProducts', (data) => {
            // let flag1 = cartProducts[0]?.cartItems?.find((e) => data.find((project_id) => e.project_id == project_id))
            // if (isAuthenticated && data && flag1 ) {
            setIsLoading(true)
            getAllCartProducts(search)
            // }
            console.log('fdgd', data)
        })
        return () => {
            socket.off('cartProducts', (data) => {
                console.log(data, 'poioiuiu')
            })
            socket.disconnect()
        }
    }, [])

    useEffect(() => {
        if (isAuthenticated) {
            setIsLoading(true)
            getAllCartProducts(search)
            /* getPendingOnCartTransaction(search) */
        }
    }, [isAuthenticated])

    useEffect(() => {
        setCartProducts(search_allproducts.cartrecords.length ? search_allproducts.cartrecords : [])
        setNonCartProducts([])

        let buyNowFilter = {
            table: 'cart_temp',
            field: 'cart_id',
            extraField: '',
            filters: {
                paid: {
                    value: '0',
                    type: 'in',
                    field: 'crt.paid',
                },
            },
        }
        loadPendingCount(buyNowFilter, 'buynowCount')

        setTimeout(() => {
            setIsLoading(false)
        }, 1500)
    }, [search_allproducts])

    /*useEffect(() => {
        if (cart_pending_payment) {
            if (cart_pending_payment.length) {
                setPendingPayment(cart_pending_payment)
            }
            console.log('cart_pending_payment', cart_pending_payment)
        }
    }, [cart_pending_payment])*/

    useEffect(() => {
        if (responseStatus) {
            if (responseStatus.from === 'cartchange' || responseStatus.from === 'addToCart') {
                if (responseStatus.status === 'success') {
                    getAllCartProducts(search)
                }
            }
        }
    }, [responseStatus])

    return (
        <div className="cart">
            {isLoading ? (
                <div className="container">
                    <Loaders name="cart" isLoading={isLoading} loop={1} />
                </div>
            ) : nonCartProducts.length || cartProducts.length ? (
                <div>
                    {cartProducts.length ? (
                        <>
                            {cartProducts.map((data, index) => (
                                <>
                                    {data.cartItems.length ? (
                                        <>
                                            <div className="customContainer">
                                                {/* <CustomAlert
                                                            type="warning"
                                                            icon="pending_actions"
                                                            label="Your current transaction status is
                                                                under 'Pending' status.
                                                                Please wait for a while and try
                                                                again later."
                                                        />
                                                        <CustomAlert
                                                            type="warning"
                                                            icon="production_quantity_limits"
                                                            label="One or more products in your cart
                                                            are under pending payment by another
                                                            user. Please wait for a while and
                                                            try again later."
                                                        /> */}
                                                <div className="cartCnt d-flex justify-content-between">
                                                    <div className="cartLt">
                                                        <div className="cartProducts">
                                                            <div className="cartHead d-flex justify-content-start align-items-center">
                                                                <h1>shopping cart</h1>
                                                                <span>
                                                                    <span>
                                                                        {data.cartItems.length}{' '}
                                                                        results found
                                                                    </span>
                                                                </span>
                                                            </div>
                                                            <div>
                                                                <h3 className="crtActnTl">
                                                                    {
                                                                        data.cartItems[0]
                                                                            .auctionlot_title
                                                                    }
                                                                </h3>
                                                                {data.cartItems.map(
                                                                    (data, index) => (
                                                                        <CartItem
                                                                            key={index}
                                                                            data={data}
                                                                            blockRemoveCart={false}
                                                                            from="buynowcart"
                                                                            changeOnCart={
                                                                                changeOnCart
                                                                            }
                                                                        />
                                                                    ),
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="cartRt">
                                                        <div className="cartSummary">
                                                            <div>
                                                                <h3>
                                                                    {t('shopping_cart_summary')}
                                                                </h3>
                                                                <div className="cartSummaryValue">
                                                                    <label>
                                                                        {t('total_payable')} (
                                                                        {
                                                                            data.cartValues
                                                                                .total_items
                                                                        }{' '}
                                                                        {t('items')}):
                                                                    </label>
                                                                    <h5>
                                                                        {currencyFormat(
                                                                            data.cartValues
                                                                                .total_amount,
                                                                        )}
                                                                    </h5>
                                                                </div>
                                                            </div>
                                                            <PrimaryButton
                                                                label={t('proceed_to_checkout')}
                                                                onClick={() =>
                                                                    handleRedirectInternal(
                                                                        history,
                                                                        `checkout/buynow/${data.cartValues.cart_group}`,
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    ) : (
                                        <></>
                                        // <div className="cartRt">
                                        //     <div className="cartSummary">
                                        //         <h3>Shopping Cart Summary</h3>
                                        //         <label>Subtotal (0 Items)</label>
                                        //         <h5>{currencyFormat(0)}</h5>
                                        //     </div>
                                        // </div>
                                    )}
                                </>
                            ))}
                        </>
                    ) : null}
                    <div className="cart customContainer">
                        <div className="d-flex justify-content-between">
                            <div className="cartLt">
                                {nonCartProducts.length ? (
                                    <div className="cartProducts">
                                        <div className="cartHead d-flex justify-content-start align-items-center">
                                            <h1>{t('your_items')}</h1>
                                            <span>
                                                {nonCartProducts.length ? (
                                                    <span>
                                                        {nonCartProducts.length}{' '}
                                                        {t('results_found')}
                                                    </span>
                                                ) : null}
                                            </span>
                                        </div>
                                        <div>
                                            {nonCartProducts.map((data, index) => (
                                                <CartItem
                                                    data={data}
                                                    key={index}
                                                    from="buynowcart"
                                                    changeOnCart={changeOnCart}
                                                />
                                            ))}
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="customContainer">
                    <div className="d-flex justify-content-between">
                        <EmptyCart />
                    </div>
                </div>
            )}
        </div>
    )
}

export default Cart
