import React, { useState, useContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Button, Drawer, ListItem } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import PrimaryButton from '../../../../product/components/atoms/PrimaryButton'
import './ProductCard.css'
import CommonContext from '../../../../product/context/common/commonContext'
import CustomCommonContext from '../../../../custom/context/common/commonContext'
import { useFormik } from 'formik'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import AvatarImage from '../../atoms/AvatarImage'
import { dateTimeFormatFunction } from '../../../../product/common/components'
import Timer from '../../../../product/common/timer'
import { dateFormatDay, dateFormatMonth } from '../../../common/components.js'

const useStyles = makeStyles({
    fullList: {
        width: 'auto',
    },
})

function AuctionCard(props) {
    const { t } = useTranslation()
    const commonContext = useContext(CommonContext)
    const customCommonContext = useContext(CustomCommonContext)
    const { allLocations } = customCommonContext
    const { setSearchValue } = commonContext
    const [auction, setAuction] = useState({})

    const history = useHistory()
    const classes = useStyles()

    const [state, setState] = useState({
        bottom: false,
    })

    useEffect(() => {
        setAuction(props.data)
    }, [props.data])

    const formik = useFormik({
        initialValues: {
            auctionid: '',
        },
    })

    const auctionRedirect = (id) => {
        history.push({
            pathname: '/liveAuction/' + id,
            state: auction,
        })
        window.scrollTo(0, 0)
    }

    const handleRedirectInternal = (path) => {
        history.push(`/${path}`)
        window.scrollTo(0, 0)
    }

    const handleClose = (state) => {
        state(false)
    }

    const handleOpen = (state) => {
        state(true)
    }

    const toggleDrawer = (anchor, open, data) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return
        }
        if (open) {
            setState({ ...state, [anchor]: open, data: data })
        } else {
            setState({ ...state, [anchor]: open, data: {} })
        }
    }

    return (
        <div className="auctionCard d-flex justify-content-between align-items-center">
            <div className="auctionCardLt d-flex">
                <div className="acImg">
                    <AvatarImage data={auction} folder="auction" />

                    <div
                        className="acOverlay cursorDecoy"
                        onClick={() => auctionRedirect(auction.id)}
                    >
                        <h5>Starts On</h5>
                        <span>
                            <h2>{dateFormatDay(auction.date_added)}</h2>
                            <h4>{dateFormatMonth(auction.date_added)}</h4>
                        </span>
                        <h5>
                            {auction?.projects_count ? auction.projects_count : props.count} Total
                            Lots/Items
                        </h5>
                    </div>
                </div>
                <div className="acContent">
                    <h2>{auction.title}</h2>
                    <p
                        className="acAddress"
                        dangerouslySetInnerHTML={{
                            __html: auction.description,
                        }}
                    ></p>
                    <div className="acMiscInfo">
                        <p>
                            <span className="material-icons">today</span>Bidding Starts:
                        </p>
                        <span>{dateTimeFormatFunction(auction.date_added)}</span>
                    </div>
                    <div className="acMiscInfo">
                        <p>
                            <span className="material-icons">event</span>Bidding Ends:
                        </p>
                        <span>{dateTimeFormatFunction(auction.date_closed)}</span>
                    </div>
                    <div className="acMiscInfo">
                        <p>
                            <span className="material-icons">place</span>Location:
                        </p>
                        {allLocations
                            .filter((value) => auction.location_id === value.id)
                            .map((location) => (
                                <span>{location.name}</span>
                            ))}
                    </div>
                    <div className="acMiscInfo">
                        <p>
                            <span className="material-icons">local_activity</span>Event Type:
                        </p>
                        <span className="capitalize">Event Type</span>
                    </div>
                </div>
            </div>
            <div className="acActBtn">
                <div
                    className={`acTimer d-flex justify-content-center align-items-center flex-wrap ${
                        auction?.future_active == 1
                            ? 'upcomingAuction'
                            : auction?.future_active == 0
                            ? 'activeAuction'
                            : auction?.market_status == 'closed'
                            ? 'closedAuction'
                            : ''
                    }`}
                >
                    <h6>
                        <span className="material-icons">schedule</span>
                    </h6>
                    <p>
                        {auction.date_closed ? (
                            <Timer
                                date_added={auction.date_added}
                                date_closed={auction.date_closed}
                                withText={1}
                                endText={'Time Left' + ':'}
                                startText={'Starts In' + ':'}
                            ></Timer>
                        ) : null}
                    </p>
                </div>
                <PrimaryButton
                    onClick={() => auctionRedirect(auction.id)}
                    label="View Auction"
                    btnSize="small"
                />
            </div>
        </div>
    )
}

export default AuctionCard
