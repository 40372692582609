import React, { useState, useContext, useEffect } from 'react'
import { Button } from '@material-ui/core'
import './Footer.css'
import AutopltContext from '../../../../product/context/autopilot/autopltContext'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { LOGO, LOGO_WHITE, SITE_NAME } from '../../../../Utils'
import { mapData } from '../../../../product/common/components'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import PrimaryButton from '../../../../product/components/atoms/PrimaryButton'
import SecondaryButton from '../../../../product/components/atoms/SecondaryButton'
import { Divider } from '@material-ui/core'
import UserContext from '../../../../product/context/user/userContext'

const Footer = () => {
    const { t } = useTranslation()
    const autopltContext = useContext(AutopltContext)
    const userContext = useContext(UserContext)
    const { addCustomer, responseStatus: responseStatusAutoPlt } = autopltContext
    const { submitContactUs, responseStatus: subscribeResponse } = userContext
    const validationArray = Yup.object({
        name: Yup.string().nullable(),
        email: Yup.string().email('Invalid Email Format').required('Required'),
        phone: Yup.string()
            .trim()
            .matches(/^[0-9- +()]*$/g, 'Only numbers are allowed')
            .min(10, 'Please enter correct mobile number')
            .required('required'),
        zip: Yup.string().nullable().min(3, 'Minimum 3 characters').max(7, 'Maximum 7 characters'),
    })
    const formik = useFormik({
        initialValues: {
            email: '',
            name: '',
            phone: '',
            zip: '',
        },
        validationSchema: validationArray,
        onSubmit: (values) => {
            submitContactUs(values)
        },
    })

    const subscribe = [
        {
            label: 'Name',
            placeholder: 'Enter your name',
            class: 'subsInp',
            type: 'text',
            variant: 'standard',
            name: 'name',
            shrink: false,
            formik: formik,
        },
        {
            label: 'Email',
            placeholder: 'Enter your email address',
            class: 'subsInp',
            type: 'text',
            variant: 'standard',
            name: 'email',
            shrink: false,
            formik: formik,
        },
        {
            label: 'Phone Number',
            placeholder: 'Phone Number',
            class: 'subsInp',
            variant: 'standard',
            type: 'phone',
            shrink: false,
            name: 'phone',
            formik: formik,
        },
        {
            label: 'Zip',
            placeholder: 'enter zip',
            class: 'subsInp',
            type: 'number',
            variant: 'standard',
            name: 'zip',
            shrink: false,
            formik: formik,
        },
    ]

    useEffect(() => {
        if (
            subscribeResponse &&
            subscribeResponse.from === 'submitContactUs' &&
            subscribeResponse.status === 'success'
        ) {
            formik.resetForm()
        }
        console.log(subscribeResponse, 'subscribeResponse')
    }, [subscribeResponse])
    return (
        <footer className={`noPrint ${window.location.pathname === '/' ? 'homeFooter' : ''}`}>
            <div className="ftrInrCntnr customContainer d-flex align-items-center justify-content-between">
                <div className="logoContainer">
                    <img src={LOGO_WHITE} />
                </div>
                <div className="ftrLnks d-flex align-items-center">
                    <div className="lnkSts">
                        <h4>Customer Service</h4>
                        <ul>
                            <li>
                                <Link to="/terms_of_use">Terms & Conditions</Link>
                            </li>
                            <li>
                                <Link to="/support">Support Desk</Link>
                            </li>
                            <li>
                                <a href="tel:+888-925-BIDS(2537)"> Call us </a>
                            </li>
                            <li>
                                <a
                                    href="https://www.calendly.com/bidashift/"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Schedule a Demo
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="lnkSts">
                        <h4>General</h4>
                        <ul>
                            <li>
                                <Link to="/contactus">Contact Us</Link>
                            </li>
                            <li>
                                <Link to="/faq">FAQ</Link>
                            </li>
                            <li>
                                <Link to="/privacy_policy">Privacy & Accessibility</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer
