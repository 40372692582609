import React, { useState, useContext, useEffect, useRef } from 'react'
import ImageGallery from 'react-image-gallery'
import 'react-image-gallery/styles/css/image-gallery.css'
import { Button, Tooltip } from '@material-ui/core'
// import CustomInput from '../../atoms/Inputs/CustomInput'
// import PrimaryButton from '../../atoms/PrimaryButton'
import { withStyles } from '@material-ui/core/styles'
import MuiAccordion from '@material-ui/core/Accordion'
import MuiAccordionSummary from '@material-ui/core/AccordionSummary'
import MuiAccordionDetails from '@material-ui/core/AccordionDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import './ProductView.css'
import Buynow from '../../molecules/Buynow/BuynowItem'
import CustomCommonContext from '../../../../custom/context/common/commonContext'
import ProductCommonContext from '../../../../product/context/common/commonContext'
import { useTranslation } from 'react-i18next'
import { dateFormatFront, currencyFormat, handleRedirectInternal } from '../../../common/components'
// import TertiaryButton from '../../atoms/TertiaryButton'
import PreBidding from '../../molecules/Reverse/PreBidding/PreBiddingItem'
import { useHistory } from 'react-router-dom'
import { messageHandler } from '../../../../product/common/socketHandler'
import Timer from '../../../../product/common/timer'
import ReverseBidding from '../../molecules/Reverse/Bidding/BiddingItem'
import ProductContext from '../../../../product/context/product/productContext'
import AuthContext from '../../../../product/context/auth/authContext'
import Loaders from '../../molecules/Loaders'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import ShippingData from '../shippingPrice'
import { Link } from 'react-router-dom'
import BidHistoryComp from '../BidHistory/BidHistoryComp'
import AlertContext from '../../../../product/context/alert/alertContext'
import openSocket from 'socket.io-client'

const Accordion = withStyles({
    root: {
        border: '1px solid rgba(0, 0, 0, .125)',
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
})(MuiAccordion)

const AccordionSummary = withStyles({
    root: {
        backgroundColor: '#fff',
        marginBottom: -1,
        minHeight: 40,
        '&$expanded': {
            minHeight: 40,
        },
    },
    content: {
        '&$expanded': {
            margin: '0',
        },
    },
    expanded: {},
})(MuiAccordionSummary)

const AccordionDetails = withStyles((theme) => ({
    root: {
        padding: '10px',
    },
}))(MuiAccordionDetails)
const ProductView = (props) => {
    const { t } = useTranslation()
    const [expanded, setExpanded] = useState()
    const [images, setImages] = useState([])
    const [reloadBidHistory, setReloadBidHistory] = useState(true)
    let [viewProduct, setViewProduct] = useState([])
    const [productBidChange, setProductBidChange] = useState(0)
    const [product, setProduct] = useState()
    const [isLoading, setIsLoading] = useState(props.loading && true)
    const history = useHistory()

    const authContext = useContext(AuthContext)
    const alertContext = useContext(AlertContext)
    const productContext = useContext(ProductContext)
    const customCommonContext = useContext(CustomCommonContext)
    const productCommonContext = useContext(ProductCommonContext)
    const { setAlert } = alertContext
    const { isAuthenticated, user } = authContext
    const { search_allsimilar, getAllSimilarProducts } = productContext
    const { configVariables, allCategory, allCondition, allLocations } = customCommonContext
    const { setBidHistoryValue } = productCommonContext

    useEffect(() => {
        setProduct([props.data])
    }, [props.data])

    useEffect(() => {
        setIsLoading(props.loading && true)

        if (product) {
            const search = {
                limit: 3,
                page: 1,
                orderby: 'p.date_closed,asc',
                order: '',
                similars: {
                    category: {
                        value: [product[0].categoryTypeId],
                        type: 'array',
                        field: 'it.categoryTypeId',
                    },
                    condition: {
                        value: [product[0].conditionTypeId],
                        type: 'array',
                        field: 'it.conditionTypeId',
                    },
                    location: {
                        value: [product[0].location_id],
                        type: 'array',
                        field: 'p.location_id',
                    },
                    auctionid: {
                        value: [product[0].auctionid],
                        type: 'array',
                        field: 'p.auctionid',
                    },
                },
                filters: {
                    product_id: {
                        value: product[0].id,
                        type: 'notin',
                        field: 'p.id',
                    },
                },
            }
            getAllSimilarProducts(search)
        }
    }, [product ? product[0].id : 0])

    useEffect(() => {
        setIsLoading(false)

        console.log('search_allproducts', search_allsimilar)
        setViewProduct(search_allsimilar.records.length ? search_allsimilar.records : [])
    }, [search_allsimilar])

    useEffect(() => {
        setViewProduct([])
        return () => {
            setViewProduct([])
        }
    }, [])

    const addDefaultSrc = (e) => {
        e.target.src = '/assets/images/noimage.png'
    }

    useEffect(() => {
        let noImageArray = [
            {
                original: '/assets/images/noimage.png',
                thumbnail: '/assets/images/noimage.png',
            },
        ]
        if (product) {
            if (product[0].allattachmentlist) {
                if (product[0].allattachmentlist.length) {
                    product[0].allattachmentlist.forEach(function (data) {
                        if (data.type === 2) {
                            data['original'] = data.file_name
                            data['thumbnail'] = data.file_name
                        } else {
                            data['original'] = global.images_url + data['file_name']
                            data['thumbnail'] = global.images_url + data['file_name']
                        }
                    })
                    setImages(product[0].allattachmentlist)
                    // console.log('images: ', images)
                } else {
                    setImages(noImageArray)
                }
            } else {
                setImages(noImageArray)
            }
        } else {
            setImages(noImageArray)
        }
    }, [product])

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false)
    }

    useEffect(() => {
        if (product) {
            setBidHistoryValue({
                id: product[0].id,
                proxy: false,
            })
        }
    }, [product])

    const viewProductRef = useRef(product)
    const userRef = useRef(user)

    useEffect(() => {
        viewProductRef.current = product
        userRef.current = user
    }, [user, product])

    const handler = (message, type) => {
        messageHandler(
            message,
            viewProductRef.current,
            userRef.current,
            setAlert,
            setProduct,
            type,
            bidHistoryChange,
        )
    }

    useEffect(() => {
        if (productBidChange) {
            if (productBidChange === product[0].id) {
                setReloadBidHistory(!reloadBidHistory)
            }
            setProductBidChange(0)
        }
    }, [productBidChange, product])

    const bidHistoryChange = (data) => {
        setProductBidChange(data.product_id)
    }

    useEffect(() => {
        const socket = openSocket(`${process.env.REACT_APP_DOMAIN}/`, {
            transports: ['websocket'],
        })
        socket.on('realclosedupdates', (data) => {
            handler(data, 'realclosedupdates')
        })
        socket.on('bidAddtime', (data) => {
            handler(data, 'bidAddtime')
        })
        socket.on('preBidAddtime', (data) => {
            handler(data, 'preBidAddtime')
        })
        return () => {
            socket.off('realclosedupdates', (data) => {
                handler(data, 'realclosedupdates')
            })
            socket.off('bidAddtime', (data) => {
                handler(data, 'bidAddtime')
            })
            socket.off('preBidAddtime', (data) => {
                handler(data, 'preBidAddtime')
            })
            socket.disconnect()
        }
    }, [])

    return (
        <div className={`productView ${props.standalone && 'standalonePage'}`} id="productView">
            <div className="customContainer">
                {isLoading ? (
                    <Loaders name="product_view" isLoading={isLoading} loop={1} />
                ) : product ? (
                    <>
                        {isAuthenticated && (
                            <>
                                {product[0].bidtopstatus === 'outbid' && (
                                    <div className="pvBidStatus">
                                        <h4 className="outbid">{'Oops you have been outbid'}.</h4>
                                    </div>
                                )}
                                {product[0].bidtopstatus === 'winner' && (
                                    <div className="pvBidStatus">
                                        <h4 className="winning">
                                            {'Yay you are winning this item'}.
                                        </h4>
                                    </div>
                                )}
                                {product[0].bidtopstatus === 'won' && (
                                    <div className="pvBidStatus">
                                        <h4 className="won">
                                            {'Congratulations you have won thisi tem'}
                                        </h4>
                                    </div>
                                )}
                                {product[0].bidtopstatus === 'lost' && (
                                    <div className="pvBidStatus">
                                        <h4 className="lost">
                                            {'Unfortunately you have lost this item'}
                                        </h4>
                                    </div>
                                )}
                            </>
                        )}
                        <div className="pdtContainer d-flex align-items-start justify-content-between">
                            <div className="lftPdtContainer bgBckWrp pdtDtlCntnr">
                                <h2>{product[0].title}</h2>
                                <h6
                                    className="dscpData"
                                    dangerouslySetInnerHTML={{
                                        __html: product[0].desc_proc,
                                    }}
                                ></h6>
                                <div className="grdCntnr">
                                    <div className="d-flex align-items-center">
                                        <label className="m-0">Started On:</label>
                                        <p className="m-0">
                                            {dateFormatFront(product[0].date_added)}
                                        </p>
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <label className="m-0">Ends On:</label>
                                        <p className="m-0">
                                            {dateFormatFront(product[0].date_closed)}
                                        </p>
                                    </div>
                                    {product[0].auction ? (
                                        <>
                                            {product[0].market_status === 'open' ? (
                                                <>
                                                    <>
                                                        {product[0].bidhistory.length > 0 ? (
                                                            <>
                                                                <div className="d-flex align-items-center">
                                                                    <label className="m-0">
                                                                        Current Bid:
                                                                    </label>
                                                                    <p className="m-0 prcAmount">
                                                                        {currencyFormat(
                                                                            product[0].wprice,
                                                                        )}
                                                                    </p>
                                                                </div>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <div className="d-flex align-items-center">
                                                                    <label className="m-0">
                                                                        Starting Bid:
                                                                    </label>
                                                                    <p className="m-0 prcAmount">
                                                                        {currencyFormat(
                                                                            product[0].sprice,
                                                                        )}
                                                                    </p>
                                                                </div>
                                                            </>
                                                        )}
                                                    </>
                                                </>
                                            ) : null}
                                        </>
                                    ) : null}

                                    <div className="d-flex align-items-center">
                                        <label className="m-0">Location:</label>
                                        <p className="m-0">
                                            {allLocations
                                                .filter(
                                                    (value) => product[0].location_id === value.id,
                                                )
                                                .map((location) => (
                                                    //    console.log(location),
                                                    <span className="adrsInfoCrd">
                                                        {location.name}
                                                    </span>
                                                ))}
                                        </p>
                                    </div>
                                </div>
                                {product[0].market_status === 'open' ? (
                                    <div className="pvTimerBox">
                                        <span className="material-icons">schedule</span>
                                        <h4>
                                            <Timer
                                                date_added={product[0].date_added}
                                                date_closed={product[0].date_closed}
                                                daysText={' Days '}
                                                hoursText={' Hours '}
                                                minutesText={' Minutes '}
                                                secondsText={' Seconds '}
                                                withText={0}
                                                endText={'Time left' + ':'}
                                                startText={'Starts in' + ':'}
                                            ></Timer>
                                        </h4>
                                    </div>
                                ) : (
                                    <div className="pvTimerBox">
                                        <span className="material-icons">schedule</span>
                                        <label>Closed On</label>
                                        <h4>{dateFormatFront(product[0].date_closed)}</h4>
                                    </div>
                                )}
                                <div className="bdngContainer">
                                    <div className="pvBidBox">
                                        {product[0].market_status === 'open' &&
                                        !product[0].future_active ? (
                                            <>
                                                {product[0].auction ? (
                                                    <ReverseBidding
                                                        data={product[0]}
                                                        type={
                                                            'hard'
                                                            // product.custom_auctionlot_bid_type
                                                            //     ? product.custom_auctionlot_bid_type
                                                            //     : product.bid_type
                                                        }
                                                        accept_rejected={
                                                            product[0].enable_duplicate
                                                        }
                                                        accept_non_winning={
                                                            product[0]
                                                                .custom_auctionlot_enable_non_winning
                                                        }
                                                    />
                                                ) : null}
                                            </>
                                        ) : (
                                            <PreBidding
                                                data={product[0]}
                                                type={'hard'}
                                                showMessage={true}
                                            />
                                        )}
                                        <p className="bdInfoTxt">
                                            Fixed Single Bid - Place your bid on the current price.
                                        </p>
                                    </div>
                                    <div className="pvBidBox">
                                        {product[0].market_status === 'open' &&
                                        !product[0].future_active ? (
                                            <>
                                                {product[0].auction ? (
                                                    <ReverseBidding
                                                        data={product[0]}
                                                        type={'proxy'}
                                                        accept_rejected={
                                                            product[0].enable_duplicate
                                                        }
                                                        accept_non_winning={
                                                            product[0]
                                                                .custom_auctionlot_enable_non_winning
                                                        }
                                                    />
                                                ) : null}
                                            </>
                                        ) : (
                                            <PreBidding
                                                data={product[0]}
                                                type={'proxy'}
                                                showMessage={true}
                                            />
                                        )}
                                        <p className="bdInfoTxt">
                                            Proxy Bid - System will automatically bid for you at the
                                            bid increment when others outbid you down to your proxy
                                            bid value. If outbid, you will be notified, if you allow
                                            notifications in settings.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="rtPdtContainer bgBckWrp bdHstWrp">
                                <h4 className="d-flex align-items-center mb-3">
                                    <span className="material-icons mr-2">history</span>Bid History
                                </h4>
                                <BidHistoryComp
                                    noPageCount
                                    noHeader
                                    id={product[0].id}
                                    refreshData={reloadBidHistory}
                                    hideName={
                                        product[0].custom_auctionlot_isname_visible === 0
                                            ? true
                                            : false
                                    }
                                    hideValue={
                                        product[0].custom_auctionlot_isbidvalue_visible === 0
                                            ? true
                                            : false
                                    }
                                />
                            </div>
                        </div>
                    </>
                ) : (
                    ''
                )}
            </div>
        </div>
    )
}
export default ProductView
