import React, { useState, useContext, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import openSocket from 'socket.io-client'
// import GridView from '../../custom/components/molecules/ProductCard/GridView'
import './auction.css'
import CustomSelect from '../../product/components/atoms/Inputs/CustomSelect'
import { Button, MenuItem } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
// import ListView from '../../custom/components/molecules/ProductCard/ListView'
import Drawer from '@material-ui/core/Drawer'
// import ProductView from '../../custom/components/organisms/ProductView'
import ProductContext from '../../product/context/product/productContext'
import AuthContext from '../../product/context/auth/authContext'
import AlertContext from '../../product/context/alert/alertContext'
import CommonContext from '../../product/context/common/commonContext'
import customCommonContext from '../../product/context/common/commonContext'
import { Pagination } from '@material-ui/lab'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer'
import AuctionCard from '../../custom/components/molecules/ProductCard/AuctionCard'
import AuctionContext from '../../product/context/auction/auctionContext'
import NoRecordsFound from '../../product/components/atoms/NoRecordsFound'
import { useFormik } from 'formik'
import { messageHandler } from '../../product/common/socketHandler'
import { useCustomMediaQuery, mapData } from '../../product/common/components'
import Loaders from '../../custom/components/molecules/Loaders'
import AuctionCardGrid from '../../custom/components/molecules/ProductCard/AuctionCardGrid'
import CustomCommonContext from '../../custom/context/common/commonContext'
const useStyles = makeStyles({
    fullList: {
        width: 'auto',
    },
})

const auction = () => {
    const productContext = useContext(ProductContext)
    const authContext = useContext(AuthContext)
    const auctionContext = useContext(AuctionContext)
    const alertContext = useContext(AlertContext)
    const commonContext = useContext(CommonContext)
    const customCommonContext = useContext(CustomCommonContext)
    const { allLocations } = customCommonContext
    const { setSearchValue, searchValue, clearSearchValue } = commonContext
    const { search_allauctions, getAllAuctionProducts } = auctionContext
    const [isLoading, setIsLoading] = useState(true)
    const { t } = useTranslation()
    const classes = useStyles()

    const {
        search_allproducts,
        getAllSearchProducts,
        responseStatus,
        active_sorts,
        getAllActiveSorts,
    } = productContext
    const { user, isAuthenticated, saveSearch } = authContext

    const auctionFormik = useFormik({
        initialValues: {
            limit: 12,
            page: 1,
            location: [],
            state: [],
            city: [],
            category: [],
            condition: [],
            radius: '',
            price: '',
            enddate: null,
            fromprice: 0,
            toprice: 0,
            auctionid: '',
            searchbar: '',
            market_status: '',
            orderby: 'ac.id, desc',
            filters: {
                location: {
                    value: [],
                    type: 'array',
                    field: 'cac.location_id',
                },
            },
            having: {
                future_active: {
                    value: 0,
                    type: 'in',
                    field: 'future_active',
                },
            },
        },
        onSubmit: (values) => {
            setIsLoading(true)
            getAllAuctions(values)
        },
    })

    const { setAlert } = alertContext

    const [auctionView, setAuctionView] = useState('List')
    const [state, setState] = useState({
        right: false,
        bottom: false,
        data: {},
    })
    let [auctions, setAuctions] = useState([])
    let [viewProduct, setViewProduct] = useState([])
    const onHandlePageAuction = (event, value) => {
        auctionFormik.setFieldValue('page', value)
    }
    // Options for pagination
    const pageOptions = [
        {
            value: 12,
            show: 'Results 12 Per Page',
        },
        {
            value: 24,
            show: 'Results 24 Per Page',
        },
        {
            value: 36,
            show: 'Results 36 Per Page',
        },
    ]
    // Set sort variables
    const sortShow = [
        {
            value: 'ac.id, desc',
            show: 'None',
        },
        {
            value: 'ac.date_closed, asc',
            show: 'Time Remaining',
        },
        {
            value: 1,
            show: 'Ending Today',
        },
    ]

    const viewProductRef = useRef(viewProduct)
    const userRef = useRef(user)
    // handler
    const handler = (message, type) => {
        messageHandler(
            message,
            viewProductRef.current,
            userRef.current,
            setAlert,
            setViewProduct,
            type,
        )
    }

    // useEffect(() => {
    //     if (responseStatus) {
    //         if (responseStatus.from === 'watchlist') {
    //             handler(
    //                 {
    //                     id: responseStatus.data.project_id,
    //                     status: responseStatus.data.status,
    //                 },
    //                 'watchlistAdded',
    //             )
    //         }
    //     }
    // }, [responseStatus])

    useEffect(() => {
        if (isAuthenticated) {
            setIsLoading(true)
            getAllAuctionProducts(auctionFormik.values, 'allactive')
        }
    }, [isAuthenticated])

    useEffect(() => {
        const socket = openSocket(`${process.env.REACT_APP_DOMAIN}/`)
        socket.on('realclosedupdates', (data) => {
            handler(data, 'realclosedupdates')
        })
        socket.on('bidAddtime', (data) => {
            handler(data, 'bidAddtime')
        })
        return () => {
            socket.off('realclosedupdates', (data) => {
                handler(data, 'realclosedupdates')
            })
            socket.off('bidAddtime', (data) => {
                handler(data, 'bidAddtime')
            })
            socket.disconnect()
        }
    }, [])

    useEffect(() => {
        getAllActiveSorts({})
    }, [])

    useEffect(() => {
        setIsLoading(true)
        // Used to Get all auction products on page load
        getAllAuctionProducts(auctionFormik.values, 'allactive')
    }, [auctionFormik.values])

    useEffect(() => {
        if (search_allauctions.from === 'allactive') {
            setAuctions(search_allauctions.records.length ? search_allauctions.records : [])
            setTimeout(() => {
                setIsLoading(false)
            }, 1500)
        }
    }, [search_allauctions])

    const topFilter = {
        formik: auctionFormik,
        data: [
            {
                label: 'Results Per Page',
                class: 'filterSelect',
                type: 'select',
                options: pageOptions,
                size: 'small',
                name: 'limit',
                isNoDefaultOptionValue: true,
            },
            {
                label: 'Sort By',
                class: 'filterSelect',
                type: 'select',
                options: sortShow,
                name: 'orderby',
                size: 'small',
                isNoDefaultOptionValue: true,
            },
            {
                label: 'Location',
                type: 'multiselect',
                filter: true,
                name: 'location',
                size: 'small',
                class: 'filterSelect',
                options: allLocations
                    .filter((main) => {
                        if (
                            active_sorts && active_sorts.sorts && active_sorts.sorts.sortlocations
                                ? active_sorts.sorts.sortlocations.includes(main.id.toString())
                                : true
                        ) {
                            return true
                        }
                    })
                    .map((location) => {
                        let subCategoryChanged = {}
                        subCategoryChanged.show = ` ${location.name} `
                        subCategoryChanged.value = location.id
                        return subCategoryChanged
                    }),
            },
        ],
    }

    const changeAuctionType = (type) => {
        if (type == 'open') {
            auctionFormik.setFieldValue('market_status', '')
            auctionFormik.values.having.future_active.value = 0
        } else if (type == 'closed') {
            auctionFormik.setFieldValue('market_status', 'closed')
            auctionFormik.values.having.future_active.value = 0
        } else if (type == 'upcoming') {
            auctionFormik.setFieldValue('market_status', 'open')
            // auctionFormik.setFieldValue('future_active', 1)
            auctionFormik.values.having.future_active.value = 1
        }
    }

    return (
        <div className="search">
            <div className="customContainer">
                <div className="srchInrCntnr py-5">
                    <div className="searchMiscFilters w-100 mb-3 d-flex justify-content-end align-items-end">
                        <div className="filtersAucType">
                            <Button
                                variant="outlined"
                                onClick={() => changeAuctionType('open')}
                                className={`${
                                    auctionFormik?.values.market_status == '' ? 'active' : ''
                                }`}
                            >
                                Active
                            </Button>
                            <Button
                                variant="outlined"
                                onClick={() => changeAuctionType('upcoming')}
                                className={`${
                                    auctionFormik?.values.having.future_active.value == 1
                                        ? 'active'
                                        : ''
                                }`}
                            >
                                Upcoming
                            </Button>
                            <Button
                                className={`${
                                    auctionFormik?.values.market_status == 'closed' ? 'active' : ''
                                }`}
                                variant="outlined"
                                onClick={() => changeAuctionType('closed')}
                            >
                                Past
                            </Button>
                        </div>
                        {mapData(topFilter)}
                    </div>
                    <div className="searchCnt d-flex justify-content-start align-items-start">
                        <section className="homeRecentlyListed homeMarginBottom mb-0 w-100">
                            <div className="searchHead">
                                {/* <h2 className="homeTitle">{'Live Auctions'}</h2> */}
                            </div>
                            {isLoading ? (
                                <Loaders name="product_list_view" isLoading={isLoading} loop={4} />
                            ) : auctions.length ? (
                                <div className="homeSpecialItems">
                                    <div
                                        className={`${
                                            auctionView == 'Grid' ? 'grdVwCntnr' : 'w-100'
                                        }`}
                                    >
                                        {auctions.map((data, index) => (
                                            <>
                                                <AuctionCard key={data.id} data={data} />
                                            </>
                                        ))}
                                    </div>

                                    <div className="d-flex justify-content-between align-items-left flex-wrap w-100 my-3 pagination-wrapper col-md-12">
                                        <h6>
                                            {'Showing'}&nbsp; {search_allauctions.setDisp} {' of '}
                                            &nbsp;
                                            {search_allauctions.totalRecords}
                                        </h6>
                                        <Pagination
                                            count={Math.ceil(
                                                search_allauctions.totalRecords /
                                                    auctionFormik.values.limit,
                                            )}
                                            page={auctionFormik.values.page}
                                            onChange={onHandlePageAuction}
                                            siblingCount={3}
                                            showFirstButton
                                            showLastButton
                                            boundaryCount={2}
                                        />
                                    </div>
                                </div>
                            ) : (
                                <NoRecordsFound />
                            )}
                        </section>
                    </div>
                </div>
            </div>
            {/* PRODUCT VIEW DRAWER */}
        </div>
    )
}
export default auction
