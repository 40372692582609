import React, { useContext, useState, useEffect } from 'react'
import moment from 'moment'
import PrimaryButton from '../../../../product/components/atoms/PrimaryButton'
import SecondaryButton from '../../../../product/components/atoms/SecondaryButton'
import FullScreenPopup from '../../../../product/components/organisms/FullScreenPopup'
import AuctionContext from '../../../../product/context/seller/auction/auctionContext'
import UserContext from '../../../../product/context/seller/user/userContext'
import CustomCommonContext from '../../../../custom/context/common/commonContext'
import AuthContext from '../../../../product/context/auth/authContext'
import SettingContext from '../../../../product/context/seller/setting/settingContext'

import {
    mapData,
    dateTimeFormatFunction,
    handleRedirectInternal,
} from '../../../../product/common/components'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { LinearProgress } from '@material-ui/core'
import Loaders from '../../../../product/components/molecules/Loaders'

const UserManage = (props) => {
    const auctionContext = useContext(AuctionContext)
    const settingContext = useContext(SettingContext)
    const authContext = useContext(AuthContext)

    const { user, isAuthenticated } = authContext

    const {
        single_auction,
        auction_projects,
        getSingleAuction,
        getAllAuctProjects,
        auctionAction,
        responseStatus,
    } = auctionContext
    const {
        searchTableAction,
        searchTable,
        table_data,
        responseStatus: responseStatusSetting,
    } = settingContext
    const { getAllUsers, all_users } = useContext(UserContext)
    const { allOrganization, allLocations } = useContext(CustomCommonContext)

    const [reload, setReload] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [locationBody, setLocationBody] = useState([])

    const data = props.data
    const toggleFullScreenPopup = props.function
    const [activeAuctProjects, setActiveAuctProjects] = useState([])

    const validationArray = Yup.object({
        title: Yup.string().required('Required!'),
        location_id: Yup.string().required('Required!'),
        description: Yup.string().required('Required!'),
        date_added: Yup.string().required('Required!').nullable(),
        date_closed: Yup.string().required('Required!').nullable(),
        ending_enable: Yup.boolean(),
        ending_items: Yup.number().when('ending_enable', {
            is: (end) => end === true,
            then: Yup.number().min(0, 'Not valid!').required('Required!'),
        }),
        ending_mins: Yup.number().when('ending_enable', {
            is: (end) => end === true,
            then: Yup.number().min(0, 'Not valid!').required('Required!'),
        }),
        project_id: Yup.array().required('Required!'),
        avatar: Yup.array().required('Required!'),
        selltype: Yup.boolean(),
        business_categoryid: Yup.array().when('selltype', {
            is: (avatar) => avatar,
            then: Yup.array().required('Required!'),
        }),
        selectedbuyer: Yup.array().when('selltype', {
            is: (avatar) => avatar,
            then: Yup.array().required('Required!'),
        }),
    })

    const formik = useFormik({
        initialValues: {
            id: 0,
            title: '',
            location_id: '',
            description: '',
            date_added: dateTimeFormatFunction(new Date()),
            date_closed: dateTimeFormatFunction(new Date()),
            ending_enable: true,
            ending_items: 0,
            project_status: 'draft',
            ending_mins: 0,
            project_id: [],
            avatar: [],
            selltype: false,
            selectedbuyer: [],
            business_categoryid: [],
        },
        validateOnBlur: false,
        validationSchema: validationArray,
        onSubmit: (values) => {
            setIsLoading(true)
            const value = values
            value.date_added = moment(value.date_added, 'MM-DD-YYYY h:mm a').format()
            value.date_closed = moment(value.date_closed, 'MM-DD-YYYY h:mm a').format()
            auctionAction(value)
        },
    })
    // useEffect(() => {
    //     console.log('heree 3333', data)
    //     // resetFrom()
    // }, [data]
    useEffect(() => {
        return () => setIsLoading(false)
    }, [])
    useEffect(() => {
        if (isAuthenticated) {
            setIsLoading(true)
            if (data.status === 'edit' && data.id > 0) {
                formik.values.id = data.id
                getSingleAuction(formik.values)
                getAllAuctProjects(formik.values)
            } else {
                formik.values.id = 0
                getAllAuctProjects(formik.values)
                console.log('heree 1222')
                resetFrom()
            }
        }
    }, [data.id, isAuthenticated])

    useEffect(() => {
        setIsLoading(false)
        if (single_auction.record && data.status === 'edit' && data.id > 0) {
            const auction = single_auction.record
            const project_id = auction.auction_lots
                ? auction.auction_lots.split(',').map(Number)
                : []
            formik.values.title = auction.title
            formik.values.location_id = auction.location_id
            formik.values.description = auction.description
            formik.values.project_id = project_id
            formik.values.date_added = dateTimeFormatFunction(auction.date_added)
            formik.values.date_closed = dateTimeFormatFunction(auction.date_closed)
            formik.values.ending_enable = auction.ending_enable === 1 ? true : false
            formik.values.ending_items = auction.ending_items
            formik.values.ending_mins = auction.ending_mins
            formik.values.avatar = auction.avatar.split(',')
            formik.values.business_categoryid = auction.business_categoryid
                ? auction.business_categoryid.toString().split(',').map(Number)
                : []
            formik.values.selltype = auction.selltype === '1' ? true : false
            formik.values.selectedbuyer = auction.selectedbuyer
                ? auction.selectedbuyer.split(',').map(Number)
                : []
            formik.handleReset()
        } else {
            resetFrom()
        }
    }, [single_auction])

    useEffect(() => {
        console.log('formik.values', formik.values)
    }, [formik.values])

    const resetFrom = () => {
        formik.values.title = ''
        formik.values.location_id = ''
        formik.values.description = ''
        formik.values.date_added = dateTimeFormatFunction(new Date())
        formik.values.date_closed = dateTimeFormatFunction(new Date())
        formik.values.ending_enable = true
        formik.values.ending_items = 0
        formik.values.ending_mins = 0
        formik.values.avatar = []
        formik.values.project_id = []
        formik.values.selltype = false
        formik.values.selectedbuyer = []
        formik.values.business_categoryid = []
        formik.handleReset()
    }

    useEffect(() => {
        setIsLoading(false)
        const projectToShow = []
        if (auction_projects && auction_projects.records.length > 0) {
            auction_projects.records.map((lot, index) => {
                projectToShow.push({ value: lot.id, show: `${index + 1}: ${lot.title} ` })
            })
            setActiveAuctProjects(projectToShow)
        } else {
            setActiveAuctProjects([])
        }
    }, [auction_projects])

    useEffect(() => {
        if (table_data && table_data.records) {
            if (table_data.from === 'locations') {
                setLocationBody(
                    table_data.records.length
                        ? table_data.records.filter((element) => {
                              return element.active == 1
                          })
                        : [],
                )
                setIsLoading(false)
            }
        }
    }, [table_data])

    const personalDetailsNew = [
        {
            label: 'Auction Title *',
            type: 'text',
            placeholder: 'Enter your Auction Title',
            class: 'col-sm-6 col-12',
            name: 'title',
            formik: formik,
        },
        {
            label: 'Location *',
            placeholder: 'Select location',
            type: 'select',
            class: 'col-sm-6 col-12',
            options: locationBody.length
                ? locationBody.map((location) => {
                      let locationChanged = {}
                      locationChanged.show = location.name
                      locationChanged.value = location.id
                      return locationChanged
                  })
                : null,
            defaultOptionValue: 'Select a Location',
            name: 'location_id',
            formik: formik,
        },
        {
            label: 'Start date *',
            placeholder: 'Enter Start date',
            type: 'datetime',
            disablePast: true,
            class: 'col-md-3 col-sm-6 col-12',
            name: 'date_added',
            formik: formik,
        },
        {
            label: 'End Date *',
            placeholder: 'Enter End Date',
            disablePast: true,
            type: 'datetime',
            class: 'col-md-3 col-sm-6 col-12',
            name: 'date_closed',
            minDate: dateTimeFormatFunction(formik.values.date_added),
            minDateMessage: `Date should not be before minimal date ${dateTimeFormatFunction(
                formik.values.date_added,
            )}`,
            formik: formik,
        },
        {
            label: 'Bid Type *',
            placeholder: 'Select Bid Type',
            type: 'select',
            class: 'col-sm-6 col-12',
            options: [
                { show: 'Proxy', value: 'proxy' },
                { show: 'Hard', value: 'hard' },
            ],
            defaultOptionValue: 'Select a Bid Type',
            name: 'bid_type',
            formik: formik,
        },
        {
            label: 'Auction Item *',
            placeholder: 'enter Auction Item name',
            type: 'multiselect',
            class: 'col-12',
            options: activeAuctProjects,
            name: 'project_id',
            formik: formik,
        },

        {
            label: 'Description *',
            placeholder: 'Enter Description',
            type: 'ckeditor',
            class: 'col-12',
            name: 'description',
            formik: formik,
        },
        {
            label: 'Enable Staggered Separate Shift Auctions',
            placeholder: 'Enable Staggered Separate Shift Auctions',
            type: 'checkbox',
            class: 'col-sm-6 col-12',
            name: 'ending_enable',
            formik: formik,
        },
        {
            label: 'Items',
            placeholder: 'Enable Items',
            type: 'number',
            disabled: formik.values.ending_enable ? false : true,
            class: 'col-md-3 col-sm-6 col-12',
            name: 'ending_items',
            formik: formik,
        },
        {
            label: 'Minutes',
            placeholder: 'Enable Minutes',
            type: 'number',
            disabled: formik.values.ending_enable ? false : true,
            class: 'col-md-3 col-sm-6 col-12',
            name: 'ending_mins',
            formik: formik,
        },
        {
            label: 'Only Invited',
            type: 'checkbox',
            class: 'col-12',
            name: 'selltype',
            formik: formik,
        },
        {
            label: 'Business Category',
            placeholder: 'Select condition',
            type: 'multiselect',
            class: 'col-sm-6 col-12',
            options: allOrganization
                .filter(
                    (value) =>
                        value.active === 1 || formik.values.business_categoryid.includes(value.id),
                )
                .map((user) => {
                    let userChanged = {}
                    userChanged.show = user.description
                    userChanged.value = user.id
                    return userChanged
                }),
            disabled: formik.values.selltype ? false : true,
            defaultOptionValue: 'Select a Business Category',
            name: 'business_categoryid',
            formik: formik,
        },
        {
            label: 'Invited Company',
            placeholder: 'Select condition',
            type: 'multiselect',
            class: 'col-sm-6 col-12',
            options: formik.values.business_categoryid.length
                ? all_users.records.map((user) => {
                      let userChanged = {}
                      userChanged.show = user.companyname + ' (' + user.email + ')'
                      userChanged.value = user.id
                      return userChanged
                  })
                : [],
            disabled: formik.values.selltype ? false : true,
            defaultOptionValue: 'Select a Condition',
            name: 'selectedbuyer',
            formik: formik,
        },
        {
            type: 'uploadDropZone',
            formik: formik,
            name: 'avatar',
            titleText: 'Upload auction image',
            innerText: 'Recommended dimensions: 380px * 380px',
            class: 'col-12',
            folder: 'auction',
            multiple: false,
            accept: 'image/*',
        },
    ]

    useEffect(() => {
        if (!formik.values.selltype) {
            formik.values.business_categoryid = []
            formik.values.selectedbuyer = []
            setReload(!reload)
        }
    }, [formik.values.selltype])

    useEffect(() => {
        if (formik.values.business_categoryid.length) {
            let allBuyers = []
            all_users.records.map((user) => {
                allBuyers.push(parseInt(user.id, 10))
                return true
            })
            formik.values.selectedbuyer = allBuyers
            setReload(!reload)
        }
    }, [all_users])
    useEffect(() => {
        if (isAuthenticated) {
            if (formik.values.business_categoryid) {
                console.log('formik.values.business_categoryid', formik.values.business_categoryid)
                getAllUsers({
                    email: '',
                    first_name: '',
                    last_name: '',
                    page: 1,
                    limit: 1000,
                    order: '',
                    orderby: '',
                    filters: {
                        action: {
                            value: 'active',
                            type: 'in',
                            field: 'u.status',
                        },
                        business_category: {
                            value: formik.values.business_categoryid
                                ? formik.values.business_categoryid
                                : [],
                            type: 'findinarray',
                            field: 'cu.business_category',
                        },
                    },

                    business_categoryid: formik.values.business_categoryid,
                })
            }
        }
    }, [formik.values.business_categoryid, isAuthenticated])

    const formSubmit = (data) => {
        formik.values.project_status = data
        formik.values.market_status = data
        formik.submitForm()
    }

    useEffect(() => {
        if (formik.values.date_closed && formik.values.date_added) {
            let dateClosed = moment(formik.values.date_closed, 'MM-DD-YYYY h:mm a').format()
            let dateAdded = moment(formik.values.date_added, 'MM-DD-YYYY h:mm a').format()
            if (dateClosed <= dateAdded) {
                formik.values.date_closed = null
                setReload(!reload)
            }
        }
    }, [formik.values.date_added, formik.values.date_closed])

    useEffect(() => {
        if (responseStatus) {
            if (responseStatus.from === 'auctionAction') {
                if (responseStatus.status === 'success') {
                    setIsLoading(false)
                    formik.resetForm()
                    toggleFullScreenPopup(false)
                }
            }
        }
    }, [responseStatus])

    return (
        <FullScreenPopup
            modaltitle={
                data.status === 'new'
                    ? 'ADD NEW AUCTION'
                    : `EDIT AUCTION: ${single_auction.record.title} `
            }
            open={data.status === 'new' || data.status === 'edit' ? data.popup : false}
            handleClose={() => toggleFullScreenPopup(false)}
        >
            <div className="addUserModal">
                <div className="fspBody">
                    {formik.isSubmitting ? <LinearProgress /> : null}
                    {isLoading ? (
                        <div className="fspLoader">
                            <Loaders isLoading={isLoading} />
                        </div>
                    ) : (
                        <>
                            {data.status === 'edit' && (
                                <div className="listingInfo">
                                    <span className="material-icons">info</span>
                                    <h6>
                                        Created at:
                                        <span>
                                            {dateTimeFormatFunction(
                                                single_auction.record.created_at,
                                            )}
                                        </span>
                                    </h6>
                                    <span className="liDivider">|</span>
                                    <h6>
                                        Last update at:
                                        <span>
                                            {dateTimeFormatFunction(
                                                single_auction.record.updated_at,
                                            )}
                                        </span>
                                    </h6>
                                    <span className="liDivider">|</span>

                                    <h6>
                                        Posted by:
                                        <span>
                                            {single_auction.record.as_employees_emp_firstname}{' '}
                                            {single_auction.record.as_employees_emp_lastname}{' '}
                                        </span>
                                    </h6>
                                    <span className="liDivider">|</span>
                                    <h6>
                                        Items Count:
                                        <span>{single_auction.record.projectcount}</span>
                                    </h6>
                                </div>
                            )}
                            <form onSubmit={formik.handleSubmit} autoComplete="nofill">
                                <div className="row">
                                    {Object.values(mapData(personalDetailsNew))}
                                </div>
                                <div className="actionButton d-flex justify-content-center align-items-center flex-wrap">
                                    <SecondaryButton
                                        label="Cancel"
                                        onClick={() => toggleFullScreenPopup(false)}
                                    />

                                    {(data.status === 'edit' &&
                                        single_auction?.record?.market_status === 'draft') ||
                                    data.status === 'new' ? (
                                        <SecondaryButton
                                            onClick={() => {
                                                formSubmit('draft')
                                            }}
                                            type="button"
                                            label="Draft"
                                        />
                                    ) : null}

                                    {(data.status === 'edit' &&
                                        (single_auction?.record?.market_status === 'draft' ||
                                            single_auction?.record?.market_status === 'open')) ||
                                    data.status === 'new' ? (
                                        <PrimaryButton
                                            onClick={() => {
                                                formSubmit('open')
                                            }}
                                            type="button"
                                            label="Submit"
                                        />
                                    ) : null}
                                </div>
                            </form>
                        </>
                    )}
                </div>
            </div>
        </FullScreenPopup>
    )
}

export default UserManage
