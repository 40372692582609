import React, { useContext, useState, useEffect } from 'react'
import './TemplateList.css'
import { useHistory } from 'react-router-dom'
import CustomTable from '../../../../product/components/molecules/CustomTable'
import CustomSearch from '../../../../product/components/molecules/CustomSearch'

import { useFormik } from 'formik'
import AuctionContext from '../../../../product/context/seller/template/auction/auctionContext'
import AuthContext from '../../../../product/context/auth/authContext'
import { handleRedirectInternal } from '../../../../product/common/components'

const TemplateList = () => {
    const auctionContext = useContext(AuctionContext)
    const authContext = useContext(AuthContext)

    const { isAuthenticated, user } = authContext

    const history = useHistory()

    const [isLoading, setIsLoading] = useState(true)

    const { all_auctions, getAllAuctions } = auctionContext

    const [tableBody, setTableBody] = useState([])

    const formik = useFormik({
        initialValues: {
            page: 1,
            limit: 20,
            order: '',
            orderby: '',
            filters: {
                searchterm: {
                    value: '',
                    type: 'like',
                    field: 'tac.template_name',
                },
                fromdate: {
                    value: '',
                    type: 'dategreaterequal',
                    field: 'tac.date_closed',
                },
                todate: {
                    value: '',
                    type: 'datesmallerequal',
                    field: 'tac.date_closed',
                },
            },
        },
        onSubmit: (values) => {
            setIsLoading(true)
            getAllAuctions(values)
        },
    })
    useEffect(() => {
        if (all_auctions) {
            setTableBody(all_auctions.records.length ? all_auctions.records : [])
            setIsLoading(false)
        }
    }, [all_auctions])

    useEffect(() => {
        if (isAuthenticated) {
            setTableBody([])
            setIsLoading(true)
            formik.values.filters.searchterm.value = ''
            formik.values.filters.fromdate.value = ''
            formik.values.filters.todate.value = ''
            formik.values.page = 1
            getAllAuctions(formik.values)
            /*  return () => {
                setTableBody([])
            }*/
        }
    }, [isAuthenticated])

    const onClickEdit = (id, secondaryID, type) => {
        if (type === 'use') {
            handleRedirectInternal(history, `post_project/auction_detail/template/${id}`)
        }
    }

    const getMultipleAction = () => {
        let actionPush = [
            {
                buttonType: 'library_add_check',
                clickType: 'use',
                onclick: onClickEdit,
                tooltipTitle: 'Use Template',
            },
        ]
        return actionPush
    }

    const tableValues = [
        {
            field: 'id',
            type: '',
            firstChild: true,
            sort: true,
            sortField: 'ac.id',
            disablePadding: false,
            label: 'ID',
        },
        {
            field: 'template_name',
            type: '',
            firstChild: false,
            sort: true,
            disablePadding: false,
            label: 'Template Name',
        },
        {
            field: 'created_at',
            type: 'datetime',
            firstChild: false,
            sort: true,
            disablePadding: false,
            label: 'Created At',
        },
        {
            field: 'action',
            type: 'button',
            isMultiple: true,
            clickID: 'id',
            sort: false,
            firstChild: false,
            disablePadding: false,
            label: 'Action',
            isMultiple: getMultipleAction(),
        },
    ]

    const tableActions = []

    const searchInfo = {
        formik: formik,
        data: [
            {
                label: 'Template Name',
                placeholder: 'Enter template name',
                class: 'col-md-4 col-sm-6 col-12',
                type: 'text',
                name: 'searchterm',
                filter: true,
            },
        ],
    }
    return (
        <div className="dpTable templateTable">
            <div className="dashboardInner templatesWrapper auctionTemplates">
                <CustomSearch searchInfo={searchInfo} formik={formik} />
                <CustomTable
                    formik={formik.values}
                    tableBody={tableBody}
                    hideCompact={true}
                    setDense={true}
                    tableValues={tableValues}
                    tableActions={tableActions}
                    allData={all_auctions}
                    isLoading={isLoading}
                    loadingTemplate={'table'}
                    changeTableFunction={getAllAuctions}
                />
            </div>
        </div>
    )
}

export default TemplateList
