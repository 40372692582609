import React, { useContext, useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import DashboardLayout from '../../../Dashboard/DashboardLayout'
import CustomTable from '../../../../product/components/molecules/CustomTable'
import CustomSearch from '../../../../product/components/molecules/CustomSearch'
import CommonContext from '../../../../custom/context/common/commonContext'
import { useFormik } from 'formik'
import AuctionContext from '../../../../product/context/seller/template/auction/auctionContext'
import AuthContext from '../../../../product/context/auth/authContext'
import { Button } from '@material-ui/core'
import AuctionManage from './AuctionManage'
import CustomDialog from '../../../../product/components/organisms/Dialog'
import PrimaryButton from '../../../../product/components/atoms/PrimaryButton'
import SecondaryButton from '../../../../product/components/atoms/SecondaryButton'
import {
    mapData,
    dateTimeFormatFunction,
    handleRedirectInternal,
} from '../../../../product/common/components'
import moment from 'moment'
import * as Yup from 'yup'
import FormDialog from '../../../../product/components/organisms/Dialog'

const AuctionLots = (props) => {
    const auctionContext = useContext(AuctionContext)
    const authContext = useContext(AuthContext)
    const { membership } = useContext(CommonContext)
    const { user, isAuthenticated } = authContext

    const history = useHistory()

    const [isLoading, setIsLoading] = useState(true)
    const [subPopup, setSubPopup] = useState(false)

    const {
        all_auctions,
        getAllAuctions,
        changeAuctionStatus,
        responseStatus: responseStatusAuction,
        clearResponse: clearResponseAuction,
    } = auctionContext

    const [manage, setManage] = useState({
        popup: false,
        status: 'new',
        id: 0,
    })

    const toggleFullScreenPopup = (popup, status, id) => {
        if (popup) {
            setManage({ popup, status, id })
        } else {
            setManage({ popup, status: 'new', id: 0 })
        }
    }

    const [tableBody, setTableBody] = useState([])

    const formik = useFormik({
        initialValues: {
            page: 1,
            limit: 20,
            order: '',
            orderby: '',
            filters: {
                searchterm: {
                    value: '',
                    type: 'like',
                    field: 'tac.template_name',
                },
                fromdate: {
                    value: '',
                    type: 'dategreaterequal',
                    field: 'tac.date_closed',
                },
                todate: {
                    value: '',
                    type: 'datesmallerequal',
                    field: 'tac.date_closed',
                },
            },
        },
        onSubmit: (values) => {
            setIsLoading(true)
            getAllAuctions(values)
        },
    })

    useEffect(() => {
        if (all_auctions) {
            setTableBody(all_auctions.records.length ? all_auctions.records : [])
            setIsLoading(false)
        }
    }, [all_auctions])

    useEffect(() => {
        if (isAuthenticated) {
            setTableBody([])
            setIsLoading(true)
            formik.values.filters.searchterm.value = ''
            formik.values.filters.fromdate.value = ''
            formik.values.filters.todate.value = ''
            formik.values.page = 1
            getAllAuctions(formik.values)
            /*  return () => {
                setTableBody([])
            }*/
        }
    }, [isAuthenticated])

    const onClickEdit = (id, secondaryID, type) => {
        console.log('onClickEdit', id, type)
        if (type === 'use') {
            handleRedirectInternal(history, `post_project/auction_detail/template/${id}`)
        } else {
            toggleFullScreenPopup(true, type, id)
        }
    }

    const validationArray = Yup.object({
        action: Yup.string().required('Action is required'),
    })
    const formikSelection = useFormik({
        initialValues: {
            action: '',
            auction_id: '',
            mainUpdate: false,
        },
        validationSchema: validationArray,
        onSubmit: (values) => {
            if (values.action === 'removed' || values.action === 'closenow') {
                const value = values
                setIsLoading(true)
                changeAuctionStatus(value)
            }
        },
    })

    const [changeStatus, setChangeStatus] = React.useState(false)

    const changeChangeStatus = () => {
        setChangeStatus(!changeStatus)
    }

    const onSelectMultiProducts = (data, action) => {
        console.log('data', data)
        console.log('action', action)
        formikSelection.values.action = action
        formikSelection.values.auction_id = data
        if (action === 'removed' || action === 'closenow') {
            setChangeStatus(true)
        }
    }

    useEffect(() => {
        if (responseStatusAuction) {
            if (
                responseStatusAuction.status === 'success' &&
                responseStatusAuction.from === 'auctionchange'
            ) {
                setChangeStatus(false)
                getAllAuctions(formik.values)
            }
        }
    }, [responseStatusAuction])

    const getMultipleAction = () => {
        let actionPush = [
            {
                buttonType: 'edit',
                clickType: 'edit',
                onclick: onClickEdit,
                tooltipTitle: 'Edit Template',
            },
            {
                buttonType: 'library_add_check',
                clickType: 'use',
                onclick: onClickEdit,
                tooltipTitle: 'Use Template',
            },
        ]
        return actionPush
    }

    const tableValues = [
        {
            field: 'id',
            type: '',
            firstChild: true,
            sort: true,
            sortField: 'ac.id',
            disablePadding: false,
            label: 'ID',
        },
        {
            field: 'template_name',
            type: '',
            firstChild: false,
            sort: true,
            disablePadding: false,
            label: 'Template Name',
        },
        // {
        //     field: 'projectcount',
        //     type: '',
        //     sort: true,
        //     firstChild: false,
        //     disablePadding: false,
        //     label: 'No of items',
        // },
        // {
        //     field: 'market_status',
        //     type: 'capitalize',
        //     sort: true,
        //     firstChild: false,
        //     disablePadding: false,
        //     label: 'Status',
        // },
        {
            field: 'created_at',
            type: 'datetime',
            firstChild: false,
            sort: true,
            disablePadding: false,
            label: 'Created At',
        },
        {
            field: 'updated_at',
            type: 'datetime',
            firstChild: false,
            sort: true,
            disablePadding: false,
            label: 'Updated At',
        },
        // {
        //     field: 'as_employees_emp_firstname,as_employees_emp_lastname',
        //     type: '',
        //     firstChild: false,
        //     sort: true,
        //     disablePadding: false,
        //     label: 'Posted By',
        // },
        {
            field: 'action',
            type: 'button',
            isMultiple: true,
            clickID: 'id',
            sort: false,
            firstChild: false,
            disablePadding: false,
            label: 'Action',
            isMultiple: getMultipleAction(),
        },
    ]

    const tableActions = [
        {
            label: 'Terminate',
            icon: <span className="material-icons">block</span>,
            onclick: onSelectMultiProducts,
            type: 'closenow',
        },
        {
            label: 'Delete',
            icon: <span className="material-icons">delete</span>,
            onclick: onSelectMultiProducts,
            type: 'removed',
        },
    ]

    const searchInfo = {
        formik: formik,
        data: [
            {
                label: 'Template Name',
                placeholder: 'Enter template name',
                class: 'col-md-4 col-sm-6 col-12',
                type: 'text',
                name: 'searchterm',
                filter: true,
            },
            // {
            //     label: 'Closed From Date',
            //     placeholder: 'Date Ending from',
            //     class: 'col-md-4 col-sm-6 col-12',
            //     type: 'date',
            //     name: 'fromdate',
            //     filter: true,
            // },
            // {
            //     label: 'Closed To Date',
            //     placeholder: 'Date Ending To',
            //     class: 'col-md-4 col-sm-6 col-12',
            //     type: 'date',
            //     name: 'todate',
            //     filter: true,
            // },
        ],
    }

    useEffect(() => {
        if (responseStatusAuction) {
            if (
                responseStatusAuction.status === 'success' &&
                responseStatusAuction.from === 'auctionAction'
            ) {
                toggleFullScreenPopup(false)
                getAllAuctions(formik.values)
            }
        }
    }, [responseStatusAuction])

    return (
        <DashboardLayout>
            <div className="dashboardInner templatesWrapper auctionTemplates">
                <CustomSearch searchInfo={searchInfo} formik={formik} />
                <div className="miscActions d-flex justify-content-between align-items-center mt-4">
                    {membership && membership.auction_template ? (
                        <Button
                            className="addTempBtn"
                            onClick={() => toggleFullScreenPopup(true, 'new', 0)}
                        >
                            <span className="material-icons-outlined">add_circle_outline</span>Add
                            New Template
                        </Button>
                    ) : (
                        <Button
                            className="addTempBtn"
                            onClick={() => {
                                setSubPopup(true)
                            }}
                        >
                            <span className="material-icons-outlined">add_circle_outline</span>Add
                            New Template
                        </Button>
                    )}
                </div>

                <CustomTable
                    formik={formik.values}
                    tableBody={tableBody}
                    tableValues={tableValues}
                    tableActions={tableActions}
                    allData={all_auctions}
                    isLoading={isLoading}
                    loadingTemplate={'table'}
                    changeTableFunction={getAllAuctions}
                />
            </div>
            <AuctionManage data={manage} function={toggleFullScreenPopup} />
            <CustomDialog
                title={'Change auction status'}
                open={changeStatus}
                function={changeChangeStatus}
            >
                <h5>
                    {formikSelection.values.action === 'closenow'
                        ? 'Are you sure you want to close the auctions and lots related'
                        : 'Are you sure you want to change the status'}
                </h5>
                <div className="actionWrapper">
                    <Button onClick={() => changeChangeStatus()}>Cancel</Button>
                    <form onSubmit={formikSelection.handleSubmit} autoComplete="nofill">
                        <PrimaryButton type="submit" label="Confirm" />
                    </form>
                </div>
            </CustomDialog>
            <FormDialog open={subPopup} openFunction={() => setSubPopup(false)}>
                <div className="upgrdPup">
                    <p className="text-center mb-1">
                        <span className="material-icons">warning</span>
                    </p>
                    <p className="text-center">
                        Silver/Gold plan needed to enable this, please upgrade your plan
                    </p>
                    <div className="d-flex align-items-center justify-content-end">
                        <SecondaryButton label="Cancel" onClick={() => setSubPopup(false)} />
                        <PrimaryButton
                            btnSize="ml-2"
                            label="Upgrade"
                            onClick={() => (
                                handleRedirectInternal(history, 'subscription'), setSubPopup(false)
                            )}
                        />
                    </div>
                </div>
            </FormDialog>
        </DashboardLayout>
    )
}

export default AuctionLots
