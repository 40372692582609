import React, { useState, useContext, useEffect, useRef } from 'react'
import './invoice.css'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import CartItem from '../../custom/components/molecules/ProductCard/CartItem'
import { Link } from 'react-router-dom'
import { useHistory } from 'react-router-dom'
import Typography from '@material-ui/core/Typography'
import AuthContext from '../../product/context/auth/authContext'
import InvoiceContext from '../../product/context/invoice/invoiceContext'
import DashboardLayout from '../Dashboard/DashboardLayout'
import {
    dateFormatFront,
    currencyFormat,
    dateTimeFormatFunction,
    capitalize,
} from '../../custom/common/components'
import { Button } from '@material-ui/core'
import { LOGO, SITE_NAME } from '../../Utils'

const Invoice = (props) => {
    const authContext = useContext(AuthContext)
    const invoiceContext = useContext(InvoiceContext)
    const history = useHistory()
    const { search_allinvoiceproducts, getAllInvoiceProducts } = invoiceContext
    const { isAuthenticated } = authContext

    const [print, setPrint] = useState(false)
    const [search, setSearch] = useState({
        invoice_id: props.match.params.id,
    })

    let [invoiceDetails, setInvoiceDetails] = useState({
        invoiceProducts: [],
        cartValues: {},
        appointmentDetail: {},
        locationDetail: {},
        invoiceTransactions: [],
        invoiceUser: [],
        invoiceAddress: [],
    })

    // temp lang Manager
    const t = (text) => {
        text = text.charAt(0).toUpperCase() + text.slice(1)
        return text.replace(/_/g, ' ')
    }

    useEffect(() => {
        if (isAuthenticated) {
            getAllInvoiceProducts(search)
        }
    }, [isAuthenticated])

    useEffect(() => {
        setInvoiceDetails({
            invoiceProducts: search_allinvoiceproducts.invoicerecords.length
                ? search_allinvoiceproducts.invoicerecords
                : [],
            cartValues: search_allinvoiceproducts.cartvalues,
            appointmentDetail: search_allinvoiceproducts.appointmentrecord,
            locationDetail: search_allinvoiceproducts.locationrecord,
            invoiceTransactions: search_allinvoiceproducts.transactionrecords,
            invoiceUser: search_allinvoiceproducts.invoiceuserrecords,
            invoiceAddress: search_allinvoiceproducts.invoiceaddressrecord,
        })
    }, [search_allinvoiceproducts])

    useEffect(() => {
        setInvoiceDetails({
            invoiceProducts: [],
            cartValues: {},
            appointmentDetail: {},
            locationDetail: {},
            invoiceTransactions: [],
            invoiceUser: [],
            invoiceAddress: [],
        })
        return () => {
            setInvoiceDetails({
                invoiceProducts: [],
                cartValues: {},
                appointmentDetail: {},
                locationDetail: {},
                invoiceTransactions: [],
                invoiceUser: [],
                invoiceAddress: [],
            })
        }
    }, [])

    const ref = React.createRef()
    const options = {}

    const generatePDF = (toPdf) => {
        setPrint(true)
        toPdf()
    }
    const completedPDF = () => {
        setPrint(false)
    }
    return (
        <div className="container-lg productInvoice">
            <h3 className="invoiceTitle">{'Invoice'}</h3>
            <div className="d-flex justify-content-end align-items-center mb-2 noPrint">
                {/* <Breadcrumbs aria-label="breadcrumb" className="naBreadcrumbs">
                        <Link to="/dashboard/won">Dashboard</Link>
                        <Typography color="textPrimary">Invoice</Typography>
                    </Breadcrumbs> */}
                <Button onClick={() => window.print()}>
                    <span className="material-icons">print</span>
                    {'Print'}
                </Button>
                <Button
                    onClick={() => {
                        history.goBack()
                        window.scrollTo(0, 0)
                    }}
                    className="moveBack"
                >
                    <span className="material-icons">arrow_back</span>
                    {'Back'}
                </Button>
            </div>
            <div
                className={`invoiceView ${print ? 'invoiceViewPrint' : ''}`}
                ref={ref}
                style={{ height: '100%', width: '100%' }}
            >
                <div className="card-header d-flex justify-content-between align-items-center p-4">
                    <a className="pt-2 d-inline-block" data-abc="true">
                        <img
                            src={LOGO}
                            className="invLogo"
                            alt={SITE_NAME}
                            style={{ width: '140px' }}
                        />
                    </a>
                    <div className="float-right invDate">
                        <h5 className="mb-0">
                            {'Invoice'} #{invoiceDetails.cartValues.common_invoice}{' '}
                        </h5>
                        <h5 className="mb-0">
                            {'Date'}: {dateFormatFront(invoiceDetails.cartValues.cart_paiddate)}
                        </h5>
                        <h5 className="mb-0">
                            {'Status'}: {invoiceDetails.cartValues.cart_paid ? 'Paid' : 'Unpaid'}
                        </h5>
                    </div>
                </div>
                <div className="card-body">
                    <div className="row mb-4">
                        {invoiceDetails.invoiceProducts?.length && (
                            <div className="col-sm-6 invAddr">
                                <h5 className="mb-3">From:</h5>
                                <h3 className="text-dark mb-1">{SITE_NAME}</h3>
                                <div>
                                    {
                                        invoiceDetails.invoiceProducts[0]
                                            .custom_projects_origin_address1
                                    }
                                </div>
                                <div>
                                    {invoiceDetails.invoiceProducts[0].custom_projects_origin_city},{' '}
                                    {invoiceDetails.invoiceProducts[0].custom_projects_origin_state}
                                    ,{' '}
                                    {
                                        invoiceDetails.invoiceProducts[0]
                                            .custom_projects_origin_country
                                    }{' '}
                                    - {invoiceDetails.invoiceProducts[0].custom_projects_origin_zip}
                                </div>

                                <div>
                                    Email:{' '}
                                    {invoiceDetails.invoiceProducts[0].custom_projects_origin_email}
                                </div>

                                <div>
                                    Phone:{' '}
                                    {invoiceDetails.invoiceProducts[0].custom_projects_origin_phone}
                                </div>
                            </div>
                        )}
                        {invoiceDetails.invoiceUser?.length ? (
                            <div className="col-sm-6 invAddr text-right">
                                <h5 className="mb-3">To:</h5>
                                <h3 className="text-dark mb-1">
                                    {invoiceDetails.invoiceUser[0].first_name}{' '}
                                    {invoiceDetails.invoiceUser[0].last_name}
                                </h3>
                                {invoiceDetails.invoiceAddress.length ? (
                                    <>
                                        <div>
                                            {invoiceDetails.invoiceAddress[0].address1 &&
                                                invoiceDetails.invoiceAddress[0].address1}
                                        </div>
                                        <div>
                                            {invoiceDetails.invoiceAddress[0].address2 &&
                                                invoiceDetails.invoiceAddress[0].address2}
                                        </div>
                                        {invoiceDetails.invoiceAddress[0].city &&
                                            invoiceDetails.invoiceAddress[0].state && (
                                                <div>
                                                    {invoiceDetails.invoiceAddress[0].city},{' '}
                                                    {invoiceDetails.invoiceAddress[0].state}{' '}
                                                    {invoiceDetails.invoiceAddress[0].country} -{' '}
                                                    {invoiceDetails.invoiceAddress[0].zip}
                                                </div>
                                            )}
                                    </>
                                ) : null}

                                <div>Email: {invoiceDetails.invoiceUser[0].email}</div>

                                <div>Phone: {invoiceDetails.invoiceUser[0].phone}</div>
                            </div>
                        ) : null}
                    </div>
                    {/* {invoiceDetails.cartValues.cart_payment ? (
                        <>
                            <h4 className="invoiceSubTitle">
                                Payment Type:{' '}
                                {invoiceDetails.cartValues.cart_payment === 'online' ? (
                                    <>{t('online_payment')}</>
                                ) : invoiceDetails.cartValues.cart_payment === 'offline' ? (
                                    <>{t('offline_payment')}</>
                                ) : invoiceDetails.cartValues.cart_payment === 'deposit' ? (
                                    <>{t('deposit')}</>
                                ) : null}
                            </h4>
                        </>
                    ) : null} */}
                    {invoiceDetails.appointmentDetail &&
                    invoiceDetails.appointmentDetail.appointmentTime &&
                    invoiceDetails.appointmentDetail.appointmentTime != '0000-00-00 00:00:00' ? (
                        <>
                            <h4 className="invoiceSubTitle">
                                {'Appointment Scheduled at'}:{' '}
                                {dateTimeFormatFunction(
                                    invoiceDetails.appointmentDetail.appointmentTime,
                                )}
                            </h4>
                        </>
                    ) : null}
                    {invoiceDetails.invoiceTransactions.length ? (
                        <>
                            <h4 className="invoiceSubTitle">{'Transaction Details'}</h4>
                            <div className="transDet d-flex justify-content-between flex-wrap">
                                <>
                                    <table className="table table-bordered">
                                        <thead>
                                            <tr>
                                                {/* <th scope="col">S.No</th> */}
                                                <th scope="col">{'Transaction ID'}</th>
                                                <th scope="col">{'Paid On'}</th>
                                                <th scope="col">{'Method'}</th>
                                                <th scope="col">{'Amount'}</th>
                                                <th scope="col">{'Status'}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {invoiceDetails.invoiceTransactions.map(
                                                (data, index) => (
                                                    <tr key={index}>
                                                        <td
                                                            data-title={'Transaction ID'}
                                                            className="transcationIdCnt"
                                                        >
                                                            {data.trans_id
                                                                ? `# ${data.trans_id}`
                                                                : '-'}
                                                        </td>
                                                        <td data-title={'Paid On'}>
                                                            {dateTimeFormatFunction(
                                                                data.created_at,
                                                            )}
                                                        </td>
                                                        <td data-title={'Card'}>
                                                            {data.card_type == 'offline' &&
                                                            data.payment_method.toLowerCase() ==
                                                                'cash'
                                                                ? 'Manual'
                                                                : capitalize(data.payment_method)}
                                                        </td>
                                                        <td data-title={'Amount'}>
                                                            {currencyFormat(data.amount)}
                                                        </td>
                                                        <td
                                                            data-title={'Payment Status'}
                                                            style={{
                                                                color:
                                                                    data.status === 'success'
                                                                        ? 'green'
                                                                        : 'red',
                                                            }}
                                                        >
                                                            {capitalize(data.status)}
                                                        </td>
                                                    </tr>
                                                ),
                                            )}
                                        </tbody>
                                    </table>
                                </>
                            </div>
                        </>
                    ) : null}
                    <h4 className="invoiceSubTitle mt-5">{'Product Details'}</h4>

                    <div className="table-responsive">
                        {invoiceDetails.invoiceProducts.length ? (
                            <div className="cartProducts">
                                <div>
                                    {invoiceDetails.invoiceProducts.map((data, index) => (
                                        <CartItem data={data} from="invoice" />
                                    ))}
                                </div>
                            </div>
                        ) : null}
                    </div>
                    <div className="row mt-5">
                        <div className="col-12">
                            <div className="table-responsive">
                                <table className="table table-clear nellisInvTot">
                                    <tbody>
                                        <tr>
                                            <td className="left" align="right">
                                                <span className="text-dark">{'Items'}</span>
                                            </td>
                                            <td className="right">
                                                <span className="text-dark">
                                                    {invoiceDetails.invoiceProducts.length}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="left" align="right">
                                                <span className="text-dark">{'Bid Amount'}</span>
                                            </td>
                                            <td className="right">
                                                <span className="text-dark">
                                                    {currencyFormat(
                                                        invoiceDetails.cartValues.per_total,
                                                    )}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="left" align="right">
                                                <span className="text-dark">{'Sales Tax'}</span>
                                            </td>
                                            <td className="right">
                                                <span className="text-dark">
                                                    {currencyFormat(
                                                        invoiceDetails.cartValues.total_tax,
                                                    )}
                                                </span>
                                            </td>
                                        </tr>
                                        {invoiceDetails.cartValues.total_shipping ? (
                                            <tr>
                                                <td className="left" align="right">
                                                    <span className="text-dark">
                                                        {'Total Shipping'}
                                                    </span>
                                                </td>
                                                <td className="right">
                                                    <span className="text-dark">
                                                        {currencyFormat(
                                                            invoiceDetails.cartValues
                                                                .total_shipping,
                                                        )}
                                                    </span>
                                                </td>
                                            </tr>
                                        ) : null}
                                        <tr>
                                            <td className="left" align="right">
                                                <span className="text-dark">{'Total Amount'}</span>
                                            </td>
                                            <td className="right">
                                                <span className="text-dark">
                                                    {currencyFormat(
                                                        invoiceDetails.cartValues.total_amount,
                                                    )}
                                                </span>
                                            </td>
                                        </tr>

                                        {invoiceDetails.cartValues.total_deposit ? (
                                            <tr>
                                                <td className="left" align="right">
                                                    <span className="text-dark">
                                                        {'Bid Deposit'}
                                                    </span>
                                                </td>
                                                <td className="right">
                                                    <span className="text-dark">
                                                        -{' '}
                                                        {currencyFormat(
                                                            invoiceDetails.cartValues.total_deposit,
                                                        )}
                                                    </span>
                                                </td>
                                            </tr>
                                        ) : null}

                                        {/*invoiceDetails.cartValues.total_paid ? (
                                            <tr>
                                                <td className="left" align="right">
                                                    <span className="text-dark">
                                                        {t('total_paid')}
                                                    </span>
                                                </td>
                                                <td className="right">
                                                    <span className="text-dark">
                                                        {currencyFormat(
                                                            invoiceDetails.cartValues.total_paid,
                                                        )}
                                                    </span>
                                                </td>
                                            </tr>
                                                        ) : null*/}

                                        {/*invoiceDetails.cartValues.total_due_amount ? (
                                            <tr>
                                                <td className="left" align="right">
                                                    <span className="text-dark">
                                                        {'Pending Amount'}
                                                    </span>
                                                </td>
                                                <td className="right">
                                                    <span className="text-dark">
                                                        {currencyFormat(
                                                            invoiceDetails.cartValues
                                                                .total_due_amount,
                                                        )}
                                                    </span>
                                                </td>
                                            </tr>
                                                        ) : null*/}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <h4 className="thankText">{'Thank You for Shopping With Us'}</h4>
        </div>
    )
}

export default Invoice
