import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import './RegistrationLayout.css'
import { useTranslation } from 'react-i18next'
import { Button } from '@material-ui/core'
import { handleRedirectInternal } from '../../../common/components'
const RegistrationLayout = (props) => {
    const { t } = useTranslation()
    const history = useHistory()
    return (
        <div className="logRegLayout">
            <div className="ltLgContainer">
                {(window.location.pathname === '/login' ||
                    window.location.pathname.includes('/registration')) && (
                    <div className="logRegBtnCntnr">
                        <Button
                            className={`${window.location.pathname === '/login' ? 'active' : ''}`}
                            onClick={() => handleRedirectInternal(history, 'login')}
                        >
                            Login
                        </Button>
                        <Button
                            className={`${
                                window.location.pathname.includes('/registration') ? 'active' : ''
                            }`}
                            onClick={() => handleRedirectInternal(history, 'registration')}
                        >
                            Register
                        </Button>
                    </div>
                )}
            </div>
            <div className="rtLgContainer">
                {props.children}
                <div className="ftrCntnr">
                    <div>
                        <Link to="/terms_of_use">Terms of Use</Link>
                        <span className="dvdr">|</span>
                        <Link to="/privacy_policy">Privacy Policy</Link>
                    </div>
                    <p className="cprtCnt">Copyright © 2022 BidAShift.com. All Rights Reserved.</p>
                </div>
            </div>
        </div>
    )
}

export default RegistrationLayout
