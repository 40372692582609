import React, { useContext, useState, useEffect } from 'react'
import Moment from 'moment'
import PrimaryButton from '../../../../product/components/atoms/PrimaryButton'
import SecondaryButton from '../../../../product/components/atoms/SecondaryButton'
import FullScreenPopup from '../../../../product/components/organisms/FullScreenPopup'
import AuctionContext from '../../../../product/context/seller/template/auction/auctionContext'
import UserContext from '../../../../product/context/seller/user/userContext'
import CustomCommonContext from '../../../../custom/context/common/commonContext'
import AuthContext from '../../../../product/context/auth/authContext'
import SettingContext from '../../../../product/context/seller/setting/settingContext'
import {
    mapData,
    dateTimeFormatFunction,
    handleRedirectInternal,
} from '../../../../product/common/components'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { LinearProgress } from '@material-ui/core'
import Loaders from '../../../../product/components/molecules/Loaders'

const UserManage = (props) => {
    const auctionContext = useContext(AuctionContext)
    const settingContext = useContext(SettingContext)
    const authContext = useContext(AuthContext)

    const { getAllUsers, all_users } = useContext(UserContext)
    const { allLocations, allTimeZone, allEventType, membership } = useContext(CustomCommonContext)
    const { single_auction, auction_projects, getSingleAuction, auctionAction, responseStatus } =
        auctionContext
    const { user, isAuthenticated } = authContext
    const { table_data, searchTable } = settingContext

    const [reload, setReload] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [locationBody, setLocationBody] = useState([])

    const data = props.data
    const toggleFullScreenPopup = props.function
    const [activeAuctProjects, setActiveAuctProjects] = useState([])

    const validationArray = Yup.object({
        template_name: Yup.string().required('Required!'),
        title: Yup.string().required('Required!'),
        event_type: Yup.string().required('Required!'),
        location_id: Yup.string().required('Required!'),
        time_zone: Yup.string(),
        description: Yup.string().required('Required!'),
        ending_enable: Yup.boolean(),
        extends_enable: Yup.boolean(),
        ending_items: Yup.number().when('ending_enable', {
            is: (end) => end === 1,
            then: Yup.number().min(0, 'Not valid!'),
        }),
        ending_mins: Yup.number().when('ending_enable', {
            is: (end) => end === 1,
            then: Yup.number().min(0, 'Not valid!'),
        }),
        min_time: Yup.number().when('extends_enable', {
            is: (end) => end === 1,
            then: Yup.number().min(0, 'Not valid!').required('Required!'),
        }),
        extends_time: Yup.number().when('extends_enable', {
            is: (end) => end === 1,
            then: Yup.number().min(0, 'Not valid!').required('Required!'),
        }),
        enable_proxy: Yup.boolean(),
        isname_visible: Yup.boolean(),
        isbidvalue_visible: Yup.boolean(),
        prebid_available: Yup.boolean(),
        organization_id: Yup.array(),
        selltype: Yup.boolean(),
        selectedbuyer: Yup.array().when('selltype', {
            is: (avatar) => avatar,
            then: Yup.array(),
        }),
    })

    const formik = useFormik({
        initialValues: {
            id: 0,
            template_name: '',
            title: '',
            event_type: '',
            location_id: '',
            time_zone: '',
            description: '',
            ending_enable: false,
            ending_items: 0,
            ending_mins: 0,
            enable_proxy: 0,
            isname_visible: 0,
            isbidvalue_visible: 0,
            prebid_available: 0,
            organization_id: [],
            selltype: true,
            selectedbuyer: [],
            extends_enable: false,
            min_time: 0,
            extends_time: 0,
        },
        validateOnBlur: false,
        validationSchema: validationArray,
        onSubmit: (values) => {
            setIsLoading(true)
            const value = values
            auctionAction(value)
        },
    })

    useEffect(() => {
        setIsLoading(true)
        if (data.status === 'edit' && data.id > 0) {
            formik.values.id = data.id
            getSingleAuction(formik.values)
        } else {
            formik.values.id = 0
            resetFrom()
        }
    }, [data.id])

    useEffect(() => {
        setIsLoading(false)
        if (single_auction.record && data.status === 'edit' && data.id > 0) {
            const auction = single_auction.record
            formik.values.template_name = auction.template_name ? auction.template_name : ''
            formik.values.title = auction.title ? auction.title : ''
            formik.values.event_type = auction.event_type ? auction.event_type : ''
            formik.values.location_id = auction.location_id ? auction.location_id : ''
            formik.values.time_zone = auction.time_zone ? auction.time_zone : ''
            formik.values.description = auction.description ? auction.description : ''
            formik.values.ending_enable = auction.ending_enable === 1 ? true : false

            formik.values.ending_items = auction.ending_items ? auction.ending_items : 0
            formik.values.ending_mins = auction.ending_mins ? auction.ending_mins : 0
            formik.values.enable_proxy = auction.enable_proxy ? auction.enable_proxy : 0
            formik.values.isname_visible = auction.isname_visible ? auction.isname_visible : 0
            formik.values.isbidvalue_visible = auction.isbidvalue_visible
                ? auction.isbidvalue_visible
                : 0
            formik.values.prebid_available = auction.prebid_available ? auction.prebid_available : 0

            formik.values.extends_enable = auction.extends_enable
                ? auction.extends_enable === 1
                    ? true
                    : false
                : false
            formik.values.min_time = auction.min_time ? auction.min_time : 0
            formik.values.extends_time = auction.extends_time ? auction.extends_time : 0

            // formik.values.organization_id = auction.organization_id
            //     ? auction.organization_id.toString().split(',').map(Number)
            //     : []
            formik.values.selltype = auction.selltype === '1' ? true : false
            formik.values.selectedbuyer = auction.selectedbuyer
                ? auction.selectedbuyer.split(',').map(Number)
                : []

            setReload(!reload)
        } else {
            resetFrom()
        }
    }, [single_auction])

    const resetFrom = () => {
        formik.values.template_name = ''
        formik.values.title = ''
        formik.values.event_type = ''
        formik.values.location_id = ''
        formik.values.time_zone = ''
        formik.values.description = ''
        formik.values.ending_enable = false
        formik.values.ending_items = 0
        formik.values.ending_mins = 0
        formik.values.enable_proxy = 0
        formik.values.isname_visible = 0
        formik.values.isbidvalue_visible = 0
        formik.values.prebid_available = 0
        formik.values.selltype = true
        formik.values.selectedbuyer = []
        // formik.values.organization_id = []
        setIsLoading(false)
        formik.handleReset()
    }
    useEffect(() => {
        if (isAuthenticated) {
            if (user) {
                let formikSearchTable = {
                    page: 1,
                    limit: 10000,
                    order: '',
                    orderby: '',
                    table: 'locations',
                    filters: {
                        name: {
                            value: '',
                            type: 'like',
                            field: 'l.name',
                        },
                        organization_id: {
                            value: user.organization_id.toString(),
                            type: 'in',
                            field: 'l.organization_id',
                        },
                    },
                }
                searchTable(formikSearchTable, 'locations')
            }
        }
    }, [user, isAuthenticated])

    useEffect(() => {
        if (table_data && table_data.records) {
            if (table_data.from === 'locations') {
                setLocationBody(
                    table_data.records.length
                        ? table_data.records.filter((element) => {
                              return element.active == 1
                          })
                        : [],
                )
                setIsLoading(false)
            }
        }
    }, [table_data])

    // useEffect(() => {
    //     setIsLoading(false)
    //     const projectToShow = []
    //     if (auction_projects && auction_projects.records.length > 0) {
    //         auction_projects.records.map((lot, index) => {
    //             projectToShow.push({ value: lot.id, show: `${index + 1}: ${lot.title} ` })
    //         })
    //         setActiveAuctProjects(projectToShow)
    //     } else {
    //         setActiveAuctProjects([])
    //     }
    // }, [auction_projects])

    const templateData = {
        formik: formik,
        data: [
            {
                label: 'Template Name *',
                type: 'text',
                placeholder: 'Enter your Template Name ',
                class: 'col-sm-12 col-12',
                name: 'template_name',
            },
            {
                label: 'Auction Title *',
                type: 'text',
                placeholder: 'Enter your Auction Title',
                class: 'col-12',
                name: 'title',
            },
            {
                label: 'Shift type',
                name: 'event_type',
                placeholder: 'Select Shift',
                type: 'select',
                defaultOptionValue: 'Select a Location',
                options: allEventType
                    ?.filter((event) => {
                        if (membership && !membership.multishift) {
                            return event.id !== 2
                        }
                        return event
                    })
                    .map((event) => {
                        let eventType = {}
                        eventType.show = event.description
                        eventType.value = event.id
                        return eventType
                    }),
                defaultOptionValue: 'Select a Event',
                class: 'col-12',
            },
            {
                label: 'Location *',
                placeholder: 'Select location',
                type: 'select',
                class: 'col-12',
                options:
                    locationBody && locationBody.length
                        ? locationBody.map((location) => {
                              let locationChanged = {}
                              locationChanged.show = location.name
                              locationChanged.value = location.id
                              return locationChanged
                          })
                        : [],
                defaultOptionValue: 'Select a Location',
                name: 'location_id',
            },
            {
                label: 'Time zone',
                placeholder: 'Select Event',
                name: 'time_zone',
                type: 'select',
                placeholder: 'Select time zone',
                options: allTimeZone?.map((time) => {
                    let timeChanged = {}
                    timeChanged.show = time.description
                    timeChanged.value = time.id
                    return timeChanged
                }),
                class: 'col-md-12 col-12',
            },
            {
                label: 'Description *',
                placeholder: 'Enter Description',
                type: 'ckeditor',
                class: 'col-12 mb-4',
                name: 'description',
            },
            {
                label: 'Enable Staggered Separate Shift Auctions',
                placeholder: 'Enable Staggered Separate Shift Auctions',
                type: 'checkbox',
                class: 'col-sm-6 col-12',
                name: 'ending_enable',
                disabled: formik.values?.event_type == 2 ? false : true,
            },
            {
                label: 'Items',
                placeholder: 'Enable Items',
                type: 'number',
                disabled: formik.values.ending_enable ? false : true,
                class: 'col-md-3 col-sm-6 col-12',
                name: 'ending_items',
            },
            {
                label: 'Minutes',
                placeholder: 'Enable Minutes',
                type: 'number',
                disabled: formik.values.ending_enable ? false : true,
                class: 'col-md-3 col-sm-6 col-12',
                name: 'ending_mins',
            },
            {
                label: 'Enable Extended Auction (Anti Sniping) ',
                placeholder: 'Enable Extended Auction (Anti Sniping) ',
                type: 'checkbox',
                class: 'col-sm-6 col-12',
                disabled: membership?.extend_auction ? false : true,
                name: 'extends_enable',
            },
            {
                label: 'Minimum Time',
                placeholder: 'Minimum Time',
                type: 'number',
                disabled: membership?.extend_auction ? false : true,
                class: 'col-md-3 col-sm-6 col-12',
                name: 'min_time',
            },
            {
                label: 'Extended Time',
                placeholder: 'Extended Time',
                type: 'number',
                // disabled: formik.values.extends_enable ? false : true,
                disabled: membership?.extend_auction ? false : true,
                class: 'col-md-3 col-sm-6 col-12',
                name: 'extends_time',
            },
            {
                title: 'Are the bidders allowed to see other bidders by name?',
                type: 'radio',
                name: 'isname_visible',
                class: 'col-12 mb-4',
                int: 1,
                item: [
                    { id: 1, description: 'Yes' },
                    { id: 0, description: 'No' },
                ],
            },
            {
                title: 'Are the bidders allowed to see other bid values?',
                type: 'radio',
                name: 'isbidvalue_visible',
                class: 'col-12 mb-4',
                int: 1,
                item: [
                    { id: 1, description: 'Yes' },
                    { id: 0, description: 'No' },
                ],
            },
            {
                title: 'Are the bidders allowed to pre-bid an auction?',
                type: 'radio',
                name: 'prebid_available',
                class: 'col-12',
                int: 1,
                item: [
                    { id: 1, description: 'Yes' },
                    { id: 0, description: 'No' },
                ],
            },
            {
                title: 'Are the bidders allowed to proxy bid?',
                type: 'radio',
                disabled: membership?.pre_bid ? false : true,
                name: 'enable_proxy',
                class: 'col-12',
                int: 1,
                item: [
                    { id: 1, description: 'Yes' },
                    { id: 0, description: 'No' },
                ],
            },
            {
                label: 'Selected Users',
                placeholder: 'Select condition',
                type: 'multiselect',
                class: 'col-12',
                options:
                    all_users && all_users.records
                        ? all_users.records.map((user) => {
                              let userChanged = {}
                              userChanged.show =
                                  user.first_name + ' ' + user.last_name + ' (' + user.email + ')'
                              userChanged.value = user.id
                              return userChanged
                          })
                        : [],
                disabled: formik.values.selltype ? false : true,
                defaultOptionValue: 'Select a Condition',
                name: 'selectedbuyer',
            },
        ],
    }

    // useEffect(() => {
    //     if (!formik.values.selltype) {
    //         formik.values.organization_id = []
    //         formik.values.selectedbuyer = []
    //         setReload(!reload)
    //     }
    // }, [formik.values.selltype])

    // useEffect(() => {
    //     if (formik.values.organization_id.length) {
    //         let allBuyers = []
    //         if (all_users && all_users.records) {
    //             all_users.records.map((user) => {
    //                 allBuyers.push(parseInt(user.id, 10))
    //                 return true
    //             })
    //         }
    //         formik.setFieldValue('selectedbuyer', allBuyers)
    //         setReload(!reload)
    //     }
    // }, [all_users])

    useEffect(() => {
        if (isAuthenticated) {
            if (formik.values.organization_id && formik.values.organization_id.length) {
                getAllUsers({
                    page: 1,
                    limit: 10000000,
                    order: '',
                    orderby: '',
                    filters: {
                        action: {
                            value: 'active',
                            type: 'in',
                            field: 'u.status',
                        },
                        company_id: {
                            value: formik.values.organization_id
                                ? formik.values.organization_id
                                : [],
                            type: 'findinarray',
                            field: 'cu.company_id',
                        },
                    },
                })
            }
        }
    }, [formik.values.organization_id, isAuthenticated])

    useEffect(() => {
        if (user && user.organization_id) {
            console.log('Setting organization_id', user.organization_id)
            formik.setFieldValue('organization_id', [user.organization_id])
        }
    }, [user])

    useEffect(() => {
        if (responseStatus) {
            if (responseStatus.from === 'auctionAction') {
                if (responseStatus.status === 'success') {
                    setIsLoading(false)
                    formik.resetForm()
                    toggleFullScreenPopup(false)
                }
            }
        }
    }, [responseStatus])

    console.log('all_users', all_users)
    return (
        <FullScreenPopup
            className="adTempltPopup"
            modaltitle={
                data.status === 'new'
                    ? 'ADD NEW AUCTION TEMPLATE'
                    : `EDIT AUCTION TEMPLATE: ${single_auction.record.template_name} `
            }
            open={data.status === 'new' || data.status === 'edit' ? data.popup : false}
            handleClose={() => toggleFullScreenPopup(false)}
        >
            <div className="addUserModal">
                <div className="fspBody">
                    {formik.isSubmitting ? <LinearProgress /> : null}
                    {isLoading ? (
                        <div className="fspLoader">
                            <Loaders isLoading={isLoading} />
                        </div>
                    ) : (
                        <>
                            <form onSubmit={formik.handleSubmit} autoComplete="nofill">
                                <div className="row">{mapData(templateData)}</div>
                                <div className="actionButton d-flex justify-content-center align-items-center flex-wrap">
                                    <SecondaryButton
                                        label="Cancel"
                                        onClick={() => toggleFullScreenPopup(false)}
                                    />

                                    <PrimaryButton type="submit" label="Add" btnSize="ml-2" />
                                </div>
                            </form>
                        </>
                    )}
                </div>
            </div>
        </FullScreenPopup>
    )
}

export default UserManage
