import React, { useContext, useEffect, useState } from 'react'
import PrimaryButton from '@/product/components/atoms/PrimaryButton'
import { handleRedirectInternal, mapData } from '@/product/common/components'
import { useFormik } from 'formik'
import { useHistory } from 'react-router-dom'
import UserContext from '@/product/context/user/userContext'
import { Button } from '@material-ui/core'
import * as Yup from 'yup'
import PhoneValidationComponent from '@/product/components/species/components/user/PhoneValidationComponent'

const ForgetPasswordComponent = (props) => {
    const userContext = useContext(UserContext)

    const [initialValues, setInitialValues] = useState({})
    const [validationSchema, setValidationSchema] = useState({})
    const [fields, setFields] = useState({})
    const [checker, setChecker] = useState(false)
    const [phoneVerify, setPhoneVerify] = useState(false)
    const changePhoneVerify = () => {
        setPhoneVerify(!phoneVerify)
    }

    const { forgotPassword, responseStatus, clearResponse } = userContext
    const history = useHistory()

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: (values) => {
            let value = values
            console.log(value, 'values')
            if (checker) {
                if (value.phone_verified === 1 && value.verified_phonenum === value.phone) {
                    forgotPassword(values)
                } else {
                    setPhoneVerify(true)
                }
            } else {
                forgotPassword(values)
            }
        },
    })

    //Initial setup
    useEffect(() => {
        if (props.method) {
            if (props.method.includes('sms') && props.method.length === 1) {
                setChecker(true)
            } else if (props.method.length === 1) {
                setChecker(false)
            }
        }
    }, [])
    useEffect(() => {
        let tempInitial = {}
        let tempValidation = {}
        let tempfields = {}

        if (!checker) {
            tempInitial = { email: '' }
            tempValidation = Yup.object({
                email: Yup.string().email('Invalid Email Format').required('Required'),
            })
            tempfields = {
                data: [
                    {
                        label: 'Email Address',
                        name: 'email',
                        type: 'email',
                        placeholder: 'Enter Your Email Address',
                        class: 'col-12',
                        autoFocus: true,
                    },
                ],
            }
        }

        if (checker) {
            tempInitial = {
                phone: '',
                phone_verified: 0,
                phone_verifysent: 0,
                verified_phonenum: 0,
                verify_code: '',
                isForgotPassword: 1,
            }
            tempValidation = Yup.object({
                phone: Yup.string()
                    .trim()
                    .nullable()
                    .matches(/^[0-9- +()]*$/g, 'Only numbers are allowed')
                    .min(17, 'Please enter correct mobile number')
                    .required('Required!'),
            })
            tempfields = {
                data: [
                    {
                        label: 'Phone number',
                        placeholder: 'Enter your phone number',
                        class: 'col-12',
                        type: 'phone',
                        name: 'phone',
                    },
                ],
            }
        }

        setInitialValues(tempInitial)
        setValidationSchema(tempValidation)
        setFields(tempfields)
    }, [checker])

    //default method pickup
    useEffect(() => {
        if (props.defaultMethod && props.method.length === 2) {
            if (props.defaultMethod === 'sms') {
                setChecker(true)
            }
        }
    }, [])

    const forgotPasswordForm = fields ? { formik: formik, ...fields } : {}

    useEffect(() => {
        if (responseStatus) {
            if (responseStatus.from === 'forgotPassword') {
                if (responseStatus.status === 'success') {
                    if (props.onSuccess && !checker) {
                        props.onSuccess()
                    } else if (checker) {
                        window.open(responseStatus.data.verifyurl)
                        handleRedirectInternal(history, '')
                    } else {
                        handleRedirectInternal(history, '')
                    }
                    clearResponse()
                }
            }
        }
    }, [responseStatus])

    //sms handle submit
    useEffect(() => {
        if (formik.values.phone_verified) {
            formik.handleSubmit()
        }
    }, [formik.values.phone_verified])

    useEffect(() => {
        if (props.additionalfieldUpdate) {
            props.additionalfieldUpdate(formik.values)
        }
    }, [formik.values])

    const switchHandler = () => {
        setChecker(!checker)
    }

    return (
        <>
            <form onSubmit={formik.handleSubmit} autoComplete="nofill">
                {forgotPasswordForm?.data?.length ? (
                    <div className="row">{mapData(forgotPasswordForm)}</div>
                ) : null}
                {checker ? (
                    <PrimaryButton
                        id="forgetPassword_link"
                        type="submit"
                        label={'Verify'}
                        btnSize="large"
                    />
                ) : (
                    <PrimaryButton
                        id="forgetPassword_link"
                        type="submit"
                        label={
                            props.SubmitBtnLabel ? props.SubmitBtnLabel : 'Send Reset Password Link'
                        }
                        btnSize="large"
                    />
                )}
                <br />
                {props.method?.length === 2 ? (
                    <h6 className="loginHelper">
                        <Button onClick={switchHandler}>
                            Use {checker ? 'Email ' : 'Phone number '} verification
                        </Button>
                    </h6>
                ) : null}
            </form>

            {checker ? (
                <PhoneValidationComponent
                    phoneVerify={phoneVerify}
                    formik={formik}
                    setPhoneVerify={setPhoneVerify}
                    changePhoneVerify={changePhoneVerify}
                />
            ) : null}
        </>
    )
}
export default ForgetPasswordComponent
