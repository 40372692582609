import React from 'react'
import { Link, NavLink } from 'react-router-dom'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import { Button, Fade, ListItem, MenuItem, Typography } from '@material-ui/core'
import CustomSelect from '@/product/components/atoms/Inputs/CustomSelect'

const SortByComponent = (props) => {
    const formikSearch = props.formikSearch
    const sortShow = props.sortShow
    const pageOptions = props.pageOptions

    return (
        <div className="d-flex flex-wrap justify-content-between align-items-center">
            {props.path && (
                <Breadcrumbs aria-label="breadcrumb" className="customBreadcrumbs">
                    <Link to="/auctions/all">{location.pathname.split('/')[1]}</Link>
                    <Typography color="textPrimary">
                        {props.renderHeading(location.pathname.split('/')[2])}
                    </Typography>
                </Breadcrumbs>
            )}
            <div className="searchMiscFilters w-100 mb-3 d-flex justify-content-end align-items-end">
                {props.viewSwitch && (
                    <div className="gridListToggle">
                        <Button
                            id="sort_grid"
                            className={props.auctionView === 'Grid' ? 'active' : ''}
                            onClick={() => formikSearch.setFieldValue('auctionView', 'Grid')}
                        >
                            <span className="material-icons">apps</span>
                        </Button>
                        <Button
                            id="sort_list"
                            className={props.auctionView === 'List' ? 'active' : ''}
                            onClick={() => formikSearch.setFieldValue('auctionView', 'List')}
                        >
                            <span className="material-icons">view_list</span>
                        </Button>
                    </div>
                )}
                {props.resultPerPage && (
                    <CustomSelect
                        label="Results per page"
                        name="resultsPerPage"
                        selectType="noBorder"
                        size="small"
                        value={formikSearch.values.limit}
                        onChange={(event, editor) => {
                            formikSearch.setFieldValue('limit', event.target.value)
                        }}
                    >
                        {pageOptions.map((opt, optindex) => (
                            <option value={opt.value}>{opt.show}</option>
                        ))}
                    </CustomSelect>
                )}
                {props.sortBy && (
                    <CustomSelect
                        label="Sort by"
                        value={formikSearch.values.orderby}
                        size="small"
                        selectType="noBorder"
                        name="orderby"
                        // shrink={search.orderby !== '' ? true : false}
                        onChange={(event, editor) => {
                            formikSearch.setFieldValue('orderby', event.target.value)
                        }}
                    >
                        {sortShow.map((opt, optindex) => (
                            <option value={opt.value}>{opt.show}</option>
                        ))}
                    </CustomSelect>
                )}
            </div>
        </div>
    )
}

export default SortByComponent
