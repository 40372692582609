import React, { useContext, useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import DashboardLayout from '../../Dashboard/DashboardLayout'
import CustomTable from '../../../product/components/molecules/CustomTable'
import CustomSearch from '../../../product/components/molecules/CustomSearch'
import { handleRedirectInternal } from '../../../product/common/components'
import { useFormik } from 'formik'
import UserContext from '../../../product/context/seller/user/userContext'
import PrimaryButton from '../../../product/components/atoms/PrimaryButton'
import SecondaryButton from '../../../product/components/atoms/SecondaryButton'
import CustomDialog from '../../../product/components/organisms/Dialog'
import CustomCommonContext from '../../../custom/context/common/commonContext'
import AuthContext from '../../../product/context/auth/authContext'
import { mapData } from '../../../product/common/components'
import { Button } from '@material-ui/core'
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer'
import InviteUser from './InviteUser'
import FormDialog from '../../../custom/components/organisms/Dialog'

const UserView = (props) => {
    const userContext = useContext(UserContext)
    const authContext = useContext(AuthContext)
    const customCommonContext = useContext(CustomCommonContext)

    const { allBusinessCategory, membership } = customCommonContext
    const { user, isAuthenticated } = authContext

    const [isLoading, setIsLoading] = useState(true)
    const history = useHistory()
    const {
        all_users,
        changeUserStatus,
        getAllUsers,
        responseStatus: responseStatusUser,
        clearResponse: clearResponseUser,
    } = userContext
    const [changeStatus, setChangeStatus] = React.useState(false)
    const [manage, setManage] = React.useState({
        popup: false,
        status: 'new',
        id: 0,
        user_type: 0,
    })
    const [state, setState] = useState({
        right: false,
    })
    const [subPopup, setSubPopup] = useState(false)

    const toggleDrawer = (open, id) => {
        setManage({ popup: 'Comment', status: '', id })

        setState({
            right: open,
        })
    }
    const changeChangeStatus = () => {
        setChangeStatus(!changeStatus)
    }

    const toggleFullScreenPopup = (popup, status, id, user_type) => {
        if (popup) {
            setManage({ popup, status, id, user_type })
        } else {
            setManage({ popup, status: 'new', id: 0, user_type: 0 })
        }
    }

    const [tableBody, setTableBody] = useState([])

    const formik = useFormik({
        initialValues: {
            page: 1,
            limit: 20,
            order: '',
            orderby: '',
            filters: {
                email: {
                    value: '',
                    type: 'like',
                    field: 'u.email',
                },
                first_name: {
                    value: '',
                    type: 'like',
                    field: 'u.first_name',
                },
                last_name: {
                    value: '',
                    type: 'like',
                    field: 'u.last_name',
                },
                companyid: {
                    value: user ? user.organization_id : '',
                    type: 'in',
                    field: 'cu.company_id',
                },
                // companyname: {
                //     value: '',
                //     type: 'like',
                //     field: 'u.companyname',
                // },
                // trakhees_reg_num: {
                //     value: '',
                //     type: 'like',
                //     field: 'cu.trakhees_reg_num',
                // },
                // dubai_customs_code: {
                //     value: '',
                //     type: 'like',
                //     field: 'cu.dubai_customs_code',
                // },
                // business_category: {
                //     value: [],
                //     type: 'array',
                //     field: 'cu.business_category',
                // },
            },
        },
        onSubmit: (values) => {
            setIsLoading(true)
            getAllUsers(values)
        },
    })

    useEffect(() => {
        setIsLoading(false)
        if (user && all_users) {
            setTableBody(all_users.records.length ? all_users.records : [])
        }
    }, [all_users, user])

    useEffect(() => {
        if (isAuthenticated) {
            setTableBody([])
            setIsLoading(true)
            formik.values.page = 1
            formik.values.filters.email.value = ''
            formik.values.filters.first_name.value = ''
            formik.values.filters.last_name.value = ''
            // formik.values.filters.companyname.value = ''
            // formik.values.filters.trakhees_reg_num.value = ''

            getAllUsers(formik.values)
            /*  return () => {
                setTableBody([])
            }*/
        }
    }, [isAuthenticated])

    const onClickEdit = (id, secId, type) => {
        if (type === 'Comment') {
            toggleDrawer(true, id)
        } else {
            toggleFullScreenPopup(true, 'edit', id)
        }
    }
    const formikSelection = useFormik({
        initialValues: {
            action: '',
            user_id: '',
            //   reject_notes: '',
            // additionalUpdate: true,
        },
        onSubmit: (values) => {
            if (
                values.action === 'active' ||
                values.action === 'deactivate' ||
                values.action === 'rejected'
            ) {
                changeUserStatus(values)
            }
        },
    })

    const onSelectMultiProducts = (data, action) => {
        formikSelection.values.action = action
        formikSelection.values.user_id = data
        formikSelection.values.additionalUpdate =
            action === 'deactivate' || action === 'rejected' ? true : false
        formikSelection.values.reject_notes = ''
        if (action === 'active' || action === 'deactivate' || action === 'rejected') {
            setChangeStatus(true)
        }
    }
    const getMultipleAction = () => {
        let actionPush = []
        actionPush.push({
            field: 'action',
            type: 'button',
            clickType: 'edit',
            onclick: onClickEdit,
            sort: false,
            firstChild: false,
            disablePadding: false,
            label: 'Action',
            tooltipTitle: 'Edit user',
        })
        return actionPush
    }
    const tableValues = [
        {
            field: 'id',
            type: '',
            firstChild: true,
            sort: true,
            disablePadding: false,
            label: 'ID',
        },
        {
            field: 'first_name',
            type: '',
            firstChild: true,
            sort: true,
            disablePadding: false,
            label: 'First Name',
        },
        {
            field: 'last_name',
            type: '',
            firstChild: false,
            sort: true,
            disablePadding: false,
            label: 'Last Name',
        },
        {
            field: 'email',
            type: '',
            sort: true,
            firstChild: false,
            disablePadding: false,
            label: 'Email',
        },
        // {
        //     field: 'companyname',
        //     type: '',
        //     sort: true,
        //     firstChild: false,
        //     disablePadding: false,
        //     label: 'Company',
        // },
        {
            field: 'last_login',
            type: 'date',
            sort: true,
            firstChild: false,
            disablePadding: false,
            label: 'Last Login',
        },
        {
            field: 'status',
            type: 'capitalize',
            sort: true,
            firstChild: false,
            disablePadding: false,
            label: 'Status',
        },
        {
            field: 'action',
            type: 'button',
            isMultiple: true,
            clickID: 'id',
            sort: false,
            firstChild: false,
            disablePadding: false,
            label: 'Action',
            isMultiple: getMultipleAction(),
        },
        // {
        //     field: 'deposit_amount',
        //     type: 'price',
        //     sort: true,
        //     firstChild: false,
        //     disablePadding: false,
        //     label: 'Wallet Balance',
        // },
    ]

    /*   tableValues.push({
        field: 'action',
        type: 'button',
        clickType: 'edit',
        onclick: onClickEdit,
        sort: false,
        firstChild: false,
        disablePadding: false,
        label: 'Action',
        tooltipTitle: 'Edit user',
    })*/

    const searchInfo = {
        formik: formik,
        data: [
            {
                label: 'Email ',
                placeholder: 'Enter Email',
                class: 'col-md-4 col-sm-6 col-12',
                type: 'text',
                name: 'email',
                filter: true,
            },
            {
                label: 'First Name',
                placeholder: 'Enter First Name',
                class: 'col-md-4 col-sm-6 col-12',
                type: 'text',
                name: 'first_name',
                filter: true,
            },
            {
                label: 'Last Name',
                placeholder: 'Enter Last Name',
                class: 'col-md-4 col-sm-6 col-12',
                type: 'text',
                name: 'last_name',
                filter: true,
            },
            // {
            //     label: 'Company Name',
            //     placeholder: 'Enter Company Name',
            //     class: 'col-md-4 col-sm-6 col-12',
            //     type: 'text',
            //     name: 'companyname',
            //     filter: true,
            // },
            // {
            //     label: 'Trakhees Registration Number',
            //     placeholder: 'Enter Trakhees Registration Number',
            //     class: 'col-md-4 col-sm-6 col-12',
            //     type: 'text',
            //     name: 'trakhees_reg_num',
            //     filter: true,
            // },
            // {
            //     label: 'Dubai Customs Code',
            //     placeholder: 'Enter Dubai Customs Code',
            //     class: 'col-md-4 col-sm-6 col-12',
            //     type: 'text',
            //     name: 'dubai_customs_code',
            //     filter: true,
            // },
            // {
            //     label: 'Categories',
            //     placeholder: 'Enter Business Category',
            //     class: 'col-md-4 col-sm-6 col-12',
            //     type: 'multiselect',
            //     name: 'business_category',
            //     options: allBusinessCategory
            //         .filter((value) => value.active === 1)
            //         .map((buscat) => {
            //             let busCategoryChanged = {}
            //             busCategoryChanged.show = buscat.description
            //             busCategoryChanged.value = buscat.id
            //             return busCategoryChanged
            //         }),
            //     filter: true,
            // },
        ],
    }

    const tableActions = []

    /*    if (action === 'unverified') {
        tableActions.push({
            label: 'Move to Rejected',
            icon: <span className="material-icons">cancel</span>,
            onclick: onSelectMultiProducts,
            type: 'rejected',
        })
    } */

    tableActions.push(
        {
            label: 'Move to De-activate',
            icon: <span className="material-icons">block</span>,
            onclick: onSelectMultiProducts,
            type: 'deactivate',
        },
        {
            label: 'Move to Activate',
            icon: <span className="material-icons">check_circle</span>,
            onclick: onSelectMultiProducts,
            type: 'active',
        },
        /*{
                label: 'Move to Rejected',
                icon: <span className="material-icons">cancel</span>,
                onclick: onSelectMultiProducts,
                type: 'rejected',
            },*/
    )

    useEffect(() => {
        if (responseStatusUser) {
            if (responseStatusUser.status === 'success') {
                if (responseStatusUser.from === 'changeStatus') {
                    setChangeStatus(false)
                    getAllUsers(formik.values)
                }
                if (responseStatusUser.from === 'userAction') {
                    toggleFullScreenPopup(false)
                    getAllUsers(formik.values)
                }
            }
        }
    }, [responseStatusUser])

    const rejectNotes = {
        formik: formikSelection,
        data: [
            {
                label: 'Justification Notes',
                placeholder: 'Enter Justification Notes',
                class: `col-12 ${formikSelection.values.additionalUpdate ? '' : 'd-none'}`,
                type: 'textarea',
                name: 'reject_notes',
                filter: true,
            },
        ],
    }
    useEffect(() => {
        if (user) {
            if (user.organization_id) {
                formik.setFieldValue('filters.companyid.value', user.organization_id)
            }
        }
    }, [user])

    useEffect(() => {
        if (formik.values.filters.companyid.value) {
            getAllUsers(formik.values)
        }
    }, [formik.values.filters.companyid.value])

    return (
        <DashboardLayout>
            <div className="dashboardInner userManagement">
                <CustomSearch searchInfo={searchInfo} formik={formik} />
                {tableBody.length < membership?.users_limit_to ? (
                    <div className="miscActions d-flex justify-content-between align-items-center mt-4 flex-wrap">
                        <div>
                            <Button
                                className="addTempBtn"
                                onClick={() => toggleFullScreenPopup(true, 'new', 0, 0)}
                            >
                                <span className="material-icons">person_add_alt_1</span>Invite User
                            </Button>
                            {/* <Button
                            variant="outlined"
                            className="btnOutlined"
                            onClick={() => toggleFullScreenPopup(true, 'new', 0, 1)}
                        >
                            <span className="material-icons">business</span>Add Company User
                        </Button> */}
                        </div>
                    </div>
                ) : (
                    <div className="miscActions d-flex justify-content-between align-items-center mt-4 flex-wrap">
                        <div>
                            <Button className="addTempBtn" onClick={() => setSubPopup(true)}>
                                <span className="material-icons">person_add_alt_1</span>Invite User
                            </Button>
                        </div>
                    </div>
                )}
                <CustomTable
                    formik={formik.values}
                    tableBody={tableBody}
                    tableValues={tableValues}
                    tableActions={tableActions}
                    allData={all_users}
                    isLoading={isLoading}
                    loadingTemplate={'table'}
                    changeTableFunction={getAllUsers}
                />
            </div>

            <InviteUser data={manage} function={toggleFullScreenPopup} />
            <CustomDialog
                title={'Change user status'}
                open={changeStatus}
                function={changeChangeStatus}
            >
                <h5>Are you sure you want to change the status</h5>
                <div className="row">{mapData(rejectNotes)}</div>
                <div className="actionWrapper">
                    <Button onClick={() => changeChangeStatus()}>Cancel</Button>
                    <form onSubmit={formikSelection.handleSubmit} autoComplete="nofill">
                        <PrimaryButton type="submit" label="Confirm" />
                    </form>
                </div>
            </CustomDialog>
            <FormDialog open={subPopup} openFunction={() => setSubPopup(false)}>
                <div className="upgrdPup">
                    <p className="text-center mb-1">
                        <span className="material-icons">warning</span>
                    </p>
                    {membership ? (
                        <p className="text-center">
                            Your invite user limit exceeded, please upgrade your plan to continue
                        </p>
                    ) : (
                        <p className="text-center">Buy a plan to enable this</p>
                    )}

                    <div className="d-flex align-items-center justify-content-end">
                        <SecondaryButton label="Cancel" onClick={() => setSubPopup(false)} />
                        <PrimaryButton
                            btnSize="ml-2"
                            label="Upgrade"
                            onClick={() => (
                                handleRedirectInternal(history, 'subscription'), setSubPopup(false)
                            )}
                        />
                    </div>
                </div>
            </FormDialog>
        </DashboardLayout>
    )
}

export default UserView
