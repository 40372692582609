import { GET_ALL_INCREMENTS, RESPONSE_STATUS, CLEAR_RESPONSE } from './bidTypes'

export default (state, action) => {
    switch (action.type) {
        case RESPONSE_STATUS:
            return {
                ...state,
                responseStatus: action.payload,
            }
        case CLEAR_RESPONSE:
            return {
                ...state,
                responseStatus: '',
            }

        case GET_ALL_INCREMENTS:
            return {
                ...state,
                all_increments: action.payload,
            }

        default:
            return state
    }
}
