import React, { useEffect, useContext } from 'react'
import { Route, BrowserRouter as Router, withRouter, Switch } from 'react-router-dom'
import PrivateRoute from './privateRoute'
import Login from '../views/Login'
import Header from '../custom/components/molecules/Header'
import Footer from '../custom/components/molecules/Footer'
import AuthContext from '../product/context/auth/authContext'
import CustomCommonContext from '../custom/context/common/commonContext'
import ForgotPassword from '../views/ForgotPassword'
import ResetPassword from '../views/ResetPassword'
import VerifyEmail from '../views/VerifyEmail'
import Search from '../views/Search'
import MyAuctions from '../views/Dashboard/MyAuctions'
import Registration from '../views/Registration'
import Profile from '../views/Dashboard/Profile/index'
import ChangePassword from '../views/Dashboard/Profile/ChangePassword'
import PastAuction from '../views/Dashboard/PastAuction'

import MyInvoices from '../views/Dashboard/MyInvoices'
import MyTransactions from '../views/Dashboard/MyTransactions'
import Invoice from '../views/Invoice'
import LiveAuction from '../views/ProductView/LiveAuction'
import ProductViewInvite from '../views/ProductView/ProductView_Invite'
import Home from '../views/Home'
import Cart from '../views/Cart'
import BuynowCart from '../views/BuyNowCart'
import Checkout from '../views/Checkout'

import ErrorPage from '../views/ErrorPage'
import Auction from '../views/Auction'
import Notifications from '../views/Dashboard/Notifications'
import ContactUs from '../views/ContactUs'
import StaticPage from '../views/Static'
import SavedSearch from '../views/Dashboard/SavedSearch'
import ProductCommonContext from '../product/context/common/commonContext'
import Faq from '../views/Static/Faq'
import ProductViewPage from '../views/ProductView/ProductViewPage'
// import Subscription from '../views/Subscription'
import Subscription from '../views/Subscription/index_skyflow'
import AuctionType from '../views/Seller/PostProject/AuctionType'
import AuctionDetails from '../views/Seller/PostProject/AuctionDetails'
import CreateLot from '../views/Seller/PostProject/CreateLot'
import AuctionIncrement from '../views/Seller/PostProject/AuctionIncrement'
import AuctionSetting from '../views/Seller/PostProject/AuctionSetting'
import LocationSetting from '../views/Seller/Settings/Location'
import UsersInvite from '../views/Seller/PostProject/UsersInvite'
import Follows from '../views/Dashboard/Follows'
import AllFollows from '../views/Dashboard/AllFollow'
import AuctionTemplates from '../views/Seller/Template/Auction'
import UserView from '../views/Seller/Organization/UserView'
import MySubscription from '../views/Seller/Dashboard/MySubscription'
import SellerMyAuctions from '../views/Seller/Dashboard/AuctionLots'
import Reports from '../views/Seller/Report'
import Bank from '../views/Seller/Dashboard/Bank'
import MyCardDetails from '../views/Seller/Dashboard/MyCardDetails'
import SkyflowCardDetails from '../views/Seller/Dashboard/SkyflowCardDetails'

const Routes = () => {
    const authContext = useContext(AuthContext)
    const { loadUser, callEnd } = authContext
    const customCommonContext = useContext(CustomCommonContext)
    const productCommonContext = useContext(ProductCommonContext)

    const { getGlobalVariable } = customCommonContext
    const { loaderSet, getAllCountries, getAllStates } = productCommonContext
    useEffect(() => {
        loaderSet(true)
        if (localStorage.token) {
            loadUser(localStorage.token)
        } else {
            loaderSet(false)
        }

        getGlobalVariable()
        getAllCountries()
        getAllStates()
    }, [])

    useEffect(() => {
        if (callEnd) loaderSet(false)
    }, [callEnd])

    return (
        <>
            {window.location.pathname === '/login' ||
                window.location.pathname.includes('/registration') ||
                window.location.pathname.includes('/reset_password') ||
                window.location.pathname === '/forgot_password' || <Header />}
            <Switch>
                <Route exact path="/" component={Home} />
                <Route exact path="/login" component={Login} />
                <Route exact path="/verify_email/:token" component={VerifyEmail} />
                <Route exact path="/forgot_password" component={ForgotPassword} />
                <Route exact path="/reset_password/:token" component={ResetPassword} />
                <Route exact path="/registration" component={Registration} />
                <Route exact path="/registration/organization/:oid" component={Registration} />
                <Route exact path="/registration/invite/:invid" component={Registration} />
                <Route exact path="/search" component={Search} />
                <Route exact path="/liveAuction/:aid" component={LiveAuction} />
                <Route exact path="/auction" component={Auction} />
                <Route exact path="/invite" component={ProductViewInvite} />
                <Route exact path="/productView/:pid" component={ProductViewPage} />
                <Route exact path="/contactus" component={ContactUs} />
                <Route exact path="/faq" component={Faq} />
                <Route exact path="/saved_search" component={SavedSearch} />
                <Route exact path="/past_auctions" component={PastAuction} />
                <Route exact path="/post_project/posttype" component={AuctionType} />
                <Route
                    exact
                    path="/post_project/auction_detail/:action"
                    component={AuctionDetails}
                />
                <Route
                    exact
                    path="/post_project/auction_detail/:action/:id"
                    component={AuctionDetails}
                />
                <Route exact path="/post_project/create_lot/:id" component={CreateLot} />
                <Route exact path="/post_project/bid_increment/:id" component={AuctionIncrement} />
                <Route exact path="/post_project/auction_setting/:id" component={AuctionSetting} />
                <Route exact path="/post_project/invite_user/:id" component={UsersInvite} />
                <PrivateRoute exact path="/buynowcart" component={BuynowCart} />
                <PrivateRoute exact path="/checkout/:type/:cart_id" component={Checkout} />
                <PrivateRoute exact path="/cart" component={Cart} />
                <PrivateRoute exact path="/auctions/:type" component={MyAuctions} />
                <PrivateRoute exact path="/favourites/:type" component={MyAuctions} />
                <PrivateRoute exact path="/purchases/:type" component={MyAuctions} />
                <PrivateRoute exact path="/account/profile" component={Profile} />
                <PrivateRoute exact path="/account/change" component={ChangePassword} />
                <PrivateRoute exact path="/account/notifications" component={Notifications} />
                {/* <PrivateRoute exact path="/company_profile" component={CompanyProfile} /> */}
                <PrivateRoute exact path="/invoices/:action" component={MyInvoices} />
                <PrivateRoute exact path="/payments/transactions" component={MyTransactions} />
                <PrivateRoute exact path="/invoice/:id" component={Invoice} />
                <PrivateRoute exact path="/follows" component={AllFollows} />
                <PrivateRoute exact path="/follows/:id" component={Follows} />

                {/* <Route exact path="/support" component={Faq} /> */}
                <Route exact path="/subscription" component={Subscription} />
                <Route exact path="/seller/templates" component={AuctionTemplates} />
                <Route exact path="/seller/payments/subscription" component={MySubscription} />
                <Route exact path="/seller/organization" component={UserView} />
                <Route exact path="/seller/reports" component={Reports} />
                <Route exact path="/seller/setting/location" component={LocationSetting} />
                <PrivateRoute exact path="/seller/auctions/:action" component={SellerMyAuctions} />
                <PrivateRoute exact path="/seller/payments/bank_details" component={Bank} />
                {/* <PrivateRoute exact path="/seller/payments/saved_cards" component={MyCardDetails} /> */}
                <PrivateRoute
                    exact
                    path="/seller/payments/saved_cards"
                    component={SkyflowCardDetails}
                />

                <Route
                    exact
                    path={[
                        '/about',
                        '/services',
                        '/help',
                        '/guidelines',
                        '/privacy_policy',
                        '/terms_of_use',
                        '/support',
                    ]}
                    component={StaticPage}
                />
                <Route exact path="*" component={ErrorPage} />
            </Switch>
            {window.location.pathname === '/login' ||
                window.location.pathname === '/forgot_password' ||
                window.location.pathname.includes('/reset_password') ||
                window.location.pathname.includes('/registration') || <Footer />}
        </>
    )
}
export default withRouter(Routes)
