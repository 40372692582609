import React, { useContext, useEffect, useState } from 'react'
import ProductCommonContext from '../../../product/context/common/commonContext'
import AuthContext from '../../../product/context/auth/authContext'
import * as Yup from 'yup'
import ProfileComponent from '../../../product/components/species/components/user/ProfileComponent'
import CustomCommonContext from '../../../custom/context/common/commonContext'

const MyAccount = () => {
    const productCommonContext = useContext(ProductCommonContext)
    const authContext = useContext(AuthContext)
    const customCommonContext = useContext(CustomCommonContext)
    const { allCountries, allStates } = productCommonContext
    const { allOrganization } = customCommonContext
    const [isCountry, setCountry] = useState('')
    const { user } = authContext
    // const [selectedCountry, SetCountry] = useState([])

    // useEffect(() => {
    //     if (user.country) setCountry(user.country)
    // })

    const [formikChangeValues, setFormikChangeValues] = useState({
        country: global.defaultCountry,
    })

    // Mapped over for individual registration fields

    const additionalFieldUpdate = (payload) => {
        setFormikChangeValues(payload)
    }

    const [bidinitialValues, setBidinitialValues] = useState({
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        country: global.defaultCountry,
        state: '',
        city: '',
        address1: '',
        address2: '',
        zip: '',
    })

    const [orginitialValues, setOrginitialValues] = useState({
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        companyname: '',
        country: global.defaultCountry,
        state: '',
        city: '',
        address1: '',
        address2: '',
        zip: '',
        admin: 1,
        organization: 1,
    })

    const validationArray = Yup.object({
        first_name: Yup.string()
            .min(2, 'minimum_2_characters')
            .max(15, 'maximum_15_characters')
            .required('required'),
        last_name: Yup.string()
            .min(1, 'mininum_1_characters')
            .max(15, 'maximum_15_characters')
            .required('required'),
        address1: Yup.string()
            .trim()
            .matches(/^[0-9a-zA-Z- ,.#()]*$/g, 'some_kind_of_special_characters_not_allowed')
            .max(250, 'maximum_250_characters')
            .required('required'),
        address2: Yup.string(),
        phone: Yup.string()
            .trim()
            .matches(/^[0-9- +()]*$/g, 'only_numbers_are_allowed')
            .min(10, 'Please enter correct mobile number')
            .required('required'),
        country: Yup.string().trim().required('required'),
        city: Yup.string().required('required'),
        state: Yup.string().required('required'),
        zip: Yup.string()
            .trim()
            .matches(/^[0-9]*$/g, 'the_positive_number_only')
            .max(6, 'maximum_6_characters')
            .required('required'),
    })
    const formFields = {
        data: [
            {
                label: 'First name',
                type: 'text',
                placeholder: 'enter your first name',
                class: 'col-md-6 col-sm-12',
                name: 'first_name',
            },
            {
                label: 'Last name',
                placeholder: 'enter your last name',
                class: 'col-md-6 col-sm-12',
                type: 'text',
                name: 'last_name',
            },
            {
                label: 'Email address',
                placeholder: 'enter your email address',
                class: 'col-md-6 col-sm-12',
                type: 'text',
                disabled: true,
                name: 'email',
            },
            {
                label: 'Phone number',
                placeholder: 'phone number',
                class: 'col-md-6 col-sm-12',
                type: 'phone',
                name: 'phone',
                disabled: true,
            },
            {
                label: 'Address 1',
                placeholder: 'Enter your address',
                class: 'col-md-6 col-sm-12',
                type: 'text',
                name: 'address1',
            },
            {
                label: 'Address 2',
                placeholder: 'Enter your address',
                class: 'col-md-6 col-sm-12',
                type: 'text',
                name: 'address2',
            },
            {
                label: 'Country',
                placeholder: 'Enter your country',
                class: 'col-md-6 col-sm-12',
                type: 'select',
                options: allCountries
                    .filter((country) => global.ignoreCountry.indexOf(country.isoCode) === -1)
                    .map((country) => {
                        let busCategoryChanged = {}
                        busCategoryChanged.show = country.name
                        busCategoryChanged.value = country.isoCode
                        return busCategoryChanged
                    }),
                name: 'country',
            },
            {
                label: 'State',
                placeholder: 'Select your state',
                class: 'col-md-6 col-sm-12',
                type: 'select',
                options: allStates
                    .filter(
                        (state) =>
                            (formikChangeValues.country
                                ? formikChangeValues.country.indexOf(state.countryCode) !== -1
                                : global.defaultCountry.indexOf(state.countryCode) !== -1) &&
                            global.ignoreStates.indexOf(state.isoCode) === -1,
                    )
                    .map((buscat) => {
                        let busCategoryChanged = {}
                        busCategoryChanged.show = buscat.name
                        busCategoryChanged.value = buscat.isoCode
                        return busCategoryChanged
                    }),
                name: 'state',
            },
            {
                label: 'City',
                placeholder: 'Enter your city',
                class: 'col-md-6 col-sm-12',
                type: 'text',
                name: 'city',
            },
            {
                label: 'Zipcode',
                placeholder: 'Enter your zip code',
                class: 'col-md-6 col-sm-12',
                type: 'number',
                name: 'zip',
            },
        ],
    }

    const orgformFields = {
        data: [
            {
                label: 'First name',
                type: 'text',
                placeholder: 'enter your first name',
                class: 'col-md-6 col-sm-12',
                name: 'first_name',
            },
            {
                label: 'Last name',
                placeholder: 'enter your last name',
                class: 'col-md-6 col-sm-12',
                type: 'text',
                name: 'last_name',
            },
            {
                label: 'Organization name',
                placeholder: 'Enter your organization name',
                class: 'col-md-6 col-sm-12',
                type: 'text',
                name: 'companyname',
            },
            {
                label: 'Email address',
                placeholder: 'enter your email address',
                class: 'col-md-6 col-sm-12',
                type: 'text',
                disabled: true,
                name: 'email',
            },
            {
                label: 'Phone number',
                placeholder: 'phone number',
                class: 'col-md-6 col-sm-12',
                type: 'phone',
                name: 'phone',
                disabled: true,
            },
            {
                label: 'Address 1',
                placeholder: 'Enter your address',
                class: 'col-md-6 col-sm-12',
                type: 'text',
                name: 'address1',
            },
            {
                label: 'Address 2',
                placeholder: 'Enter your address',
                class: 'col-md-6 col-sm-12',
                type: 'text',
                name: 'address2',
            },
            {
                label: 'Country',
                placeholder: 'Enter your country',
                class: 'col-md-6 col-sm-12',
                type: 'select',
                options: allCountries
                    .filter((country) => global.ignoreCountry.indexOf(country.isoCode) === -1)
                    .map((country) => {
                        let busCategoryChanged = {}
                        busCategoryChanged.show = country.name
                        busCategoryChanged.value = country.isoCode
                        return busCategoryChanged
                    }),
                name: 'country',
            },
            {
                label: 'State',
                placeholder: 'Select your state',
                class: 'col-md-6 col-sm-12',
                type: 'select',
                options: allStates
                    .filter(
                        (state) =>
                            (formikChangeValues.country
                                ? formikChangeValues.country.indexOf(state.countryCode) !== -1
                                : global.defaultCountry.indexOf(state.countryCode) !== -1) &&
                            global.ignoreStates.indexOf(state.isoCode) === -1,
                    )
                    .map((buscat) => {
                        let busCategoryChanged = {}
                        busCategoryChanged.show = buscat.name
                        busCategoryChanged.value = buscat.isoCode
                        return busCategoryChanged
                    }),
                name: 'state',
            },
            {
                label: 'City',
                placeholder: 'Enter your city',
                class: 'col-md-6 col-sm-12',
                type: 'text',
                name: 'city',
            },
            {
                label: 'Zipcode',
                placeholder: 'Enter your zip code',
                class: 'col-md-6 col-sm-12',
                type: 'number',
                name: 'zip',
            },
        ],
    }
    useEffect(() => {
        if (user) {
            if (user.admin) {
                setOrginitialValues({
                    ...orginitialValues,
                    first_name: user.first_name || '',
                    last_name: user.last_name || '',
                    email: user.email || '',
                    phone: user.phone || '',
                    companyname: user.companyname || '',
                    country: user.country || global.defaultCountry,
                    state: user.state || '',
                    city: user.city || '',
                    address1: user.address1 || '',
                    address2: user.address2 || '',
                    zip: user.zip || '',
                })
            } else {
                setBidinitialValues({
                    ...bidinitialValues,
                    first_name: user.first_name || '',
                    last_name: user.last_name || '',
                    email: user.email || '',
                    phone: user.phone || '',
                    country: user.country || global.defaultCountry,
                    state: user.state || '',
                    city: user.city || '',
                    address1: user.address1 || '',
                    address2: user.address2 || '',
                    zip: user.zip || '',
                })
            }
        }
    }, [user])

    return (
        <>
            {user && user.admin ? (
                <>
                    <ProfileComponent
                        initialValues={orginitialValues}
                        validationSchema={validationArray}
                        formFields={orgformFields}
                        additionalfieldUpdate={additionalFieldUpdate}
                    />
                </>
            ) : (
                <>
                    <ProfileComponent
                        initialValues={bidinitialValues}
                        validationSchema={validationArray}
                        formFields={formFields}
                        additionalfieldUpdate={additionalFieldUpdate}
                    />
                </>
            )}
        </>
    )
}
export default MyAccount
