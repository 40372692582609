import React, { useState, useEffect, useContext } from 'react'
import SettingContext from '../../../product/context/seller/setting/settingContext'
import AuthContext from '../../../product/context/auth/authContext'
import FullScreenPopup from '../../../product/components/organisms/FullScreenPopup'
import SecondaryButton from '../../../product/components/atoms/SecondaryButton'
import CustomCommonContext from '../../../custom/context/common/commonContext'
import PrimaryButton from '../../../product/components/atoms/PrimaryButton'
import { mapData } from '../../../product/common/components'
import { useFormik } from 'formik'
import * as Yup from 'yup'

const LocationManage = (props) => {
    const settingcontext = useContext(SettingContext)
    const authContext = useContext(AuthContext)
    const { allLocations, getGlobalVariable } = useContext(CustomCommonContext)

    const { manage, toggleFullScreenPopup } = props
    const {
        searchTableAction,
        searchTable,
        table_data,
        responseStatus: responseStatusSetting,
    } = settingcontext
    const { user, isAuthenticated } = authContext

    const validationArray = Yup.object({
        name: Yup.string().required('Required!'),
    })

    const formik = useFormik({
        initialValues: {
            id: 0,
            name: '',
            table: 'locations',
            active: 1,
            organization_id: user ? user.organization_id : '',
        },
        validationSchema: validationArray,
        onSubmit: (values) => {
            searchTableAction(values)
        },
    })

    const details = [
        {
            label: 'Name *',
            placeholder: 'Enter the Location',
            type: 'textarea',
            class: 'col-12',
            name: 'name',
            formik: formik,
        },
        {
            label: 'Active',
            placeholder: 'Make Active',
            type: 'switch',
            class: 'col-12 col-md-4',
            name: 'active',
            formik: formik,
        },
    ]
    useEffect(() => {
        if (user) {
            if (user && user.organization_id) {
                formik.setFieldValue('organization_id', user.organization_id)
            }
        }
    }, [user])

    useEffect(() => {
        if (manage.status === 'edit') {
            formik.setFieldValue('id', manage.id)
            const template = allLocations.filter((cat) => cat.id === manage.id)
            formik.setValues({
                ...template[0],
                table: 'locations',
            })
        } else {
            resetFrom()
        }
    }, [manage])

    const resetFrom = () => {
        formik.setValues({
            id: 0,
            name: '',
            table: 'locations',
            active: 1,
            organization_id: user ? user.organization_id : '',
        })
    }

    useEffect(() => {
        if (responseStatusSetting) {
            console.log('responseStatusSetting', responseStatusSetting)
            if (
                responseStatusSetting.from === 'CommunicationSearchTable' &&
                responseStatusSetting.status === 'success'
            ) {
                toggleFullScreenPopup(false)
                if (user && user.organization_id) {
                    searchTable(
                        {
                            page: 1,
                            limit: 20,
                            order: '',
                            orderby: '',
                            table: 'locations',
                            filters: {
                                name: {
                                    value: '',
                                    type: 'like',
                                    field: 'l.name',
                                },
                                organization_id: {
                                    value: user.organization_id,
                                    type: 'in',
                                    field: 'l.organization_id',
                                },
                            },
                        },
                        'locations',
                    )
                }
            }
        }
    }, [responseStatusSetting])

    return (
        <FullScreenPopup
            modaltitle={manage.status === 'new' ? 'Add New Location' : 'Edit Location'}
            open={manage.popup}
            className="adTempltPopup"
            handleClose={() => toggleFullScreenPopup(false, 'new', 0)}
        >
            <div className="dashboard addNewListing mt-4">
                <div className="fspBody">
                    <form onSubmit={formik.handleSubmit} autoComplete="nofill">
                        <div className="row">{Object.values(mapData(details))}</div>
                        <div className="actionButton loca d-flex justify-content-center align-items-center">
                            <SecondaryButton
                                label="Cancel"
                                style={{ marginRight: '5px' }}
                                onClick={() => toggleFullScreenPopup(false)}
                            />
                            <PrimaryButton type="submit" label="Submit" />
                        </div>
                    </form>
                </div>
            </div>
        </FullScreenPopup>
    )
}

export default LocationManage
