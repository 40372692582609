import React from 'react'
import PrimaryButton from '../../product/components/atoms/PrimaryButton'
import '../Login/Login.css'
import { useTranslation } from 'react-i18next'
import { mapData, handleRedirectInternal } from '../../product/common/components'
import * as Yup from 'yup'
import { Link, useHistory } from 'react-router-dom'
import RegistrationLayout from '../../custom/components/templates/RegistrationLayout'
import { LOGO } from '../../Utils'
import ResetPasswordComponent from '../../product/components/species/components/user/ResetPasswordComponent'

const ForgotPassword = (props) => {
    const validationArray = Yup.object({
        user_token: Yup.string().required('Required'),
        password: Yup.string().min(8, 'Minimum 8 characters').required('Required'),
        confirm_password: Yup.string()
            .oneOf([Yup.ref('password')], 'Passwords Not Match')
            .required('Required'),
    })

    const initialValues = {
        user_token: props.match.params.token,
        password: '',
        confirm_password: '',
    }

    const resetPasswordData = {
        data: [
            {
                label: 'New Password',
                name: 'password',
                type: 'password',
                placeholder: 'Enter Your New Password',
                class: 'col-md-12',
            },
            {
                label: 'Confirm Password',
                name: 'confirm_password',
                type: 'password',
                placeholder: 'Re-Enter Your Password',
                class: 'col-md-12',
            },
        ],
    }

    return (
        <RegistrationLayout>
            <div className="login resetPassword">
                <Link to="/">
                    <img className="brandLogo" src={LOGO} alt={'Jafza Ezone Logo'} />
                </Link>
                <div className="container">
                    <div className="d-flex justify-content-center align-items-start">
                        <div className="loginLt rstPwdWrp">
                            <h2>Reset Password</h2>
                            <h4>Create a New Password</h4>
                            {/* <form onSubmit={formik.handleSubmit} autoComplete="nofill">
                                <div className="row">
                                    {Object.values(mapData(resetPasswordData))}
                                </div>
                                <PrimaryButton type="submit" label={'Change'} />
                            </form> */}
                            <ResetPasswordComponent
                                initialValues={initialValues}
                                formFields={resetPasswordData}
                                validationSchema={validationArray}
                                SubmitBtnLabel="Change"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </RegistrationLayout>
    )
}
export default ForgotPassword
