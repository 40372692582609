import React, { useContext, useEffect, useState } from 'react'
import { mapData } from '../../../../product/common/components'
import PrimaryButton from '../../../../product/components/atoms/PrimaryButton'
import UserContext from '../../../../product/context/user/userContext'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import AuthContext from '../../../../product/context/auth/authContext'
import Popup from '../Popup'

function UpdatePhoneNumber(props) {
    const authContext = useContext(AuthContext)
    const userContext = useContext(UserContext)
    const { user, isAuthenticated, loadUser } = authContext
    const { updateProfile, responseStatus: responseStatusUser } = userContext

    const [disabled, setDisabled] = useState(false)
    const [reload, setReload] = useState(false)

    const validationArray = Yup.object({
        phone: Yup.string()
            .trim()
            .matches(/^[0-9- +()]*$/g, 'Only numbers are allowed')
            .max(20, 'Maximum 20 charaters')
            .min(10, 'Minimum 10 characters')
            .required('Required!'),
    })

    const formik = useFormik({
        initialValues: {
            phone: '',
        },
        validationSchema: validationArray,
        onSubmit: (values) => {
            updateProfile(values)
        },
    })

    const profile = [
        {
            label: 'Mobile number',
            placeholder: 'Enter your mobile number',
            class: 'col-md-12',
            type: 'phone',
            name: 'phone',
            labelType: 'capsLabel',
            formik: formik,
        },
    ]

    useEffect(() => {
        if (responseStatusUser) {
            if (responseStatusUser.from === 'updateProfile') {
                if (responseStatusUser.status === 'success') {
                    props.function(false, 'new', null)
                }
            }
        }
    }, [responseStatusUser])
    return (
        <>
            <Popup
                open={
                    props.data.popup &&
                    (props.data.status === 'new' || props.data.status === 'update')
                }
                size="md"
                handleClose={() => props.function(false, 'new', null)}
                modaltitle="Update Phone Number"
            >
                <div className="addressInfo">
                    <form onSubmit={formik.handleSubmit} autoComplete="nofill">
                        {mapData(profile)}
                        <div className="col-12">
                            <PrimaryButton
                                // disabled={disabled}
                                btnSize="large"
                                type="submit"
                                label={'Save'}
                            />
                        </div>
                    </form>
                </div>
            </Popup>
        </>
    )
}

export default UpdatePhoneNumber
