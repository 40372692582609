import React, { useContext, useEffect, useState } from 'react'
import { mapData, converDate } from '../../../../product/common/components'
import PrimaryButton from '../../../../product/components/atoms/PrimaryButton'
import { Button } from '@material-ui/core'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import CustomDialog from '../../organisms/Dialog/index'

import StripeCardContext from '../../../../product/context/stripe/card/cardContext'
import AuthContext from '../../../../product/context/auth/authContext'
import ProductCommonContext from '../../../../product/context/common/commonContext'
import StripeCustomerContext from '../../../../product/context/stripe/customer/customerContext'
import AlertContext from '../../../../product/context/alert/alertContext'
import Popup from '../../organisms/Popup'
import Loaders from '../../molecules/Loaders'

function CardDetails(props) {
    const authContext = useContext(AuthContext)
    const productCommonContext = useContext(ProductCommonContext)
    const alertContext = useContext(AlertContext)
    const [loading, setLoading] = useState(false)
    const { setAlert } = alertContext
    const { user, loadUser } = authContext

    const { getPreviousData, clearPreviousData, previous_data, allCountries, allStates } =
        productCommonContext

    const {
        updateStripeCustomer,
        getStripeCustomer,
        customer_details,
        responseStatus: responseStatusStripeCustomer,
    } = useContext(StripeCustomerContext)

    const {
        get_all_card_details,
        createStripeCard,
        createStripeCardWithCustomer,
        deleteStripeCard,
        updateStripeCard,
        responseStatus,
        clearResponse,
    } = useContext(StripeCardContext)
    const [disabled, setDisabled] = useState(false)
    const [changeStatus, setChangeStatus] = useState(false)
    const changeChangeStatus = () => {
        setChangeStatus(!changeStatus)
    }

    const [reload, setReload] = useState(false)
    const creditCardYearOptions = []
    let date = new Date()
    let currentYear = date.getFullYear()
    let upToYear = parseInt(currentYear) + 25
    for (let year = parseInt(currentYear); year < parseInt(upToYear); year++) {
        creditCardYearOptions.push({
            value: year,
            show: year,
        })
    }
    const creditCardMonthOptions = []
    for (let month = parseInt(1); month <= parseInt(12); month++) {
        let monthValue = ('0' + month).slice(-2)
        creditCardMonthOptions.push({
            value: monthValue,
            show: monthValue,
        })
    }

    const validationCard = Yup.object({
        card_number: Yup.string()
            .min(12, 'Invalid credit card number!')
            .max(18, 'Invalid credit card number!')
            .required('Required!'),
        card_name: Yup.string()
            .trim()
            .matches(/^[a-zA-Z ]*$/g, 'No Special Characters and Numbers Allowed')
            .required('Required!'),
        card_exp_month: Yup.string().required('Required!'),
        card_exp_year: Yup.string().required('Required!'),
        card_cvc: Yup.string()
            .required('Required!')
            .min(3, 'Mininum 3 Numbers required')
            .max(4, 'Maximum 4 Numbers required'),
        card_address_line1: Yup.string().required('Required!'),
        card_address_line2: Yup.string(),
        card_address_city: Yup.string().required('Required!'),
        card_address_state: Yup.string().required('Required!'),
        card_address_country: Yup.string().required('Required!'),
        card_address_zip: Yup.string().required('Required!'),
        // phone: Yup.string().matches(phonenumber, 'Phone number is not valid'),
    })

    const cardFormik = useFormik({
        initialValues: {
            account_id: process.env.REACT_APP_STRIPE_ACCOUNT,
            customer_id: user ? user.stripe_id : '',
            card_number: '',
            card_name: '',
            card_exp_month: '',
            card_exp_year: '',
            card_cvc: '',
            cvv: '',
            card_address_line1: '',
            card_address_line2: '',
            card_address_city: '',
            card_address_state: '',
            card_address_zip: '',
            card_address_country: '',
            is_primary: '',
            user_id: '',
            id: '',
            card_token: true,
        },
        validationSchema: validationCard,
        enableReinitialize: true,
        onSubmit: (values) => {
            if (values.customer_id) {
                setDisabled(true)
                createStripeCard({
                    ...values,
                    card_address_country: values.card_address_country,
                })
            } else {
                const paymentInfo = {
                    account_id: values.account_id,
                    description: 'NPS',
                    email: user.email,
                    name: values.card_name,
                    address: {
                        line1: values.card_address_line1,
                        line2: values.card_address_line2,
                        city: values.card_address_city,
                        state: values.card_address_state,
                        postal_code: values.card_address_zip,
                        country: values.card_address_country,
                    },
                    card_name: values.card_name,
                    card_address_line1: values.card_address_line1,
                    card_address_city: values.card_address_city,
                    card_address_state: values.card_address_state,
                    card_address_zip: values.card_address_zip,
                    card_address_country: values.card_address_country,
                    card_number: values.card_number,
                    card_exp_month: values.card_exp_month,
                    card_exp_year: values.card_exp_year,
                    card_cvc: values.card_cvc,
                    card_token: true,
                }

                createStripeCardWithCustomer(paymentInfo)
            }
            setLoading(true)
        },
    })

    const validationupdateCard = Yup.object({
        exp_month: Yup.string().required('Required!'),
        exp_year: Yup.string().required('Required!'),
    })

    const updateCardformik = useFormik({
        initialValues: {
            account_id: process.env.REACT_APP_STRIPE_ACCOUNT,
            customer_id: user ? user.stripe_id : '',
            source_id: '',
            exp_month: '',
            exp_year: '',
        },
        validationSchema: validationupdateCard,
        onSubmit: (values) => {
            updateStripeCard(values)
        },
    })

    const formikSelection = useFormik({
        initialValues: {
            data: '',
            status: '',
        },
        onSubmit: (values) => {
            const dataValue = values
            if (dataValue.status === 'delete') {
                deleteStripeCard({
                    account_id: process.env.REACT_APP_STRIPE_ACCOUNT,
                    customer_id: user.stripe_id,
                    source_id: props.data.data.id,
                })
                setChangeStatus(false)
                props.function(false, 'new', null)
            } else if (dataValue.status === 'default') {
                updateStripeCustomer({
                    account_id: process.env.REACT_APP_STRIPE_ACCOUNT,
                    customer_id: user.stripe_id,
                    default_source: props.data.data.id,
                })
                setChangeStatus(false)
                props.function(false, 'new', null)
            }
        },
    })

    // useEffect(() => {
    //     console.log('here')
    //     if (previous_data) {
    //         if (previous_data.records.length) {
    //             // setAlert(
    //             //     'Removal of Card/ACH is permitted only after 6 weeks from the last bid',
    //             //     'error',
    //             // )
    //             // formikSelection.values.data = ''
    //             // formikSelection.values.status = ''
    //             // clearPreviousData()
    //             // props.function(false, 'new', null)
    //         } else if (formikSelection.values.status) {
    //             setChangeStatus(true)
    //         }
    //     }
    // }, [previous_data])
    useEffect(() => {
        if (props.data.data) {
            if (props.data.status === 'delete') {
                formikSelection.values.data = props.data.data
                formikSelection.values.status = props.data.status
                if (get_all_card_details.records.length > 1) {
                    setChangeStatus(true)
                } else {
                    setAlert('Default card is required', 'error')
                    setChangeStatus(false)
                    props.function(false, 'new', null)
                }
            } else if (props.data.status === 'default') {
                formikSelection.values.data = props.data.data
                formikSelection.values.status = props.data.status
                setChangeStatus(true)
            } else if (props.data.status === 'update') {
                updateCardformik.values.source_id = props.data.data.id
                updateCardformik.values.customer_id = props.data.data.customer
                updateCardformik.values.exp_month = ('0' + props.data.data.exp_month).slice(-2)
                updateCardformik.values.exp_year = props.data.data.exp_year
                setReload(!reload)
            }
        }
    }, [props.data.data])
    useEffect(() => {
        if (user) {
            if (user.stripe_id) {
                cardFormik.setFieldValue('customer_id', user.stripe_id)
            }
        }
    }, [user])

    useEffect(() => {
        if (responseStatus) {
            if (
                responseStatus.from === 'card_source_create' ||
                responseStatus.from === 'card_source_create_customer_card'
            ) {
                if (responseStatus.status === 'success') {
                    cardFormik.resetForm()
                    clearResponse()
                    props.function(false, 'new', null)
                }
                setDisabled(false)
            }
            setLoading(false)
        }
    }, [responseStatus])

    const getStripeCustomerDetails = () => {
        if (user && user.stripe_id) {
            getStripeCustomer({
                account_id: process.env.REACT_APP_STRIPE_ACCOUNT,
                customer_id: user.stripe_id,
            })
        }
    }

    useEffect(() => {
        if (responseStatusStripeCustomer) {
            if (responseStatusStripeCustomer.from === 'update') {
                if (responseStatusStripeCustomer.status === 'success') {
                    getStripeCustomerDetails()
                }
            }
        }
    }, [responseStatusStripeCustomer])

    const cardUpdate = {
        formik: updateCardformik,
        data: [
            {
                label: 'Expiry Month',
                placeholder: 'MM',
                class: 'col-6',
                type: 'select',
                name: 'exp_month',
                options: creditCardMonthOptions,
            },
            {
                label: 'Expiry Year',
                placeholder: 'YYYY',
                class: 'col-6',
                type: 'select',
                name: 'exp_year',
                options: creditCardYearOptions,
            },
        ],
    }

    const cardDetails = {
        formik: cardFormik,
        data: [
            {
                label: 'Card Holder Name',
                type: 'text',
                placeholder: 'Enter your card holder name',
                class: 'col-12',
                name: 'card_name',
            },
            {
                label: 'Card No',
                type: 'number',
                placeholder: 'Enter your card no',
                class: 'col-12',
                name: 'card_number',
            },
            {
                label: 'Expiry Month',
                placeholder: 'MM',
                class: 'col-6',
                type: 'select',
                name: 'card_exp_month',
                options: creditCardMonthOptions,
            },
            {
                label: 'Expiry Year',
                placeholder: 'YYYY',
                class: 'col-6',
                type: 'select',
                name: 'card_exp_year',
                options: creditCardYearOptions,
            },
            {
                label: 'CVV',
                type: 'text',
                placeholder: 'Enter your Cvv',
                class: 'col-12',
                name: 'card_cvc',
            },
            {
                label: 'Card address line 1',
                type: 'text',
                placeholder: 'Enter card address line1',
                class: 'col-12',
                name: 'card_address_line1',
            },
            {
                label: 'Card address line 2',
                type: 'text',
                placeholder: 'Enter card address line2',
                class: 'col-12',
                name: 'card_address_line2',
            },
            {
                label: 'Card address city',
                type: 'text',
                placeholder: 'Enter card address city',
                class: 'col-12',
                name: 'card_address_city',
            },
            // {
            //     label: 'Card address state',
            //     type: 'text',
            //     placeholder: 'Enter card address state',
            //     class: 'col-12',
            //     name: 'card_address_state',
            // },
            // {
            //     label: 'Card address country',
            //     type: 'text',
            //     placeholder: 'Enter card address country',
            //     class: 'col-12',
            //     name: 'card_address_country',
            // },
            {
                label: 'Card address zip',
                type: 'text',
                placeholder: 'Enter card address zip',
                class: 'col-12',
                name: 'card_address_zip',
            },
            {
                label: 'Card address country',
                placeholder: 'Select card address country',
                class: 'col-12',
                type: 'select',
                options: allCountries
                    ? allCountries
                          .filter((country) => global.ignoreCountry.indexOf(country.isoCode) === -1)
                          .map((country) => {
                              let busCategoryChanged = {}
                              busCategoryChanged.show = country.name
                              busCategoryChanged.value = country.isoCode
                              return busCategoryChanged
                          })
                    : [],
                name: 'card_address_country',
            },
            {
                label: 'Card address state',
                placeholder: 'Select card address state',
                class: 'col-12',
                type: 'select',
                options: allStates
                    .filter(
                        (state) =>
                            (cardFormik.values.card_address_country
                                ? cardFormik.values.card_address_country.indexOf(
                                      state.countryCode,
                                  ) !== -1
                                : global.defaultCountry.indexOf(state.countryCode) !== -1) &&
                            global.ignoreStates.indexOf(state.isoCode) === -1,
                    )
                    .map((buscat) => {
                        let busCategoryChanged = {}
                        busCategoryChanged.show = buscat.name
                        busCategoryChanged.value = buscat.isoCode
                        return busCategoryChanged
                    }),
                name: 'card_address_state',
            },
        ],
    }

    return (
        <>
            <Loaders className="home" isLoading={loading} />
            <Popup
                open={props.data.popup && props.data.status === 'new'}
                size="md"
                handleClose={() => props.function(false, 'new', null)}
                modaltitle="Add Card Details"
            >
                <div className="cardDetails">
                    <form onSubmit={cardFormik.handleSubmit} autoComplete="nofill">
                        <div className="row">{mapData(cardDetails)}</div>
                        <div className="row">
                            <div className="col-12">
                                <PrimaryButton
                                    disabled={disabled}
                                    btnSize="large"
                                    type="submit"
                                    label={'Save'}
                                />
                            </div>
                        </div>
                    </form>
                </div>
            </Popup>

            <CustomDialog title={'Confirmation'} open={changeStatus} function={changeChangeStatus}>
                <h5>
                    {formikSelection.values.status === 'delete'
                        ? 'Are you sure you want to delete this card from your account?'
                        : formikSelection.values.status === 'default'
                        ? 'Are you sure you want to make this default'
                        : 'Are you sure you want to change the status'}
                </h5>
                <div className="actionWrapper">
                    <Button onClick={() => changeChangeStatus()}>Cancel</Button>
                    <form onSubmit={formikSelection.handleSubmit} autoComplete="nofill">
                        <PrimaryButton type="submit" label="Confirm" />
                    </form>
                </div>
            </CustomDialog>

            <Popup
                open={props.data.popup && props.data.status === 'update'}
                handleClose={() => props.function(false, 'update', null)}
                size="md"
                modaltitle="Update Card Details"
            >
                <form onSubmit={updateCardformik.handleSubmit} autoComplete="nofill">
                    <div className="row">
                        {mapData(cardUpdate)}
                        <div className="col-12">
                            <PrimaryButton
                                disabled={disabled}
                                btnSize="large"
                                type="submit"
                                label={'Save'}
                            />
                        </div>
                    </div>
                </form>
            </Popup>
        </>
    )
}

export default CardDetails
