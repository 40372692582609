import React, { useState, useContext, useEffect, useRef } from 'react'
import { NavLink, useHistory } from 'react-router-dom'
import { LOGO, SITE_NAME } from '../../../../Utils'
import { Link } from 'react-router-dom'
import {
    Badge,
    Button,
    Divider,
    ListItem,
    Menu,
    MenuItem,
    SwipeableDrawer,
} from '@material-ui/core'
import AuthContext from '../../../../product/context/auth/authContext'
import CommonContext from '../../../../product/context/common/commonContext'
import AlertContext from '../../../../product/context/alert/alertContext'
import { handleRedirectInternal, useCustomMediaQuery } from '../../../../product/common/components'
import AuctionContext from '../../../../product/context/seller/auction/auctionContext'
import './Header.css'
import BidHistory from '../../../components/organisms/BidHistory'
import Loaders from '../Loaders'
import CustomCommonContext from '../../../context/common/commonContext'
import Popover from '@material-ui/core/Popover'
import MenuList from '@material-ui/core/MenuList'
import Moment from 'moment'
import FormDialog from '../../organisms/Dialog'
import PrimaryButton from '../../../../product/components/atoms/PrimaryButton'
import SecondaryButton from '../../../../product/components/atoms/SecondaryButton'
import SettingContext from '../../../../product/context/seller/setting/settingContext'

const Header = (props) => {
    const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent)
    const authContext = useContext(AuthContext)
    const alertContext = useContext(AlertContext)
    const commonContext = useContext(CommonContext)
    const settingContext = useContext(SettingContext)
    const customCommonContext = useContext(CustomCommonContext)
    const {
        all_auctions,
        getAllAuctions,
        responseStatus: responseStatusSeller,
    } = useContext(AuctionContext)
    const { getAllBidIncrements } = settingContext
    const { setCurrentMembership, allBiddingPlan, membership } = customCommonContext
    const { setAlert } = alertContext
    const { user, isAuthenticated, loadPendingCount, isAdmin, cartCount, logout } = authContext

    const [state, setState] = useState({
        right: false,
        top: false,
    })
    const history = useHistory()
    const [anchormenu, setanchormenu] = useState(null)
    const [upcommingAuctions, setupcommingAuctions] = useState([])
    const [activeAuctions, setActiveAuctions] = useState([])
    const { searchValue, setSearchValue, isLoading } = commonContext
    const [searchText, setSearchText] = useState('')
    const [subPopup, setSubPopup] = useState(false)
    const mainMenu = useRef()

    let PresentDate = Moment().format('MM-DD-YYYY')
    let previousDate = Moment().subtract(1, 'year').format('MM-DD-YYYY')

    const onLogOut = () => {
        logout()
        setAlert('Logged out successfully', 'success')
        handleRedirectInternal(history, '')
        setanchormenu(null)
        setSearchText('')
    }

    const toggleDrawer = (anchor, open) => (event) => {
        setState({ ...state, [anchor]: open })
    }

    const handleMenu = (event) => {
        setanchormenu(event.currentTarget)
    }

    const handleClose = (history, path) => {
        setanchormenu(null)
        if (history && path && path !== 'backdropClick') {
            handleRedirectInternal(history, path)
        }
    }

    const getSearchBarObjet = (value) => {
        return {
            limit: 12,
            page: 1,
            orderby: 'p.id, desc',
            order: '',
            filters: {
                searchbar: {
                    value: value,
                    type: 'like',
                    field: 'p.title', //p.desc_proc,
                },
            },
        }
    }

    const handleSearchTextChange = (e) => {
        setSearchText(e.target.value)
    }

    const handleSearchButtonClick = () => {
        setSearchValue(getSearchBarObjet(searchText)).then(() => {
            setState({ ...state, top: false })
            if (location.pathname != '/search') {
                handleRedirectInternal(history, 'search')
            }
        })

        // if (searchText != '') {
        // } else {
        //     // setSearch({
        //     //     ...search,
        //     //     filters: {
        //     //         ...search.filters,
        //     //         searchbar: {
        //     //             ...search.filters.searchbar,
        //     //             value: searchValue.filters.searchbar.value,
        //     //         },
        //     //     },
        //     // })
        // }
    }

    const handleSearchKeyDown = (e) => {
        if (e.key === 'Enter') {
            setSearchValue(getSearchBarObjet(searchText))
            if (location.pathname != '/search') {
                handleRedirectInternal(history, 'search')
            }
        }
    }

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault()
            setOpen(false)
        }
    }

    // useEffect(() => {
    //     if (searchValue && searchValue.filters && searchValue.filters.searchbar) {
    //         setSearchText(searchValue.filters.searchbar.value)
    //     }
    // }, [searchValue])

    useEffect(() => {
        if (isAuthenticated) {
            if (user) {
                if (user.admin) {
                    getAllBidIncrements({
                        limit: 100,
                        page: 1,
                    })
                }
                if (user.membership) {
                    if (allBiddingPlan && allBiddingPlan.length) {
                        let activePlan = allBiddingPlan.filter(
                            (value) => value.type === user.membership,
                        )
                        if (activePlan.length) {
                            setCurrentMembership(activePlan[0])
                        }
                    }
                } else {
                    setCurrentMembership('')
                }
            }
        }
    }, [isAuthenticated, user, allBiddingPlan])

    const [count, setCount] = useState({
        auctionCount: 0,
        buynowCount: 0,
    })
    const getAuctionFunction = (payload, from) => {
        getAllAuctions(payload, from)
    }

    useEffect(() => {
        if (isAuthenticated) {
            if (user) {
                if (user.admin) {
                    getAuctionFunction(
                        {
                            page: 1,
                            limit: 100,
                            order: '',
                            orderby: '',
                            filters: {
                                searchterm: {
                                    value: '',
                                    type: 'like',
                                    field: 'ac.title',
                                },
                                fromdate: {
                                    value: previousDate,
                                    type: 'dategreaterequal',
                                    field: 'ac.date_closed',
                                },
                                todate: {
                                    value: PresentDate,
                                    type: 'datesmallerequal',
                                    field: 'ac.date_added',
                                },
                            },
                        },
                        'activeAuctions',
                    )
                    getAuctionFunction(
                        {
                            page: 1,
                            limit: 100,
                            order: '',
                            orderby: '',
                            having: {
                                future_active: {
                                    value: 1,
                                    type: 'in',
                                    field: 'future_active',
                                },
                            },
                        },
                        'CommingAuctions',
                    )
                }
            }
        }
    }, [isAuthenticated, user, responseStatusSeller])

    useEffect(() => {
        if (cartCount?.auctionCount) {
            setCount({ ...count, auctionCount: cartCount.auctionCount.totalRecords })
        }
        if (cartCount?.buynowCount) {
            setCount({ ...count, buynowCount: cartCount.buynowCount.totalRecords })
        }
    }, [cartCount])

    useEffect(() => {
        if (all_auctions && all_auctions.from === 'activeAuctions') {
            if (all_auctions.records.length) {
                setActiveAuctions(all_auctions.records)
            }
        }
    }, [all_auctions])

    useEffect(() => {
        if (all_auctions && all_auctions.from === 'CommingAuctions') {
            if (all_auctions.records.length) {
                setupcommingAuctions(all_auctions.records)
            }
        }
    }, [all_auctions])

    return (
        <>
            {useCustomMediaQuery('(min-width: 1024px)') ? (
                <>
                    {/* DESKTOP NAVIGATION */}
                    <Loaders name="home" isLoading={isLoading} />
                    <header
                        className={`mainHeader noPrint ${
                            window.location.pathname === '/' ? 'homeHeader' : ''
                        }`}
                    >
                        <div className="customContainer h-100 d-flex justify-content-between align-items-center">
                            <div className="headLt d-flex justify-content-start align-items-center">
                                <Link to="/">
                                    <img src={LOGO} alt={`${SITE_NAME} Logo`} />
                                </Link>
                            </div>
                            <div>
                                <div className="headRt deskNav d-flex justify-content-start align-items-center">
                                    <ul className="d-flex justify-content-start align-items-center">
                                        <li>
                                            <NavLink
                                                activeClassName="inactive"
                                                className={`${
                                                    window.location.pathname === '/' && 'active'
                                                }`}
                                                to="/"
                                            >
                                                Home
                                            </NavLink>
                                        </li>
                                        {isAuthenticated && user?.admin ? null : (
                                            <li>
                                                <NavLink activeClassName="active" to="/auction">
                                                    Auctions
                                                </NavLink>
                                            </li>
                                        )}

                                        {!isAuthenticated || user?.admin ? (
                                            <li>
                                                <NavLink
                                                    activeClassName="active"
                                                    to="/subscription"
                                                >
                                                    Pricing
                                                </NavLink>
                                            </li>
                                        ) : null}

                                        {isAuthenticated && (
                                            <>
                                                {user?.admin ? (
                                                    <>
                                                        {Number(activeAuctions.length) +
                                                            Number(upcommingAuctions.length) <
                                                        membership?.auctions_year ? (
                                                            <li>
                                                                <NavLink
                                                                    activeClassName="active"
                                                                    to="/post_project/posttype"
                                                                >
                                                                    Post an Auction
                                                                </NavLink>
                                                            </li>
                                                        ) : (
                                                            <li onClick={() => setSubPopup(true)}>
                                                                <a className="cursorDecoy">
                                                                    Post an Auction
                                                                </a>
                                                            </li>
                                                        )}
                                                        <li>
                                                            <NavLink
                                                                activeClassName="active"
                                                                to="/seller/auctions/open"
                                                            >
                                                                My Auctions
                                                            </NavLink>
                                                        </li>
                                                    </>
                                                ) : (
                                                    ''
                                                )}
                                            </>
                                        )}
                                        <li>
                                            <NavLink activeClassName="active" to="/about">
                                                About Us
                                            </NavLink>
                                        </li>
                                        {/* {isAuthenticated && user?.admin ? null : (
                                            <>
                                                <li className="headCart">
                                                    <Button
                                                        onClick={() =>
                                                            handleRedirectInternal(
                                                                history,
                                                                'favourites/watchlist',
                                                            )
                                                        }
                                                    >
                                                        <Badge color="primary">
                                                            <span className="material-icons">
                                                                favorite
                                                            </span>
                                                        </Badge>
                                                    </Button>
                                                </li>
                                            </>
                                        )} */}
                                        {isAuthenticated ? (
                                            <>
                                                {/* <li className="headCart">
                                                <Button
                                                    ref={notiDrop}
                                                    aria-controls="simple-notification"
                                                    aria-haspopup="true"
                                                    onClick={handleMenuNoti}
                                                >
                                                    <Badge badgeContent={unreadMsg} color="primary">
                                                        <span className="material-icons">
                                                            notifications
                                                        </span>
                                                    </Badge>
                                                </Button>
                                                <Popover
                                                    id="simple-notification"
                                                    anchorEl={notiDrop.current}
                                                    keepMounted
                                                    class={`notiDropMenu ${
                                                        document.body.dir === 'rtl' && 'rtl'
                                                    }`}
                                                    onClose={() => handleClose(history, '')}
                                                    open={Boolean(notiDropDown)}
                                                    anchorOrigin={{
                                                        vertical: 'bottom',
                                                        horizontal: 'right',
                                                    }}
                                                    transformOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'right',
                                                    }}
                                                >
                                                    <div className="ntiHdr">
                                                        <span className="material-icons">
                                                            notifications
                                                        </span>
                                                        <h4 className="m-0">Notification</h4>
                                                    </div>
                                                    <ul className="notiList">
                                                        {all_notifications.length ? (
                                                            all_notifications.map((data) => (
                                                                <li
                                                                    className={`${
                                                                        data.read_msg === 0
                                                                            ? 'newNoti'
                                                                            : ''
                                                                    }`}
                                                                    key={data.id}
                                                                >
                                                                    {data.comment}
                                                                </li>
                                                            ))
                                                        ) : (
                                                            <li className="emtNoti">
                                                                <span className="material-icons">
                                                                    notifications
                                                                </span>
                                                                <h4 className="m-0">
                                                                    No Notification Found!
                                                                </h4>
                                                            </li>
                                                        )}
                                                    </ul>
                                                </Popover>
                                            </li>
                                            <li className="headCart">
                                                <Button
                                                    onClick={() =>
                                                        handleRedirectInternal(
                                                            history,
                                                            'favourites/watchlist',
                                                        )
                                                    }
                                                >
                                                    <Badge color="primary">
                                                        <span className="material-icons">
                                                            favorite
                                                        </span>
                                                    </Badge>
                                                </Button>
                                            </li>

                                            <li className="headCart">
                                                <Button
                                                    aria-controls="simple-menu"
                                                    aria-haspopup="true"
                                                    onClick={handleCart}
                                                >
                                                    <Badge
                                                        badgeContent={
                                                            count.buynowCount + count.auctionCount
                                                        }
                                                        color="primary"
                                                    >
                                                        <span className="material-icons">
                                                            shopping_cart
                                                        </span>
                                                    </Badge>
                                                </Button>
                                                <Popover
                                                    anchorEl={anchorcart}
                                                    anchorOrigin={{
                                                        vertical: 'bottom',
                                                        horizontal: 'right',
                                                    }}
                                                    transformOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'right',
                                                    }}
                                                    id="simple-cart"
                                                    keepMounted
                                                    class={`headerDropMenu ${
                                                        document.body.dir === 'rtl' && 'rtl'
                                                    }`}
                                                    open={Boolean(anchorcart)}
                                                    onClose={handleClose}
                                                >
                                                    <MenuList
                                                        autoFocusItem={open}
                                                        id="menu-list-grow"
                                                        onKeyDown={handleListKeyDown}
                                                    >
                                                        <MenuItem
                                                            onClick={() =>
                                                                handleClose(history, 'buynowcart')
                                                            }
                                                        >
                                                            <Badge
                                                                badgeContent={count.buynowCount}
                                                                color="primary"
                                                            >
                                                                <span className="material-icons">
                                                                    shopping_bag
                                                                </span>
                                                            </Badge>
                                                            {t('buy_now_cart')}
                                                        </MenuItem>
                                                        <MenuItem
                                                            onClick={() =>
                                                                handleClose(history, 'cart')
                                                            }
                                                        >
                                                            <Badge
                                                                badgeContent={count.auctionCount}
                                                                color="primary"
                                                            >
                                                                <span className="material-icons">
                                                                    gavel
                                                                </span>
                                                            </Badge>

                                                            {t('auction_cart')}
                                                        </MenuItem>
                                                    </MenuList>
                                                </Popover>
                                            </li> */}
                                                <li>
                                                    <Button
                                                        ref={mainMenu}
                                                        aria-controls="simple-menu"
                                                        aria-haspopup="true"
                                                        onClick={handleMenu}
                                                    >
                                                        <img src="/assets/svg/user.svg" />
                                                        Hello {user.first_name}
                                                    </Button>
                                                    <Popover
                                                        id="simple-menu"
                                                        anchorEl={mainMenu.current}
                                                        keepMounted
                                                        class={`headerDropMenu ${
                                                            document.body.dir === 'rtl' && 'rtl'
                                                        }`}
                                                        onClose={() => handleClose(history, '')}
                                                        open={Boolean(anchormenu)}
                                                        anchorOrigin={{
                                                            vertical: 'bottom',
                                                            horizontal: 'right',
                                                        }}
                                                        transformOrigin={{
                                                            vertical: 'top',
                                                            horizontal: 'right',
                                                        }}
                                                    >
                                                        <MenuList
                                                            autoFocusItem={open}
                                                            id="menu-list-grow"
                                                            onKeyDown={handleListKeyDown}
                                                        >
                                                            {user?.admin ? null : (
                                                                <MenuItem
                                                                    onClick={() =>
                                                                        handleClose(
                                                                            history,
                                                                            'auctions/all',
                                                                        )
                                                                    }
                                                                >
                                                                    <span className="material-icons">
                                                                        gavel
                                                                    </span>
                                                                    My Bids
                                                                </MenuItem>
                                                            )}
                                                            <MenuItem
                                                                onClick={() =>
                                                                    handleClose(
                                                                        history,
                                                                        'account/profile',
                                                                    )
                                                                }
                                                            >
                                                                <span className="material-icons">
                                                                    person
                                                                </span>
                                                                Profile
                                                            </MenuItem>
                                                            {user?.admin ? null : (
                                                                <MenuItem
                                                                    onClick={() =>
                                                                        handleClose(
                                                                            history,
                                                                            'account/notifications',
                                                                        )
                                                                    }
                                                                >
                                                                    <span className="material-icons">
                                                                        notifications
                                                                    </span>
                                                                    Notification
                                                                </MenuItem>
                                                            )}
                                                            <MenuItem onClick={() => onLogOut()}>
                                                                <span className="material-icons">
                                                                    power_settings_new
                                                                </span>
                                                                Logout
                                                            </MenuItem>
                                                        </MenuList>
                                                    </Popover>
                                                </li>
                                            </>
                                        ) : (
                                            <>
                                                <li className="lgRegCntnr">
                                                    <img src="/assets/svg/user.svg" />
                                                    <Link to="/login">Login</Link>/
                                                    <Link to="/registration">Register</Link>
                                                </li>
                                                {/* <li>
                                                <TertiaryButton
                                                    label="Login"
                                                    btnSize="small"
                                                    onClick={() =>
                                                        handleRedirectInternal(history, 'login')
                                                    }
                                                />
                                            </li>
                                            <li>
                                                <SecondaryButton
                                                    label="Sign up"
                                                    btnSize="small"
                                                    onClick={() =>
                                                        handleRedirectInternal(
                                                            history,
                                                            'registration',
                                                        )
                                                    }
                                                />
                                            </li> */}
                                            </>
                                        )}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </header>
                </>
            ) : (
                <>
                    {/* MOBILE / TAB NAVIGATION */}
                    <header
                        className={`mainHeader customContainer d-flex justify-content-between align-items-center ${
                            window.location.pathname === '/' ? 'homeHeader' : ''
                        }`}
                    >
                        <div className="headLt d-flex justify-content-start">
                            <Link to="/">
                                <img src={LOGO} alt={`${SITE_NAME} Logo`} />
                            </Link>
                        </div>
                        <div className="headRt">
                            <ul className="d-flex justify-content-start ml-auto align-items-center">
                                {/* {isAuthenticated && (
                                    <li>
                                        <Button onClick={toggleDrawer('top', true)}>
                                            <span className="material-icons">search</span>
                                        </Button>
                                    </li>
                                )} */}
                                {isAuthenticated && (
                                    <>
                                        {/* <li className="headCart">
                                            <Link to="/cart">
                                                <Badge color="primary">
                                                    <span className="material-icons">
                                                        shopping_cart
                                                    </span>
                                                </Badge>
                                            </Link>
                                        </li> */}
                                        {/* <li className="headCart">
                                            <Link to="/buynowcart">
                                                <Badge color="primary">
                                                    <span className="material-icons">
                                                        shopping_bag
                                                    </span>
                                                </Badge>
                                            </Link>
                                        </li> */}
                                    </>
                                )}
                                <li>
                                    <Button
                                        className="respNavBtn"
                                        onClick={toggleDrawer('right', true)}
                                    >
                                        <span className="material-icons">menu</span>
                                    </Button>
                                </li>
                            </ul>
                        </div>
                    </header>
                    <React.Fragment>
                        <SwipeableDrawer
                            className="respHeaderSearch"
                            anchor={'top'}
                            open={state['top']}
                            disableBackdropTransition={!iOS}
                            disableDiscovery={iOS}
                            disableSwipeToOpen={false}
                            onClose={toggleDrawer('top', false)}
                            onOpen={toggleDrawer('top', true)}
                        >
                            <div className="form-inline my-2 my-lg-0 headerSearchForm">
                                <input
                                    className="form-control"
                                    type="search"
                                    placeholder="Search NPS Products"
                                    aria-label="Search"
                                    value={searchText}
                                    onChange={handleSearchTextChange}
                                    onKeyDown={handleSearchKeyDown}
                                />
                                <Button className="btn" onClick={handleSearchButtonClick}>
                                    <span className="material-icons">search</span>
                                </Button>
                            </div>
                        </SwipeableDrawer>
                    </React.Fragment>

                    <React.Fragment>
                        <SwipeableDrawer
                            className="headerDrawer"
                            anchor={'right'}
                            open={state['right']}
                            disableBackdropTransition={!iOS}
                            disableDiscovery={iOS}
                            disableSwipeToOpen={false}
                            onClose={toggleDrawer('right', false)}
                            onOpen={toggleDrawer('right', true)}
                        >
                            <div className="headRt respNav d-flex justify-content-start align-items-center">
                                <div className="naLogoHead d-flex justify-content-between align-items-center">
                                    <Link to="/">
                                        <img src={LOGO} alt={`${SITE_NAME} Logo`} height="60" />
                                    </Link>
                                    <Button
                                        className="headDrawerClose"
                                        onClick={toggleDrawer('right', false)}
                                    >
                                        <span className="material-icons">clear</span>
                                    </Button>
                                </div>
                                <Divider />
                                <ul className="navRespLinks" onClick={toggleDrawer('right', false)}>
                                    {isAuthenticated && user?.admin ? (
                                        <>
                                            <ListItem button>
                                                <NavLink
                                                    activeClassName="active"
                                                    to={'/post_project/posttype'}
                                                >
                                                    <span className="material-icons">post_add</span>
                                                    Post an Auction
                                                </NavLink>
                                            </ListItem>
                                        </>
                                    ) : (
                                        <ListItem button>
                                            <NavLink activeClassName="active" to="/auction">
                                                <span className="material-icons">live_tv</span>
                                                Live Auctions
                                            </NavLink>
                                        </ListItem>
                                    )}
                                    {/* <ListItem button>
                                        <NavLink activeClassName="active" to="/search">
                                            <span className="material-icons">category</span>
                                            {t('products')}
                                        </NavLink>
                                    </ListItem> */}

                                    {/* {console.log('User Types ==>', user)} */}
                                    {isAuthenticated && (
                                        <>
                                            {user?.admin ? (
                                                <ListItem button>
                                                    <NavLink
                                                        activeClassName="active"
                                                        to="/seller/auctions/upcoming"
                                                    >
                                                        <span className="material-icons">
                                                            gavel
                                                        </span>
                                                        My Auction
                                                    </NavLink>
                                                </ListItem>
                                            ) : (
                                                <ListItem button>
                                                    <NavLink
                                                        activeClassName="active"
                                                        to="/auctions/all"
                                                    >
                                                        <span className="material-icons">
                                                            gavel
                                                        </span>
                                                        {'My Bids'}
                                                    </NavLink>
                                                </ListItem>
                                            )}
                                        </>
                                    )}
                                    {isAuthenticated ? (
                                        <>
                                            <ListItem button>
                                                <NavLink
                                                    activeClassName="active"
                                                    to={'/account/profile'}
                                                >
                                                    <span className="material-icons">person</span>
                                                    {'Profile'}
                                                </NavLink>
                                            </ListItem>

                                            {/* <ListItem button>
                                                <NavLink
                                                    activeClassName="active"
                                                    to="/purchases/buynow"
                                                >
                                                    <span className="material-icons">
                                                        shopping_basket
                                                    </span>
                                                    {t('my_purchases')}
                                                </NavLink>
                                            </ListItem> */}

                                            {/* <ListItem button>
                                                <NavLink activeClassName="active" to="/cart">
                                                    <span className="material-icons">
                                                        shopping_cart
                                                    </span>
                                                    {'Auction Cart'}
                                                </NavLink>
                                            </ListItem> */}
                                            {/* <ListItem button>
                                                <NavLink
                                                    activeClassName="active"
                                                    to="/seller/payments/saved_cards"
                                                >
                                                    <span className="material-icons">
                                                        credit_card
                                                    </span>
                                                    {'Card Details'}
                                                </NavLink>
                                            </ListItem> */}
                                            {isAuthenticated && user?.admin ? (
                                                <>
                                                    <ListItem button>
                                                        <NavLink
                                                            activeClassName="active"
                                                            to="/seller/organization"
                                                        >
                                                            <span className="material-icons">
                                                                corporate_fare
                                                            </span>
                                                            Organization
                                                        </NavLink>
                                                    </ListItem>
                                                    <ListItem button>
                                                        <NavLink
                                                            activeClassName="active"
                                                            to="/seller/templates"
                                                        >
                                                            <span className="material-icons">
                                                                table_chart
                                                            </span>
                                                            Templates
                                                        </NavLink>
                                                    </ListItem>
                                                    <ListItem button>
                                                        <NavLink
                                                            activeClassName="active"
                                                            to="/seller/reports"
                                                        >
                                                            <span className="material-icons">
                                                                description
                                                            </span>
                                                            Reports
                                                        </NavLink>
                                                    </ListItem>
                                                    <ListItem button>
                                                        <NavLink
                                                            activeClassName="active"
                                                            to="/seller/setting/location"
                                                        >
                                                            <span className="material-icons">
                                                                location_on
                                                            </span>
                                                            Location
                                                        </NavLink>
                                                    </ListItem>
                                                    <ListItem button>
                                                        <NavLink
                                                            activeClassName="active"
                                                            to="/seller/payments/saved_cards"
                                                        >
                                                            <span className="material-icons">
                                                                credit_card
                                                            </span>
                                                            {'My Payment'}
                                                        </NavLink>
                                                    </ListItem>
                                                </>
                                            ) : (
                                                ''
                                            )}
                                            {/* <ListItem button>
                                                <NavLink
                                                    activeClassName="active"
                                                    to="/seller/payments/bank_details"
                                                >
                                                    <span className="material-icons">
                                                        account_balance
                                                    </span>
                                                    {'Bank Details'}
                                                </NavLink>
                                            </ListItem>
                                            <ListItem button>
                                                <NavLink
                                                    activeClassName="active"
                                                    to="/seller/payments/saved_cards"
                                                >
                                                    <span className="material-icons">
                                                        credit_card
                                                    </span>
                                                    {'Card Details'}
                                                </NavLink>
                                            </ListItem> */}
                                            {/* <ListItem button>
                                                <NavLink activeClassName="active" to="/buynowcart">
                                                    <span className="material-icons">
                                                        shopping_bag
                                                    </span>
                                                    {'Buy Now Cart'}
                                                </NavLink>
                                            </ListItem> */}
                                            {isAuthenticated && user?.admin ? null : (
                                                <ListItem button>
                                                    <NavLink activeClassName="active" to="/follows">
                                                        <span className="material-icons">
                                                            favorite
                                                        </span>
                                                        {'Favorites'}
                                                    </NavLink>
                                                </ListItem>
                                            )}
                                            {/* <ListItem button>
                                                <NavLink
                                                    activeClassName="active"
                                                    to="/purchases/buynow"
                                                >
                                                    <span className="material-icons">
                                                        shopping_basket
                                                    </span>
                                                    {'My Purchases'}
                                                </NavLink>
                                            </ListItem> */}
                                            {/* <ListItem button>
                                                <NavLink
                                                    activeClassName="active"
                                                    to="/favourites/watchlist"
                                                >
                                                    <span className="material-icons">favorite</span>
                                                    {'Favorites'}
                                                </NavLink>
                                            </ListItem> */}
                                            {/* <ListItem button>
                                                <NavLink activeClassName="active" to="/wallet">
                                                    <span className="material-icons">
                                                        account_balance_wallet
                                                    </span>
                                                    {t('my_wallet')}
                                                </NavLink>
                                            </ListItem> */}
                                            {/* <ListItem button>
                                                <NavLink
                                                    activeClassName="active"
                                                    to="/transactions"
                                                >
                                                    <span className="material-icons">
                                                        swap_vert
                                                    </span>
                                                    My Transactions
                                                </NavLink>
                                            </ListItem> */}
                                            {/* <ListItem button>
                                                <NavLink
                                                    activeClassName="active"
                                                    to="/invoices/all"
                                                >
                                                    <span className="material-icons">receipt</span>
                                                    {'My Invoices'}
                                                </NavLink>
                                            </ListItem> */}
                                            {/* <ListItem button>
                                                <NavLink
                                                    activeClassName="active"
                                                    to="/saved_search"
                                                >
                                                    <span className="material-icons">bookmark</span>
                                                    {'My Saved Searches'}
                                                </NavLink>
                                            </ListItem> */}
                                            <ListItem button>
                                                <NavLink activeClassName="active" to="/contactus">
                                                    <span className="material-icons">
                                                        contact_support
                                                    </span>
                                                    {'Contact'}
                                                </NavLink>
                                            </ListItem>

                                            <ListItem button>
                                                <NavLink activeClassName="active" to="/about">
                                                    <span className="material-icons">info</span>
                                                    {'About'}
                                                </NavLink>
                                            </ListItem>
                                        </>
                                    ) : (
                                        <>
                                            <ListItem button>
                                                <NavLink to="/login" ActiveclassName="inactive">
                                                    <span className="material-icons">login</span>
                                                    {'Login'}
                                                </NavLink>
                                            </ListItem>
                                            <ListItem button>
                                                <NavLink
                                                    to="/registration"
                                                    ActiveclassName="inactive"
                                                >
                                                    <span className="material-icons">
                                                        app_registration
                                                    </span>
                                                    {'Register'}
                                                </NavLink>
                                            </ListItem>
                                        </>
                                    )}
                                    {isAuthenticated && (
                                        <ListItem className="" button onClick={() => onLogOut()}>
                                            <span className="material-icons">
                                                power_settings_new
                                            </span>
                                            {'Logout'}
                                        </ListItem>
                                    )}
                                </ul>
                                {/* <ul className="navRespLinks">
                                    <ListItem button>
                                        <LanguageSwitch />
                                    </ListItem>
                                </ul> */}
                            </div>
                        </SwipeableDrawer>
                    </React.Fragment>
                </>
            )}

            <FormDialog open={subPopup} openFunction={() => setSubPopup(false)}>
                <div className="upgrdPup">
                    <p className="text-center mb-1">
                        <span className="material-icons">warning</span>
                    </p>
                    {membership ? (
                        <p className="text-center">
                            You subscription limit exceeded, please upgrade your plan
                        </p>
                    ) : (
                        <p className="text-center">Please buy a plan to enable Auction postings</p>
                    )}

                    <div className="d-flex align-items-center justify-content-end">
                        <SecondaryButton label="Cancel" onClick={() => setSubPopup(false)} />
                        <PrimaryButton
                            btnSize="ml-2"
                            label="Upgrade"
                            onClick={() => (
                                handleRedirectInternal(history, 'subscription'), setSubPopup(false)
                            )}
                        />
                    </div>
                </div>
            </FormDialog>

            <BidHistory />
        </>
    )
}
export default Header
