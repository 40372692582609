import React, { useContext, useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
// import FullScreenPopup from '../FullScreenPopup'
import Modal from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop'
import { useFormik } from 'formik'
import { Pagination } from '@material-ui/lab'
import { Button } from '@material-ui/core'
import CustomTable from '../../../../product/components/molecules/CustomTable'
import PrimaryButton from '../../../../product/components/atoms/PrimaryButton'
import CustomDialog from '../../../../product/components/organisms/Dialog'
import FullScreenPopup from '../../../../product/components/organisms/FullScreenPopup'
import ProductContext from '../../../../product/context/product/productContext'
import SellerProductContext from '../../../../product/context/seller/product/productContext'
import SellerBidContext from '../../../../product/context/seller/bid/bidContext'
import AuthContext from '../../../../product/context/auth/authContext'
import CommonContext from '../../../../product/context/common/commonContext'
import ReportContext from '../../../../product/context/seller/report/reportContext'
import Slide from '@material-ui/core/Slide'
import Dialog from '@material-ui/core/Dialog'
import {
    currencyFormat,
    dateTimeFormatFunction,
    maskEmailFront,
} from '../../../../custom/common/components'

const useStyles = makeStyles((theme, maxWidth) => ({
    modal: {
        display: 'flex',
        padding: theme.spacing(1),
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        width: '100%',
        maxWidth: '55rem',
    },
}))

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})

const BidHistory = (props) => {
    const classes = useStyles()
    const productContext = useContext(ProductContext)
    const sellerProductContext = useContext(SellerProductContext)
    const sellerBidContext = useContext(SellerBidContext)
    const authContext = useContext(AuthContext)
    const {
        search_allbidhistory: search_allbidhistory_seller,
        getAllBidHistory: getAllBidHistorySeller,
    } = sellerProductContext

    const { search_allbidhistory, getAllBidHistory } = productContext
    const { bidCancel, responseStatus: responseStatusBid } = sellerBidContext

    const { user, isAuthenticated } = authContext

    const [changeStatus, setChangeStatus] = React.useState(false)
    const [isLoading, setIsLoading] = React.useState(false)
    const changeChangeStatus = () => {
        setChangeStatus(!changeStatus)
    }

    const commonContext = useContext(CommonContext)
    const { bidHistoryValue, clearBidHistoryValue } = commonContext

    const { getAllExportDownload } = useContext(ReportContext)

    const [tableBodySeller, setTableBodySeller] = useState([])

    const [tableBody, setTableBody] = useState([])
    const [reload, setReload] = useState(false)
    const [data, setData] = useState({
        popup: false,
        status: 'new',
        id: 0,
    })
    const [showName, setShowName] = useState(true)
    const [showValue, setShowValue] = useState(true)

    const getBidHistoryFunction = (values) => {
        if (values.seller) {
            getAllBidHistorySeller(values)
        } else {
            let BidderValues = {
                limit: values.limit,
                page: values.page,
                id: values.filters.project_id.value,
                order: values.order,
            }
            getAllBidHistory(BidderValues)
        }
    }

    const formikData = useFormik({
        initialValues: {
            page: 1,
            limit: 20,
            order: 'asc',
            orderby: '',
            get_proxy: false,
            declined: false,
            delete_bid: false,
            seller: false,
            filters: {
                project_id: {
                    value: 0,
                    type: 'in',
                    field: 'b.project_id',
                },
                user_id: {
                    value: '',
                    type: 'in',
                    field: 'b.user_id',
                },
            },
        },
    })

    useEffect(() => {
        if (bidHistoryValue) {
            if (bidHistoryValue.id) {
                setShowName(
                    bidHistoryValue?.displayName === false ? bidHistoryValue.displayName : true,
                )
                setShowValue(
                    bidHistoryValue?.displayValue === false ? bidHistoryValue.displayValue : true,
                )

                console.log('bidHistoryValue', bidHistoryValue)
                formikData.values.filters.project_id.value = bidHistoryValue.id
                formikData.values.get_proxy = bidHistoryValue.proxy
                formikData.values.delete_bid = bidHistoryValue.delete_bid
                if (bidHistoryValue.declined) {
                    formikData.values.declined = bidHistoryValue.declined
                }
                if (bidHistoryValue.user_id) {
                    formikData.values.filters.user_id.value = bidHistoryValue.user_id
                }
                if (bidHistoryValue.seller) {
                    formikData.values.seller = bidHistoryValue.seller
                }
            } else {
                formikData.values.filters.project_id.value = bidHistoryValue
            }
            setData({ popup: true, id: bidHistoryValue })
            getBidHistoryFunction(formikData.values)
            clearBidHistoryValue()
            console.log(bidHistoryValue, 'bidHistoryValue')
        }
        return () => {
            clearBidHistoryValue()
        }
    }, [bidHistoryValue])

    useEffect(() => {
        if (search_allbidhistory) {
            setTableBody(search_allbidhistory.records.length ? search_allbidhistory.records : [])
        }
    }, [search_allbidhistory])

    useEffect(() => {
        if (search_allbidhistory_seller) {
            setTableBodySeller(
                search_allbidhistory_seller.records.length
                    ? search_allbidhistory_seller.records
                    : [],
            )
        }
    }, [search_allbidhistory_seller])

    const tableValues = [
        {
            field: 'created_at',
            type: 'datetime',
            firstChild: true,
            sort: true,
            disablePadding: false,
            label: 'Date',
        },
        {
            field: 'user_id',
            type: '',
            firstChild: false,
            sort: false,
            disablePadding: false,
            label: 'User ID',
        },
        {
            field: 'users_first_name,users_last_name',
            type: '',
            sort: false,
            firstChild: false,
            disablePadding: false,
            label: 'User Name',
        },
        {
            field: 'users_email',
            type: '',
            sort: false,
            firstChild: false,
            disablePadding: false,
            label: 'User Email',
        },
    ]

    if (formikData.values.declined) {
        tableValues.push({
            field: 'bidtext',
            condition: true,
            conditionValue: {
                formula: {
                    key1: 'declined',
                    key2: 1,
                    notation: '===',
                },
                true: {
                    type: 'text',
                    value: 'Declined',
                },
                false: {
                    type: 'text',
                    value: 'Accepted',
                },
            },
            type: 'text',
            sort: true,
            firstChild: false,
            disablePadding: false,
            label: 'Status',
        })
    }

    if (formikData.values.get_proxy) {
        tableValues.push({
            field: 'bidtext',
            condition: true,
            conditionValue: {
                formula: {
                    key1: 'proxytable',
                    key2: 1,
                    notation: '===',
                },
                true: {
                    type: 'text',
                    value: 'Max',
                },
                false: {
                    type: 'value',
                    value: 'bidText',
                },
            },
            type: 'text',
            sort: true,
            firstChild: false,
            disablePadding: false,
            label: 'Type',
        })
    }
    tableValues.push({
        field: 'proposed_amount',
        type: 'price',
        sort: true,
        firstChild: false,
        disablePadding: false,
        label: 'Amount',
    })

    const formikSelection = useFormik({
        initialValues: {
            action: '',
            id: '',
            pid: '',
        },
        onSubmit: (values) => {
            if (values.action === 'delete') {
                setIsLoading(true)
                bidCancel(values)
            }
        },
    })

    const onClickEdit = (id, secondaryID, type) => {
        formikSelection.values.action = type
        formikSelection.values.id = id
        formikSelection.values.pid = formikData.values.filters.project_id.value
        if (type === 'delete') {
            setChangeStatus(true)
        }
    }

    if (formikData.values.delete_bid) {
        tableValues.push({
            field: 'action',
            condition: true,
            type: 'button',
            conditionValue: {
                formula: {
                    key1: 'proxytable',
                    key2: 1,
                    notation: '!==',
                },
                true: {
                    type: 'button',
                    value: 'delete',
                },
                false: {
                    type: 'button',
                    value: '',
                },
            },
            clickType: 'delete',
            onclick: onClickEdit,
            sort: false,
            firstChild: false,
            disablePadding: false,
            label: 'Action',
            tooltipTitle: 'Delete Bid',
        })
    }

    useEffect(() => {
        if (responseStatusBid) {
            if (responseStatusBid.from === 'bidCancel') {
                if (responseStatusBid.status === 'success') {
                    changeChangeStatus()
                    getBidHistoryFunction(formikData.values)
                    setReload(!reload)
                    setIsLoading(false)
                }
            }
        }
    }, [responseStatusBid])

    const tableActions = []

    const handleChangePage = (event, newPage) => {
        formikData.values.page = newPage + 1
        getBidHistoryFunction(formikData.values)
    }

    return data.popup ? (
        <Dialog
            fullScreen={formikData.values.seller ? true : false}
            className="infoModal"
            open={data.popup}
            onClose={() => setData({ popup: false, id: 0 })}
            TransitionComponent={Transition}
            backdropClick={true}
        >
            {formikData.values.seller ? (
                <>
                    <div className="bidHistoryModal seller">
                        <div className="modal-header  container-fluid">
                            <h4 className="modal-title">Bid History</h4>
                            <Button
                                className="close"
                                onClick={() => {
                                    setData({ popup: false, id: 0 })
                                    setTableBodySeller([])
                                }}
                            >
                                <span className="material-icons">close</span>
                            </Button>
                        </div>
                        <div className="fspBody">
                            <Button
                                variant="outlined"
                                className="exportBtn"
                                onClick={() =>
                                    getAllExportDownload({
                                        action: 'export',
                                        order: 'asc',
                                        orderby: '',
                                        search: `b.project_id=${formikData.values.filters.project_id.value}`,
                                        report: `bid_history`,
                                    })
                                }
                            >
                                {' '}
                                <span className="material-icons">get_app</span>
                                Export All Data
                            </Button>
                            <CustomTable
                                formik={formikData.values}
                                tableBody={tableBodySeller}
                                tableValues={tableValues}
                                tableActions={tableActions}
                                allData={search_allbidhistory_seller}
                                changeTableFunction={getAllBidHistorySeller}
                            />
                        </div>
                    </div>
                    <CustomDialog
                        title={'Change Bid Status'}
                        open={changeStatus}
                        function={changeChangeStatus}
                    >
                        <h5>Are you sure you want to delete the bid</h5>
                        <div className="actionWrapper">
                            <Button onClick={() => changeChangeStatus()}>Cancel</Button>
                            <form onSubmit={formikSelection.handleSubmit} autoComplete="nofill">
                                <PrimaryButton type="submit" label="Confirm" />
                            </form>
                        </div>
                    </CustomDialog>
                </>
            ) : (
                <div className="bidHistoryModal">
                    <div className={classes.paper}>
                        <div className="modal fade show custom-modal">
                            <div className="modal-dialog modal-lg">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h4 className="modal-title">Bid History</h4>
                                        <Button
                                            className="close"
                                            onClick={() => {
                                                setData({ popup: false, id: 0 })
                                                setTableBody([])
                                            }}
                                        >
                                            <span className="material-icons">close</span>
                                        </Button>
                                    </div>
                                    <div className="modal-body cm-body table-responsive">
                                        <table className="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th scope="col">ID</th>
                                                    <th scope="col">Bidder Name</th>
                                                    <th scope="col">Current Bid</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {tableBody.length > 0 ? (
                                                    <>
                                                        {tableBody.map((data, index) => (
                                                            <>
                                                                <tr>
                                                                    <td>{index + 1}</td>
                                                                    <td>
                                                                        {data.user_id ===
                                                                        user.user_id
                                                                            ? data.first_name +
                                                                              ' ' +
                                                                              data.last_name
                                                                            : showName
                                                                            ? data.first_name +
                                                                              ' ' +
                                                                              data.last_name
                                                                            : maskEmailFront(
                                                                                  data.first_name +
                                                                                      ' ' +
                                                                                      data.last_name,
                                                                              )}
                                                                    </td>
                                                                    <td className="bidAmountCol">
                                                                        {data.user_id ===
                                                                        user.user_id
                                                                            ? currencyFormat(
                                                                                  data.proposed_amount,
                                                                              )
                                                                            : showValue
                                                                            ? currencyFormat(
                                                                                  data.proposed_amount,
                                                                              )
                                                                            : '***'}
                                                                    </td>
                                                                </tr>
                                                            </>
                                                        ))}
                                                    </>
                                                ) : (
                                                    <>
                                                        <tr>
                                                            <td className="text-center" colSpan={3}>
                                                                No Bids Found!
                                                            </td>
                                                        </tr>
                                                    </>
                                                )}
                                            </tbody>
                                        </table>
                                        <div className="d-flex justify-content-between align-items-center flex-wrap w-100 mb-3 pagination-wrapper col-md-12">
                                            <h6>
                                                {'Showing'}&nbsp; {search_allbidhistory.setDisp}
                                                &nbsp;{'of'}&nbsp;
                                                {search_allbidhistory.totalRecords}
                                            </h6>
                                            <Pagination
                                                count={Math.ceil(
                                                    search_allbidhistory.totalRecords /
                                                        formikData.values.limit,
                                                )}
                                                page={formikData.values.page}
                                                onChange={handleChangePage}
                                                siblingCount={3}
                                                showFirstButton
                                                showLastButton
                                                boundaryCount={2}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </Dialog>
    ) : null
}

export default BidHistory
