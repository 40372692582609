import React, { useEffect, useState, useContext } from 'react'
import { useFormik } from 'formik'
import moment from 'moment'
import { useHistory } from 'react-router-dom'
import PrimaryButton from '@/product/components/atoms/PrimaryButton'
import SecondaryButton from '@/product/components/atoms/SecondaryButton'
import { handleRedirectInternal, mapData } from '@/product/common/components'
import SellerAuctionContext from '@/product/context/seller/auction/auctionContext'
import authContext from '@/product/context/auth/authContext'
import SellerTemplateAuctionContext from '@/product/context/seller/template/auction/auctionContext'

const AuctionComponent = (props) => {
    const data = props.data
    const history = useHistory()
    const sellerAuctionContext = useContext(SellerAuctionContext)
    const { user, isAuthenticated } = useContext(authContext)
    const sellerTemplateAuctionContext = useContext(SellerTemplateAuctionContext)

    const [isLoading, setIsLoading] = useState([])

    const {
        getSingleAuction,
        single_auction,
        auctionAction,
        responseStatus: responseStatusSeller,
    } = sellerAuctionContext
    const {
        getSingleAuction: getSingleAuctionTemplate,
        single_auction: single_auctiontemplate,
        responseStatus: responseStatusSellerTemplate,
    } = sellerTemplateAuctionContext

    const formik = useFormik({
        initialValues: props.initialValues,
        validationSchema: props.validationArray,
        enableReinitialize: true,
        onSubmit: (values) => {
            setIsLoading(true)
            const value = values
            if (value.date_added && value.date_closed) {
                value.date_added = moment(value.date_added, 'MM-DD-YYYY h:mm a').format()
                value.date_closed = moment(value.date_closed, 'MM-DD-YYYY h:mm a').format()
            }
            console.log(values, 'values')
            console.log(value, 'value')
            auctionAction(value)
        },
    })

    const auctionform = props.formFields ? { formik: formik, ...props.formFields } : {}

    useEffect(() => {
        if (responseStatusSeller) {
            if (responseStatusSeller.status === 'success') {
                if (responseStatusSeller.from === 'auctionAction') {
                    setIsLoading(false)
                    props.onSuccess()
                }
            }
        }
    }, [responseStatusSeller])

    useEffect(() => {
        if (isAuthenticated) {
            setIsLoading(true)
            if (data.status === 'edit' && data.id > 0) {
                formik.values.id = data.id
                getSingleAuction(formik.values)
                // getAllAuctProjects(formik.values)
            } else if (data.status === 'template' && data.id > 0) {
                getSingleAuctionTemplate({ id: data.id })
                // getAllAuctProjects(formik.values)
            } else {
                formik.values.id = 0
                // getAllAuctProjects(formik.values)
                // console.log('heree 1222')
                // resetFrom()
            }
        }
    }, [data.id, isAuthenticated])

    useEffect(() => {
        if (props.handleSingleAuctionTemplate) {
            props.handleSingleAuctionTemplate(single_auctiontemplate)
        }
    }, [single_auctiontemplate])

    useEffect(() => {
        if (props.handleSingleAuctionEdit) {
            props.handleSingleAuctionEdit(single_auction)
        }
    }, [single_auction])

    useEffect(() => {
        if (props.setResetValue && props.resetValue && props.resetValue.isActive) {
            formik.values.event_type = props.resetValue.data
            props.setResetValue({ isActive: false, data: '' })
        }
    }, [props.setResetValue, props.resetValue])

    useEffect(() => {
        if (formik.values.event_type == 1) {
            formik.values.ending_enable = false
            formik.values.ending_items = 0
            formik.values.ending_mins = 0
        }
    }, [formik.values.event_type])

    useEffect(() => {
        if (props.additionalfieldUpdate) {
            props.additionalfieldUpdate(formik.values)
        }
    }, [formik.values])

    return (
        <>
            <div className="row">{mapData(auctionform)}</div>

            <div className="btnWrpr d-flex align-items-center justify-content-center mt-3">
                {props.onFormBack ? (
                    <SecondaryButton
                        id="previous"
                        label={props.BackBtnLabel ? props.BackBtnLabel : 'Previous'}
                        onClick={() => {
                            props.onFormBack()
                        }}
                    />
                ) : null}
                <PrimaryButton
                    id="auctionComp_next"
                    label={props.SubmitBtnLabel ? props.SubmitBtnLabel : 'Next'}
                    onClick={() => {
                        formik.handleSubmit()
                    }}
                />
            </div>
        </>
    )
}

export default AuctionComponent
