import React, { useContext, useEffect, useState } from 'react'
import PrimaryButton from '@/product/components/atoms/PrimaryButton'
import { mapData } from '@/product/common/components'
import AuthContext from '@/product/context/auth/authContext'
import UserContext from '@/product/context/user/userContext'
import CommonContext from '@/custom/context/common/commonContext'
import { useFormik } from 'formik'
import { useHistory } from 'react-router-dom'
import SecondaryButton from '@/product/components/atoms/SecondaryButton'
import PhoneValidationComponent from '@/product/components/species/components/user/PhoneValidationComponent'
import AddressViewComponent from '@/product/components/species/components/user/profile/AddressViewComponent'
import { editValue, initialFunction, validationFunction } from '../../dynamic/common/FieldFunction'
import ProductCommonContext from '@/product/context/common/commonContext'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import { Button } from '@material-ui/core'
const ProfileComponent = (props) => {
    const userContext = useContext(UserContext)
    const authContext = useContext(AuthContext)
    const commonContext = useContext(CommonContext)
    const { t } = useTranslation()
    const history = useHistory()

    const { updateProfile, updatePreference, responseStatus } = userContext
    const { allNotifications, configFeatures, alldata_all_dropdown, allLocations } = commonContext
    const { allCountries, allStates, allCities } = useContext(ProductCommonContext)
    const { user, loadUser } = authContext

    const [phoneVerify, setPhoneVerify] = useState(false)
    const [isSecondaryBtn, setSecondaryBtn] = useState(false)
    const [arrayValue, setArrayValue] = useState([])
    const [initialValues, setInitialValues] = useState({})
    const [validationArray, setValidationArray] = useState({})
    const [valueLoaded, setValueLoaded] = useState(false)
    const formFields = props.formFields

    const changePhoneVerify = () => {
        setPhoneVerify(!phoneVerify)
    }

    const [currentCountry, setCurrentCountry] = useState()

    const submitHandler = (payload) => {
        if (isSecondaryBtn) {
            updateProfile(payload, props.noAlert, 'saveExit')
        } else {
            updateProfile(payload, props.noAlert)
        }
    }

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object().shape({ ...validationArray }),
        enableReinitialize: true,
        onSubmit: (values) => {
            if (props.phoneVerify) {
                if (formik.values.phone) {
                    if (user.phone === formik.values.phone) {
                        delete values.email
                        submitHandler(values)
                    } else if (
                        values.phone_verified === 1 &&
                        formik.values.verified_phonenum === formik.values.phone
                    ) {
                        delete values.email
                        submitHandler(values)
                    } else {
                        setPhoneVerify(true)
                    }
                } else {
                    delete values.email
                    submitHandler(values)
                }
            } else {
                delete values.email
                submitHandler(values)
            }
        },
    })

    const formikPreference = useFormik({
        initialValues: {
            email_prefer: [],
            sms_prefer: [],
        },
        onSubmit: (values) => {
            updatePreference(values)
        },
    })

    const emailPreference = [
        {
            name: 'email_prefer',
            type: 'checkboxarray',
            class: 'col-12',
            item: allNotifications.filter((inner) => inner.email === 1),
            formik: formikPreference,
        },
    ]

    const smsPreference = [
        {
            name: 'sms_prefer',
            type: 'checkboxarray',
            class: 'col-12',
            item: allNotifications.filter((inner) => inner.sms === 1),
            formik: formikPreference,
        },
    ]

    const subLevelOptions = (data) => {
        let valueDrop = []
        console.log(data, 'subselect')
        valueDrop = alldata_all_dropdown
            .filter((subcat) => {
                if (
                    formik.values[data.sub_field] &&
                    parseInt(subcat.level, 10) === parseInt(data.sub_level, 10)
                ) {
                    if (
                        parseInt(subcat.active, 10) === 1 ||
                        (formik.values[data.name] && formik.values[data.name] === subcat.id)
                    ) {
                        if (data.diff_value) {
                            let mainId = alldata_all_dropdown.filter(
                                (item) => item.value === formik.values[data.sub_field],
                            )[0]
                            console.log(mainId, 'mainId')
                            return parseInt(subcat.level_id, 10) === parseInt(mainId?.id, 10)
                        } else {
                            return (
                                parseInt(formik.values[data.sub_field], 10) ===
                                parseInt(subcat.level_id, 10)
                            )
                        }
                    } else {
                        return false
                    }
                } else {
                    return false
                }
            })
            .map((subcat) => {
                console.log(subcat, 'subb')
                let subCategoryChanged = {}
                subCategoryChanged.show = subcat.description
                subCategoryChanged.value = data.diff_value ? subcat.value : subcat.id
                return subCategoryChanged
            })
        if (valueDrop.length) {
            return valueDrop
        } else {
            return valueDrop
        }
    }

    const subStateOptions = (data) => {
        let valueDrop = allStates
            .filter(
                (state) =>
                    (formik.values.country
                        ? formik.values.country.indexOf(state.countryCode) !== -1
                        : global.defaultCountry.indexOf(state.countryCode) !== -1) &&
                    global.ignoreStates.indexOf(state.isoCode) === -1,
            )
            .map((buscat) => {
                let busCategoryChanged = {}
                busCategoryChanged.show = buscat.name
                busCategoryChanged.value = buscat.isoCode
                return busCategoryChanged
            })
        return valueDrop
    }

    const subCityOptions = (data) => {
        let valueDrop = allCities
            .filter(
                (state) =>
                    (formik.values.country && formik.values.state
                        ? formik.values.country == state.countryCode &&
                          formik.values.state == state.stateCode
                        : global.defaultCountry.indexOf(state.countryCode) !== -1) &&
                    global.ignoreCity.indexOf(state.name) === -1,
            )
            .map((buscat) => {
                let busCategoryChanged = {}
                busCategoryChanged.show = buscat.name
                busCategoryChanged.value = buscat.name
                return busCategoryChanged
            })
        console.log(valueDrop, 'drop')
        return valueDrop
    }

    useEffect(() => {
        setArrayValue([])
        if (formFields.length) {
            let mapValueArray = formFields
            const index = null
            let { tempMapData, show } = initialFunction(
                mapValueArray,
                alldata_all_dropdown,
                subLevelOptions,
                allCountries,
                subStateOptions,
                subCityOptions,
                allLocations,
                index,
            )
            setArrayValue(tempMapData)
        }
        // setInitialValues({
        //     ...initialValues,
        //     companyname: user.companyname || '',
        //     companydesc: user.companydesc || '',
        //     uploadlogo: user.uploadlogo ? user.uploadlogo.split(',') : [],
        //     uploadbannerimg: user.uploadbannerimg ? user.uploadbannerimg.split(',') : [],
        //     ui_value: user.ui_value || '',
        //     company_dba: user.company_dba || '',
        // })
    }, [formFields])

    useEffect(() => {
        setValidationArray({})
        if (formFields.length) {
            let mapValueArray = formFields
            if (props.customValidation) {
                let handle = validationFunction(mapValueArray, props.customValidation, t)
                setValidationArray(handle)
            } else {
                let handle = validationFunction(mapValueArray, undefined, t)
                setValidationArray(handle)
            }
        }
    }, [formFields, props.customValidation])

    useEffect(() => {
        setValueLoaded(false)
        if (user) {
            if (Object.keys(user).length) {
                const index = null
                let objectPush = editValue(formFields, user, index)
                setInitialValues(objectPush)
                setTimeout(() => {
                    if (Object.keys(objectPush).length) {
                        setValueLoaded(true)
                    }
                }, 1000)
                console.log(objectPush, 'onnnnn')
            }
        }
    }, [user])

    useEffect(() => {
        if (props.additionalfieldUpdate) {
            props.additionalfieldUpdate(formik.values)
        }
    }, [formik.values])

    useEffect(() => {
        if (responseStatus) {
            if (responseStatus.from === 'updateProfile') {
                if (responseStatus.status === 'success') {
                    if (props.onSuccess) {
                        props.onSuccess()
                    }
                }
            } else if (responseStatus.from === 'saveExit') {
                if (responseStatus.status === 'success') {
                    if (props.onFormSaveExit) {
                        props.onFormSaveExit()
                    }
                }
            }
        }
    }, [responseStatus])

    return (
        <>
            {valueLoaded ? (
                <form onSubmit={formik.handleSubmit} autoComplete="nofill">
                    <div className="accChild">
                        {props.showHeadText ? (
                            <> {props.headText ? <h4>{props.headText}</h4> : <h4>Profile </h4>} </>
                        ) : null}
                        <div className="row">{mapData({ formik, data: arrayValue })}</div>
                    </div>
                    {props.customBtnGroup ? (
                        <div className="customBtnGroup">{props.customBtnGroup}</div>
                    ) : (
                        <>
                            <div className="profileAct">
                                {props.onFormBack ? (
                                    <SecondaryButton
                                        id="prev"
                                        onClick={props.onFormBack ? props.onFormBack : null}
                                        disabled={
                                            props.backBtnDisabled ? props.backBtnDisabled : null
                                        }
                                        label={props.BackBtnLabel ? props.BackBtnLabel : 'Prev'}
                                    />
                                ) : (
                                    ''
                                )}

                                {props.onFormSkip ? (
                                    <Button
                                        id="skip"
                                        className="btnSkip"
                                        onClick={props.onFormSkip}
                                    >
                                        {props.SkipBtnLabel ? props.SkipBtnLabel : 'Skip'}
                                    </Button>
                                ) : (
                                    ''
                                )}
                                {props.SaveExitBtnLabel ? (
                                    <SecondaryButton
                                        id="save_and_exit"
                                        className="btnSkip"
                                        onClick={() => {
                                            formik.handleSubmit()
                                            setSecondaryBtn(true)
                                        }}
                                        label={
                                            props.SaveExitBtnLabel
                                                ? props.SaveExitBtnLabel
                                                : 'Save & Exit'
                                        }
                                    />
                                ) : (
                                    ''
                                )}
                                <PrimaryButton
                                    id="profile_saveBtn"
                                    onClick={() => {
                                        formik.handleSubmit()
                                        setSecondaryBtn(false)
                                    }}
                                    disabled={
                                        props.successBtnDisabled ? props.successBtnDisabled : false
                                    }
                                    label={props.SubmitBtnLabel ? props.SubmitBtnLabel : 'Save'}
                                />
                            </div>
                        </>
                    )}

                    {props.addressDetails ? (
                        <AddressViewComponent validateAddress={props.validateAddress || false} />
                    ) : null}
                    {props.phoneVerify ? (
                        <PhoneValidationComponent
                            phoneVerify={phoneVerify}
                            formik={formik}
                            setPhoneVerify={setPhoneVerify}
                            changePhoneVerify={changePhoneVerify}
                        />
                    ) : null}
                </form>
            ) : null}
        </>
    )
}

export default ProfileComponent
