import React, { useContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import PrimaryButton from '@/product/components/atoms/PrimaryButton'
import AuthContext from '@/product/context/auth/authContext'
import { useFormik } from 'formik'
import { handleRedirectInternal, mapData } from '@/product/common/components'

const LoginComponent = (props) => {
    const history = useHistory()
    const authContext = useContext(AuthContext)

    const { login, responseStatus, clearResponse, isAuthenticated } = authContext

    // useEffect(() => {
    //     if (isAuthenticated) {
    //         handleRedirectInternal(history, '')
    //     }
    // }, [isAuthenticated])

    const formik = useFormik({
        initialValues: props.initialValues,
        validationSchema: props.validationSchema,
        onSubmit: (values) => {
            if (values.remember_me) {
                localStorage.email = values.email
                localStorage.password = values.password
                localStorage.remember_me = values.remember_me
            } else {
                delete localStorage.email
                delete localStorage.password
                delete localStorage.remember_me
            }

            login(values)
        },
    })

    const LoginForm = props.formFields ? { formik: formik, ...props.formFields } : {}

    const rememberMe = {
        formik: formik,
        data: [
            {
                label: 'Remember Me',
                name: 'remember_me',
                type: 'checkbox',
                placeholder: 'Remember Me',
                class: '',
            },
        ],
    }

    useEffect(() => {
        if (responseStatus) {
            if (responseStatus.from === 'login') {
                if (responseStatus.status === 'success') {
                    if (props.onSuccess) {
                        formik.resetForm()
                        props.onSuccess(responseStatus.data)
                    } else {
                        formik.resetForm()
                        handleRedirectInternal(history, 'search')
                    }
                }
            }
        }
    }, [responseStatus])

    useEffect(() => {
        if (props.additionalfieldUpdate) {
            props.additionalfieldUpdate(formik.values)
        }
    }, [formik.values])

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <div className="row">{mapData(LoginForm)}</div>
                <div className="d-flex loginActBox justify-content-between align-items-center">
                    {props.rememberMe ? mapData(rememberMe) : ''}
                </div>
                <div className="loginActCnt">
                    <PrimaryButton
                        id="login_btn"
                        label={props.SubmitBtnLabel ? props.SubmitBtnLabel : 'Login'}
                        type="submit"
                    />
                    {props.registerAccountLabel ? props.registerAccountLabel : null}
                </div>
            </form>
        </>
    )
}

export default LoginComponent
