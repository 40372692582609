import React, { useContext, useEffect } from 'react'
import PrimaryButton from '@/product/components/atoms/PrimaryButton'
import { handleRedirectInternal, mapData } from '@/product/common/components'
import { useFormik } from 'formik'
import { useHistory } from 'react-router-dom'
import UserContext from '@/product/context/user/userContext'

const ResetPasswordComponent = (props) => {
    const history = useHistory()
    const userContext = useContext(UserContext)
    const { resetPassword, responseStatus, clearResponse } = userContext

    const formik = useFormik({
        initialValues: props.initialValues,
        validationSchema: props.validationSchema,
        onSubmit: (values) => {
            resetPassword(values)
        },
    })

    const ResetPasswordForm = props.formFields ? { formik: formik, ...props.formFields } : {}

    useEffect(() => {
        if (responseStatus) {
            if (responseStatus.from === 'resetPassword') {
                if (responseStatus.status === 'success') {
                    if (props.onSuccess) {
                        props.onSuccess()
                    } else {
                        handleRedirectInternal(history, '')
                    }
                }
            }
        }
    }, [responseStatus])

    useEffect(() => {
        if (props.additionalFieldUpdate) {
            props.additionalFieldUpdate(formik.values)
        }
    }, [formik.values])

    return (
        <form onSubmit={formik.handleSubmit} autoComplete="nofill">
            {mapData(ResetPasswordForm)}
            <div className="col-12">
                <PrimaryButton
                    id="user_reset_password"
                    type="submit"
                    label={props.SubmitBtnLabel ? props.SubmitBtnLabel : 'change'}
                />
            </div>
        </form>
    )
}
export default ResetPasswordComponent
