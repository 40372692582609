import React, { useContext, useState, useEffect } from 'react'
import PrimaryButton from '../../../product/components/atoms/PrimaryButton'
import AuthContext from '../../../product/context/auth/authContext'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import Loaders from '../../../product/components/molecules/Loaders'
import CustomTable from '../../../product/components/molecules/CustomTable'
import CustomSearch from '../../../product/components/molecules/CustomSearch'
import SettingContext from '../../../product/context/seller/setting/settingContext'
import DashboardLayout from '../../Dashboard/DashboardLayout'
import CustomDialog from '../../../product/components/organisms/Dialog'
import { Button } from '@material-ui/core'
import LocationManage from './LocationManage'

const Location = () => {
    const settingcontext = useContext(SettingContext)
    const authContext = useContext(AuthContext)
    const { user, isAuthenticated } = authContext

    const {
        searchTableAction,
        searchTable,
        table_data,
        responseStatus: responseStatusSetting,
    } = settingcontext

    const [isLoading, setIsLoading] = useState(false)
    const [tableBody, setTableBody] = useState([])
    const [changeStatus, setChangeStatus] = useState(false)

    const changeChangeStatus = () => {
        setChangeStatus(!changeStatus)
    }

    const [manage, setManage] = useState({
        popup: false,
        status: 'new',
        id: 0,
    })

    const toggleFullScreenPopup = (popup, status, id) => {
        if (popup) {
            setManage({ popup, status, id })
        } else {
            setManage({ popup, status: 'new', id: 0 })
        }
    }

    const formikUsers = useFormik({
        initialValues: {
            page: 1,
            limit: 20,
            order: '',
            orderby: '',
            table: 'locations',
            filters: {
                name: {
                    value: '',
                    type: 'like',
                    field: 'l.name',
                },
                organization_id: {
                    value: '',
                    type: 'in',
                    field: 'l.organization_id',
                },
            },
        },
        onSubmit: (values) => {
            setIsLoading(true)
            searchTable(values, 'locations')
        },
    })

    useEffect(() => {
        if (table_data && table_data.records) {
            if (table_data.from === 'locations') {
                setTableBody(
                    table_data.records.length
                        ? table_data.records.filter((element) => {
                              return element.active == 1
                          })
                        : [],
                )
                setIsLoading(false)
            }
        }
    }, [table_data])

    useEffect(() => {
        if (isAuthenticated) {
            if (user) {
                setTableBody([])
                setIsLoading(true)
                formikUsers.values.page = 1
                formikUsers.values.filters.name.value = ''
                formikUsers.values.filters.organization_id.value = user.organization_id
                // formik.values.filters.companyname.value = ''
                // formik.values.filters.trakhees_reg_num.value = ''

                searchTable(formikUsers.values, 'locations')
                /*  return () => {
                    setTableBody([])
                }*/
            }
        }
    }, [user, isAuthenticated])

    const searchInfo = {
        formik: formikUsers,
        data: [
            {
                label: 'Location',
                placeholder: 'Enter Location',
                class: 'col-md-4 col-sm-6 col-12',
                type: 'text',
                name: 'name',
                filter: true,
            },
        ],
    }

    const onClickEdit = (id, secondaryID, type) => {
        toggleFullScreenPopup(true, 'edit', id)
    }

    const tableValues = [
        {
            field: 'id',
            type: '',
            firstChild: true,
            sort: true,
            disablePadding: false,
            label: 'ID',
        },
        {
            field: 'name',
            type: '',
            firstChild: true,
            sort: true,
            disablePadding: false,
            label: 'Name',
        },
        {
            field: 'active',
            condition: true,
            conditionValue: {
                formula: {
                    key1: 'active',
                    key2: 1,
                    notation: '===',
                },
                true: {
                    type: 'text',
                    value: 'Active',
                },
                false: {
                    type: 'text',
                    value: 'Deactive',
                },
            },
            type: 'text',
            sort: true,
            firstChild: false,
            disablePadding: false,
            label: 'Status',
        },
        {
            field: 'action',
            type: 'button',
            clickType: 'edit',
            onclick: onClickEdit,
            sort: false,
            firstChild: false,
            disablePadding: false,
            label: 'Action',
            tooltipTitle: 'Edit location',
        },
    ]

    const formikSelection = useFormik({
        initialValues: {
            action: '',
            active: 0,
            id: 0,
            table: 'locations',
        },
        onSubmit: (values) => {
            const dataValue = values
            if (
                dataValue.action === 'active' ||
                dataValue.action === 'deactive' ||
                dataValue.action === 'delete'
            ) {
                dataValue.active =
                    dataValue.action === 'delete' ? 2 : dataValue.action === 'active' ? 1 : 0
                delete dataValue.action
                searchTableAction(values)
            }
        },
    })

    const onSelectMultiProducts = (data, action) => {
        formikSelection.values.action = action
        formikSelection.values.id = data
        if (action === 'active' || action === 'deactive' || action === 'delete') {
            setChangeStatus(true)
        }
    }

    const tableActions = []
    tableActions.push(
        {
            label: 'Move to Active',
            icon: <span className="material-icons">check_circle</span>,
            onclick: onSelectMultiProducts,
            type: 'active',
        },
        {
            label: 'Move to Deactive',
            icon: <span className="material-icons">block</span>,
            onclick: onSelectMultiProducts,
            type: 'deactive',
        },
        {
            label: 'Delete Permanently',
            icon: <span className="material-icons">cancel</span>,
            onclick: onSelectMultiProducts,
            type: 'delete',
        },
    )

    useEffect(() => {
        if (responseStatusSetting) {
            console.log('responseStatusSetting', responseStatusSetting)
            if (
                responseStatusSetting.from === 'CommunicationSearchTable' &&
                responseStatusSetting.status === 'success'
            ) {
                setChangeStatus(false)
                toggleFullScreenPopup(false)
                setTableBody([])
                // searchTable(formikUsers.values, 'locations')
            }
        }
    }, [responseStatusSetting])

    useEffect(() => {
        if (user) {
            if (user && user.organization_id) {
                formikUsers.setFieldValue('filters.organization_id.value', user.organization_id)
            }
        }
    }, [user])

    return (
        <DashboardLayout>
            <div className="dashboardInner reportManagement">
                <div className="addUserModal">
                    <div className="dashboardInner userManagement mt-4">
                        <CustomSearch searchInfo={searchInfo} formik={formikUsers} />
                        <div className="d-flex justify-content-between align-items-center mt-4">
                            <Button
                                variant="outlined"
                                className="btnOutlined"
                                onClick={() => toggleFullScreenPopup(true, 'new', 0)}
                            >
                                <span className="material-icons">note_add</span>Add New Location
                            </Button>
                        </div>

                        <CustomTable
                            formik={formikUsers.values}
                            tableBody={tableBody}
                            tableValues={tableValues}
                            tableActions={tableActions}
                            allData={table_data}
                            isLoading={isLoading}
                            loadingTemplate={'table'}
                            changeTableFunction={searchTable}
                            fromVariable="locations"
                        />
                    </div>

                    <LocationManage toggleFullScreenPopup={toggleFullScreenPopup} manage={manage} />

                    <CustomDialog
                        title={'Change status'}
                        open={changeStatus}
                        function={changeChangeStatus}
                    >
                        <h5>
                            {formikSelection.values.action === 'deleted'
                                ? 'Are you sure you want to delete'
                                : 'Are you sure you want to change the status'}
                        </h5>
                        <div className="actionWrapper">
                            <Button onClick={() => changeChangeStatus()}>Cancel</Button>
                            <form onSubmit={formikSelection.handleSubmit} autoComplete="nofill">
                                <PrimaryButton type="submit" label="Confirm" />
                            </form>
                        </div>
                    </CustomDialog>
                </div>
            </div>
        </DashboardLayout>
    )
}

export default Location
