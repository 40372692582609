import React, { useState, useContext } from 'react'
import AuctionComponent from '../../../product/components/species/components/auction/AuctionComponent'
import { handleRedirectInternal } from '../../../product/common/components'
import CommonContext from '../../../custom/context/common/commonContext'
import { useHistory } from 'react-router-dom'
import PostTemplate from './PostTemplate'
import * as Yup from 'yup'
import './PostProject.css'

const AuctionSetting = (props) => {
    const history = useHistory()
    const { membership } = useContext(CommonContext)
    const [manage, setManage] = useState({
        popup: false,
        status: 'edit',
        id: props.match.params.id,
    })

    const validationArray = Yup.object({
        enable_proxy: Yup.boolean(),
        isname_visible: Yup.boolean(),
        isbidvalue_visible: Yup.boolean(),
        prebid_available: Yup.boolean(),
        enable_duplicate: Yup.boolean(),
        enable_non_winning: Yup.boolean(),
    })

    const [initialValues, setInitialValues] = useState({
        id: 0,
        enable_proxy: 0,
        isname_visible: 0,
        isbidvalue_visible: 0,
        prebid_available: 0,
        enable_duplicate: 0,
        enable_non_winning: 0,
    })

    const formFields = {
        data: [
            {
                title: 'Are the bidders allowed to see other bidders by name?',
                type: 'radio',
                name: 'isname_visible',
                class: 'col-12 mb-4',
                int: 1,
                item: [
                    { id: 1, description: 'Yes' },
                    { id: 0, description: 'No' },
                ],
            },
            {
                title: 'Are the bidders allowed to see other bid values?',
                type: 'radio',
                name: 'isbidvalue_visible',
                class: 'col-12 mb-4',
                disabled: false,
                int: 1,
                item: [
                    { id: 1, description: 'Yes' },
                    { id: 0, description: 'No' },
                ],
            },
            {
                title: 'Are the bidders allowed to pre-bid an auction?',
                type: 'radio',
                disabled: membership?.pre_bid ? false : true,
                name: 'prebid_available',
                class: 'col-12',
                int: 1,
                item: [
                    { id: 1, description: 'Yes' },
                    { id: 0, description: 'No' },
                ],
            },
            {
                title: 'Are the bidders allowed to proxy bid?',
                type: 'radio',
                disabled: membership?.proxy_bid ? false : true,
                name: 'enable_proxy',
                class: 'col-12',
                int: 1,
                item: [
                    { id: 1, description: 'Yes' },
                    { id: 0, description: 'No' },
                ],
            },
            {
                title: 'Allow the duplicate bid/non-winning bid',
                type: 'radio',
                disabled: membership?.duplicate_bid ? false : true,
                name: 'enable_duplicate',
                class: 'col-12',
                int: 1,
                item: [
                    { id: 1, description: 'Yes' },
                    { id: 0, description: 'No' },
                ],
            },
            {
                title: 'Are the bidders allowed to non winning bid?',
                type: 'radio',
                disabled: membership?.non_winning_bid ? false : true,
                name: 'enable_non_winning',
                class: 'col-12',
                int: 1,
                item: [
                    { id: 1, description: 'Yes' },
                    { id: 0, description: 'No' },
                ],
            },
        ],
    }

    const handleSingleAuctionEdit = (single_auction) => {
        if (single_auction) {
            if (single_auction.record && manage.status === 'edit' && manage.id > 0) {
                const auction = single_auction.record
                setInitialValues({
                    id: manage.id,
                    enable_proxy: auction.enable_proxy ? auction.enable_proxy : 0,
                    isname_visible: auction.isname_visible ? auction.isname_visible : 0,
                    isbidvalue_visible: auction.isbidvalue_visible ? auction.isbidvalue_visible : 0,
                    prebid_available: auction.prebid_available ? auction.prebid_available : 0,
                    enable_duplicate: auction.enable_duplicate ? auction.enable_duplicate : 0,
                    enable_non_winning: auction.enable_non_winning ? auction.enable_non_winning : 0,
                })
            }
        }
    }
    const handleBack = () => {
        handleRedirectInternal(history, `post_project/create_lot/${manage.id}`)
    }

    const handleNext = () => {
        handleRedirectInternal(history, `post_project/bid_increment/${manage.id}`)
    }

    return (
        <PostTemplate stepCount={3}>
            <div className="stpInrDiv">
                <h4>Enter your auction settings</h4>
                <AuctionComponent
                    validationArray={validationArray}
                    initialValues={initialValues}
                    handleSingleAuctionEdit={handleSingleAuctionEdit}
                    data={manage}
                    formFields={formFields}
                    onSuccess={handleNext}
                    onFormBack={handleBack}
                    SubmitBtnLabel="Next"
                    BackBtnLabel="Previous"
                />
            </div>
        </PostTemplate>
    )
}

export default AuctionSetting
