import React, { useContext, useState, useEffect } from 'react'
import './index.css'
import CommonContext from '../../../../product/context/common/commonContext'
import { Pagination } from '@material-ui/lab'
import ProductContext from '../../../../product/context/product/productContext'
import AuthContext from '../../../../product/context/auth/authContext'
import {
    currencyFormat,
    dateTimeFormatFunction,
    maskEmailFront,
} from '../../../../custom/common/components'

const BidHistoryComp = (props) => {
    let [viewProduct, setViewProduct] = useState([])

    const commonContext = useContext(CommonContext)
    const productContext = useContext(ProductContext)
    const authContext = useContext(AuthContext)
    const { bidHistoryValue, clearBidHistoryValue } = commonContext
    const { search_allbidhistory, getAllBidHistory } = productContext
    const { user, isAuthenticated } = authContext
    const [showValue, setShowValue] = useState(true)
    const [showName, setShowName] = useState(true)

    const [search, setSearch] = useState({
        limit: 10,
        page: 1,
        id: 0,
        orderby: 'asc',
        filters: {
            bidValues: {
                value: '0',
                type: 'in',
                field: 'b.declined',
            },
        },
    })

    useEffect(() => {
        if (props.id) {
            setSearch({ ...search, id: props.id, page: 1 })
        }
    }, [props.id])

    useEffect(() => {
        if (props.hideName) {
            setShowName(false)
        }
    }, [props.hideName])

    useEffect(() => {
        if (props.hideValue) {
            setShowValue(false)
        }
    }, [props.hideValue])

    console.log('showName', props.displayName, showName)
    console.log('showValue', showValue)
    console.log('props.refreshData', props.refreshData)

    useEffect(() => {
        if (search.id !== 0) {
            getAllBidHistory(search)
        }
    }, [search, props.refreshData])

    const onHandlePage = (event, value) => {
        setSearch({ ...search, page: value })
    }

    useEffect(() => {
        setViewProduct(search_allbidhistory.records.length ? search_allbidhistory.records : [])
    }, [search_allbidhistory])

    return (
        <div className="bidHistoryTable">
            <table className="table table-bordered">
                {!props.noHeader && (
                    <thead>
                        <tr>
                            <th scope="col">{'ID'}</th>
                            <th scope="col">{'Bidder Name'}</th>
                            <th scope="col">{'Current Bid'}</th>
                            {/* <th scope="col">{'Date'}</th> */}
                        </tr>
                    </thead>
                )}
                <tbody>
                    <>
                        {viewProduct.length > 0 ? (
                            <>
                                {viewProduct.map((data, index) => (
                                    <>
                                        <tr>
                                            <td>
                                                {index === 0 && (
                                                    <span className="material-icons badgeIcon">
                                                        military_tech
                                                    </span>
                                                )}
                                                {index + 1}
                                            </td>
                                            <td>
                                                {
                                                    Number(data.user_id) === Number(user.id)
                                                        ? `${user?.first_name || ''} ${
                                                              user?.last_name || ''
                                                          }`
                                                        : `**** ****`
                                                    // : showName
                                                    // ? data.first_name + ' ' + data.last_name
                                                }
                                            </td>
                                            <td className="bidAmountCol">
                                                {Number(data.user_id) === Number(user.id)
                                                    ? currencyFormat(data.proposed_amount)
                                                    : showValue
                                                    ? currencyFormat(data.proposed_amount)
                                                    : `***`}
                                            </td>
                                        </tr>
                                    </>
                                ))}
                            </>
                        ) : (
                            <>
                                <tr>
                                    <td className="text-center" colSpan={3}>
                                        No Bids Found!
                                    </td>
                                </tr>
                            </>
                        )}
                    </>
                </tbody>
            </table>
            {!props.noPagination && (
                <>
                    {viewProduct.length > 0 && (
                        <div className="d-flex justify-content-between align-items-center flex-wrap w-100 mt-5 mb-3 pagination-wrapper">
                            <h6>
                                {'Showing'}&nbsp; {search_allbidhistory.setDisp}{' '}
                                {search_allbidhistory.totalRecords}
                            </h6>
                            <Pagination
                                count={Math.ceil(search_allbidhistory.totalRecords / search.limit)}
                                page={search.page}
                                onChange={onHandlePage}
                                siblingCount={3}
                                showFirstButton
                                showLastButton
                                boundaryCount={2}
                            />
                        </div>
                    )}
                </>
            )}
        </div>
    )
}

export default BidHistoryComp
