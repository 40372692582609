import React, { useContext, useState, useEffect } from 'react'
import { Divider, ListItem } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { NavLink } from 'react-router-dom'
import './Dashboard.css'
import { makeStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import Collapse from '@material-ui/core/Collapse'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import { handleRedirectInternal } from '../../product/common/components'
import AuthContext from '../../product/context/auth/authContext'
const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
}))

const SideNav = () => {
    const classes = useStyles()
    const history = useHistory()
    const authContext = useContext(AuthContext)
    const { user } = authContext
    const [expanded, setExpanded] = useState('')

    const handleChange = (panel) => {
        if (expanded === panel) {
            setExpanded('')
        } else {
            setExpanded(panel)
        }
    }

    useEffect(() => {
        if (location.pathname.split('/')[1]) {
            let path = location.pathname.split('/')[1]
            if (path === 'seller') {
                path = path + '/' + location.pathname.split('/')[2]
            }
            setExpanded(path)
        }
    }, [location.pathname])

    return (
        <div className="sideNav">
            <ul>
                <ListItem button onClick={() => handleChange('account')}>
                    <div
                        className={`d-flex justify-content-between align-items-center w-100 test ${
                            expanded === 'account' && 'activeDropdown'
                        }`}
                    >
                        <span className="d-flex">
                            <span className="material-icons">person</span>
                            My Account
                        </span>
                        {expanded === 'account' ? <ExpandLess /> : <ExpandMore />}
                    </div>
                </ListItem>

                <Collapse in={expanded === 'account'} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItem button>
                            <NavLink activeClassName="active" to="/account/profile">
                                Profile
                            </NavLink>
                        </ListItem>
                        <ListItem button>
                            <NavLink activeClassName="active" to="/account/change">
                                Change Password
                            </NavLink>
                        </ListItem>
                        {!user?.admin ? (
                            <ListItem button>
                                <NavLink activeClassName="active" to="/account/notifications">
                                    My Notifications
                                </NavLink>
                            </ListItem>
                        ) : null}
                    </List>
                </Collapse>

                {user?.admin ? (
                    <>
                        <ListItem button onClick={() => handleChange('seller/auctions')}>
                            <div
                                className={`d-flex justify-content-between align-items-center w-100 test ${
                                    expanded === 'seller/auctions' && 'activeDropdown'
                                }`}
                            >
                                <span className="d-flex">
                                    <span className="material-icons">gavel</span>
                                    My Auctions
                                </span>
                                {expanded === 'seller/auctions' ? <ExpandLess /> : <ExpandMore />}
                            </div>
                        </ListItem>

                        <Collapse in={expanded === 'seller/auctions'} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <ListItem button>
                                    <NavLink
                                        activeClassName="active"
                                        to="/seller/auctions/upcoming"
                                    >
                                        Upcoming
                                    </NavLink>
                                </ListItem>

                                <ListItem button>
                                    <NavLink activeClassName="active" to="/seller/auctions/open">
                                        Active
                                    </NavLink>
                                </ListItem>

                                <ListItem button>
                                    <NavLink activeClassName="active" to="/seller/auctions/closed">
                                        Past
                                    </NavLink>
                                </ListItem>

                                {/* <ListItem button>
                                    <NavLink activeClassName="active" to="/seller/auctions/unsold">
                                        Unsold
                                    </NavLink>
                                </ListItem> */}
                            </List>
                        </Collapse>
                        <ListItem button>
                            <NavLink activeClassName="active" to="/seller/templates">
                                <span className="material-icons">table_chart</span>
                                Templates
                            </NavLink>
                        </ListItem>
                        <ListItem button>
                            <NavLink activeClassName="active" to="/seller/organization">
                                <span className="material-icons">corporate_fare</span>
                                Organization
                            </NavLink>
                        </ListItem>

                        <ListItem button>
                            <NavLink activeClassName="active" to="/seller/reports">
                                <span className="material-icons">description</span>
                                Reports
                            </NavLink>
                        </ListItem>

                        <ListItem button onClick={() => handleChange('seller/payments')}>
                            <div
                                className={`d-flex justify-content-between align-items-center w-100 test ${
                                    expanded === 'seller/payments' && 'activeDropdown'
                                }`}
                            >
                                <span className="d-flex">
                                    <span className="material-icons">credit_card</span>
                                    My Payments
                                </span>
                                {expanded === 'seller/payments' ? <ExpandLess /> : <ExpandMore />}
                            </div>
                        </ListItem>

                        <Collapse in={expanded === 'seller/payments'} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                {/* <ListItem button>
                                    <NavLink activeClassName="active" to="/payments/transactions">
                                        Transactions
                                    </NavLink>
                                </ListItem> */}

                                <ListItem button>
                                    <NavLink
                                        activeClassName="active"
                                        to="/seller/payments/saved_cards"
                                    >
                                        Card Details
                                    </NavLink>
                                </ListItem>

                                <ListItem button>
                                    <NavLink
                                        activeClassName="active"
                                        to="/seller/payments/bank_details"
                                    >
                                        Bank Details
                                    </NavLink>
                                </ListItem>

                                <ListItem button>
                                    <NavLink
                                        activeClassName="active"
                                        to="/seller/payments/subscription"
                                    >
                                        My Subscription
                                    </NavLink>
                                </ListItem>
                            </List>
                        </Collapse>
                        <ListItem button onClick={() => handleChange('seller/setting')}>
                            <div
                                className={`d-flex justify-content-between align-items-center w-100 test ${
                                    expanded === 'seller/setting' && 'activeDropdown'
                                }`}
                            >
                                <span className="d-flex">
                                    <span className="material-icons">settings</span>
                                    Settings
                                </span>
                                {expanded === 'seller/setting' ? <ExpandLess /> : <ExpandMore />}
                            </div>
                        </ListItem>

                        <Collapse in={expanded === 'seller/setting'} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                {/* <ListItem button>
                                    <NavLink activeClassName="active" to="/payments/transactions">
                                        Transactions
                                    </NavLink>
                                </ListItem> */}

                                <ListItem button>
                                    <NavLink activeClassName="active" to="/seller/setting/location">
                                        Location
                                    </NavLink>
                                </ListItem>
                            </List>
                        </Collapse>
                    </>
                ) : (
                    <>
                        <ListItem button onClick={() => handleChange('auctions')}>
                            <div
                                className={`d-flex justify-content-between align-items-center w-100 test ${
                                    expanded === 'auctions' && 'activeDropdown'
                                }`}
                            >
                                <span className="d-flex">
                                    <span className="material-icons">gavel</span>
                                    {'My Bids'}
                                </span>
                                {expanded === 'auctions' ? <ExpandLess /> : <ExpandMore />}
                            </div>
                        </ListItem>

                        <Collapse in={expanded === 'auctions'} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <ListItem button>
                                    <NavLink activeClassName="active" to="/auctions/all">
                                        All
                                    </NavLink>
                                </ListItem>

                                <ListItem button>
                                    <NavLink activeClassName="active" to="/auctions/winning">
                                        Winning
                                    </NavLink>
                                </ListItem>

                                <ListItem button>
                                    <NavLink activeClassName="active" to="/auctions/outbid">
                                        Currently Outbid
                                    </NavLink>
                                </ListItem>
                                <ListItem button>
                                    <NavLink activeClassName="active" to="/auctions/won">
                                        Won
                                    </NavLink>
                                </ListItem>
                                {/* <ListItem button>
                                    <NavLink activeClassName="active" to="/auctions/lost">
                                        Lost
                                    </NavLink>
                                </ListItem> */}
                            </List>
                        </Collapse>

                        {/* <ListItem button>
                            <NavLink activeClassName="active" to="/favourites/watchlist">
                                <span className="material-icons">visibility</span>
                                My Watchlist
                            </NavLink>
                        </ListItem> */}
                        <ListItem button>
                            <NavLink activeClassName="active" to="/follows">
                                <span className="d-flex">
                                    <span className="material-icons">favorite</span>
                                    My Favourites
                                </span>
                            </NavLink>
                        </ListItem>

                        {/* <ListItem button>
                            <NavLink activeClassName="active" to="/past_auctions">
                                <span className="d-flex">
                                    <span className="material-icons">history</span>
                                    Past Auctions
                                </span>
                            </NavLink>
                        </ListItem> */}

                        {/* <ListItem button>
                            <NavLink activeClassName="active" to="/buyer/invites">
                                <span className="material-icons">bookmark</span> Invited Auctions
                            </NavLink>
                        </ListItem> */}
                    </>
                )}

                {/* <ListItem button>
                    <NavLink activeClassName="active" to="/wallet">
                        <span className="material-icons">account_balance_wallet</span>
                        {t('my_wallet')}
                    </NavLink>
                </ListItem> */}

                {/* <ListItem button>
                    <NavLink activeClassName="active" to="/transactions">
                        <span className="material-icons">account_balance_wallet</span>
                        {t('my_transactions')}
                    </NavLink>
                </ListItem> */}

                {/* <ListItem button onClick={() => handleChange('invoices')}>
                    <div
                        className={`d-flex justify-content-between align-items-center w-100 test ${
                            expanded === 'invoices' && 'activeDropdown'
                        }`}
                    >
                        <span className="d-flex">
                            <span className="material-icons">receipt</span>
                            {'My Invoices'}
                        </span>
                        {expanded === 'invoices' ? <ExpandLess /> : <ExpandMore />}
                    </div>
                </ListItem>

                <Collapse in={expanded === 'invoices'} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItem button>
                            <NavLink activeClassName="active" to="/invoices/all">
                                All
                            </NavLink>
                        </ListItem>

                        <ListItem button>
                            <NavLink activeClassName="active" to="/invoices/paid">
                                Paid
                            </NavLink>
                        </ListItem>

                        <ListItem button>
                            <NavLink activeClassName="active" to="/invoices/partial">
                                Unpaid
                            </NavLink>
                        </ListItem>
                    </List>
                </Collapse> */}
            </ul>
        </div>
    )
}

export default SideNav
