import React, { useState, useContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { scrollTop } from '../../product/common/components'
import CommonContext from '../../product/context/common/commonContext'
import './StaticPages.css'

const Static = (props) => {
    const commonContext = useContext(CommonContext)

    const { static_page, getStaticPage } = commonContext
    let [viewStatic, setviewStatic] = useState([])

    const [search, setSearch] = useState({
        id: 0,
    })

    useEffect(() => {
        if (props.location.pathname === '/about') {
            setSearch({ id: 'about' })
        } else if (props.location.pathname === '/services') {
            setSearch({ id: 'services' })
        } else if (props.location.pathname === '/help') {
            setSearch({ id: 'help' })
        } else if (props.location.pathname === '/guidelines') {
            setSearch({ id: 'guidelines' })
        } else if (props.location.pathname === '/privacy_policy') {
            setSearch({ id: 'privacy_policy' })
        } else if (props.location.pathname === '/terms_of_use') {
            setSearch({ id: 'terms_of_use' })
        } else if (props.location.pathname === '/support') {
            setSearch({ id: 'support' })
        }

        scrollTop()
    }, [props.location.pathname])

    useEffect(() => {
        if (search.id !== 0) {
            getStaticPage(search)
        }
    }, [search])

    useEffect(() => {
        setviewStatic(static_page.record ? static_page.record : null)
    }, [static_page])

    return (
        <div className="staticPages">
            <div
                className={`container ${
                    window.location.pathname.includes('/privacy_policy') ? 'privacyWrapper' : ''
                }`}
            >
                <div className="d-flex justify-content-center align-items-start">
                    {viewStatic.content_type === 'file' ? (
                        <object>
                            <embed
                                src={`${global.site_url}/uploads/static_files/${viewStatic.file_path}`}
                                type="application/pdf"
                            ></embed>
                        </object>
                    ) : (
                        <div
                            className="stacPgInfoWr"
                            dangerouslySetInnerHTML={{
                                __html: viewStatic.content,
                            }}
                        ></div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default Static
