import React, { useState, useRef, useEffect, useContext } from 'react'
import { Button, Fade, Tooltip } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import CustomInput from '../../../../product/components/atoms/Inputs/CustomInput'
import PrimaryButton from '../../../../product/components/atoms/PrimaryButton'
import SecondaryButton from '../../../../product/components/atoms/SecondaryButton'
import './ProductCard.css'
import FavouriteCheckbox from '../../../../product/components/atoms/FavoriteCheckbox'
// import Popup from '../../../../custom/organisms/Popup'
import Timer from '../../../../product/common/timer'
import Buynow from '../Buynow/BuynowItem'
import { dateFormatFront } from '../../../common/components'
import { google, outlook, office365, yahoo, ics } from 'calendar-link'
import { handleRedirectInternal, currencyFormat } from '../../../common/components'
import CommonContext from '../../../../product/context/common/commonContext'
import CustomCommonContext from '../../../../custom/context/common/commonContext'
import AuthContext from '../../../../product/context/auth/authContext'
import ReverseBidding from '../Reverse/Bidding/BiddingItem'
import PreBidding from '../Reverse/PreBidding/PreBiddingItem'
import { useTranslation } from 'react-i18next'
import UpdatePhoneNumber from './../../organisms/UpdatePhoneNumber/index'
import alertContext from '../../../../product/context/alert/alertContext'
import AvatarImage from '../../atoms/AvatarImage'
import InfoIcon from '@material-ui/icons/Info'

const ListView = (props) => {
    const [addCard, setAddCard] = React.useState(false)
    const product = props.data
    const auction = props.auction ? props.auction : null
    const commonContext = useContext(CommonContext)
    const authContext = useContext(AuthContext)
    const customCommonContext = useContext(CustomCommonContext)

    const { t } = useTranslation()
    const { user, isAuthenticated } = authContext
    const { setBidHistoryValue } = commonContext
    const { setAlert } = useContext(alertContext)
    const { configVariables, allLocations, allCondition } = customCommonContext
    const [event, setEvent] = useState(null)
    const [manageuser, setManageUser] = useState({
        popup: false,
        status: 'new',
        data: null,
    })
    const onMakePaymentClick = (data) => {
        if (user.phone == null) {
            toggleFullScreenUserPopup(true, 'new', null)
            setAlert('Please enter phone number', 'error')
        } else {
            handleRedirectInternal(history, `checkout/auction/${data.group_carts}`)
        }
    }
    const history = useHistory()
    // const viewProduct = props.viewProduct
    const toggleFullScreenUserPopup = (popup, status, data) => {
        setManageUser({ popup, status, data })
    }
    const toggleModal = (state, action) => {
        setAddCard(!addCard)
    }
    const addDefaultSrc = (e) => {
        e.target.src = '/assets/images/noimage.png'
    }

    const didUserBid = (userID, bidHistory) => {
        let didBid = false
        bidHistory.forEach((element, index, array) => {
            if (element.user_id === userID) {
                didBid = true
            }
        })
        return didBid
    }
    console.log(product, 'prod')
    console.log('auction', auction)

    useEffect(() => {
        if (Object.keys(product).length) {
            setEvent({
                title: product.title,
                description: product.short_desc,
                start: product.slot_start_time,
                end: product.slot_end_time,
                url: window.location.href,
            })
        }
    }, [product])

    return (
        <Fade in={true} timeout={600}>
            <div className="productCardList d-flex justify-content-between align-items-start">
                <div className="psListLt d-flex">
                    {/* <div className="pclImg">
                        {product.market_status === 'open' && (
                            <FavouriteCheckbox
                                watchlisted={product.watchlistid}
                                project_id={product.id}
                            />
                        )}
                    </div> */}

                    <div className="listContent">
                        <h2
                            // onClick={() =>
                            //     handleRedirectInternal(history, `productView/${product.id}`)
                            // }
                            className="listProdTitle"
                        >
                            {product.title}
                        </h2>
                        <div className="allTmrWrp d-flex align-items-center">
                            <p>
                                {dateFormatFront(product.slot_start_time)} -{' '}
                                {dateFormatFront(product.slot_end_time)}
                            </p>
                            {product.market_status === 'open' ? (
                                <div
                                    className={`listTimer d-flex justify-content-center align-items-center flex-wrap ${
                                        product?.future_active == 1
                                            ? 'upcomingSlot'
                                            : product?.future_active == 0
                                            ? 'activeSlot'
                                            : product?.market_status == 'closed'
                                            ? 'closedSlot'
                                            : ''
                                    }`}
                                >
                                    <span className="material-icons">schedule</span>
                                    <h4>
                                        <Timer
                                            date_added={product.date_added}
                                            date_closed={product.date_closed}
                                            daysText={' Days '}
                                            hoursText={' Hours '}
                                            minutesText={' Minutes '}
                                            secondsText={' Seconds '}
                                            withText={0}
                                            endText={'Time left' + ':'}
                                            startText={'Starts in' + ':'}
                                        ></Timer>
                                    </h4>
                                </div>
                            ) : (
                                <div className="listTimer d-flex justify-content-center align-items-center flex-wrap">
                                    <span className="material-icons">schedule</span>
                                    <label>Auction Closed:</label>
                                    <h4>{dateFormatFront(product.date_closed)}</h4>
                                </div>
                            )}
                        </div>
                        {product.market_status === 'open' ? (
                            <>
                                {
                                    <div className="listBidInfo d-flex justify-content-start align-items-center">
                                        <h6 className="txtLabel">{product.cbidtext}:</h6>
                                        <p className="d-flex justify-content-between align-items-center">
                                            <span className="bpriceValue">
                                                {currencyFormat(product.wprice)}
                                            </span>
                                            <span
                                                className="cursorDecoy vwBdHtr"
                                                onClick={() =>
                                                    setBidHistoryValue({
                                                        id: product.id,
                                                        proxy: true,
                                                        displayName:
                                                            auction?.isname_visible === 1
                                                                ? true
                                                                : false,
                                                        displayValue:
                                                            auction?.isbidvalue_visible === 1
                                                                ? true
                                                                : false,
                                                    })
                                                }
                                            >
                                                View Bid History
                                            </span>
                                        </p>
                                    </div>
                                }
                            </>
                        ) : (
                            <div className="listBidInfo d-flex justify-content-start align-items-center">
                                <h6>{product.cbidtext}:</h6>
                                <p className="d-flex justify-content-between align-items-center">
                                    <span>{currencyFormat(product.buynowamount)}</span>
                                    {product.market_status === 'open' ? (
                                        <>
                                            <span className="listDivider">|</span>
                                            <span
                                                className="cursorDecoy"
                                                onClick={() =>
                                                    setBidHistoryValue({
                                                        id: product.id,
                                                        proxy: false,
                                                        displayName:
                                                            auction?.isname_visible === 1
                                                                ? true
                                                                : false,
                                                        displayValue:
                                                            auction?.isbidvalue_visible === 1
                                                                ? true
                                                                : false,
                                                    })
                                                }
                                            >
                                                {product.bid_count} {'Bids'}
                                            </span>
                                        </>
                                    ) : null}
                                </p>
                            </div>
                        )}
                    </div>
                </div>

                <div className="listActBtn">
                    <div className="listBidStatus">
                        {isAuthenticated && (
                            <>
                                {product.bidtopstatus === 'outbid' ? (
                                    <h4 className="productWinningStatus outbid">
                                        {'Your rank in bidders: ' + product.rank}
                                    </h4>
                                ) : null}
                                {product.bidtopstatus === 'winner' ? (
                                    product.rprice >= product.current_bid ? (
                                        <h4 className="productWinningStatus winning">
                                            {'Your rank in bidders: ' + product.rank}
                                        </h4>
                                    ) : (
                                        <h4 className="productWinningStatus winning">
                                            {'Your rank in bidders: ' + product.rank}
                                            <span>
                                                <br />
                                                But Reserve price not met!
                                            </span>
                                        </h4>
                                    )
                                ) : null}

                                {product.bidtopstatus === 'won' ? (
                                    <h4 className="productWinningStatus won">Won</h4>
                                ) : null}
                                {product.bidtopstatus === 'lost' ? (
                                    <h4 className="productWinningStatus lost">Lost</h4>
                                ) : null}
                            </>
                        )}
                    </div>
                    {props.from === 'dashboard' && product.bidtopstatus === 'lost' ? (
                        <PrimaryButton
                            label="View"
                            onClick={() =>
                                handleRedirectInternal(history, `liveAuction/${product.auctionid}`)
                            }
                        />
                    ) : null}

                    {props.from === 'dashboard' &&
                    (props.action === 'won' || props.action === 'buynow') &&
                    product.buynowed &&
                    product.buynowed === user.id ? (
                        <div className="actionAfterWin">
                            {parseInt(product.buynowpaid, 10) === 1 ? (
                                <>
                                    <SecondaryButton
                                        label={'View Invoice'}
                                        btnSize="small"
                                        onClick={() =>
                                            handleRedirectInternal(
                                                history,
                                                `invoice/${product.common_invoice}`,
                                            )
                                        }
                                    />
                                </>
                            ) : (
                                <>
                                    {/* <PrimaryButton
                                        label={'Make Payment '}
                                        btnSize="small"
                                        onClick={() => onMakePaymentClick(product)}
                                    /> */}
                                </>
                            )}
                        </div>
                    ) : product.market_status === 'open' && !product.future_active ? (
                        <>
                            <div className="listBidBox">
                                <div className="d-flex align-items-start">
                                    <ReverseBidding
                                        data={product}
                                        type={'hard'}
                                        accept_rejected={product.enable_duplicate}
                                        accept_non_winning={
                                            product.custom_auctionlot_enable_non_winning
                                        }
                                    />
                                    <span className="pt-2 tlTipWpr">
                                        <Tooltip
                                            title={
                                                'Fixed Single Bid - Place your bid on the current price.'
                                            }
                                            placement="top"
                                        >
                                            <InfoIcon className="ml-2" />
                                        </Tooltip>
                                    </span>
                                </div>
                                {auction && auction.enable_proxy ? (
                                    <div className="d-flex align-items-start">
                                        <ReverseBidding
                                            data={product}
                                            type={'proxy'}
                                            accept_rejected={product.enable_duplicate}
                                            accept_non_winning={
                                                product.custom_auctionlot_enable_non_winning
                                            }
                                        />
                                        <span className="pt-2 tlTipWpr">
                                            <Tooltip
                                                title="Proxy Bid - System will automatically bid for you at the bid
                                        increment when others outbid you down to your proxy bid
                                        value. If outbid, you will be notified, if you allow
                                        notifications in settings."
                                                placement="top"
                                            >
                                                <InfoIcon className="ml-2" />
                                            </Tooltip>
                                        </span>
                                    </div>
                                ) : null}
                            </div>
                        </>
                    ) : product.market_status === 'open' &&
                      product.future_active &&
                      auction?.prebid_available ? (
                        <>
                            <PreBidding data={product} type={'hard'} showMessage={true} />
                            {auction && auction.enable_proxy ? (
                                <PreBidding data={product} type={'proxy'} />
                            ) : null}
                        </>
                    ) : product.buynowed && product.buynowed === user.id ? (
                        <div className="actionAfterWin">
                            {parseInt(product.buynowpaid, 10) === 1 ? (
                                <>
                                    <SecondaryButton
                                        label={'View Invoice'}
                                        btnSize="small"
                                        onClick={() =>
                                            handleRedirectInternal(
                                                history,
                                                `invoice/${product.common_invoice}`,
                                            )
                                        }
                                    />
                                </>
                            ) : (
                                <>
                                    {/* <PrimaryButton
                                        label={'Make Payment'}
                                        btnSize="small"
                                        onClick={() => onMakePaymentClick(product)}
                                    /> */}
                                    {event ? (
                                        <div className="adCaledarDiv d-flex flex-wrap align-items-center justify-content-center">
                                            <label className="m-0 mb-2">
                                                <small>Add to Calendar:</small>
                                            </label>
                                            <div className="d-flex align-items-center">
                                                <a
                                                    href={google(event)}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <Tooltip title="Google Calendar">
                                                        <img src="/assets/svg/google_calendar.svg" />
                                                    </Tooltip>
                                                </a>
                                                <a
                                                    href={outlook(event)}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <Tooltip title="Outlook">
                                                        <img src="/assets/svg/outlook.svg" />
                                                    </Tooltip>
                                                </a>
                                                <a
                                                    href={office365(event)}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <Tooltip title="Office 365">
                                                        <img src="/assets/svg/office.svg" />
                                                    </Tooltip>
                                                </a>
                                                <a
                                                    href={yahoo(event)}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <Tooltip title="Yahoo">
                                                        <img src="/assets/svg/yahoo.svg" />
                                                    </Tooltip>
                                                </a>
                                                <a
                                                    href={ics(event)}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <Tooltip title="iCalendar">
                                                        <img src="/assets/images/iCalendar.png" />
                                                    </Tooltip>
                                                </a>
                                            </div>
                                        </div>
                                    ) : null}
                                </>
                            )}
                        </div>
                    ) : null}
                </div>
                <div className="PaymentModal">
                    <UpdatePhoneNumber data={manageuser} function={toggleFullScreenUserPopup} />
                </div>
            </div>
        </Fade>
    )
}
export default ListView
