import Skyflow from 'skyflow-js'
import axios from 'axios'

const skyflowClient = Skyflow.init({
    vaultID: 'b427787ffcfa416faa1a5e15152d8616',
    vaultURL: 'https://e3540c874ee5.vault.skyflowapis.com',
    getBearerToken: () => {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await axios.get(
                    `${process.env.REACT_APP_DOMAIN}` + '/api/skyflow/generateToken',
                )
                resolve(res.data.accessToken)
            } catch (err) {
                console.log(err)
                reject('Cannot get token')
            }
        })
    },
    options: {
        logLevel: Skyflow.LogLevel.ERROR,
        env: Skyflow.Env.DEV,
    },
})

export default skyflowClient
