import React, { useState, useContext, useEffect } from 'react'
import FullScreenPopup from '../../../custom/components/organisms/FullScreenPopup'
import PrimaryButton from '../../../product/components/atoms/PrimaryButton'
import { handleRedirectInternal } from '../../../product/common/components'
import CommonContext from '../../../custom/context/common/commonContext'
import TemplateList from '../../Seller/Template/Auction/TemplateList'
import { useHistory } from 'react-router-dom'
import PostTemplate from './PostTemplate'
import { useFormik } from 'formik'
import './PostProject.css'
import * as Yup from 'yup'
import FormDialog from '../../../product/components/organisms/Dialog'
import SecondaryButton from '../../../product/components/atoms/SecondaryButton'
import AuthContext from '../../../product/context/auth/authContext'
import SettingContext from '../../../product/context/seller/setting/settingContext'

const AuctionType = () => {
    const history = useHistory()
    const { membership } = useContext(CommonContext)
    const authContext = useContext(AuthContext)
    const settingContext = useContext(SettingContext)

    const { user, isAuthenticated } = authContext
    const { table_data, searchTable } = settingContext

    const [templatePopup, setTemplatePopup] = useState(false)
    const [subPopup, setSubPopup] = useState(false)

    const validationArray = Yup.object({
        type: Yup.string().required('required'),
    })

    const formik = useFormik({
        initialValues: {
            type: '',
        },
        validationSchema: validationArray,
        onSubmit: (values) => {
            if (values.type === 'scratch') {
                handleRedirectInternal(history, 'post_project/auction_detail/new')
            } else {
                setTemplatePopup(true)
            }
        },
    })

    useEffect(() => {
        if (isAuthenticated) {
            if (user) {
                let formikSearchTable = {
                    page: 1,
                    limit: 10000,
                    order: '',
                    orderby: '',
                    table: 'locations',
                    filters: {
                        name: {
                            value: '',
                            type: 'like',
                            field: 'l.name',
                        },
                        organization_id: {
                            value: user.organization_id.toString(),
                            type: 'in',
                            field: 'l.organization_id',
                        },
                    },
                }
                searchTable(formikSearchTable, 'locations')
            }
        }
    }, [user, isAuthenticated])

    return (
        <PostTemplate stepCount={0}>
            <div className="stpInrDiv">
                <form onSubmit={formik.handleSubmit}>
                    <h4>How would you like to setup the auction?</h4>
                    <div className="typeBoxWrpr">
                        {membership?.auction_template ? (
                            <div
                                onClick={() => {
                                    formik.setFieldValue('type', 'template')
                                }}
                                className={`typeBox ${
                                    formik.values.type === 'template' ? 'active' : ''
                                }`}
                            >
                                <img src="/assets/svg/template.svg" />
                                <p>Select from a template</p>
                            </div>
                        ) : (
                            <div
                                onClick={() => {
                                    setSubPopup(true)
                                }}
                                className={`typeBox ${
                                    formik.values.type === 'template' ? 'active' : ''
                                }`}
                            >
                                <img src="/assets/svg/template.svg" />
                                <p>Select from a template</p>
                                <p className="subscrbInfo">Buy Silver/Gold plan to enable this!</p>
                            </div>
                        )}
                        <div
                            onClick={() => {
                                formik.setFieldValue('type', 'scratch')
                            }}
                            className={`typeBox ${
                                formik.values.type === 'scratch' ? 'active' : ''
                            }`}
                        >
                            <img src="/assets/svg/scratch.svg" />
                            <p>Start from scratch</p>
                        </div>
                    </div>
                    <div className="btnWrpr d-flex align-items-center justify-content-center">
                        <PrimaryButton label="Next" type="submit" />
                    </div>
                </form>
                <FullScreenPopup
                    open={templatePopup}
                    className="templatePopupModal"
                    handleClose={() => setTemplatePopup(false)}
                    modaltitle=""
                >
                    <h4 className="slctTmpTtle">Select Template</h4>
                    <div className="tmpltsWrppr">
                        <TemplateList />
                    </div>
                </FullScreenPopup>

                <FormDialog open={subPopup} openFunction={() => setSubPopup(false)}>
                    <div className="upgrdPup">
                        <p className="text-center mb-1">
                            <span className="material-icons">warning</span>
                        </p>
                        <p className="text-center">
                            Silver/Gold plan needed to enable this, please upgrade your plan
                        </p>
                        <div className="d-flex align-items-center justify-content-end">
                            <SecondaryButton label="Cancel" onClick={() => setSubPopup(false)} />
                            <PrimaryButton
                                btnSize="ml-2"
                                label="Upgrade"
                                onClick={() => (
                                    handleRedirectInternal(history, 'subscription'),
                                    setSubPopup(false)
                                )}
                            />
                        </div>
                    </div>
                </FormDialog>
            </div>
        </PostTemplate>
    )
}

export default AuctionType
