import React, { useState, useContext, useEffect, useRef } from 'react'
import openSocket from 'socket.io-client'
import './Dashboard.css'
import SideNav from './SideNav'
import FilterPanel from '../../custom/components/organisms/FilterPanel'
import GridView from '../../custom/components/molecules/ProductCard/GridView'
import CustomSelect from '../../product/components/atoms/Inputs/CustomSelect'
import NoRecordsFound from '../../product/components/atoms/NoRecordsFound'
import { Button, Fade, ListItem, MenuItem, Typography } from '@material-ui/core'
import ListView from '../../custom/components/molecules/ProductCard/ListView'
import Drawer from '@material-ui/core/Drawer'
import ProductView from '../../custom/components/organisms/ProductView'
import ProductContext from '../../product/context/product/productContext'
import AuthContext from '../../product/context/auth/authContext'
import AlertContext from '../../product/context/alert/alertContext'
import { Pagination } from '@material-ui/lab'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import { Link, NavLink } from 'react-router-dom'
import { messageHandler } from '../../product/common/socketHandler'
import { useCustomMediaQuery } from '../../product/common/components'
import { useFormik } from 'formik'
import { useHistory } from 'react-router-dom'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import DashboardLayout from './DashboardLayout'
import { useTranslation } from 'react-i18next'
import Loaders from '../../custom/components/molecules/Loaders'
import SortByComponent from '../../product/components/species/components/product/SortByComponent'
import ProductViewComponent from '../../product/components/species/components/product/ProductViewComponent'
const useStyles = makeStyles({
    fullList: {
        width: 'auto',
    },
})

const MyAuctions = (props) => {
    const pageOptions = [
        {
            value: '12',
            show: 'Results 12 per Page',
        },
        {
            value: '24',
            show: 'Results 24 per Page',
        },
        {
            value: '36',
            show: 'Results 36 per Page',
        },
    ]

    const formikSearch = useFormik({
        initialValues: {
            limit: 12,
            page: 1,
            orderby: 'p.date_closed,asc',
            order: '',
            filters: {
                category: {
                    value: [],
                    type: 'array',
                    field: 'it.categoryTypeId',
                },
                condition: {
                    value: [],
                    type: 'array',
                    field: 'it.conditionTypeId',
                },
                location: {
                    value: [],
                    type: 'array',
                    field: 'p.location_id',
                },
                price: {
                    value: '',
                    type: 'greaterequal',
                    field: 'p.wprice',
                },
                searchbar: {
                    value: '',
                    type: 'like',
                    field: 'p.title', //p.desc_proc',
                },
                auctionid: {
                    value: [],
                    type: 'array',
                    field: 'p.auctionid',
                },
            },
            action: props.match.params.type,
        },
    })

    const sortShow = [
        {
            value: '',
            show: 'None',
        },
        {
            value: 'p.wprice,asc',
            show: 'Current Price Low to High',
        },
        {
            value: 'p.wprice,desc',
            show: 'Current Price High to Low',
        },
        {
            value: 'p.date_closed,asc',
            show: 'Closing Date',
        },
        {
            value: 1,
            show: 'Ending Today',
        },
        {
            value: 'p.bprice,asc',
            show: 'Buy Now Price Low to High',
        },
        {
            value: 'p.bprice,desc',
            show: 'Buy Now Price High to Low',
        },
    ]
    useEffect(() => {
        formikSearch.setFieldValue('action', props.match.params.type)
    }, [props.match.params.type])

    const formik = useFormik({
        initialValues: {
            searchbar: '',
        },
    })

    const clearSearchFilter = (action) => {
        formikSearch.values = {
            ...formikSearch.values,
            limit: 10,
            page: 1,
            orderby: 'p.date_closed,asc',
            order: '',
            filters: {
                category: {
                    ...formikSearch.filters.category,
                    value: [],
                },
                condition: {
                    ...formikSearch.filters.condition,
                    value: [],
                },
                location: {
                    ...formikSearch.filters.location,
                    value: [],
                },
                price: {
                    ...formikSearch.filters.price,
                    value: '',
                },
                searchbar: {
                    ...formikSearch.filters.searchbar,
                    value: '',
                },
                auctionid: {
                    ...formikSearch.filters.auctionid,
                    value: [],
                },
            },
        }
        formik.values.searchbar = ''
    }

    const renderHeading = () => {
        switch (props.match.params.type) {
            case 'auction':
                return 'My Bids'
            case 'watchlist':
                return 'My Favorites'
            case 'winning':
                return 'Winning Auctions'
            case 'outbid':
                return 'Outbid Auctions'
            case 'won':
                return 'Won Auctions'
            case 'buynow':
                return 'Buy Now'
            default:
                return 'My Bids'
        }
    }

    return (
        <DashboardLayout title={renderHeading()}>
            <div className="dashboardInner myAuctions">
                <SortByComponent
                    renderHeading={renderHeading}
                    formikSearch={formikSearch}
                    sortShow={sortShow}
                    pageOptions={pageOptions}
                    viewSwitch={true}
                    resultPerPage={true}
                    sortBy={true}
                    path={true}
                />

                {props.location.pathname == '/auctions/all' && (
                    <div className="auctionStatus">
                        {/* {isAuthenticated && user?.admin ? ( }
                        )*/}
                        <ListItem button>
                            <NavLink activeClassName="active" to="/auctions/all">
                                All
                            </NavLink>
                        </ListItem>

                        <ListItem button>
                            <NavLink activeClassName="active" to="/auctions/winning">
                                Winning
                            </NavLink>
                        </ListItem>

                        <ListItem button>
                            <NavLink activeClassName="active" to="/auctions/outbid">
                                Currently Outbid
                            </NavLink>
                        </ListItem>
                        <ListItem button>
                            <NavLink activeClassName="active" to="/auctions/won">
                                Won
                            </NavLink>
                        </ListItem>
                        {/* <ListItem button>
                            <NavLink activeClassName="active" to="/auctions/lost">
                                Lost
                            </NavLink>
                        </ListItem> */}
                    </div>
                )}
                <div>
                    <ProductViewComponent formikSearch={formikSearch} />
                </div>
            </div>
        </DashboardLayout>
    )
}

export default MyAuctions
