import React from 'react'
import './EmptyCart.css'
import { handleRedirectInternal } from '../../product/common/components'
import { useHistory } from 'react-router-dom'
import PrimaryButton from '../../product/components/atoms/PrimaryButton'
import { useTranslation } from 'react-i18next'
function EmptyCart() {
    const history = useHistory()
    const { t } = useTranslation()
    return (
        <div className="emptyCart container d-flex justify-content-between flex-wrap text-center">
            <img className="emptyCartImg" src="./assets/svg/emptyState.svg" />
            <div className="emptyCartActions w-100">
                <h2>{'Oops Your Shopping Cart Seems to be Empty'}</h2>
                <h4>{'Search Our Inventory for Amazing Products'}</h4>
                <PrimaryButton
                    label={'Search for Products'}
                    onClick={() => handleRedirectInternal(history, 'search')}
                />
            </div>
        </div>
    )
}

export default EmptyCart
