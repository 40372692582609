import React, { useState } from 'react'
import './Login.css'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'
import * as Yup from 'yup'
import { handleRedirectInternal } from '@/product/common/components'
import { LOGO, SITE_NAME } from '../../Utils'
import RegistrationLayout from '@/custom/components/templates/RegistrationLayout'
import LoginComponent from '@/product/components/species/components/user/LoginComponent'

const useStyles = makeStyles((theme, maxWidth) => ({
    modal: {
        display: 'flex',
        padding: theme.spacing(1),
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        width: '100%',
        maxWidth: '55rem',
    },
}))

const Login = () => {
    const history = useHistory()
    const [toggleForgotPassword, setToggleForgotPassword] = useState(false)

    let [passwordShown, setPasswordShown] = useState(false)

    const togglePasswordVisiblity = () => {
        setPasswordShown(passwordShown ? false : true)
    }

    const handleRedirection = () => {
        handleRedirectInternal(history, '')
    }

    const validationArray = Yup.object({
        email: Yup.string().email('Invalid Email Format').required('Required'),
        password: Yup.string().required('Required'),
    })

    const initialValues = {
        email: localStorage.email ? localStorage.email : '',
        password: localStorage.password ? localStorage.password : '',
        remember_me: localStorage.remember_me ? localStorage.remember_me : false,
    }

    const loginInfo = {
        data: [
            {
                label: 'Email address',
                name: 'email',
                type: 'email',
                placeholder: 'Enter your email address',
                class: 'col-12',
                autoFocus: true,
            },
            {
                label: 'Password',
                name: 'password',
                type: passwordShown ? 'text' : 'password',
                placeholder: 'Enter your password',
                class: 'col-12',
                endAdornment: passwordShown ? (
                    <span
                        className="material-icons cursorPointer"
                        onClick={togglePasswordVisiblity}
                    >
                        visibility_off
                    </span>
                ) : (
                    <span
                        className="material-icons cursorPointer"
                        onClick={togglePasswordVisiblity}
                    >
                        visibility
                    </span>
                ),
            },
        ],
    }

    return (
        <RegistrationLayout>
            <div className="login">
                <Link to="/">
                    <img className="brandLogo" src={LOGO} alt={`${SITE_NAME} Logo`} />
                </Link>
                <LoginComponent
                    initialValues={initialValues}
                    rememberMe={true}
                    formFields={loginInfo}
                    validationSchema={validationArray}
                    onSuccess={handleRedirection}
                    SubmitBtnLabel="Login"
                />
                <h6 className="loginMiscAction ">
                    <div>
                        <p>Forgot Password?</p>
                        <p>
                            No worries, Get your password reset{' '}
                            <Link to="/forgot_password">here</Link>
                        </p>
                        <p>
                            Don&apos;t have an account?
                            <Link to="/registration"> Register here</Link>
                        </p>
                    </div>
                </h6>
            </div>
        </RegistrationLayout>
    )
}

export default Login
