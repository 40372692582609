import React, { useState, useEffect, useContext } from 'react'
import './PostProject.css'
import * as Yup from 'yup'
import { handleRedirectInternal } from '../../../product/common/components'
import AuctionComponent from '../../../product/components/species/components/auction/AuctionComponent'
import UserContext from '../../../product/context/seller/user/userContext'
import AuthContext from '../../../product/context/auth/authContext'
import PostTemplate from './PostTemplate'
import { useHistory } from 'react-router-dom'
import { Button } from '@material-ui/core'
import PrimaryButton from '../../../product/components/atoms/PrimaryButton'
import SecondaryButton from '../../../product/components/atoms/SecondaryButton'
import FormDialog from '../../../custom/components/organisms/Dialog'
import InviteUser from '../../Seller/Organization/InviteUser'
import CustomCommonContext from '../../../custom/context/common/commonContext'

const UsersInvite = (props) => {
    const authContext = useContext(AuthContext)
    const { user, isAuthenticated } = authContext

    const { getAllUsers, all_users } = useContext(UserContext)
    const customCommonContext = useContext(CustomCommonContext)

    const { membership } = customCommonContext
    const [organizationID, setOrganizationID] = useState([])
    const [subPopup, setSubPopup] = useState(false)
    const [tableBody, setTableBody] = useState([])
    const history = useHistory()

    const [manage, setManage] = useState({
        popup: false,
        status: 'edit',
        id: props.match.params.id,
    })
    const [InviteManage, setInviteManage] = useState({
        popup: false,
        status: 'new',
        id: 0,
        user_type: 0,
    })

    const validationArray = Yup.object({
        organization_id: Yup.array(),
        selltype: Yup.boolean(),
        selectedbuyer: Yup.array().when('selltype', {
            is: (avatar) => avatar,
            then: Yup.array(),
        }),
    })

    const [initialValues, setInitialValues] = useState({
        id: 0,
        organization_id: [],
        selltype: true,
        selectedbuyer: [],
    })

    const formFields = {
        data: [
            {
                label: 'Selected Users',
                placeholder: 'Select condition',
                type: 'multiselect',
                class: 'col-sm-12 col-12',
                options:
                    all_users && all_users.records
                        ? all_users.records.map((user) => {
                              let userChanged = {}
                              userChanged.show =
                                  user.first_name + ' ' + user.last_name + ' (' + user.email + ')'
                              userChanged.value = user.id
                              return userChanged
                          })
                        : [],
                disabled: false,
                defaultOptionValue: 'Select a Condition',
                name: 'selectedbuyer',
            },
        ],
    }

    const toggleFullScreenPopup = (popup, status, id, user_type) => {
        if (popup) {
            setInviteManage({ popup, status, id, user_type })
        } else {
            setInviteManage({ popup, status: 'new', id: 0, user_type: 0 })
        }
    }
    const handleSingleAuctionEdit = (single_auction) => {
        if (single_auction) {
            if (single_auction.record && manage.status === 'edit' && manage.id > 0) {
                const auction = single_auction.record
                setInitialValues({
                    id: manage.id,
                    selltype: auction.selltype === '1' ? true : false,
                    selectedbuyer: auction.selectedbuyer
                        ? auction.selectedbuyer.split(',').map(Number)
                        : [],
                })
            }
        }
    }

    const handleBack = () => {
        handleRedirectInternal(history, `post_project/bid_increment/${manage.id}`)
    }
    const handleNext = () => {
        handleRedirectInternal(history, `seller/auctions/open`)
    }

    useEffect(() => {
        if (isAuthenticated) {
            if (organizationID && organizationID.length) {
                getAllUsers({
                    page: 1,
                    limit: 10000000,
                    order: '',
                    orderby: '',
                    filters: {
                        action: {
                            value: 'active',
                            type: 'in',
                            field: 'u.status',
                        },
                        company_id: {
                            value: organizationID ? organizationID : [],
                            type: 'findinarray',
                            field: 'cu.company_id',
                        },
                    },
                })
            }
        }
    }, [organizationID, isAuthenticated])

    useEffect(() => {
        if (user && user.organization_id) {
            setOrganizationID([user.organization_id])
        }
    }, [user])

    useEffect(() => {
        if (user && all_users) {
            setTableBody(all_users.records.length ? all_users.records : [])
        }
    }, [all_users, user])
    return (
        <PostTemplate stepCount={5}>
            <div className="stpInrDiv">
                <h4>Invite the users you would like to participate in the auction</h4>
                {tableBody.length < membership?.users_limit_to ? (
                    <div className="miscActions d-flex justify-content-between align-items-center mt-4 mb-4 flex-wrap">
                        <div>
                            <Button
                                className="adNwSltBtn"
                                onClick={() => toggleFullScreenPopup(true, 'new', 0, 0)}
                            >
                                <span className="material-icons mr-2">person_add_alt_1</span>Invite
                                User
                            </Button>
                        </div>
                    </div>
                ) : (
                    <div className="miscActions d-flex justify-content-between align-items-center mt-4 mb-4 flex-wrap">
                        <div>
                            <Button className="adNwSltBtn" onClick={() => setSubPopup(true)}>
                                <span className="material-icons mr-2">person_add_alt_1</span>Invite
                                User
                            </Button>
                        </div>
                    </div>
                )}
                <AuctionComponent
                    validationArray={validationArray}
                    initialValues={initialValues}
                    handleSingleAuctionEdit={handleSingleAuctionEdit}
                    data={manage}
                    formFields={formFields}
                    onSuccess={handleNext}
                    onFormBack={handleBack}
                    SubmitBtnLabel="Finish"
                    BackBtnLabel="Previous"
                />
            </div>
            <InviteUser data={InviteManage} function={toggleFullScreenPopup}></InviteUser>
            <FormDialog open={subPopup} openFunction={() => setSubPopup(false)}>
                <div className="upgrdPup">
                    <p className="text-center mb-1">
                        <span className="material-icons">warning</span>
                    </p>
                    <p className="text-center">
                        Your invite user limit exceeded, please upgrade your plan to continue
                    </p>
                    <div className="d-flex align-items-center justify-content-end">
                        <SecondaryButton label="Cancel" onClick={() => setSubPopup(false)} />
                        <PrimaryButton
                            btnSize="ml-2"
                            label="Upgrade"
                            onClick={() => (
                                handleRedirectInternal(history, 'subscription'), setSubPopup(false)
                            )}
                        />
                    </div>
                </div>
            </FormDialog>
        </PostTemplate>
    )
}

export default UsersInvite
