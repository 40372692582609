import React, { useContext, useEffect, useState } from 'react'
import RegistrationLayout from '../../custom/components/templates/RegistrationLayout'
import './Registration.css'
import * as Yup from 'yup'
import { Link, useHistory } from 'react-router-dom'
import CommonContext from '../../custom/context/common/commonContext'
import ProductCommonContext from '../../product/context/common/commonContext'
import { Button, Fade } from '@material-ui/core'
import CustomCommonContext from '../../custom/context/common/commonContext'
import { makeStyles } from '@material-ui/core/styles'
import { LOGO, SITE_NAME } from '../../Utils'
import { handleRedirectInternal } from '../../product/common/components'

import RegisterComponent from '../../product/components/species/components/user/RegisterComponent'
import UserContext from '../../product/context/user/userContext'

const Registration = (props) => {
    const history = useHistory()
    const customCommonContext = useContext(CustomCommonContext)

    const { allOrganization } = customCommonContext

    const commonContext = useContext(CommonContext)
    const userContext = useContext(UserContext)
    const productCommonContext = useContext(ProductCommonContext)
    const [selectedCountry, SetCountry] = useState([])
    const [formikChangeValues, setFormikChangeValues] = useState({
        country: global.defaultCountry,
    })
    const [show, setShow] = useState(false)
    const { configFeatures } = commonContext
    const { allCountries, allStates } = productCommonContext
    const { getInviteDetails, invite_data, responseStatus: responseStatusUser } = userContext

    const [regType, setRegType] = useState(0)
    const orgid = props.match.params.oid
    const invid = props.match.params.invid

    // YUP Validation array to ensure all required fields are given values
    const SubmitBtnLable = 'Signup'

    const validation = Yup.object({
        email: Yup.string()
            .email('Invalid Email Format')
            .max(250, 'Maximum 250 Characters')
            .required('Required'),
        password: Yup.string().min(8, 'Minimum 8 Characters').required('Required'),
        confirm_password: Yup.string()
            .oneOf([Yup.ref('password')], 'Password does not match')
            .required('Required'),
        first_name: Yup.string()
            .min(2, 'Mininum 2 characters')
            .max(15, 'Maximum 15 characters')
            .required('Required!'),
        last_name: Yup.string()
            .min(2, 'Mininum 2 characters')
            .max(15, 'Maximum 15 characters')
            .required('Required!'),
        address1: Yup.string().required('Required'),
        address2: Yup.string(),
        phone: Yup.string()
            .trim()
            .nullable()
            .matches(/^[0-9- +()]*$/g, 'Only numbers are allowed')
            .min(10, 'Please enter correct mobile number')
            .required('Required!'),
        country: Yup.string().trim().required('Required'),
        city: Yup.string()
            .trim()
            .matches(/^[0-9a-zA-Z ]*$/g, 'Only characters are allowed')
            .required('Required'),
        state: Yup.string().trim().required('Required'),
        zip: Yup.string()
            .trim()
            .matches(/^[0-9]*$/g, 'Positive Numbers only')
            .max(6, 'Maximum 6 characters')
            .required('Required'),
    })

    const validation1 = Yup.object({
        email: Yup.string()
            .email('Invalid Email Format')
            .max(250, 'Maximum 250 Characters')
            .required('Required'),
        password: Yup.string().min(8, 'Minimum 8 Characters').required('Required'),
        confirm_password: Yup.string()
            .oneOf([Yup.ref('password')], 'Password does not match')
            .required('Required'),
        first_name: Yup.string()
            .min(2, 'Mininum 2 characters')
            .max(15, 'Maximum 15 characters')
            .required('Required!'),
        last_name: Yup.string()
            .min(2, 'Mininum 2 characters')
            .max(15, 'Maximum 15 characters')
            .required('Required!'),
        address1: Yup.string().required('Required'),
        address2: Yup.string(),
        phone: Yup.string()
            .trim()
            .nullable()
            .matches(/^[0-9- +()]*$/g, 'Only numbers are allowed')
            .min(10, 'Please enter correct mobile number')
            .required('Required!'),
        country: Yup.string().trim().required('Required'),
        city: Yup.string()
            .trim()
            .matches(/^[0-9a-zA-Z ]*$/g, 'Only characters are allowed')
            .required('Required'),
        companyname: Yup.string().required('Required'),
        state: Yup.string().trim().required('Required'),
        zip: Yup.string()
            .trim()
            .matches(/^[0-9]*$/g, 'Positive Numbers only')
            .max(6, 'Maximum 6 characters')
            .required('Required'),
    })

    // Set Initial values
    const [bidinitialValues, setBidinitialValues] = useState({
        first_name: '',
        last_name: '',
        email: '',
        confirm_email: '',
        phone: '',
        password: '',
        confirm_password: '',
        country: global.defaultCountry,
        state: '',
        city: '',
        address1: '',
        address2: '',
        zip: '',
        phone_verified: 0,
        termCheck: 0,
        status: 'email_unverified',
        admin: 0,
        company_id: orgid ? orgid : '',
        invite_id: 0,
    })

    const [orginitialValues, setOrginitialValues] = useState({
        first_name: '',
        last_name: '',
        email: '',
        confirm_email: '',
        phone: '',
        companyname: '',
        password: '',
        confirm_password: '',
        country: global.defaultCountry,
        state: '',
        city: '',
        address1: '',
        address2: '',
        zip: '',
        phone_verified: 0,
        termCheck: 0,
        admin: 1,
        organization: 1,
        emp_type: 'seller',
        status: 'email_unverified',
    })

    // Mapped over for individual registration fields
    const formFields = {
        data: [
            {
                label: 'Enter your first name',
                name: 'first_name',
                type: 'text',
                placeholder: 'Enter your first name',
                class: 'col-12 col-sm-6',
                autoFocus: true,
            },
            {
                label: 'Enter your last name',
                name: 'last_name',
                type: 'text',
                placeholder: 'Enter your last name',
                class: 'col-12 col-sm-6',
            },
            {
                label: 'Enter your email address',
                name: 'email',
                type: 'email',
                placeholder: 'Enter your email address',
                class: 'col-12 col-sm-6',
                disabled: show ? true : false,
            },
            {
                label: 'Enter your mobile number',
                name: 'phone',
                type: 'phone',
                placeholder: 'Enter your mobile number',
                class: 'col-12 col-sm-6',
            },
            {
                label: 'Enter your password',
                name: 'password',
                type: 'password',
                placeholder: 'Enter your password',
                class: 'col-12 col-sm-6',
            },
            {
                label: 'Re-enter your password',
                name: 'confirm_password',
                type: 'password',
                placeholder: 'Re-enter your password',
                class: 'col-12 col-sm-6',
            },
            {
                label: 'Enter your address line 1',
                name: 'address1',
                type: 'text',
                placeholder: 'Enter your address line 1',
                class: 'col-12 col-sm-6',
            },
            {
                label: 'Enter your address line 2',
                name: 'address2',
                type: 'text',
                placeholder: 'Enter your address line 2',
                class: 'col-12 col-sm-6',
            },
            {
                label: 'Select a country',
                placeholder: 'Select a country',
                class: 'col-12 col-sm-6',
                type: 'select',
                options: allCountries
                    .filter((country) => global.ignoreCountry.indexOf(country.isoCode) === -1)
                    .map((country) => {
                        let busCategoryChanged = {}
                        busCategoryChanged.show = country.name
                        busCategoryChanged.value = country.isoCode
                        return busCategoryChanged
                    }),
                name: 'country',
            },

            {
                label: 'Select a state',
                placeholder: 'Select a state',
                class: 'col-12 col-sm-6',
                type: 'select',
                options: allStates
                    .filter(
                        (state) =>
                            (formikChangeValues.country
                                ? formikChangeValues.country.indexOf(state.countryCode) !== -1
                                : global.defaultCountry.indexOf(state.countryCode) !== -1) &&
                            global.ignoreStates.indexOf(state.isoCode) === -1,
                    )
                    .map((buscat) => {
                        let busCategoryChanged = {}
                        busCategoryChanged.show = buscat.name
                        busCategoryChanged.value = buscat.isoCode
                        return busCategoryChanged
                    }),
                name: 'state',
            },
            {
                label: 'Enter your city',
                name: 'city',
                type: 'text',
                placeholder: 'Enter your city',
                class: 'col-12 col-sm-6',
            },
            {
                label: 'Enter your zip code',
                placeholder: 'Enter your zip code',
                class: 'col-12 col-sm-3',
                type: 'text',
                name: 'zip',
            },
            // {
            //     label: 'Select your organization',
            //     placeholder: 'Select your organization',
            //     class: 'col-12 col-sm-6',
            //     type: 'select',
            //     options:
            //         allOrganization && allOrganization.length
            //             ? allOrganization
            //                   .filter((buscat) => {
            //                       if (buscat.active === 1) return buscat
            //                   })
            //                   .map((buscat) => {
            //                       let busCategoryChanged = {}
            //                       busCategoryChanged.show = buscat.description
            //                       busCategoryChanged.value = buscat.id
            //                       return busCategoryChanged
            //                   })
            //             : null,
            //     disabled: orgid ? true : false,
            //     name: 'company_id',
            // },
        ],
    }

    const additionalFieldUpdate = (payload) => {
        setFormikChangeValues(payload)
    }

    const orgformFields = {
        data: [
            {
                label: 'Enter your first name',
                name: 'first_name',
                type: 'text',
                placeholder: 'Enter your first name',
                class: 'col-12 col-sm-6',
                autoFocus: true,
            },
            {
                label: 'Enter your last name',
                name: 'last_name',
                type: 'text',
                placeholder: 'Enter your last name',
                class: 'col-12 col-sm-6',
            },
            {
                label: 'Enter your email address',
                name: 'email',
                type: 'email',
                placeholder: 'Enter your email address',
                class: 'col-12 col-sm-6',
            },
            {
                label: 'Enter your mobile number',
                name: 'phone',
                type: 'phone',
                placeholder: 'Enter your mobile number',
                class: 'col-12 col-sm-6',
            },
            {
                label: 'Enter your password',
                name: 'password',
                type: 'password',
                placeholder: 'Enter your password',
                class: 'col-12 col-sm-6',
            },
            {
                label: 'Re-enter your password',
                name: 'confirm_password',
                type: 'password',
                placeholder: 'Re-enter your password',
                class: 'col-12 col-sm-6',
            },
            {
                label: 'Enter your organization name',
                name: 'companyname',
                type: 'text',
                placeholder: 'Enter your first name',
                class: 'col-12 col-sm-6',
                autoFocus: true,
            },
            {
                label: 'Enter your address line 1',
                name: 'address1',
                type: 'text',
                placeholder: 'Enter your address line 1',
                class: 'col-12 col-sm-6',
            },
            {
                label: 'Enter your address line 2',
                name: 'address2',
                type: 'text',
                placeholder: 'Enter your address line 2',
                class: 'col-12 col-sm-6',
            },
            {
                label: 'Select a country',
                placeholder: 'Select a country',
                class: 'col-12 col-sm-6',
                type: 'select',
                options: allCountries
                    .filter((country) => global.ignoreCountry.indexOf(country.isoCode) === -1)
                    .map((country) => {
                        let busCategoryChanged = {}
                        busCategoryChanged.show = country.name
                        busCategoryChanged.value = country.isoCode
                        return busCategoryChanged
                    }),
                name: 'country',
            },
            {
                label: 'Select a state',
                placeholder: 'Select a state',
                class: 'col-12 col-sm-6',
                type: 'select',
                options: allStates
                    .filter(
                        (state) =>
                            (formikChangeValues.country
                                ? formikChangeValues.country.indexOf(state.countryCode) !== -1
                                : global.defaultCountry.indexOf(state.countryCode) !== -1) &&
                            global.ignoreStates.indexOf(state.isoCode) === -1,
                    )
                    .map((buscat) => {
                        let busCategoryChanged = {}
                        busCategoryChanged.show = buscat.name
                        busCategoryChanged.value = buscat.isoCode
                        return busCategoryChanged
                    }),
                name: 'state',
            },
            {
                label: 'Enter your city',
                name: 'city',
                type: 'text',
                placeholder: 'Enter your city',
                class: 'col-12 col-sm-6',
            },
            {
                label: 'Enter your zip code',
                placeholder: 'Enter your zip code',
                class: 'col-12 col-sm-3',
                type: 'text',
                name: 'zip',
            },
        ],
    }
    useEffect(() => {
        if (invid) {
            getInviteDetails({ id: invid })
        }
    }, [invid])

    useEffect(() => {
        console.log('invite_data', invite_data)
        if (invite_data) {
            if (invite_data.record) {
                console.log('invite_data', invite_data.record)
                setRegType(0)
                setShow(true)
                setBidinitialValues({
                    ...bidinitialValues,
                    email: invite_data.record.email,
                    company_id: invite_data.record.organization_id,
                    invite_id: invite_data.record.id,
                })
            }
        }
    }, [invite_data])

    useEffect(() => {
        if (responseStatusUser) {
            if (responseStatusUser.from === 'getInviteDetails') {
                if (responseStatusUser.status !== 'success') {
                    handleRedirectInternal(history, 'login')
                }
            }
        }
    }, [responseStatusUser])

    console.log('orginitialValues', bidinitialValues)
    return (
        <RegistrationLayout>
            <div className="login regContainer">
                <Link to="/">
                    <img className="brandLogo" src={LOGO} alt={`${SITE_NAME} Logo`} />
                </Link>
                <div className="rgTypeWrpr d-flex align-items-center justify-content-start mt-5 pb-2">
                    <label className="m-0 mr-4">Register as a</label>
                    <Button
                        className={`tabBtns ${regType === 0 ? 'active ' : ''}`}
                        onClick={() => setRegType(0)}
                    >
                        <span className="material-icons">
                            {regType === 0 ? 'radio_button_checked ' : 'radio_button_unchecked'}
                        </span>
                        Bidder
                    </Button>
                    <Button
                        className={`tabBtns ${regType === 1 ? 'active ' : ''}`}
                        onClick={() => setRegType(1)}
                    >
                        <span className="material-icons">
                            {regType === 1 ? 'radio_button_checked ' : 'radio_button_unchecked'}
                        </span>
                        Organization
                    </Button>
                </div>
                {regType === 0 ? (
                    <Fade in={regType === 0 && true} timeout={600}>
                        <RegisterComponent
                            initialValues={bidinitialValues}
                            validationSchema={validation}
                            SubmitBtnLable={SubmitBtnLable}
                            formFields={formFields}
                            additionalFieldUpdate={additionalFieldUpdate}
                            isConfirmPopup={true}
                            popupSubmitBtnLable="Confirm as Bidder"
                            popupBodyLabel="Register as Bidder"
                        />
                    </Fade>
                ) : (
                    <Fade in={regType === 1 && true} timeout={600}>
                        <RegisterComponent
                            initialValues={orginitialValues}
                            validationSchema={validation1}
                            SubmitBtnLable={SubmitBtnLable}
                            formFields={orgformFields}
                            additionalFieldUpdate={additionalFieldUpdate}
                            isConfirmPopup={true}
                            popupSubmitBtnLable="Confirm as Organization"
                            popupBodyLabel="Register as Organization"
                        />
                    </Fade>
                )}
            </div>
        </RegistrationLayout>
    )
}

export default Registration
