import React, { useState, useEffect, useContext } from 'react'
import './PostProject.css'
import PrimaryButton from '../../../product/components/atoms/PrimaryButton'
import SecondaryButton from '../../../product/components/atoms/SecondaryButton'
import SellerBidContext from '../../../product/context/seller/bid/bidContext'
import AuthContext from '../../../product/context/auth/authContext'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import moment from 'moment'
import { mapData, handleRedirectInternal } from '../../../product/common/components'
import { Button, IconButton } from '@material-ui/core'
import FormDialog from '../../../custom/components/organisms/Dialog'

import PostTemplate from './PostTemplate'
import { useHistory } from 'react-router-dom'

const CreateLot = (props) => {
    const history = useHistory()
    const sellerBidContext = useContext(SellerBidContext)
    const authContext = useContext(AuthContext)
    const [slotPopup, setSlotPopup] = useState(false)
    const [reload, setReload] = useState(false)

    const [changeStatus, setChangeStatus] = React.useState(false)
    const changeChangeStatus = () => {
        setChangeStatus(!changeStatus)
    }

    const [manage, setManage] = useState({
        popup: false,
        status: 'edit',
        id: props.match.params.id,
    })
    const {
        getAllBidIncrements,
        all_increments,
        bidIncrementAction,
        changeBidIncrementStatus,
        responseStatus: responseStatusSellerBid,
    } = sellerBidContext

    const { isAuthenticated } = authContext
    const [tableBody, setTableBody] = useState([])
    const [isLoading, setIsLoading] = useState(true)

    const validationArray = Yup.object({
        increment: Yup.number('Numbers only!')
            .integer('Decimal not accept')
            .min(1, 'Should be greater than 1')
            .required('Required!'),
        from_amount: Yup.number('Numbers only!')
            .integer('Decimal not accept')
            .min(0, 'Minimum 0')
            .required('Required!'),
        to_amount: Yup.number('Numbers only!')
            .integer('Decimal not accept')
            .moreThan(Yup.ref('from_amount'), 'To Amount must be more than From Amount')
            .max(10000000000)
            .required('Required!'),
    })

    const formik = useFormik({
        initialValues: {
            id: 0,
            auction_id: manage.id,
            from_amount: 0,
            to_amount: 0,
            increment: 0,
            type: 'range',
        },
        validationSchema: validationArray,
        onSubmit: (values) => {
            const value = values
            bidIncrementAction(value)
            setSlotPopup(false)
            resetFrom()
        },
    })

    const formikSelection = useFormik({
        initialValues: {
            action: '',
            bid_id: '',
        },
        onSubmit: (values) => {
            const dataValue = values
            if (
                dataValue.action === 'active' ||
                dataValue.action === 'deactive' ||
                dataValue.action === 'delete'
            ) {
                dataValue.active =
                    dataValue.action === 'delete' ? 2 : dataValue.action === 'active' ? 1 : 0
                delete dataValue.action
                setIsLoading(true)
                changeBidIncrementStatus(dataValue)
            }
        },
    })

    const onSelectMultiProducts = (data, action) => {
        formikSelection.values.action = action
        formikSelection.values.bid_id = [data.id]
        if (action === 'delete') {
            setChangeStatus(true)
        }
    }

    const slotValue = {
        formik: formik,
        data: [
            {
                label: 'From Amount',
                placeholder: 'Enter From Amount',
                class: 'col-12',
                type: 'number',
                shrink: true,
                name: 'from_amount',
                min: 0,
            },
            {
                label: 'To Amount',
                placeholder: 'Enter To Amount',
                class: 'col-12 ',
                type: 'number',
                shrink: true,
                name: 'to_amount',
            },
            {
                label: 'Increment Value',
                placeholder: `Enter Bid Increment Value`,
                class: 'col-12',
                name: 'increment',
                type: 'number',
                min: 1,
            },
            // {
            //     label: 'Type',
            //     class: 'col-12',
            //     type: 'select',
            //     options: [
            //         {
            //             value: 'range',
            //             show: 'Range',
            //         },
            //         {
            //             value: 'default',
            //             show: 'Default',
            //         },
            //     ],
            //     name: 'type',
            //     isNoDefaultOptionValue: true,
            // },
        ],
    }

    const listing = useFormik({
        initialValues: {
            page: 1,
            limit: 20,
            order: 'asc',
            orderby: 'cbi.from_amount',
            table: 'custom_bid_increment',
            filters: {
                auctionid: {
                    value: manage.id,
                    type: 'in',
                    field: 'cbi.auction_id',
                },
                active: {
                    value: 1,
                    type: 'in',
                    field: 'cbi.active',
                },
            },
        },
    })

    const onClickEdit = (data) => {
        formik.values.id = data.id
        formik.values.from_amount = data.from_amount
        formik.values.to_amount = data.to_amount
        formik.values.type = data.type
        formik.values.increment = data.increment
        setReload(!reload)
        setSlotPopup(true)
    }
    const addClick = () => {
        resetFrom()
        setSlotPopup(true)
    }

    const cancelClick = () => {
        setSlotPopup(false)
        resetFrom()
    }

    const resetFrom = () => {
        formik.values.id = 0
        formik.values.from_amount = 0
        formik.values.to_amount = 0
        formik.values.increment = 0
        formik.values.type = 'range'
        formik.handleReset()
    }

    useEffect(() => {
        if (isAuthenticated && manage.id) {
            getAllBidIncrements(listing.values)
        }
    }, [isAuthenticated])

    useEffect(() => {
        if (responseStatusSellerBid) {
            if (responseStatusSellerBid.from === 'bidIncrementAction') {
                if (responseStatusSellerBid.status === 'success') {
                    setIsLoading(true)
                    getAllBidIncrements(listing.values)
                }
            }
            if (responseStatusSellerBid.from === 'changeBidIncrementStatus') {
                if (responseStatusSellerBid.status === 'success') {
                    setIsLoading(true)
                    setChangeStatus(false)
                    getAllBidIncrements(listing.values)
                }
            }
        }
    }, [responseStatusSellerBid])

    useEffect(() => {
        if (all_increments) {
            setTableBody(all_increments.records.length ? all_increments.records : [])
        }
        setIsLoading(false)
    }, [all_increments])

    console.log(all_increments.records, 'alll')

    return (
        <PostTemplate stepCount={4}>
            <div className="stpInrDiv">
                <h4>Bid Increments for the Auction</h4>
                {tableBody.length > 0 ? (
                    <>
                        <div className="slotLstWrpr text-left">
                            <Button className="adNwSltBtn" onClick={() => addClick()}>
                                <span className="material-icons-outlined">add_circle_outline</span>
                                Add a new increment
                            </Button>

                            <div className="table-responsive dpTable mt-4">
                                <table className="table text-left">
                                    <thead>
                                        <tr>
                                            <th>From Amount</th>
                                            <th>To Amount</th>
                                            <th>Bid Increment</th>
                                            {/* <th>Type</th> */}
                                            <th>Edit</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {tableBody.map((data, index) => (
                                            <tr key={index}>
                                                <td>{data.from_amount}</td>
                                                <td>{data.to_amount}</td>
                                                <td>{data.increment}</td>
                                                {/* <td>{data.type}</td> */}
                                                <td>
                                                    <div className="d-flex align-items-center">
                                                        <IconButton
                                                            className="icnBtn mr-2"
                                                            onClick={() => onClickEdit(data)}
                                                        >
                                                            <span className="material-icons">
                                                                edit
                                                            </span>
                                                        </IconButton>
                                                        <IconButton
                                                            className="icnBtn"
                                                            onClick={() =>
                                                                onSelectMultiProducts(
                                                                    data,
                                                                    'delete',
                                                                )
                                                            }
                                                        >
                                                            <span className="material-icons">
                                                                delete
                                                            </span>
                                                        </IconButton>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <Button className="slotEntBtn" onClick={() => setSlotPopup(true)}>
                            <img src="/assets/svg/createslot.svg" />
                            <p>Create a new bid increment</p>
                        </Button>
                    </>
                )}
                <div className="btnWrpr d-flex align-items-center justify-content-center">
                    <SecondaryButton
                        label="Previous"
                        onClick={() => {
                            {
                                handleRedirectInternal(
                                    history,
                                    `post_project/auction_setting/${manage.id}`,
                                )
                            }
                        }}
                    />
                    <PrimaryButton
                        label="Next"
                        onClick={() =>
                            handleRedirectInternal(history, `post_project/invite_user/${manage.id}`)
                        }
                    />
                </div>
            </div>
            <FormDialog
                className="adSlotPopup"
                title="Add New Bid Increment"
                open={slotPopup}
                openFunction={() => setSlotPopup(false)}
            >
                <form onSubmit={formik.handleSubmit}>
                    <div className="row">{mapData(slotValue)}</div>
                </form>
                <div className="d-flex align-items-center justify-content-end mt-4">
                    <SecondaryButton label="Cancel" onClick={() => cancelClick()} />
                    <PrimaryButton
                        btnSize="ml-2"
                        label="Add"
                        onClick={() => formik.handleSubmit()}
                    />
                </div>
            </FormDialog>

            <FormDialog
                title={'Change increment status'}
                open={changeStatus}
                openFunction={changeChangeStatus}
            >
                <h5>Are you sure you want to change the status</h5>
                <div className="actionWrapper">
                    <Button onClick={() => changeChangeStatus()}>Cancel</Button>
                    <form onSubmit={formikSelection.handleSubmit} autoComplete="nofill">
                        <PrimaryButton type="submit" label="Confirm" />
                    </form>
                </div>
            </FormDialog>
        </PostTemplate>
    )
}

export default CreateLot
