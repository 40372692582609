import {
    COMMON_VALUES,
    RESPONSE_STATUS,
    CLEAR_RESPONSE,
    LOGIN_MODAL,
    SIGNUP_MODAL,
    UPDATE_CART_CHECKOUT,
    SET_MEMBERSHIP,
} from './commonTypes'

export default (state, action) => {
    switch (action.type) {
        case COMMON_VALUES:
            return {
                ...state,
                ...action.payload,
            }
        case RESPONSE_STATUS:
            return {
                ...state,
                responseStatus: action.payload,
            }
        case CLEAR_RESPONSE:
            return {
                ...state,
                responseStatus: '',
            }
        case LOGIN_MODAL:
            return {
                ...state,
                loginModal: !state.loginModal,
            }
        case SIGNUP_MODAL:
            return {
                ...state,
                signupModal: !state.signupModal,
            }
        case UPDATE_CART_CHECKOUT:
            return {
                ...state,
                cart_item: action.cart_item,
            }
        case SET_MEMBERSHIP:
            return {
                ...state,
                ...action.payload,
            }
        default:
            return state
    }
}
