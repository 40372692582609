import React, { useEffect, useState, useContext } from 'react'
import { Link, useHistory, NavLink } from 'react-router-dom'
import { Button, IconButton, ListItem } from '@material-ui/core'
import { handleRedirectInternal } from '../../../product/common/components'
import DashboardLayout from '../../Dashboard/DashboardLayout'
import UserContext from '../../../product/context/user/userContext'
import AuthContext from '../../../product/context/auth/authContext'
import CommonContext from '../../../custom/context/common/commonContext'
import CustomDialog from '../../../product/components/organisms/Dialog'
import PrimaryButton from '../../../product/components/atoms/PrimaryButton'
import {
    dateTimeFormatFunction,
    capitalize,
    dateFormatFunction,
    currencyFormat,
} from '../../../custom/common/components'
import { useFormik } from 'formik'
import * as Yup from 'yup'

const MySubscription = () => {
    const authContext = useContext(AuthContext)
    const { loadUser, user, isAuthenticated } = authContext
    const userContext = useContext(UserContext)
    const { updateProfile, responseStatus: responseStatusUser } = userContext

    const { membership } = useContext(CommonContext)
    const [toggleDialog, setToggleDialog] = useState(false)

    const history = useHistory()
    const [expandPlan, setExpandPlan] = useState(true)

    const validationArray = Yup.object({})

    const formik = useFormik({
        initialValues: { membership_autopay: 0 },
        validationSchema: validationArray,
        onSubmit: (values) => {
            if (toggleDialog) {
                updateProfile(values, 'cancelMemberhip')
            } else {
                setToggleDialog(true)
            }
        },
    })

    useEffect(() => {
        if (responseStatusUser) {
            if (responseStatusUser.from === 'updateProfile') {
                if (responseStatusUser.status === 'success') {
                    setToggleDialog(false)
                }
            }
        }
    }, [responseStatusUser])

    return (
        <DashboardLayout title="My Subscription">
            <div className="dashboardInner subsptWrapper">
                <div className="auctionStatus">
                    <ListItem button>
                        <NavLink activeClassName="active" to="/seller/payments/saved_cards">
                            Card Details
                        </NavLink>
                    </ListItem>

                    <ListItem button>
                        <NavLink activeClassName="active" to="/seller/payments/bank_details">
                            Bank Details
                        </NavLink>
                    </ListItem>

                    <ListItem button>
                        <NavLink activeClassName="active" to="/seller/payments/subscription">
                            My Subscription
                        </NavLink>
                    </ListItem>
                </div>
                <div className="d-flex align-items-center justify-content-between mb-4">
                    <h4 className="mb-0">My Subscription</h4>
                    <Link className="seeSubPlan" to="/subscription">
                        See Subscription Plans & Pricing
                    </Link>
                </div>
                {membership ? (
                    <>
                        {' '}
                        <ul className="plnInfoList">
                            <li>
                                <label>My Plan:</label>
                                <p className="m-0 plnName">
                                    {capitalize(membership.type)} Plan -{' '}
                                    {user.membership_period === 'monthly' ? (
                                        <>
                                            {currencyFormat(membership.monthly_deposit_amount)}
                                            /month
                                        </>
                                    ) : user.membership_period === 'yearly' ? (
                                        <>
                                            {currencyFormat(membership.yearly_deposit_amount)}
                                            /year
                                        </>
                                    ) : user.membership_period === 'free' ? (
                                        <>Free Trial</>
                                    ) : null}
                                </p>
                                <p
                                    className="changePlan cursorDecoy"
                                    onClick={() => handleRedirectInternal(history, 'subscription')}
                                >
                                    <span className="material-icons-outlined">edit</span>{' '}
                                    <u>Change</u>
                                </p>
                                {user.membership_autopay === 1 ? (
                                    <p
                                        className="changePlan cursorDecoy"
                                        onClick={() => {
                                            formik.setFieldValue('membership_autopay', 0)
                                            setToggleDialog(true)
                                        }}
                                    >
                                        <span className="material-icons-outlined">delete</span>{' '}
                                        <u>Cancel</u>
                                    </p>
                                ) : (
                                    <p
                                        className="changePlan cursorDecoy"
                                        onClick={() => {
                                            formik.setFieldValue('membership_autopay', 1)
                                            setToggleDialog(true)
                                        }}
                                    >
                                        <span className="material-icons-outlined">check</span>{' '}
                                        <u>Activate</u>
                                    </p>
                                )}
                            </li>
                            <li>
                                <label>Start Date:</label>
                                <p className="m-0">{dateFormatFunction(user.membership_date)}</p>
                            </li>
                            <li>
                                <label>Plan Status:</label>
                                {user.membership_autopay === 1 ? (
                                    <p className="m-0 plnStus">
                                        Active <span className="material-icons">circle</span>
                                    </p>
                                ) : (
                                    <p className="m-0 plnStus inactive">
                                        In-active <span className="material-icons">circle</span>
                                    </p>
                                )}
                            </li>
                        </ul>
                        {/* <h4 className="acrdBtn cursorDecoy" onClick={() => setExpandPlan(!expandPlan)}>
                    See plan details{' '}
                    {expandPlan === true ? (
                        <span className="material-icons">expand_less</span>
                    ) : (
                        <span className="material-icons">expand_more</span>
                    )}
                </h4> */}
                        <div className="plnDtls">
                            <p className="nxtPyInfo">
                                {user.membership_autopay === 1 ? (
                                    <>
                                        <span className="material-icons">info</span> Next Upcoming
                                        Payment: {dateFormatFunction(user.membership_enddate)}
                                    </>
                                ) : (
                                    <>
                                        <span className="material-icons">info</span> Membership will
                                        be cancelled on:{' '}
                                        {dateFormatFunction(user.membership_enddate)}
                                    </>
                                )}
                            </p>
                        </div>{' '}
                        <CustomDialog
                            title={`${
                                formik.values.membership_autopay === 1
                                    ? 'Activate Subscription'
                                    : 'Cancel Subscription'
                            }`}
                            open={toggleDialog}
                            function={() => setToggleDialog(false)}
                        >
                            <h5>
                                {formik.values.membership_autopay === 1
                                    ? 'Automatic payment will be activated! '
                                    : 'Automatic Payment will be cancelled! '}
                            </h5>

                            <h5>
                                {formik.values.membership_autopay === 1
                                    ? 'Payment will be made once the membership expires'
                                    : 'Membership expires after end date is reached'}
                            </h5>

                            <div className="actionWrapper">
                                <Button onClick={() => setToggleDialog(false)}>{'Cancel'}</Button>
                                <PrimaryButton
                                    onClick={formik.handleSubmit}
                                    type="button"
                                    label={'Submit'}
                                />
                            </div>
                        </CustomDialog>
                    </>
                ) : null}
            </div>
        </DashboardLayout>
    )
}

export default MySubscription
