import React, { useContext } from 'react'
import '../Dashboard.css'
import DashboardLayout from '../DashboardLayout'
import * as Yup from 'yup'
import { ListItem } from '@material-ui/core'
import { NavLink } from 'react-router-dom'
import AuthContext from '../../../product/context/auth/authContext'
import ChangePasswordComponent from '../../../product/components/species/components/user/ChangePasswordComponent'

const MyAccount = () => {
    const authContext = useContext(AuthContext)
    const { user, loadUser } = authContext

    const validationPassword = Yup.object({
        change_password: Yup.boolean(),
        current_password: Yup.string().required('Required'),
        new_password: Yup.string().min(8, 'Minimum 8 characters').required('Required'),
        confirm_password: Yup.string()
            .oneOf([Yup.ref('new_password')], 'Passwords Not Match')
            .required('Required'),
    })

    const initialValues = {
        change_password: 1,
        current_password: '',
        new_password: '',
        confirm_password: '',
    }

    const changePassword = {
        data: [
            {
                label: 'Current Password',
                name: 'current_password',
                type: 'password',
                placeholder: 'Enter Your Current Password',
                class: 'col-md-6 col-sm-12',
            },
            {
                label: 'Password',
                name: 'new_password',
                type: 'password',
                placeholder: 'Enter Your Password',
                class: 'col-md-6 col-sm-12',
            },
            {
                label: 'Confirm Password',
                name: 'confirm_password',
                type: 'password',
                placeholder: 'Re-Enter Your Password',
                class: 'col-md-6 col-sm-12',
            },
        ],
    }

    return (
        <DashboardLayout title="My Account">
            <div className="dashboardInner myAccount">
                <div className="auctionStatus">
                    <ListItem button>
                        <NavLink activeClassName="active" to="/account/profile">
                            Profile
                        </NavLink>
                    </ListItem>

                    <ListItem button>
                        <NavLink activeClassName="active" to="/account/change">
                            Change Password
                        </NavLink>
                    </ListItem>

                    {!user?.admin ? (
                        <ListItem button>
                            <NavLink activeClassName="active" to="/account/notifications">
                                My Notifications
                            </NavLink>
                        </ListItem>
                    ) : null}
                </div>
                <div className="accountCnt">
                    <ChangePasswordComponent
                        validationSchema={validationPassword}
                        initialValues={initialValues}
                        formFields={changePassword}
                    />
                </div>
            </div>
        </DashboardLayout>
    )
}

export default MyAccount
