import React, { useContext } from 'react'
import '../Dashboard.css'
import DashboardLayout from '../DashboardLayout'
import AuthContext from '../../../product/context/auth/authContext'
import { useTranslation } from 'react-i18next'
import SingleProfile from './Profile'
import { ListItem } from '@material-ui/core'
import { NavLink } from 'react-router-dom'

const MyAccount = () => {
    const { t } = useTranslation()
    const authContext = useContext(AuthContext)

    const { user } = authContext

    return (
        <DashboardLayout title={'My Account'}>
            <div className="dashboardInner myAccount">
                <div className="auctionStatus">
                    <ListItem button>
                        <NavLink activeClassName="active" to="/account/profile">
                            Profile
                        </NavLink>
                    </ListItem>

                    <ListItem button>
                        <NavLink activeClassName="active" to="/account/change">
                            Change Password
                        </NavLink>
                    </ListItem>

                    {!user?.admin ? (
                        <ListItem button>
                            <NavLink activeClassName="active" to="/account/notifications">
                                My Notifications
                            </NavLink>
                        </ListItem>
                    ) : null}
                </div>
                <div className="accountCnt">
                    <SingleProfile />
                </div>
            </div>
        </DashboardLayout>
    )
}

export default MyAccount
