import React, { useContext, useState, useEffect } from 'react'
import CustomInput from '../../product/components/atoms/Inputs/CustomInput'
import PrimaryButton from '../../product/components/atoms/PrimaryButton'
import '../Login/Login.css'
import { useTranslation } from 'react-i18next'
import { mapData, handleRedirectInternal } from '../../product/common/components'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useHistory, Redirect } from 'react-router-dom'
import AlertContext from '../../product/context/alert/alertContext'
import UserContext from '../../product/context/user/userContext'

const VerifyEmail = (props) => {
    const userContext = useContext(UserContext)
    const alertContext = useContext(AlertContext)
    const { t } = useTranslation()
    const { verifyEmail, responseStatus, clearResponse } = userContext

    const history = useHistory()

    const verify_email = () => {
        const values = {}
        values.user_token = props.match.params.token
        values.status = 'active'
        verifyEmail(values)
    }
    useEffect(() => {
        verify_email()
    }, [])
    return (
        <div>
            <div className="errorPage container d-flex justify-content-between flex-wrap text-center">
                <img className="errorPageImg" src="/assets/svg/mailbox.svg" />
                <div className="errorPageActions w-100"></div>
                {responseStatus && responseStatus.status == 'success' && <Redirect to="/" />}
            </div>
        </div>
    )
}
export default VerifyEmail
