import React, { useState, useContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Button, Drawer, ListItem } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import PrimaryButton from '../../../../product/components/atoms/PrimaryButton'
import './ProductCard.css'
import CommonContext from '../../../../product/context/common/commonContext'
import CustomCommonContext from '../../../../custom/context/common/commonContext'

import { useFormik } from 'formik'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import { dateTimeFormatFunction } from '../../../../product/common/components'
import Timer from '../../../../product/common/timer'
import { dateFormatDay, dateFormatMonth } from '../../../common/components.js'
import AvatarImage from '../../atoms/AvatarImage'

const useStyles = makeStyles({
    fullList: {
        width: 'auto',
    },
})

function AuctionCardGrid(props) {
    const { t } = useTranslation()
    const commonContext = useContext(CommonContext)
    const { setSearchValue } = commonContext
    const customCommonContext = useContext(CustomCommonContext)
    const { allLocations } = customCommonContext
    const [auction, setAuction] = useState({})

    const history = useHistory()
    const classes = useStyles()

    const [state, setState] = useState({
        bottom: false,
    })

    useEffect(() => {
        setAuction(props.data)
    }, [props.data])

    const formik = useFormik({
        initialValues: {
            auctionid: '',
        },
    })

    const auctionRedirect = (id) => {
        history.push({
            pathname: '/liveAuction/' + id,
            state: auction,
        })
        window.scrollTo(0, 0)
    }

    const handleRedirectInternal = (path) => {
        history.push(`/${path}`)
        window.scrollTo(0, 0)
    }

    const handleClose = (state) => {
        state(false)
    }

    const handleOpen = (state) => {
        state(true)
    }

    const toggleDrawer = (anchor, open, data) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return
        }
        if (open) {
            setState({ ...state, [anchor]: open, data: data })
        } else {
            setState({ ...state, [anchor]: open, data: {} })
        }
    }

    return (
        <div className="auctionCardGrid">
            <div className="actnCardTopCntnr d-flex align-items-center">
                <div className="auctionCardLt">
                    <div className="acImg">
                        <AvatarImage data={auction} folder="auction" />
                        <div
                            className="acOverlay cursorDecoy"
                            onClick={() => auctionRedirect(auction.id)}
                        >
                            <span>
                                <h2>{dateFormatDay(auction.date_closed)}</h2>
                                <h4>{dateFormatMonth(auction.date_closed)}</h4>
                            </span>
                            <h5>{auction?.projects_count} Total Lots/Items</h5>
                        </div>
                    </div>
                </div>
                <div className="auctionCardRt text-center">
                    <h2 className="acTtl text-center">{auction.title}</h2>
                    <div className="acTimer">
                        <h6>
                            <span className="material-icons">timer</span>
                        </h6>
                        <p>
                            {auction.date_closed ? (
                                <Timer
                                    date_added={auction.date_added}
                                    date_closed={auction.date_closed}
                                    withText={1}
                                    endText={'Time Left' + ':'}
                                    startText={'Starts In' + ':'}
                                ></Timer>
                            ) : null}
                        </p>
                    </div>
                    <PrimaryButton
                        onClick={() => auctionRedirect(auction.id)}
                        label="View Auction"
                        btnSize="small"
                    />
                </div>
            </div>
            <div className="auctionCardBtm d-flex">
                <div className="acContent w-100">
                    <div className="acMiscInfo">
                        <p>
                            <span className="material-icons">today</span>Bidding Starts:
                        </p>
                        <span>{dateTimeFormatFunction(auction.date_added)}</span>
                    </div>
                    <div className="acMiscInfo">
                        <p>
                            <span className="material-icons">event</span>Bidding Ends:
                        </p>
                        <span>{dateTimeFormatFunction(auction.date_closed)}</span>
                    </div>
                    <div className="acMiscInfo">
                        <p>
                            <span className="material-icons">place</span>Location:
                        </p>
                        <span>
                            {allLocations
                                .filter((value) => auction.location_id === value.id)
                                .map((location) => (
                                    <span>{location.name}</span>
                                ))}
                        </span>
                    </div>
                    <div className="acMiscInfo">
                        <p>
                            <span className="material-icons">gavel</span>Type:
                        </p>
                        <span className="capitalize">{auction.bid_type}</span>
                    </div>
                </div>
            </div>
            <div className="acActBtn">{console.log(auction, 'auctionauction')}</div>
        </div>
    )
}

export default AuctionCardGrid
