import React, { useContext, useEffect, useState } from 'react'
import './Dashboard.css'
import DashboardLayout from './DashboardLayout'
import CheckBox from '../../product/components/atoms/CheckBox'
import CommonContext from '../../custom/context/common/commonContext'
import PrimaryButton from '../../product/components/atoms/PrimaryButton'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { mapData } from '../../product/common/components'
import UserContext from '../../product/context/user/userContext'
import AuthContext from '../../product/context/auth/authContext'
import { useTranslation } from 'react-i18next'
import { ListItem } from '@material-ui/core'
import { NavLink } from 'react-router-dom'
import NotificationComponent from '../../product/components/species/components/user/NotificationComponent'

function Notifications() {
    const { t } = useTranslation()
    const commonContext = useContext(CommonContext)
    const authContext = useContext(AuthContext)

    const userContext = useContext(UserContext)

    const { user, loadUser } = authContext
    const { allNotifications } = commonContext
    const { updatePreference, responseStatus: responseStatusUser } = userContext

    const initialValues = {
        email_prefer: [],
        sms_prefer: [],
    }

    return (
        <DashboardLayout title={'My Notifications'}>
            <div className="dashboardInner notifications">
                <div className="auctionStatus">
                    <ListItem button>
                        <NavLink activeClassName="active" to="/account/profile">
                            Profile
                        </NavLink>
                    </ListItem>

                    <ListItem button>
                        <NavLink activeClassName="active" to="/account/change">
                            Change Password
                        </NavLink>
                    </ListItem>

                    {!user?.admin ? (
                        <ListItem button>
                            <NavLink activeClassName="active" to="/account/notifications">
                                My Notifications
                            </NavLink>
                        </ListItem>
                    ) : null}
                </div>
                <h5 className="dashSubtitle">
                    Bidashift will send emails based on a wide variety of events that may occur as
                    seen below. Some notifications such as Payment information will be sent
                    regardless of your email preference settings.
                </h5>
                <NotificationComponent initialValues={initialValues} />
            </div>
        </DashboardLayout>
    )
}

export default Notifications
