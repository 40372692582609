import React, { useContext, useEffect, useState } from 'react'
import * as Yup from 'yup'
import PostTemplate from './PostTemplate'
import { useHistory } from 'react-router-dom'
import AuctionComponent from '../../../product/components/species/components/auction/AuctionComponent'
import './PostProject.css'
import moment from 'moment'
import { handleRedirectInternal, dateTimeFormatFunction } from '../../../product/common/components'
import SellerAuctionContext from '../../../product/context/seller/auction/auctionContext'
import CustomCommonContext from '../../../custom/context/common/commonContext'
import SettingContext from '../../../product/context/seller/setting/settingContext'
import FormDialog from '../../../custom/components/organisms/Dialog'
import PrimaryButton from '../../../product/components/atoms/PrimaryButton'
import SecondaryButton from '../../../product/components/atoms/SecondaryButton'
import LocationManage from '../Settings/LocationManage'
import { Button } from '@material-ui/core'
import AuthContext from '../../../product/context/auth/authContext'
import SellerBidContext from '../../../product/context/seller/bid/bidContext'

const AuctionDetails = (props) => {
    const history = useHistory()
    const settingContext = useContext(SettingContext)
    const authContext = useContext(AuthContext)
    const sellerBidContext = useContext(SellerBidContext)
    const { bidIncrementAction, responseStatus: responseStatusSellerBid } = sellerBidContext

    const { user, isAuthenticated } = authContext

    const [currentFormik, setCurrentFormik] = useState(null)
    const [locationBody, setLocationBody] = useState([])
    const [subPopup, setSubPopup] = useState(false)
    const [resetValue, setResetValue] = useState({
        isActive: false,
        data: '',
    })
    const [popUpManage, setPopUpManage] = useState({
        popup: false,
        status: 'new',
        id: 0,
    })

    const [manage, setManage] = useState({
        popup: false,
        status: props.match.params.action,
        id: props.match.params.action === 'new' ? 0 : props.match.params.id,
    })

    const sellerAuctionContext = useContext(SellerAuctionContext)
    const { allLocations, allTimeZone, allEventType, membership } = useContext(CustomCommonContext)
    const [auctionID, setAuctionID] = useState(0)
    const { responseStatus: responseStatusSeller } = sellerAuctionContext
    const { table_data, searchTable, bid_increments } = settingContext

    const validationArray = Yup.object({
        title: Yup.string().required('Required!'),
        event_type: Yup.string().required('Required!'),
        location_id: Yup.string().required('Required!'),
        time_zone: Yup.string().required('Required!'),
        description: Yup.string().required('Required!'),
        date_added: Yup.date('Please enter valid Date!')
            .nullable()
            .typeError('Required!')
            .required('Required!'),
        date_closed: Yup.date('Please enter valid Date!')
            .transform((__, value) =>
                value && typeof value === 'string'
                    ? new Date(moment(new Date(value.replace(/-/g, '/'))).subtract(1, 'minutes'))
                    : new Date(moment(value).subtract(1, 'minutes')),
            )
            .min(Yup.ref('date_added'), 'Should be greater than start Time')
            .nullable()
            .typeError('Required!')
            .required('Required!'),
        ending_enable: Yup.boolean(),
        extends_enable: Yup.boolean(),
        ending_items: Yup.number().when('ending_enable', {
            is: (end) => end === 1,
            then: Yup.number().min(0, 'Not valid!').required('Required!'),
        }),
        ending_mins: Yup.number().when('ending_enable', {
            is: (end) => end === 1,
            then: Yup.number().min(0, 'Not valid!').required('Required!'),
        }),
        min_time: Yup.number().when('extends_enable', {
            is: (end) => end === 1,
            then: Yup.number().min(0, 'Not valid!').required('Required!'),
        }),
        extends_time: Yup.number().when('extends_enable', {
            is: (end) => end === 1,
            then: Yup.number().min(0, 'Not valid!').required('Required!'),
        }),
        enable_proxy: Yup.boolean(),
        isname_visible: Yup.boolean(),
        isbidvalue_visible: Yup.boolean(),
        prebid_available: Yup.boolean(),
        organization_id: Yup.array(),
        selltype: Yup.boolean(),
        selectedbuyer: Yup.array().when('selltype', {
            is: (avatar) => avatar,
            then: Yup.array(),
        }),
    })

    const [initialValues, setInitialValues] = useState({
        id: 0,
        avatar: [],
        title: '',
        event_type: '',
        location_id: '',
        time_zone: '',
        description: '',
        date_added: dateTimeFormatFunction(new Date()),
        date_closed: dateTimeFormatFunction(new Date()),
        project_status: 'open',
        ending_enable: false,
        extends_enable: false,
        ending_items: 0,
        ending_mins: 0,
        min_time: 0,
        extends_time: 0,
        enable_proxy: false,
        isname_visible: 0,
        isbidvalue_visible: 0,
        prebid_available: 0,
        organization_id: [],
        selltype: true,
        selectedbuyer: [],
    })

    const handleBack = () => {
        handleRedirectInternal(history, `post_project/posttype`)
    }

    const handleNext = (id) => {
        if (!initialValues.id) {
            let bidIncrements = bid_increments.records.map((increment) => {
                delete increment.id
                let eventType = { ...increment }
                eventType.auction_id = responseStatusSeller.data.auction_id
                return eventType
            })
            bidIncrementAction({ array: true, data: bidIncrements })
        }

        handleRedirectInternal(
            history,
            `post_project/create_lot/${responseStatusSeller.data.auction_id}`,
        )
    }
    useEffect(() => {
        if (table_data && table_data.records) {
            if (table_data.from === 'locations') {
                setLocationBody(
                    table_data.records.length
                        ? table_data.records.filter((element) => {
                              return element.active == 1
                          })
                        : [],
                )
            }
        }
    }, [table_data])

    useEffect(() => {
        if (isAuthenticated) {
            searchTable(
                {
                    page: 1,
                    limit: 20,
                    order: '',
                    orderby: '',
                    table: 'locations',
                    filters: {
                        name: {
                            value: '',
                            type: 'like',
                            field: 'l.name',
                        },
                        organization_id: {
                            value: user.organization_id,
                            type: 'in',
                            field: 'l.organization_id',
                        },
                    },
                },
                'locations',
            )
        }
    }, [isAuthenticated])

    useEffect(() => {
        if (
            membership &&
            !membership.multishift &&
            currentFormik &&
            currentFormik.event_type == 2
        ) {
            setSubPopup(true)
            setResetValue({
                isActive: true,
                data: 1,
            })
        }
    }, [currentFormik, membership])

    const toggleFullScreenPopup = (popup, status, id) => {
        if (popup) {
            setPopUpManage({ popup, status, id })
        } else {
            setPopUpManage({ popup, status: 'new', id: 0 })
        }
    }

    const formFields = {
        data: [
            {
                label: 'Auction Title *',
                type: 'text',
                placeholder: 'Enter your Auction Title',
                class: 'col-12',
                name: 'title',
            },
            {
                label: 'Shift type',
                name: 'event_type',
                placeholder: 'Select Shift',
                type: 'select',
                defaultOptionValue: 'Select a Shift',
                options: allEventType?.map((event) => {
                    let eventType = {}
                    eventType.show = event.description
                    eventType.value = event.id
                    return eventType
                }),
                defaultOptionValue: 'Select a Event',
                class: 'col-12',
            },
            {
                label: 'Location *',
                placeholder: 'Select location',
                type: 'select',
                class: 'col-md-8 col-12',
                options:
                    locationBody && locationBody.length
                        ? locationBody.map((location) => {
                              let locationChanged = {}
                              locationChanged.show = location.name
                              locationChanged.value = location.id
                              return locationChanged
                          })
                        : [],
                defaultOptionValue: 'Select a Location',
                name: 'location_id',
            },
            {
                type: 'misc',
                content: (
                    <Button
                        className="addTempBtn"
                        onClick={() => toggleFullScreenPopup(true, 'new', 0)}
                    >
                        <span className="material-icons-outlined">add_circle_outline</span>Add New
                        Location
                    </Button>
                ),
                label: 'Add location',
                tooltipTitle: 'Add location',
                class: 'col-md-4 col-12 mb-4',
            },
            {
                label: 'Start date *',
                placeholder: 'Enter Start date',
                type: 'datetime',
                disablePast: true,
                class: 'col-md-6 col-12',
                name: 'date_added',
            },
            {
                label: 'End Date *',
                placeholder: 'Enter End Date',
                disablePast: true,
                type: 'datetime',
                class: 'col-md-6 col-12',
                name: 'date_closed',
                minDate: dateTimeFormatFunction(currentFormik?.date_added),
                minDateMessage: `Date should not be before minimal date ${dateTimeFormatFunction(
                    currentFormik?.date_added,
                )}`,
            },
            {
                label: 'Time zone',
                placeholder: 'Select Event',
                name: 'time_zone',
                type: 'select',
                placeholder: 'Select time zone',
                options: allTimeZone?.map((time) => {
                    let timeChanged = {}
                    timeChanged.show = time.description
                    timeChanged.value = time.id
                    return timeChanged
                }),
                class: 'col-md-12 col-12',
            },
            {
                label: 'Description *',
                placeholder: 'Enter Description',
                type: 'ckeditor',
                class: 'col-12 mb-4',
                name: 'description',
            },
            {
                label: 'Enable Staggered Separate Shift Auctions',
                placeholder: 'Enable Staggered Separate Shift Auctions',
                type: 'checkbox',
                class: 'col-sm-6 col-12',
                name: 'ending_enable',
                disabled: currentFormik?.event_type == 2 ? false : true,
            },
            {
                label: 'Items',
                placeholder: 'Enable Items',
                type: 'number',
                disabled: currentFormik?.ending_enable ? false : true,
                class: 'col-md-3 col-sm-6 col-12',
                name: 'ending_items',
                // tooltiptitle: 'Number of Shits',
            },
            {
                label: 'Minutes',
                placeholder: 'Enable Minutes',
                type: 'number',
                disabled: currentFormik?.ending_enable ? false : true,
                class: 'col-md-3 col-sm-6 col-12',
                name: 'ending_mins',
            },
            {
                label: 'Enable Extended Auction (Anti Sniping) ',
                placeholder: 'Enable Extended Auction (Anti Sniping) ',
                type: 'checkbox',
                class: 'col-sm-12 col-12',
                disabled: membership?.extend_auction ? false : true,
                name: 'extends_enable',
            },
            {
                label: 'Enable with XX minutes remaining in auction',
                placeholder: 'Minimum Time',
                type: 'number',
                disabled: currentFormik?.extends_enable ? false : true,
                class: 'col-md-6 col-sm-6 col-12',
                name: 'min_time',
            },
            {
                label: 'Extend auction by XX minutes',
                placeholder: 'Extended Time',
                type: 'number',
                // disabled: formik.values.extends_enable ? false : true,
                disabled: currentFormik?.extends_enable ? false : true,
                class: 'col-md-6 col-sm-6 col-12',
                name: 'extends_time',
            },
        ],
    }

    const handleSingleAuctionTemplate = (single_auctiontemplate) => {
        if (single_auctiontemplate) {
            if (single_auctiontemplate.record && manage.status === 'template' && manage.id > 0) {
                const auction = single_auctiontemplate.record
                setInitialValues({
                    id: 0,
                    title: auction.title ? auction.title : '',
                    event_type: auction.event_type ? auction.event_type : '',
                    location_id: auction.location_id ? auction.location_id : '',
                    time_zone: auction.time_zone ? auction.time_zone : '',
                    description: auction.description ? auction.description : '',
                    ending_enable: auction.ending_enable === 1 ? true : false,
                    date_added: dateTimeFormatFunction(new Date()),
                    date_closed: dateTimeFormatFunction(new Date()),
                    ending_items: auction.ending_items ? auction.ending_items : 0,
                    ending_mins: auction.ending_mins ? auction.ending_mins : 0,
                    enable_proxy: auction.enable_proxy === 1 ? true : false,
                    isname_visible: auction.isname_visible ? auction.isname_visible : 0,
                    isbidvalue_visible: auction.isbidvalue_visible ? auction.isbidvalue_visible : 0,
                    prebid_available: auction.prebid_available ? auction.prebid_available : 0,
                    extends_enable: auction.extends_enable
                        ? auction.extends_enable === 1
                            ? true
                            : false
                        : false,
                    min_time: auction.min_time ? auction.min_time : 0,
                    extends_time: auction.extends_time ? auction.extends_time : 0,
                    // formik.values.organization_id = auction.organization_id
                    //     ? auction.organization_id.toString().split(',').map(Number)
                    //     : []
                    selltype: auction.selltype === '1' ? true : false,
                    selectedbuyer: auction.selectedbuyer
                        ? auction.selectedbuyer.split(',').map(Number)
                        : [],
                    project_status: auction.project_status ? auction.project_status : 'open',
                    avatar: auction.avatar ? auction.avatar : [],
                })
            }
        }
    }

    const handleSingleAuctionEdit = (single_auction) => {
        if (single_auction) {
            if (single_auction.record && manage.status === 'edit' && manage.id > 0) {
                const auction = single_auction.record
                setInitialValues({
                    id: manage.id,
                    title: auction.title ? auction.title : '',
                    event_type: auction.event_type ? auction.event_type : '',
                    location_id: auction.location_id ? auction.location_id : '',
                    time_zone: auction.time_zone ? auction.time_zone : '',
                    description: auction.description ? auction.description : '',
                    ending_enable: auction.ending_enable === 1 ? true : false,
                    date_added: auction.date_added
                        ? dateTimeFormatFunction(auction.date_added)
                        : dateTimeFormatFunction(new Date()),
                    date_closed: auction.date_closed
                        ? dateTimeFormatFunction(auction.date_closed)
                        : dateTimeFormatFunction(new Date()),
                    ending_items: auction.ending_items ? auction.ending_items : 0,
                    ending_mins: auction.ending_mins ? auction.ending_mins : 0,
                    enable_proxy: auction.enable_proxy === 1 ? true : false,
                    extends_enable: auction.extends_enable
                        ? auction.extends_enable === 1
                            ? true
                            : false
                        : false,
                    min_time: auction.min_time ? auction.min_time : 0,
                    extends_time: auction.extends_time ? auction.extends_time : 0,
                    isname_visible: auction.isname_visible ? auction.isname_visible : 0,
                    isbidvalue_visible: auction.isbidvalue_visible ? auction.isbidvalue_visible : 0,
                    prebid_available: auction.prebid_available ? auction.prebid_available : 0,
                    // formik.values.organization_id = auction.organization_id
                    //     ? auction.organization_id.toString().split(',').map(Number)
                    //     : []
                    selltype: auction.selltype === '1' ? true : false,
                    selectedbuyer: auction.selectedbuyer
                        ? auction.selectedbuyer.split(',').map(Number)
                        : [],
                    project_status: auction.project_status ? auction.project_status : 'open',
                    avatar: auction.avatar ? auction.avatar : [],
                })
            }
        }
    }

    useEffect(() => {
        if (responseStatusSeller) {
            if (responseStatusSeller.status === 'success') {
                if (responseStatusSeller.from === 'auctionAction') {
                    setAuctionID(responseStatusSeller.data.auction_id)
                }
            }
        }
    }, [responseStatusSeller])

    const additionalFieldUpdate = (payload) => {
        setCurrentFormik(payload)
    }

    return (
        <>
            <>
                <PostTemplate stepCount={1}>
                    <div className="stpInrDiv">
                        <h4>Fill in the auction details here</h4>
                        <AuctionComponent
                            initialValues={initialValues}
                            validationArray={validationArray}
                            handleSingleAuctionTemplate={handleSingleAuctionTemplate}
                            handleSingleAuctionEdit={handleSingleAuctionEdit}
                            data={manage}
                            formFields={formFields}
                            additionalfieldUpdate={additionalFieldUpdate}
                            resetValue={resetValue}
                            setResetValue={setResetValue}
                            onSuccess={handleNext}
                            onFormBack={manage.status !== 'edit' ? handleBack : null}
                            SubmitBtnLabel="Next"
                            BackBtnLabel="Previous"
                        />
                    </div>
                </PostTemplate>
                <FormDialog open={subPopup} openFunction={() => setSubPopup(false)}>
                    <div className="upgrdPup">
                        <p className="text-center mb-1">
                            <span className="material-icons">warning</span>
                        </p>
                        <p className="text-center">
                            Gold plan needed to enable multishift, please upgrade your plan
                        </p>
                        <div className="d-flex align-items-center justify-content-end">
                            <SecondaryButton
                                label="Back to single shift"
                                onClick={() => {
                                    setSubPopup(false)
                                }}
                            />
                            <PrimaryButton
                                btnSize="ml-2"
                                label="Upgrade Plan"
                                onClick={() => (
                                    handleRedirectInternal(history, 'subscription'),
                                    setSubPopup(false)
                                )}
                            />
                        </div>
                    </div>
                </FormDialog>
            </>
            <LocationManage toggleFullScreenPopup={toggleFullScreenPopup} manage={popUpManage} />
        </>
    )
}

export default AuctionDetails
