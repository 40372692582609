import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import StepConnector from '@material-ui/core/StepConnector'
import './PostProject.css'

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    backButton: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}))

const ColorlibConnector = withStyles({
    alternativeLabel: {
        top: 22,
    },
})(StepConnector)

const useColorlibStepIconStyles = makeStyles({
    root: {
        backgroundColor: '#E6E9FF',
        zIndex: 1,
        color: 'var(--primColor)',
        width: 45,
        height: 45,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    active: {
        background: 'white',
        border: '2px solid var(--primColor)',
        color: 'var(--primColor)',
        fontWeight: '600',
    },
    completed: {
        background: 'var(--primColor)',
        color: 'white',
    },
})

function ColorlibStepIcon(props) {
    const classes = useColorlibStepIconStyles()
    const { active, completed } = props

    const icons = {
        1: '01',
        2: '02',
        3: '03',
        4: '04',
        5: '05',
        6: '06',
    }

    return (
        <div
            className={clsx(classes.root, {
                [classes.active]: active,
                [classes.completed]: completed,
            })}
        >
            {icons[String(props.icon)]}
        </div>
    )
}

ColorlibStepIcon.propTypes = {
    /**
     * Whether this step is active.
     */
    active: PropTypes.bool,
    /**
     * Mark the step as completed. Is passed to child components.
     */
    completed: PropTypes.bool,
    /**
     * The label displayed in the step icon.
     */
    icon: PropTypes.node,
}

function getSteps() {
    return ['', '', '', '', '', '']
}

const PostTemplate = (props) => {
    const classes = useStyles()
    const steps = getSteps()

    return (
        <div className="productPosting">
            <div className="hdrCntnr">
                <h4>Create an auction</h4>
            </div>
            <div className={classes.root}>
                <Stepper activeStep={props.stepCount} alternativeLabel>
                    {steps.map((label) => (
                        <Step key={label} connector={<ColorlibConnector />}>
                            <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
                <div className="stepBodyWrapper">
                    {props.children}
                    <img src="/assets/svg/postimage.svg" className="postImg" />
                </div>
            </div>
        </div>
    )
}

export default PostTemplate
