import React, { useContext, useEffect, useState } from 'react'
import './FilterPanel.css'
import { withStyles } from '@material-ui/core/styles'
import MuiAccordion from '@material-ui/core/Accordion'
import MuiAccordionSummary from '@material-ui/core/AccordionSummary'
import MuiAccordionDetails from '@material-ui/core/AccordionDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import RadioBox, { GreenRadio } from '../../../../product/components/atoms/RadioBox'
import ProductContext from '../../../../product/context/product/productContext'
import { useTranslation } from 'react-i18next'
import CustomCommonContext from '../../../../custom/context/common/commonContext'
import ProductCommonContext from '../../../../product/context/common/commonContext'
import { useFormik } from 'formik'
import { Button } from '@material-ui/core'
import CheckBox from '../../../../product/components/atoms/CheckBox'

const Accordion = withStyles({
    root: {
        border: '1px solid rgba(0, 0, 0, .125)',
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
})(MuiAccordion)

const AccordionSummary = withStyles({
    root: {
        backgroundColor: '#fff',
        marginBottom: -1,
        minHeight: 56,
        '&$expanded': {
            minHeight: 50,
        },
    },
    content: {
        '&$expanded': {
            margin: '12px 0',
        },
    },
    expanded: {},
})(MuiAccordionSummary)

const AccordionDetails = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiAccordionDetails)

const FilterPanel = (props) => {
    const { t } = useTranslation()
    const customCommonContext = useContext(CustomCommonContext)
    const productCommonContext = useContext(ProductCommonContext)

    const productContext = useContext(ProductContext)
    const { active_sorts, getAllActiveSorts } = productContext
    const { allCategory, allCondition, allshipment_types, allLocations } = customCommonContext
    const { setSearchValue } = productCommonContext

    const [expanded, setExpanded] = React.useState('panel0')
    const [nsearch, setNsearch] = useState({
        category: {
            value: [],
            type: 'array',
            field: 'it.categoryTypeId',
        },
        searchbar: {
            value: '',
            type: 'like',
            field: 'p.title', //,p.desc_proc',
        },
        condition: {
            value: [],
            type: 'array',
            field: 'it.conditionTypeId',
        },
        subCategory: {
            value: [],
            type: 'array',
            field: 'it.subCategoryTypeId',
        },
        third_sub_category: {
            value: [],
            type: 'array',
            field: 'it.third_sub_categoryID',
        },
        local_pickup: {
            value: [],
            type: 'array',
            field: 'p.local_pickup_check',
        },
        shipping: {
            value: [],
            type: 'array',
            field: 'p.shipping_check',
        },
        location: {
            value: [],
            type: 'array',
            field: 'p.location_id',
        },
        price: {
            value: 0,
            type: 'greaterequal',
            field: 'p.wprice',
        },
        auctionid: {
            value:
                props.search.filters.auctionid.value[0] != undefined
                    ? props.search.filters.auctionid.value
                    : [],
            type: 'array',
            field: 'p.auctionid',
        },
    })

    const sortCategoryAlphabetical = (a, b) => {
        var nameA = a.description.toUpperCase() // ignore upper and lowercase
        var nameB = b.description.toUpperCase() // ignore upper and lowercase
        if (nameA < nameB) {
            return -1
        }
        if (nameA > nameB) {
            return 1
        }

        // names must be equal
        return 0
    }

    useEffect(() => {
        getAllActiveSorts({})
        setFilter((prevState) => ({ ...prevState, allCategories: carte }))
        let carte = allCategory
            .filter((main) => {
                if (
                    //console.log(main, 'mainmain'),
                    main.level.toString() === '0' &&
                    main.level_id.toString() === '0' &&
                    main.active === 1
                ) {
                    return true
                }
            })
            .sort(sortCategoryAlphabetical)
        setFilter((prevState) => ({ ...prevState, allCategories: carte }))

        let subcart = allCategory
            .filter((subcat) => {
                if (subcat.level > 0 && subcat.active === 1 && subcat.level_id.toString() === '0') {
                    return true
                }
            })
            .sort(sortCategoryAlphabetical)
        setFilter((prevState) => ({ ...prevState, sub_category: subcart }))

        let moreOption = allCategory
            .filter((subcat) => {
                if (subcat.level > 0 && subcat.level_id > 0 && subcat.active === 1) {
                    return true
                }
            })
            .sort(sortCategoryAlphabetical)

        setFilter((prevState) => ({ ...prevState, more_option: moreOption }))

        if (props.from == 1 || props.from == 2) {
            setNsearch({ ...nsearch, ...props.search.filters })
        }
    }, [])

    useEffect(() => {
        let carte = allCategory
            .filter((main) => {
                if (
                    main.level.toString() === '0' &&
                    main.level_id.toString() === '0' &&
                    main.active === 1
                ) {
                    return true
                }
            })
            .sort(sortCategoryAlphabetical)

        setFilter((prevState) => ({ ...prevState, allCategories: carte }))

        let subcart = allCategory
            .filter((subcat) => {
                if (subcat.level > 0 && subcat.active === 1 && subcat.level_id.toString() === '0') {
                    return true
                }
            })
            .sort(sortCategoryAlphabetical)
        setFilter((prevState) => ({ ...prevState, sub_category: subcart }))

        let moreOption = allCategory
            .filter((subcat) => {
                if (subcat.level > 0 && subcat.level_id > 0 && subcat.active === 1) {
                    return true
                }
            })
            .sort(sortCategoryAlphabetical)
        setFilter((prevState) => ({ ...prevState, more_option: moreOption }))
    }, [allCategory])

    const [filter, setFilter] = useState({
        allCategories: [],
        sub_category: [],
        more_option: [],
    })

    const formik = useFormik({
        initialValues: {
            limit: 12,
            page: 1,
            orderby: 'p.id, desc',
            order: '',
            filters: nsearch,
        },
        enableReinitialize: true,
    })
    const sortValues = props.sorts

    useEffect(() => {
        if (props.search) {
            let searchValue = props.search.filters
            if (searchValue?.category?.value.length > 0) {
                formik.values.filters.category.value = searchValue.category.value.map((cat) => {
                    return cat.toString()
                })
            } else {
                formik.values.filters.category.value = []
            }
            if (searchValue?.third_sub_category?.value.length > 0) {
                formik.values.filters.third_sub_category.value =
                    searchValue.third_sub_category.value.map((cat) => {
                        return cat.toString()
                    })
            } else {
                formik.values.filters.condition.value = []
            }
            if (searchValue?.location?.value.length > 0) {
                formik.values.filters.location.value = searchValue.location.value.map((cat) => {
                    return cat.toString()
                })
            } else {
                formik.values.filters.location.value = []
            }
            if (searchValue?.price?.value) {
                formik.values.filters.price.value = searchValue.price.value
            } else {
                formik.values.filters.price.value = 0
            }
        }
    }, [props.search])

    useEffect(() => {
        if (formik.values.filters.category.value.length == 0) {
            let subcart = allCategory.filter((subcat) => {
                if (subcat.level > 0 && subcat.active === 1 && subcat.level_id.toString() === '0') {
                    return true
                }
            })
            setFilter((prevState) => ({ ...prevState, sub_category: subcart }))

            let moreOption = allCategory.filter((subcat) => {
                if (subcat.level > 0 && subcat.active == 1 && subcat.level_id > 0) {
                    return true
                }
            })
            console.log(moreOption, 'moreOption1')
            setFilter((prevState) => ({ ...prevState, more_option: moreOption }))
        }
        if (formik.values.filters.subCategory.value.length == 0) {
            let moreOption = allCategory.filter((subcat) => {
                if (subcat.level > 0 && subcat.active == 1 && subcat.level_id > 0) {
                    return true
                }
            })
            console.log(moreOption, 'moreOption2')
            setFilter((prevState) => ({ ...prevState, more_option: moreOption }))
        }
        if (formik.values.filters.category.value.length > 0) {
            let subcart = allCategory.filter((subcat) => {
                if (
                    subcat.level > 0 &&
                    subcat.active === 1 &&
                    subcat.level_id.toString() === '0' &&
                    formik.values.filters.category.value.includes(subcat.level.toString())
                ) {
                    return true
                }
            })
            setFilter((prevState) => ({ ...prevState, sub_category: subcart }))
            let moreOption = allCategory.filter((subcat) => {
                if (
                    subcat.level > 0 &&
                    subcat.level_id > 0 &&
                    subcat.active == 1 &&
                    formik.values.filters.category.value.includes(subcat.level.toString())
                ) {
                    return true
                }
            })
            setFilter((prevState) => ({ ...prevState, more_option: moreOption }))
        }
        if (formik.values.filters.subCategory.value.length > 0) {
            let moreOption = allCategory.filter((moreOpt) => {
                if (
                    moreOpt.level > 0 &&
                    moreOpt.level_id > 0 &&
                    moreOpt.active == 1 &&
                    //  formik.values.filters.category.value.includes(subcat.level.toString()) &&
                    formik.values.filters.subCategory.value.includes(moreOpt.level_id.toString()) &&
                    moreOpt.active === 1
                ) {
                    return true
                }
            })
            console.log(moreOption, 'moreOption3')
            setFilter((prevState) => ({ ...prevState, more_option: moreOption }))
        }
    }, [formik.values.filters.category, formik.values.filters.subCategory])

    useEffect(() => {
        if (props.from === 1) {
            const formikValues = formik.values

            props.setSearch({ ...formikValues })
        } else if (props.from === 2) {
            // const searchConst = props.search
            const formikValues = formik.values

            props.setSearch({ ...formikValues })
        } else if (props.from === 1) {
            if (
                formik.values.filters.category.value.length > 0 ||
                formik.values.filters.condition.value.length > 0 ||
                formik.values.filters.location.value.length > 0 ||
                formik.values.filters.price.value !== 0
            ) {
                props.setSearch({ ...formikValues })
            }
        } else {
        }
    }, [formik.values])

    let sortedCategories = []
    let sortedConditions = []
    let sortedCountries = []
    if (sortValues) {
        if (sortValues.categorysort) {
            sortedCategories = allCategory.filter((inner) =>
                sortValues.categorysort.includes(inner.id.toString()),
            )
        } else {
            sortedCategories = allCategory
        }
        if (sortValues.conditionsort) {
            sortedConditions = allCondition.filter((inner) =>
                sortValues.conditionsort.includes(inner.id.toString()),
            )
        } else {
            sortedConditions = allCondition
        }

        if (sortValues.locationsort) {
            sortedCountries = allLocations.filter((inner) =>
                sortValues.locationsort.includes(inner.id.toString()),
            )
        } else {
            sortedCountries = allLocations
        }
    } else {
        sortedCategories = allCategory
        sortedConditions = allCondition
        sortedCountries = allLocations
    }

    const filterValues = [
        {
            title: 'Category',
            type: 'check',
            name: 'category',
            //  onchange: formik.handleChange,
            item: filter.allCategories,
            // allCategory.filter((main) => {
            //     if (
            //         //console.log(main, 'mainmain'),
            //         main.level === 0 &&
            //         (active_sorts && active_sorts.sorts && active_sorts.sorts.sortcategoryTypes
            //             ? active_sorts.sorts.sortcategoryTypes.includes(main.id.toString())
            //             : true)
            //     ) {
            //         return true
            //     }
            // }),
        },
        {
            title: 'Sub Category',
            type: 'check',
            name: 'subCategory',
            item: filter.sub_category,
        },
        {
            title: 'More Options',
            type: 'check',
            name: 'third_sub_category',
            item: filter.more_option,
            // allCategory.filter((subcat) => {
            //     if (
            // subcat.id  &&
            // (active_sorts && active_sorts.sorts && active_sorts.sorts.sortsubCategoryTypes
            //     ? active_sorts.sorts.sortsubCategoryTypes.includes(subcat.id.toString())
            //     : true) &&
            // formik &&
            // formik.values.filters &&
            // formik.values.filters.category.value &&
            // formik.values.filters.category.value.length
            //     ? formik.values.filters.category.value.includes(
            //           subcat.level_id.toString(),
            //       ) &&
            //       formik.values.filters.category.value.includes(subcat.level.toString()) &&
            //       formik.values.filters.subCategory.value.includes(
            //           subcat.level_id.toString(),
            //       )
            //     : false
            //         formik.values.filters.category.value.includes(subcat.level.toString()) &&
            //         formik.values.filters.subCategory.value.includes(subcat.level_id.toString())
            //     ) {
            //         return true
            //     }
            // }),
        },
        {
            title: 'Shipping Type',
            type: 'different',
            name: 'shipType',
            item: [
                { id: 1, name: 'local_pickup', description: 'Local Pickup' },
                { id: 1, name: 'shipping', description: 'Shipping' },
            ],
        },
        {
            title: 'Price',
            type: 'radio',
            name: 'price',
            item: [
                { id: 0, description: 'All' },
                { id: 10, description: 'USD $10 & UP' },
                { id: 100, description: 'USD $100 & UP' },
                { id: 500, description: 'USD $500 & UP' },
                { id: 1000, description: 'USD $1000 & UP' },
                { id: 5000, description: 'USD $5000 & UP' },
            ],
        },
        // {
        //     title: t('conditions'),
        //     type: 'check',
        //     name: 'condition',
        //     item: allCondition,
        // },
    ]

    const handleChange = (panel) => (event, newExpanded) => {
        if (panel === expanded && !newExpanded) {
            setExpanded(newExpanded ? panel : false)
        } else {
            setExpanded(newExpanded ? panel : false)
        }
    }
    if (location.pathname === '/search') {
        filterValues.push({
            title: 'Location',
            type: 'check',
            name: 'location',
            visible: true,
            item: allLocations.filter((main) => {
                if (
                    active_sorts && active_sorts.sorts && active_sorts.sorts.sortlocations
                        ? active_sorts.sorts.sortlocations.includes(main.id.toString())
                        : true
                ) {
                    return true
                }
            }),
        })
    }

    return (
        <div className="filterPanel">
            <div className="d-flex justify-content-between align-items-center filterTitleCnt">
                <h4 className="fpTitle">{'Filters'}</h4>
                {props.clearSearch && (
                    <Button onClick={() => props.clearSearch()} className="filterClear">
                        <span className="material-icons">cancel</span>
                        {'Clear All'}
                    </Button>
                )}
            </div>
            <div className="filterAcc">
                {filterValues.map((data, index) => (
                    <Accordion
                        square
                        key={index}
                        expanded={
                            expanded === `panel${index}`
                            // || formik.values[data.name].length > 0
                        }
                        onChange={handleChange(`panel${index}`)}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1d-content"
                            id={`panel${index}d-header`}
                        >
                            <h6 className="accTitle">{data.title}</h6>
                        </AccordionSummary>
                        <AccordionDetails>
                            {/*console.log(data, 'dataaa')*/}
                            {data.item && data.item.length > 0 ? (
                                <div className="filterCheck">
                                    {data.type === 'check' ? (
                                        data.item.map((d, i) => (
                                            <>
                                                <CheckBox
                                                    name={`filters.${data.name}.value`}
                                                    label={
                                                        data.name === 'location'
                                                            ? `${d.name}`
                                                            : d.description
                                                    }
                                                    checked={
                                                        formik.values.filters[
                                                            data.name
                                                        ].value.indexOf(d.id.toString()) !== -1
                                                            ? true
                                                            : false
                                                    }
                                                    value={d.id.toString()}
                                                    onChange={formik.handleChange}
                                                />
                                            </>
                                        ))
                                    ) : data.type === 'different' ? (
                                        data.item.map((d, i) => (
                                            <>
                                                <CheckBox
                                                    name={`filters.${d.name}.value`}
                                                    label={
                                                        data.name === 'location'
                                                            ? `${d.name}`
                                                            : d.description
                                                    }
                                                    checked={
                                                        formik.values.filters[d.name].value.indexOf(
                                                            d.id.toString(),
                                                        ) !== -1
                                                            ? true
                                                            : false
                                                    }
                                                    value={d.id.toString()}
                                                    onChange={formik.handleChange}
                                                />
                                            </>
                                        ))
                                    ) : (
                                        <>
                                            <RadioBox
                                                items={data.item}
                                                value={formik.values.filters[data.name].value}
                                                onChange={formik.handleChange}
                                                name={`filters.${data.name}.value`}
                                                int={1}
                                            />
                                        </>
                                    )}
                                </div>
                            ) : (
                                <small>{`${'No'} ${data.title} ${'Found'}`}</small>
                            )}
                        </AccordionDetails>
                    </Accordion>
                ))}
            </div>
        </div>
    )
}
export default FilterPanel
