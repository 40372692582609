import React from 'react'
import '../Login/Login.css'
import * as Yup from 'yup'
import { Link, useHistory } from 'react-router-dom'
import { LOGO } from '../../Utils'
import RegistrationLayout from '../../custom/components/templates/RegistrationLayout'
import ForgetPasswordComponent from '../../product/components/species/components/user/ForgetPasswordComponent'

const ForgotPassword = () => {
    const validationArray = Yup.object({
        email: Yup.string().email('Invalid Email Format').required('Required'),
    })

    const initialValues = {
        email: '',
    }

    const forgotValues = {
        data: [
            {
                label: 'Email Address',
                name: 'email',
                type: 'email',
                placeholder: 'Enter Your Email Address',
                class: 'col-12',
                autoFocus: true,
            },
        ],
    }

    return (
        <RegistrationLayout>
            <div className="login forgotPassword">
                <Link to="/">
                    <img className="brandLogo" src={LOGO} alt={'Bid A Shift Logo'} />
                </Link>
                <div className="container">
                    <div className="d-flex justify-content-center align-items-start">
                        <div className="loginLt">
                            <h2>Forgot Password</h2>
                            <p>
                                Please enter your registered email address and we will send you a
                                password reset link shortly.
                            </p>
                            <ForgetPasswordComponent
                                initialValues={initialValues}
                                validationSchema={validationArray}
                                formFields={forgotValues}
                                SubmitBtnLabel="Send Reset Password Link"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </RegistrationLayout>
    )
}
export default ForgotPassword
